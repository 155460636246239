import React, {useEffect, useState} from "react";
import {Load, SkinnyLoad} from "../interfaces/Load";
import {useParams} from "react-router-dom";
import {fetchSuggestedCarrierForLoad, getLoad, openLoadsByLane} from "../service/loads";
import CarrierList from "./CarrierList";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import CarrierDetailsContainer from "./CarrierDetailsContainer";
import "./OpenLoads.scss";
import ProcurementBreadcrumbs from "./ProcurementBreadcrumbs";
import LoadActions from "./LoadActions";
import LaneContainer from "./LaneContainer";
import Loading from "../common/Loading";
import {useInterval} from "../util/hooks";
import {
    JETPACK_CARRIER_LOOKUP,
    JETPACK_OPEN_CARRIER_CONTACT_PANEL,
    postUsageEvent,
    sendCarrierUsageEvent
} from "../service/events";

const delay = 20000;

/**
 * Manages the display and interaction within a lane for an open load.
 *
 * Display items also shown: workflow breadcrumbs (lane and carrier if selected),
 * and the details about the load/lane.
 *
 * Will also show the recommended carrier list or the selected carrier.
 */
const OpenLoadContainer = () => {

    // current load and open loads for the lane
    const [load, setLoad] = useState<Load>();
    const [loadsInLane, setLoadsInLane] = useState<SkinnyLoad[]>([]);

    // currently selected carrier (if one is selected)
    const [carrier, setCarrier] = useState<SuggestedCarrier>();

    // available when a carrier is selected
    const [contactCarrierPanel, setContactCarrierPanel] = useState<boolean>(false);
    // available when in the carrier list section
    const [carrierLookupPanel, setCarrierLookupPanel] = useState<boolean>(false);

    // rather than having constructors with option load and carriers it will retrieve them
    // from the URL.  May want to consider having something else process these and pass them
    // in to keep this sort of dependency out of the component.
    //
    let {loadId}: { loadId?: string | undefined } = useParams();
    let {carrierId}: { carrierId?: string | undefined } = useParams();

    useEffect(() => {
        if (loadId) {
            getLoad(loadId).then(load => {
                openLoadsByLane(load.stops[0].address, load.stops[1].address)
                    .then(lane => {
                        setLoadsInLane(lane as SkinnyLoad[]);
                        setLoad(load);
                    });
            });

            // need to clear the carrier if no id is provided - required to clear the
            // breadcrumbs and other data when the user switches back to the carrier lists
            if (carrierId) {
                fetchSuggestedCarrierForLoad(loadId, carrierId).then(setCarrier);
            } else {
                setCarrier(undefined);
            }
        }
    }, [loadId, carrierId]);

    // activity event tracking
    useInterval(async () => {
        if (loadId && carrierId) {
            sendCarrierUsageEvent(carrierId);
        }
    }, delay);

    const contactCarrier = () => {
        if(loadId && carrierId) {
            postUsageEvent({
                type: JETPACK_OPEN_CARRIER_CONTACT_PANEL,
                loadId: parseInt(loadId),
                carrierId: parseInt(carrierId),
            });
        }
        setContactCarrierPanel(true);
    }

    const carrierLookup = () => {
        // if false, we're going to make it visible
        if(!carrierLookupPanel) {
            postUsageEvent({
                type: JETPACK_CARRIER_LOOKUP,
                loadId: loadId ? parseInt(loadId) : undefined,
                carrierId: carrierId ? parseInt(carrierId) : undefined
            });
        }

        setCarrierLookupPanel(!carrierLookupPanel);
    }

    const endContactCarrier = () => {
        setContactCarrierPanel(false);
    }

    // easier to move the more complicated logic of what should be displayed
    // to this inner component
    const CarrierPanelOrList = () => {
        if(loadId && carrierId) {
            if(carrier && load) {
                return <div className={"container-carrier-details"}>
                    <CarrierDetailsContainer load={load}
                                             carrier={carrier}
                                             openContactPanel={contactCarrierPanel}
                                             onContactComplete={endContactCarrier}/>
                </div>;
            } else {
                return <Loading />
            }
        }

        if(loadId) {
            if(load) {
                return <CarrierList load={load}
                             carrierLookupPanel={carrierLookupPanel}
                             carrierLookupFinished={() => setCarrierLookupPanel(false)}/>
            } else {
                return <Loading />
            }
        }

        // technically this is an error - need error panel?
        return null;
    }

    // only show once the load has been loaded
    if (load) {
        return (
            <div className={"open-load-container"}>
                <div className={"selected-load-container"}>
                    <ProcurementBreadcrumbs load={load}
                                            carrier={carrier?.carrier}
                                            onContactCarrier={contactCarrier}
                                            contactCarrierOpen={contactCarrierPanel}
                                            onCarrierLookup={carrierLookup}
                                            carrierLookupOpen={carrierLookupPanel}
                    />
                    <LoadActions load={load}/>
                    <CarrierPanelOrList />
                    <div className={"load-details"}>
                        <LaneContainer load={load} loadsInLane={loadsInLane}/>
                    </div>
                </div>
            </div>
        );
    }

    // add in activity loading indicator - needs some sort of animation
    return (<div>Loading Load...</div>);
}

export default OpenLoadContainer;
