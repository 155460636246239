import {SkinnyLoad} from "../interfaces/Load";
import {styled} from '@mui/material/styles';
import {DRAYAGE, DRY_VAN, REEFER} from "../constants/PayloadType";
import {Chip} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import React from "react";
import "./TruckType.scss";

const StyledChip = styled(Chip)`
  cursor: pointer;
  margin-left: 0.5em;
  max-height: 1.4em;

  &:first-of-type {
    margin-left: 5px;
  }
`

export const TruckType = ({task}: { task: SkinnyLoad }) => {


    const getTruckType = (task: SkinnyLoad): string => {
        switch (task.payloadType) {
            case DRY_VAN:
                return "Van";
            case REEFER:
                return "Reefer";
            case DRAYAGE:
                return "Drayage";
            default:
                return "Other";
        }
    };

    const getTruckThemeColor = (task: SkinnyLoad) => {
        switch (task.payloadType) {
            case DRY_VAN:
                return "default";
            case REEFER:
                return "primary";
            case DRAYAGE:
                return "secondary";
            default:
                return "default";
        }
    };

    return (
        <StyledChip icon={<LocalShippingIcon/>}
                    size="small"
                    color={getTruckThemeColor(task)}
                    label={getTruckType(task)}/>
    );
};
