import React, {useEffect, useState} from "react";
import {Facility, FacilityContact, FacilityHour} from "../interfaces/Facility";
import {Alert, Fab, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import {SuccessActionNotification} from "../common/SuccessActionNotification";
import AddIcon from "@mui/icons-material/Add";
import {getFacilityContacts, getFacilityHours} from "./facility-services";
import MailToLink from "../common/MailToLink";
import CallTelLink from "../common/CallTelLink";
import NoContentPanel from "../common/NoContentPanel";
import AddOrEditFacilityContact from "./AddOrEditFacilityContact";

type ContactProps = {
    facility: Facility;
}

const FacilityContacts = ({facility}: ContactProps) => {

    const [contacts, setContacts] = useState<FacilityContact[]>([]);
    const [contactSaved, setContactSaved] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selectedContact, setSelectedContact] = useState<FacilityContact>();
    const [hours, setHours] = useState<FacilityHour[]>([]);
    const shifts = [{id:0, shift: "FIRST", timing: "(7am-3pm)"}, {id:1, shift: "SECOND", timing: "(3pm- 11pm)"}, {id:2, shift: "THIRD", timing: "(11pm-7am)"}]

    useEffect(() => {
        getFacilityContacts(facility).then(data => {
            setContacts(data);
        });
        getFacilityHours(facility).then(setHours);
    }, [facility]);

    const addContact = () => {
        setSelectedContact(undefined);
        setEdit(true);
    }

    function editContact(contact: FacilityContact) {
        setSelectedContact(contact);
        setEdit(true);
    }

    function finishedEdit() {
        setEdit(false);
        getFacilityContacts(facility).then(data => {
            setContacts(data);
        });
    }

    const FacilityContactsList = () => {
        if (contacts.length === 0) {
            return <NoContentPanel>
                No Contacts Yet
                <div className={"clickable"} onClick={addContact}>Add One</div>
            </NoContentPanel>
        }

        return <List>
            
            <Alert severity="warning">Shift Timings<br />  
            {shifts.map(st => { 
                
                return <>
                <b>{st.shift}</b>: {st.timing},&nbsp;
                </>
            })}

            </Alert>
            {contacts?.map(contact => <ListItemButton key={contact.id} onClick={() => editContact(contact)}>
                <ListItemText>
                    <div className={"contact-details"}>
                        <div className={"name"}>{contact.firstName} {contact.lastName}
                            {contact.isDefault? 
                                contact.isDefault === true ?
                                <Typography 
                                className={"add-contact-icon default-contact"}
                                color={"secondary"}
                                >Default Contact</Typography>   
                                : "" : ""
                            }
                        </div>
                        <div className={"details"}>
                            {contact.role ? <div className={"role"}>{contact.role}</div> : null}
                            {contact.email ?
                                <div className={"email"}><MailToLink email={contact.email}>{contact.email}</MailToLink>
                                </div> : null}
                            {contact.phone ? <div className={"phone"}><CallTelLink
                                phone={contact.phone}>{contact.phone}</CallTelLink></div> : null}
                            <div className={"shipper"}>{contact.accountAssociated? "Shipper:" + contact.accountAssociated : ""}</div>
                        </div>
                        <div className={"working-days"}>
                            {contact.contactAvailabilities ?
                            contact.contactAvailabilities.map((st) => {
                                return <div>
                                    <div><b>  {st.dayOfWeek}</b> : {st.shift}&nbsp;&nbsp;&nbsp; </div>
                                </div>
                            }) : ''}
                        </div>
                    </div>
                </ListItemText>
            </ListItemButton>)}
        </List>
    }

    if (edit) {
        return <div className={"facility-contacts-container"}>
            <AddOrEditFacilityContact facility={facility}
                                      contact={selectedContact}
                                      completed={finishedEdit}
                                      workingDays={hours}
                                    //   contactAvailabilities={contactAvailabilities}
                                      shifts={shifts}/>
        </div>
    }

    return <div className={"facility-contacts-container"}>
        <FacilityContactsList/>

        <SuccessActionNotification message={"Contact Saved"}
                                   open={contactSaved}
                                   close={() => setContactSaved(false)}/>

        <Fab className={"add-contact-icon"}
             size={"small"}
             aria-label={"Add"}
             color={"primary"}
             onClick={addContact}>
            <AddIcon/>
        </Fab>
    </div>
}

export default FacilityContacts;
