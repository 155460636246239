import React from "react";
import {styled} from "@mui/system";
import {Chip} from "@mui/material";

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  //background-color: #8888;
  //padding: 10px;
  //border-top-right-radius: 9px;
  //border-top-left-radius: 9px;
  width: 100%;
`

const Title = styled('div')`
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
`

type TitleProps = {
    title: string;
    successful: boolean;
}

const OutcomeTitle = ({title, successful}: TitleProps) => {

    return <TitleContainer>
        <Title>{title}</Title>
        <div>{successful ?
            <Chip size="small" sx={{backgroundColor: '#8f88'}} label={"Task Successful"}/> :
            <Chip size="small" sx={{backgroundColor: '#f888'}} label={"Task will Continue"}/>}
        </div>
    </TitleContainer>;
}

export default OutcomeTitle;