import React, {useState} from "react";
import {Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import {TextareaAutosize} from "@mui/base";
import {findOpenLoadsForLanes, LocationsWithLoads} from "../service/loads";
import {Load} from "../interfaces/Load";
import SectionContainer from "../common/SectionContainer";
import Paper from "@mui/material/Paper";
import {LoadLink} from "../common/Links";
import {styled} from "@mui/system";
import NoContentPanel from "../common/NoContentPanel";
import HelpIcon from "@mui/icons-material/Help";

const StartSearch = () => {

    const [searchText, setSearchText] = useState('');
    const [loadData, setLoadData] = useState<LocationsWithLoads[]>([]);
    const [noLoadsFound, setNoLoadsFound] = useState(false);

    const searchLanes = () => {
        findOpenLoadsForLanes(searchText).then(d => {
            setLoadData(d);
            const count = d.reduce(function (n, load) {
                return n + load.loads.length;
            }, 0);
            setNoLoadsFound(count === 0);
        });
    }

    function updateSearchText(evt) {
        setSearchText(evt.target.value);
    }

    type LocationProps = {
        data: LocationsWithLoads;
    }

    const LocationResult = ({data}: LocationProps) => {
        return <>
            {data.loads.map(l =>
                <TableRow>
                    <TableCell key={data.location} sx={{verticalAlign: 'top'}}>{data.location}</TableCell>
                    <Load load={l}/>
                </TableRow>)}
        </>
    }

    type LoadProps = {
        load: Load;
    }

    const Load = ({load}: LoadProps) => {
        return <>
            <TableCell><LoadLink load={load} openLoadView={true}>Load View</LoadLink></TableCell>
            <TableCell>{load.originAddress.city}, {load.originAddress.state}</TableCell>
            <TableCell>{load.destinationAddress.city}, {load.destinationAddress.state}</TableCell>
            <TableCell>{load.miles} Miles</TableCell>
            <TableCell>{load.loadDescription}</TableCell>
        </>
    }

    const NoResults = () => {
        return <TableRow>
            <TableCell colSpan={6}>
                <NoContentPanel>No Results for search request</NoContentPanel>
            </TableCell>
        </TableRow>
    }

    const SearchTitle = () => {
        const TitleBox = styled('div')`
          display: flex;
          align-items: center;
        `

        const HelpTooltip = styled(Tooltip)`
          margin-bottom: 3px;
          color: #8c8c8c;
          cursor: help;
        `

        return <TitleBox>
            <div style={{marginRight: "5px"}}>Search</div>
            <HelpTooltip title={<>
                <div>Search looks for open loads for nearby <em>origins</em> and supports these types of locations:
                </div>
                <div>&nbsp;</div>
                <div>City, ST</div>
            </>}>
                <HelpIcon fontSize="small"/>
            </HelpTooltip>
        </TitleBox>
    }

    const ActionBox = styled(Box)`
      display: flex;
      align-items: stretch;
      gap: 10px;
    `

    return <Box>
        <SectionContainer title={<SearchTitle/>} border={false}>
            <TextareaAutosize aria-label="minimum height"
                              minRows={3}
                              style={{width: '100%'}}
                              placeholder="Paste in location searches"
                              value={searchText} onChange={updateSearchText}></TextareaAutosize>
            <ActionBox>
                <Button variant={"outlined"} onClick={searchLanes}>Search</Button>
            </ActionBox>

            {loadData.length > 0 ?
                <TableContainer component={Paper} sx={{marginTop: "15px"}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Location</TableCell>
                                <TableCell colSpan={5}>Loads</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                noLoadsFound ?
                                    <NoResults/> :
                                    loadData.map(l => <LocationResult data={l}
                                                                      key={l.location + new Date().getTime()}/>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer> : null}
        </SectionContainer>
    </Box>
}

export default StartSearch;