import React, {useEffect, useState} from 'react';
import {Load} from "../interfaces/Load";
import {getShipwellEventsForLoad} from "../service/loads";
import {ShipwellEvent} from "../interfaces/Event";
import Loading from "../common/Loading";
import ReactJson from "react-json-view";
import {Link, List, ListItem, ListItemButton} from '@mui/material';
import "./loads.scss";
import {Box, styled} from "@mui/system";
import EventTypeDispatcher from "./events/EventTypeDispatcher";
import {stopUpdatedDetailsHasUsefulInformation} from "./events/types";

interface Props {
    load: Load;
}

interface ListItemProps {
    evt: ShipwellEvent;
}

const ShipwellEvents: React.FC<Props> = ({load}) => {

    const [shipwellEvents, setShipwellEvents] = useState<ShipwellEvent[]>([]);
    const [showAllEvents, setShowAllEvents] = useState(false);

    useEffect(() => {
        getShipwellEventsForLoad(load.id).then(setShipwellEvents);
    }, [load.id]);

    function displayEvent(event: ShipwellEvent): boolean {
        if (showAllEvents) {
            return true;
        }

        if (event.eventName === 'shipment.updated'
            || event.eventName === 'shipment.delivered') {
            return false;
        }

        if (event.eventName === 'shipment.stop.updated') {
            if (!stopUpdatedDetailsHasUsefulInformation(event)) {
                return false;
            }
        }

        return true;
    }

    const EventListItem = ({evt}: ListItemProps) => {
        const [showDetails, setShowDetails] = useState(false);
        const [showSource, setShowSource] = useState(false);

        const ListItemContainer = styled('div')`
          display: grid;
          grid-template-columns: 1fr 3fr 150px 100px;
          grid-template-areas: "date type source options"
                               "json json json json";
          align-items: start;
          column-gap: 10px;
          width: 100%;
          row-gap: 15px;
        `
        const DateItem = styled('div')`grid-area: date;`
        const TypeItem = styled('div')`grid-area: type;`
        const SourceItem = styled('div')`grid-area: source;`
        const OptionsItem = styled('div')`grid-area: options;`
        const JsonItem = styled('div')`grid-area: json;`

        const RawLink = styled(Link)`
          margin-right: 5px;
        `

        return <ListItem disablePadding>
            <ListItemButton>
                <ListItemContainer>
                    <DateItem>
                        <Box sx={{"fontWeight": "bold"}}>{new Date(evt.occurredAt).toDateString()}</Box>
                        <Box sx={{
                            "fontSize": "0.8em",
                            "color": "#888"
                        }}>{new Date(evt.occurredAt).toLocaleTimeString()}</Box>
                    </DateItem>
                    <TypeItem><EventTypeDispatcher event={evt}/></TypeItem>
                    <SourceItem>{evt.sourceUser || "N/A"}</SourceItem>
                    <OptionsItem>
                        <RawLink onClick={() => setShowDetails(!showDetails)}>Details</RawLink>
                        <RawLink onClick={() => setShowSource(!showSource)}>Source</RawLink>
                    </OptionsItem>
                    <JsonItem>{showDetails ?
                        <ReactJson src={JSON.parse(evt.details)} theme="solarized"/> : null}
                        {showSource ?
                            <ReactJson src={JSON.parse(evt.source)} theme="solarized"/> : null}
                    </JsonItem>
                </ListItemContainer>
            </ListItemButton>
        </ListItem>
    }

    if (shipwellEvents) {
        // @ts-ignore
        return (
            <>
                <Link sx={{cursor: 'pointer', paddingLeft: '16px'}}
                      onClick={() => setShowAllEvents(!showAllEvents)}>
                    {showAllEvents ? 'Hide Empty Events' : 'Show All Events'}
                </Link>
                <List>
                    {shipwellEvents.filter(se => displayEvent(se)).map(se =>
                        <EventListItem evt={se} key={se.shipwellEventId}/>
                    )}
                </List>
            </>
        );
    }

    return <div><Loading/></div>
};

export default ShipwellEvents;