import React from "react";
import "./Leads.scss"
import CarrierSourceList from "./CarrierSourceList";

const Leads = () => {

    return (
        <div className={"leads-list"}>
            <CarrierSourceList />
        </div>
    );
}
export default Leads;
