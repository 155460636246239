import React, {useState} from "react";
import SectionContainer from "../common/SectionContainer";
import {LaneActivityLoad, Load, LoadRateOffer} from "../interfaces/Load";
import {getRateIntel} from "../service/lane";
import {useAbortableEffect} from "../util/hooks";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatCents, userInitials} from "../util/load-utils";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {Box, Chip, Table, TableContainer, TableFooter, TablePagination, Tooltip} from "@mui/material";
import {styled} from "@mui/system";
import ExternalLink, {CarrierLink, TorchShipmentExternalLink} from "../common/Links";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import {getLaneActivityLoads} from "../service/loads";
import NoContentPanel from "../common/NoContentPanel";
import Loading from "../common/Loading";
import DatRate from "../common/DatRate";

type PropTypes = {
    load: Load | null;
}

const TLink = styled(Link)`
  text-decoration: none;
`

export interface PastLoadProps {
    loads: LaneActivityLoad[];
}

const PastLoads = (props: PastLoadProps) => {
    const loads = props.loads;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const getMarginLabel = (load: LaneActivityLoad) => {
        const margin = ((load.revenue - load.cost) / load.cost) * 100;
        return (
            <div className={"margin-" + load.marginLevel}>
                ${(load.lineHaulRate / 100).toFixed(2)} ({margin.toFixed(2)}%)
            </div>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }


    if (loads && loads.length === 0) {
        return <NoContentPanel>No Past Loads</NoContentPanel>
    }

    const TChip = styled(Chip)`margin-left: 5px;`

    if (loads && loads.length > 0) {
        return (
            <div className={"past-loads-carrier"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Pickup</TableCell>
                            <TableCell>Rate (Margin)</TableCell>
                            <TableCell sx={{whiteSpace: "nowrap"}}>Max Buy</TableCell>
                            <TableCell>DAT</TableCell>
                            <TableCell>Carrier</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loads.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(l => (
                            <TableRow key={l.shipwellId}>
                                <TableCell>
                                    <TorchShipmentExternalLink shipwellId={l.shipwellId}>
                                        <Moment format={"MMM D"}>{new Date(l.pickup)}</Moment>
                                    </TorchShipmentExternalLink>
                                </TableCell>
                                <TableCell className={"margin-" + l.marginLevel}>
                                    {getMarginLabel(l)}
                                </TableCell>
                                <TableCell>
                                    {l.maxBuy ? formatCents(l.maxBuy) : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {l.datRate ? <DatRate rate={l.datRate} level={"minimal"} /> : "N/A"}
                                </TableCell>
                                <TableCell className={"carrier-name"}>
                                    {l.carrier ?
                                        <>
                                            <CarrierLink carrierId={l.carrier.id}>{l.carrier.name}</CarrierLink>
                                            {l.carrier.status === 'DO_NOT_USE' ? <TChip size={"small"} label={"DNU"} /> : null}
                                        </>
                                        : <Box sx={{fontStyle: 'italic'}}>No Carrier</Box>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={loads.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>);
    }

    if (loads && loads.length === 0) {
        return <NoContentPanel>No Recent Activity</NoContentPanel>;
    }

    return (<div className={"loading"}>
        <Loading />
    </div>)
}

const LaneActivity = ({load}: PropTypes) => {
    const [value, setValue] = React.useState('1');
    const [pastLoads, setPastLoads] = useState<LaneActivityLoad[]>([]);

    useAbortableEffect((status) => {
        if (load) {
            getLaneActivityLoads(load.stops[0].address, load.stops[load.stops.length - 1].address)
                .then(loads => setPastLoads(loads.reverse()));
        }
    }, [load]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <SectionContainer title={"Lane Activity"} fullBleed={true} className={"lane-panel"}>
        <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Past Loads" value="1"/>
                <Tab label="Rates" value="2"/>
            </TabList>
            <TabPanel sx={{padding: 0}} value="1">
                <PastLoads loads={pastLoads}/>
            </TabPanel>
            <TabPanel sx={{padding: 0}} value="2" className={"tab-panel"}>
                <LaneRates load={load}/>
            </TabPanel>
        </TabContext>
    </SectionContainer>
}

const LaneRates = ({load}: PropTypes) => {

    const [offers, setOffers] = useState<LoadRateOffer[]>([]);

    const updateOffers = (load, status) => {
        if (load) {
            getRateIntel(load.id).then((json) => {
                if (!status || !status.aborted) {
                    const sorted = [...json.bestOffers, ...json.sameLaneOffers].sort(
                        (first, second) =>
                            new Date(second.occurredAt).getTime() - new Date(first.occurredAt).getTime());
                    setOffers(sorted);
                }
            })
        }
    }

    useAbortableEffect((status) => {
        updateOffers(load, status)
    }, [load]);

    if (offers.length > 0) {
        return <TableContainer>
            <Table aria-label="Load Offers">
                {(offers && offers.length > 0) ?
                    <>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3}>Recent Lane Offers</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {offers.map((offer) => (
                                <TableRow key={offer.id} sx={{'& tr:last-child td': {border: 0}}}>
                                    <TableCell component="th" scope="row">
                                        <Tooltip title={offer.user.name}>
                                                <span className={"user-initials"}>
                                                    [{userInitials(offer.user.name)}]&nbsp;
                                                </span>
                                        </Tooltip>
                                        <TLink to={`/carriers/${offer.carrier.id}`}
                                               className={"carrier-link"}>
                                            {offer.carrier.name}
                                        </TLink>
                                        &nbsp;(<ExternalLink
                                        href={`https://torch.shipwell.com/shipments/${offer.loadShipwellId}`}>
                                        {offer.loadRefId}
                                    </ExternalLink>)
                                    </TableCell>
                                    <TableCell align="left">{formatCents(offer.amount)}</TableCell>
                                    <TableCell align="left">
                                        <Moment format={"MMM D"}>{offer.occurredAt}</Moment>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </>
                    : null}
            </Table>
        </TableContainer>
    }

    return <NoContentPanel>No Rates Entered</NoContentPanel>;
}

export default LaneActivity;
