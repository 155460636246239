import React, {useEffect, useState} from 'react';
import './Rate.css';
import {Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField} from '@mui/material';
import LocationAutocomplete from "./LocationAutocomplete";
import {torchFetch} from '../util/APIUtils';
import {API_BASE_URL} from '../constants';
import RateChartBundle from './RateChartBundle';
import Loads from './Loads';

import {useLocation} from "react-router-dom";
import {decodeLocation} from './helpers';
import {getRate} from '../service/lane';
import {Rate, RateAddress} from "../interfaces/RateData";


const RateItem = () => {


    const [truckType, setTruckType] = useState('DRY_VAN');
    const [weight, setWeight] = useState(40000);
    const [origin, setOrigin] = useState<RateAddress>();
    const [destination, setDestination] = useState<RateAddress>();
    const [historicalRateData, setHistoricalRateData] = useState<Rate[]>();
    const [rateData, setRateData] = useState<Rate>();
    const [loading, setLoading] = useState(false);
    const [loads, setLoads] = useState([]);

    const location = useLocation();

    const inputClassName = "input";
    const selectInputClassName = "select-input";

    useEffect(() => {
        if(location.pathname.includes("/rate/") && location.pathname.length > 6) {
            const lane = decodeLocation(location.pathname.substring(6));
            setOrigin(lane[0] as RateAddress);
            setDestination(lane[1] as RateAddress);
        }

        return () => console.log('unmounting...');
        // if the location is /rate/{location}_to_{location} then fill in the form with the location
        // specified and then run the rate quote again - may need to implement some caching on the client
        // as well if the information is still valid.

    }, [location.pathname])


    const generateRate = () => {
        getRate(origin, destination, truckType).then(rate => {
            setLoading(false);
            setRateData(rate.rate);
            setHistoricalRateData(rate.historical);
        });

        let loadsUrl = new URL(`${API_BASE_URL}/load/by_lane`),
            params = {origin: origin?.zipCode, destination: destination?.zipCode}
        Object.keys(params).forEach(key => loadsUrl.searchParams.append(key, params[key]))

        torchFetch({
            url: loadsUrl,
            method: 'get'
        }).then(json => {
            setLoads(json.content);
        });
    }

    const displayLoading = () => {
        if (loading) {
            return <LinearProgress className={loadingProgress} color="secondary"/>
        }
        return null;
    }

    const isFormInvalid = () => {
        //return false;
        return isAutocompleteEmpty(origin) || isAutocompleteEmpty(destination);
    }

    const isAutocompleteEmpty = (rec) => {
        return rec === undefined || rec === null || rec.zipCode === undefined || rec.zipCode === '';
    }

    const loadingProgress = "rate-loading-progress";
    const buttonLoadingStatus = "button-loading-status";

    return (
        <div className={"rate content-outer-container"}>
            <form>
                <div className={"layout-wrapper"}>
                    <div className={`${inputClassName} origin-entry`}>
                        <LocationAutocomplete
                            label="Origin"
                            onChange={e => setOrigin(e)}
                            defaultValue={origin}/>
                    </div>
                    <div className={`${inputClassName} destination-entry`}>
                        <LocationAutocomplete
                            label="Destination"
                            onChange={e => setDestination(e)}
                            defaultValue={destination}/>
                    </div>
                    <div className={`${inputClassName} weight-entry`}>
                        <TextField
                            id="rate-weight-field"
                            label="Load Weight"
                            name="weight"
                            value={weight}
                            fullWidth={true}
                            onChange={e => setWeight(+e.target.value)}/>
                    </div>
                    <div className={`${selectInputClassName} equipment-entry`}>
                        <FormControl style={{minWidth: '100%'}}>
                            <InputLabel id="rate-load-type-label">Truck Type</InputLabel>
                            <Select
                                labelId="rate-load-type-label"
                                id="rate-load-type-select"
                                name="truckType"
                                value={truckType}
                                onChange={e => setTruckType(""+ e.target.value)}
                                className={"equipment-select"}>
                                <MenuItem value={"DRY_VAN"}>Dry Van</MenuItem>
                                <MenuItem value={"REEFER"}>Reefer</MenuItem>
                                <MenuItem value={"DRAYAGE"}>Drayage</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className={buttonLoadingStatus}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={generateRate}
                        disabled={isFormInvalid()}>Rate</Button>
                    {displayLoading()}
                </div>
            </form>

            { rateData ?
                <div>
                    <div className={"rate-chart-entry"}>
                        <RateChartBundle current={rateData}
                            loading={false} height={400} showDetails={true} showTitles={true}
                            showCurrent={true} showAmount={true}
                            historical={historicalRateData}/>
                    </div>

                    <div className={"rates-past-loads"}>
                        <Loads loads={loads}/>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    );
}

export default RateItem;

