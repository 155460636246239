import {Load} from "../interfaces/Load";
import {LoadNote} from "../interfaces/LoadNote";
import {useEffect, useState} from "react";
import {getLoadNotesForLoad} from "../service/loads";
import {Typography} from "@mui/material";
import Loading from "../common/Loading";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {getTimeFormat} from "../service/time";
import Moment from "react-moment";

type PropLoadNotesTypes = {
    load: Load;
}

const LoadNotesContainer = ({load}: PropLoadNotesTypes) => {

    const [loadNotes, setLoadNotes] = useState<LoadNote[]>();

    useEffect(() => {
        getLoadNotesForLoad(load.id).then(setLoadNotes);
    }, [load.id]);


    if (loadNotes) {
        return (
            <Timeline position="alternate">
                {loadNotes.map(note =>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="error"/>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography >
                            <Moment format={getTimeFormat()}>{note.updated}</Moment>
                        </Typography>
                        <Typography variant="h6">
                            {note.message}
                        </Typography>
                        <Typography>
                            {note.user.name}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                )}
            </Timeline>
        );
    }
    return <div><Loading/></div>;
}

export default LoadNotesContainer;
