import React, {useMemo, useState} from "react";
import {CircularProgress, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {debounce} from "lodash";
import {searchCarriers} from "../service/carriers";
import {Carrier} from "../interfaces/Carrier";
import {ACTIVE, NEW} from "../constants/CarrierStatus";

const CarrierSearchBox = ({setCarrier, label = "Find Carrier"}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const debounceWait = 750;

    const queryCarriers = q => {
        setOpen(true);
        q = q ? q.trim() : '';
        if (q.length >= 3) {
            setLoading(true);
            searchCarriers(q).then(json => {
                setLoading(false);
                setOptions(json);
            });
        } else {
            setOptions([]);
            setOpen(false);
            setLoading(false);
        }
    }

    const delayedSearchValue = useMemo(() => debounce(v => queryCarriers(v), debounceWait), []);

    const inputChange = event => {
        delayedSearchValue(event.target.value);
    }

    function createLabel(option: Carrier) {
        return (option.status === ACTIVE) ? option.name : option.name + " (" + (option.status || NEW) + ")";
    }

    return (<div className={"carrier-search"}>
        <Autocomplete
            id={label + "-id"}
            /*key={resetCtrl}*/
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, newValue) => {
                if(setCarrier) {
                    setCarrier(newValue);
                }
            }}
            isOptionEqualToValue={(opt: Carrier, value: Carrier) => {
                return (opt.id === value.id);
            }}
            getOptionLabel={(option) => createLabel(option)}
            options={options}
            loading={loading}
            autoSelect={true}
            filterOptions={(options, state) => options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    onChange={inputChange}
                />
            )}
        />
    </div>);
}

export default CarrierSearchBox;
