import React, {useState} from "react";
import {Box, Button, Fade, TextareaAutosize} from "@mui/material";
import "../carrier/Carriers.scss"

type PropTypes = {
    noteControlOpen: boolean;
    saveFn: (note: string) => Promise<boolean>;
    cancelFn: () => void;
    existing?: string;
}

/*
    Unmanaged component
 */
const NoteControl = ({saveFn, cancelFn, noteControlOpen, existing}: PropTypes) => {

    const [note, setNote] = useState<string>(existing || '');

    const saveNote = () => {
        saveFn(note).then((res) => {
            if (res) setNote('')
        });
    }

    const updateNoteText = (evt) => {
        setNote(evt.target.value);
    }

    return (<Fade in={noteControlOpen} unmountOnExit={true} timeout={500}>
        <div className={"add-note-control"}>
            <TextareaAutosize className={"note-text-area"}
                              aria-label="New Note"
                              minRows={5}
                              value={note}
                              onChange={updateNoteText}
                              placeholder="Add a new carrier note"/>
            <Box component="span"
                 m={0}
                 className={"entry-box"}>
                <Button variant="outlined"
                        color="primary"
                        disabled={note.length < 3}
                        onClick={saveNote}
                        sx={{marginRight: "10px"}}>Add Note
                </Button>
                <Button variant="outlined"
                        color="error"
                        onClick={cancelFn}>Cancel
                </Button>
            </Box>
        </div>
    </Fade>)
}

export default NoteControl;