declare global {
    var _env_: {TORCH_ENVIRONMENT: string}|undefined; // runtime injected environment variables
}

interface TorchConfiguration {
    environment: string
    url: {
        API_BASE_URL: string,
        API_TICKET_BASE_URL: string,
        LOADY: string,
        SHIPWELL_URL: string
    }
}

const prod = {
    environment: "production",
    url: {
        API_BASE_URL: 'https://broker-api.torch-logistics.com',
        API_TICKET_BASE_URL: '',
        LOADY: 'https://loady.torch-logistics.com',
        SHIPWELL_URL: 'https://torch.shipwell.com'
    }
};

const stg = {
    environment: "staging",
    url: {
        API_BASE_URL: 'https://broker-api-stg.torch-logistics.com',
        API_TICKET_BASE_URL: 'https://torch-web.mobileprogramming.net',
        LOADY: 'https://loady-stg.torch-logistics.com',
        SHIPWELL_URL: 'https://sandbox.shipwell.com'
    }
};

const dev = {
    environment: "development",
    url: {
        // API_BASE_URL: 'https://broker-api-stg.torch-logistics.com',
        API_TICKET_BASE_URL: 'https://torch-web.mobileprogramming.net',
        // API_BASE_URL: 'https://torch-web.mobileprogramming.net',
        //Localhost port should be 8080, the port of Trailer
        API_BASE_URL: 'http://localhost:8080',
        // LOADY: 'https://loady-stg.torch-logistics.com',
        LOADY: 'http://localhost:8000',
        SHIPWELL_URL: 'https://sandbox.shipwell.com'
    }
};

let config: TorchConfiguration;
if (window._env_ !== undefined) {

    switch (window._env_.TORCH_ENVIRONMENT) {
        case 'development':
        case 'dev':
            config = dev;
            break;
        case 'staging':
        case 'stg':
            config = stg;
            break;
        default:
            config = prod;
            break;
    }
} else {
    // We must not be running with injected _env_ (i.e. react dev server)
    console.log("No injected environment found, using dev");
    config = dev;
}

console.log(`environment is ${config.environment}`);

export const API_BASE_URL = config.url.API_BASE_URL;
export const API_TICKET_BASE_URL = config.url.API_TICKET_BASE_URL;
export const LOADY_URL = config.url.LOADY;
export const SHIPWELL_URL = config.url.SHIPWELL_URL;
export const ACCESS_TOKEN = 'accessToken';
export const URL_BASE = window.location.protocol + "//" + window.location.host;

export const OAUTH2_REDIRECT_URI = URL_BASE + '/oauth2/redirect'

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
