import {Bid} from "../interfaces/Bids";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    FormGroup,
    Grid,
    Link,
    Snackbar,
    styled,
    TextField
} from "@mui/material";
import Loading from "../common/Loading";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {sendBadBid, sendBid} from "../util/APIUtils";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";


type ListProps = {
    loading: boolean;
    bids: Bid[]
}

const StopLocation = styled('div')`
  overflow: clip;
  text-transform: capitalize;
`

const UpdateText = styled('div')`
  overflow: clip;
  font-size: 12px;
`
const UpdateDate = styled('div')`
  overflow: clip;
  font-size: 10px;
`

const BidList = ({loading, bids}: ListProps) => {

    const [bidStatus, setBidStatue] = useState("");
    const [bidSnack, setBidSnack] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentBid, setCurrentBid] = useState('');

    function displayDate(s: string | undefined): string {
        if (s) {
            return new Date(s).toLocaleDateString('en-us', {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                hour:'numeric'
            })
        }
        return 'No Date Available';
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setBidSnack(false);
    };

    const handleDialogClose = () => {
        setCurrentBid('');
        setDialogOpen(false);
    };

    function getDefaultValue(id:string) {
        return Array.from(bids.values()).filter((item: Bid) => item.load_id === id)[0];
    }

    function bid(bid_id:string, torch_rate: number, params:any) {
        //if (window.confirm("Really bid $" + torch_rate + " on " + bid_id +  " ?")) {
            sendBid(bid_id, torch_rate).then(response => {
                if (response.status === 'ERROR') {
                    getDefaultValue(bid_id).status = "ERROR";
                    setBidSnack(true);
                    setBidStatue("Error in submitting " + response.error);
                }  else {
                    getDefaultValue(bid_id).status = response.status;
                    setBidSnack(true);
                    setBidStatue("Bid Submitted!" + response.error);
                }
        });
        //}
    }

    const noBid = (bid_id:string)  => {
        setCurrentBid(bid_id);
        setDialogOpen(true);
    }

    const columns: GridColDef[] = [
        {
            field: 'account_name',
            headerName: 'Client',
            width: 75,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Link target={'_blank'} href={`https://www.smart-tendering.com/#/loads/offers/modal/${params.row.load_id}`}>{params.value}</Link>
            ),
        },
        {
            field: 'base_rate',
            headerName: 'Base Rate',
            type:'number',
            width: 100,
            editable: true
        },
        {
            field: 'torch_rate',
            headerName: 'Torch Rate',
            type:'number',
            width: 100,
            editable: true
        },
        {
            field: 'rate_update_date',
            headerName: 'Update',
            width: 75,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div>
                    <UpdateDate>{!params.row.rate_update_date ? '' : displayDate(params.row.rate_update_date)}</UpdateDate>
                </div>
            ),
        },
        {
            field: 'origin_city',
            headerName: 'Trip',
            width: 200,
            renderCell: (params: GridRenderCellParams<string>) => (
                <StopLocation>
                    <Typography>{params.row.origin_city + ', ' + params.row.origin_state}</Typography>
                    <Typography>&#x2192;</Typography>
                    <Typography>{params.row.destination_city + ', ' + params.row.destination_state}</Typography>
                </StopLocation>
            ),
        },
        {
            field: 'pickup_date',
            headerName: 'Pickup',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <strong>
                    {displayDate(params.value)}
                </strong>
            ),
        }, {
            field: 'delivery_date',
            headerName: 'Drop Off',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <>{displayDate(params.value)}</>
            ),
        }, {
            field: 'mileage',
            type:'number',
            headerName: 'Miles',
            width:65
        },{
            field: 'rpm',
            type:'number',
            headerName: 'RPM',
            width:50,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography>{(params.row.torch_rate/params.row.mileage).toFixed(2)}</Typography>
            ),
        },{
            field: 'linear_feet',
            type:'number',
            headerName: 'Linear Ft.',
            width:55
        },  {
            field: 'equipment_type',
            headerName: 'Equipment'
        }, {
            field: 'lead_time',
            headerName: 'Lead time(h)'
        },{
            field: 'status',
            headerName: 'Status',
            width: 140,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div>
                    <UpdateText>{params.value}</UpdateText>
                    <UpdateDate>{!params.row.update_date ? new Date().toISOString() : displayDate(params.row.update_date)}</UpdateDate>
                </div>
            ),
        },
        {
            field: 'load_id',
            headerName: 'Bid',
            width: 190,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Grid container>
                    <Grid item>
                        <TextField
                            id="outlined-required"
                            value={params.row.torch_rate === 0 ? 'Provide rate' : params.row.torch_rate}
                            size="small"
                            style = {{width: 90}}
                        />
                    </Grid>
                    <Grid item alignItems="stretch" style={{ display: "flex" }}>
                        <Button disabled={params.row.torch_rate === 0 || params.row.status === 'NO_BID'} onClick={() => bid(params.row.load_id, params.row.torch_rate, params)}
                                color="secondary" variant="contained">Bid</Button>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'load_ref',
            headerName: 'No Bid',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Grid container>
                    <Grid item alignItems="stretch" style={{ display: "flex" }}>
                        <Button  onClick={() => noBid(params.row.load_id)}
                                color="error" variant="contained">NO Bid</Button>
                    </Grid>
                </Grid>
            )
        }
    ];

    if (loading) {
        return <><Loading/></>
    }

    return <div>
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    formJson["destination"] = event.currentTarget[0]["checked"];
                    formJson["equipment"] = event.currentTarget[1]["checked"];
                    formJson["mileage"] = event.currentTarget[2]["checked"];
                    formJson["puLocation"] = event.currentTarget[4]["checked"];
                    formJson["lane"] = event.currentTarget[5]["checked"];
                    formJson["permit"] = event.currentTarget[6]["checked"];
                    formJson["holiday"] = event.currentTarget[7]["checked"];
                    sendBadBid(currentBid,formJson).then( (result ) => {
                        setDialogOpen(false);
                        getDefaultValue(currentBid).status = "NO_BID";
                    });

                    handleDialogClose();
                },
            }}
        >
            <DialogTitle>Bad Bid Feedback</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom:'10px'}}>
                    Collecting information about this bid to improve overall bidding.
                </DialogContentText>
                <DialogContentText>
                    <Typography><strong>Rate Problems:</strong></Typography>
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel control={<Checkbox id={"destination"} />} label="Destination (Low market, not lot of carriers interested)." />
                    <FormControlLabel control={<Checkbox id={"equipment"} />}  label="Equipment Type (Reefers have different rates than Dry Vans, for example)." />
                    <FormControlLabel control={<Checkbox id ={"mileage"} />} label="Mileage (Low rate vs total miles)." />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="rpm"
                        name="rmp"
                        label="Correct Rate Per Mile"
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="correctRate"
                        name="correctRate"
                        label="Correct Rate"
                        variant="standard"
                    />
                </FormGroup>
                <DialogContentText>
                    <Typography><strong>Capacity Problems:</strong></Typography>
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel control={<Checkbox id={"puLocation"} />} label="PU Location (Low market, not lot of carriers in the area)." />
                    <FormControlLabel control={<Checkbox id={"lane"} />}  label="Lane (No popular between carriers)." />
                    <FormControlLabel control={<Checkbox id ={"permit"} />} label="Permit Needed (Carrier reduction - need permit to circulate)." />
                </FormGroup>
                <DialogContentText>
                    <Typography><strong>Other:</strong></Typography>
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel control={<Checkbox id={"holiday"} />} label="Holiday" />
                </FormGroup>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="otherProblems"
                    label="Other problems"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </DialogActions>
        </Dialog>


        <Snackbar
            open={bidSnack}
            autoHideDuration={5000}
            message={bidStatus}
            onClose={handleClose}
        />

        <DataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            loading={loading}
            rows={bids}
            columns={columns}
            getRowId={(row: any) => row.load_id}
            pageSize={50}
            rowsPerPageOptions={[50]}
            pagination
        />
    </div>

}

export default BidList;
