import React, {MouseEventHandler, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import "./TenderRejectDialog.scss";
import {getRejectionCodes} from "../service/tendered";
import {EDIDeclineCode} from "../interfaces/TenderedLoad";


export const TenderRejectDialog = ({open, handleConfirm, handleCancel}:
                                       { open: boolean, handleConfirm: Function, handleCancel: MouseEventHandler }) => {

    const [allowedCodes, setAllowedCodes] = useState<EDIDeclineCode[]>([]);
    const [codeValues, setCodeValues] = useState<EDIDeclineCode[]>([]);

    const SHIPPER_ID = "AB";

    useEffect(() => {
        getRejectionCodes(SHIPPER_ID).then(codes => {
            setAllowedCodes(codes);
        })
    }, []);

    function doReject() {
        handleConfirm()(codeValues);
    }

    return <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Decline Load"}</DialogTitle>
        <DialogContent
            className={"tender-reject-load-dialog"}>
            <DialogContentText className="reject-load-select-description">
                Decline this load?
            </DialogContentText>
            <FormControl className={"reject-load-select"}>
                <InputLabel id="reject-load-select-label" className="reject-load-select-label">Code for Declining
                    Load</InputLabel>
                <Select labelId="reject-load-select-label"
                        id="reject-load-select"
                        multiple
                        value={codeValues}
                        input={<Input />}
                        onChange={event => setCodeValues(event.target.value as EDIDeclineCode[])}
                >
                    {allowedCodes.map(code => (
                        <MenuItem key={code.id} value={code as any}>
                            {`${code.key} - ${code.value}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={doReject} color="primary" disabled={codeValues.length === 0}>OK</Button>
            <Button onClick={handleCancel} color="secondary">Cancel</Button>
        </DialogActions>
    </Dialog>
}
