import React from "react";
import {Route, Switch} from "react-router-dom";
import LoadContainer from "./LoadContainer";
import LoadSearch from "./LoadSearch";
import {styled} from "@mui/system";

const Container = styled('div')`
  padding: 20px;
  height: 100%;
`

const Loads = () => {
    return <Container>
        <Switch>
            <Route path="/loads/:loadId">
                <LoadContainer/>
            </Route>
            <Route path="*">
                <LoadSearch/>
            </Route>
        </Switch>
    </Container>;
}

export default Loads;