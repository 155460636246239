/*
    Many times we're operating within the context of a lane.  A lane has an origin and
    destination.  Within a lane, a rate can be requested which will provide other details
    specific about the shipment.

    getLaneIntel() // later
    getRate(origin, destination, type = dryvan (default)
 */
import {torchFetch} from "../util/APIUtils";
import {API_BASE_URL} from "../constants";
import {RateWithHistorical} from "../interfaces/RateData";
import {LoadWithActivity, SuggestedLoad} from "../interfaces/Load";

export function getRate(origin, destination, type = 'dryvan'): Promise<RateWithHistorical> {
    let rateRequest = {
        origin: {
            zipCode: origin.zipCode,
            city: origin.city,
            state: origin.state
        },
        destination: {
            zipCode: destination.zipCode,
            city: destination.city,
            state: destination.state
        },
        payloadType: type,
    }

    return new Promise((resolve, reject) => {
        torchFetch({
            url: `${API_BASE_URL}/dat-rate`,
            method: 'post',
            body: JSON.stringify(rateRequest)
        }).then(json => {
            resolve(json);
        })
    })
}

export function getRateIntel(loadId): Promise<LoadWithActivity> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: `${API_BASE_URL}/load/${loadId}/rate-intel`,
            method: 'get'
        }).then(json => {
            resolve(json);
        })
    })
}

export function getOtherSuggestedLoads(loadId: number): Promise<SuggestedLoad[]> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: `${API_BASE_URL}/load/${loadId}/continuing`,
            method: 'get'
        }).then(json => {
            resolve(json);
        })
    });
}