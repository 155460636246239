import React from "react";
import {getShipmentUpdatedDataDetails, getShipwellDataStateEntries, ShipwellEventProps} from "./types";
import {EventTitle} from "./EventTypeDispatcher";
import PastCurrentData from "./PastCurrentData";

const ShipmentDataUpdated = ({event, title}: ShipwellEventProps) => {

    const data = getShipmentUpdatedDataDetails(event);

    return <>
        <EventTitle>{title}</EventTitle>
        <PastCurrentData current={getShipwellDataStateEntries(data.new_data)}
                         past={getShipwellDataStateEntries(data.old_data)}/>

    </>
}

export default ShipmentDataUpdated;