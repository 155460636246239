import styled from "styled-components";

export const TicketMainContainer = styled.div`
  .ticket_board_header {
    padding: 30px 33px 25px 24px;
    border-bottom: 1px solid #ececec;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h1 {
      font-size: 30px;
      font-weight: 700;
    }
    .search_section {
      display: flex;
      align-items: center;
      gap: 14px;
      .search_input {
        position: relative;
        .MuiOutlinedInput-root {
          border-radius: 8px;
        }
        .MuiOutlinedInput-input {
          font-size: 12px;
          padding: 11px 10px 11px 35px;
        }
        .MuiInputLabel-formControl {
          transform: translate(35px, 12px) scale(1);
        }
        .MuiInputLabel-shrink {
          transform: translate(16px, -8px) scale(0.95);
        }
        .search_icon {
          position: absolute;
          left: 14px;
          top: 13px;
        }
      }
      .MuiInputBase-root {
        border-radius: 8px;
      }
      .MuiIconButton-root {
        border: 1px solid #ebeef2;
      }
      .MuiButton-contained {
        padding: 7.8px 22px;
        text-transform: capitalize;
        border-radius: 40px;
        background-color: #1976d2;
        font-size: 14px;
      }
      .MuiInputLabel-formControl {
        transform: translate(14px, 12px) scale(1);
        font-size: 12px;
      }
      .MuiSelect-select {
        font-size: 12px;
      }
      .MuiInputLabel-shrink {
        transform: translate(16px, -7px) scale(0.95);
      }
    }
  }
  .ticket_location {
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
  }
  .ticket_loadprior {
  }
  .ticket_textarea {
    border-radius: 8px;
  }
  .ticket_container_wrapper {
    display: flex;
    align-items: flex-start;
  }
  .ticket_detail {
    -webkit-tap-highlight-color: transparent;
    background: #fff;
    height: 100%;
    overflow: auto;
    position: fixed;
    transition: none;
    vertical-align: middle;
    will-change: transform;
    right: 0;
    left: auto;
    top: 180px;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform 0.5s ease, visibility 500ms;
    width: 563px;
    &.open {
      transform: translateX(0%);
      transition: transform 0.5s ease;
      visibility: visible;
    }
  }
  .ticket_container {
    background-color: #f9f9f9;
    padding: 28px 33px 24px;
    display: grid;
    gap: 14px 24px;
    flex: 1;
    cursor: pointer;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    transform: translateX(0px);
    margin-right: 0px;
    transition: margin 0.5s ease, transform 0.5s ease;
    max-height: calc(100vh - 195px);
    overflow: auto;
    &.open {
      margin-right: 563px;
    }
    .card {
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      transition: all 0.5s;
      .ticket_status {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .status {
          font-size: 10px;
          font-weight: 500;
          display: flex;
          gap: 5px;
          align-items: center;
          margin: 0;
          text-transform: capitalize;
          .dot {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            display: inline-block;
          }
        }
        .status_high {
          color: #eab308;
          .dot_high {
            background-color: #eab308;
          }
        }
        .status_medium {
          color: #61c454;
          .dot_medium {
            background-color: #61c454;
          }
        }
        .status_low {
          color: #1976d2;
          .dot_low {
            background-color: #1976d2;
          }
        }
        .status_critical {
          color: #e51400;
          .dot_critical {
            background-color: #e51400;
          }
        }
      }
      .ticket_heading {
        font-family: "Inter";
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #1f2633;
        margin: 0 0 12px 0;
      }
      .ticket_id {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 12px;
        > div {
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;
          padding: 4px 8px;
          border-radius: 20px;
          border: 1px solid #ebeef2;
          min-height: 20px;
          display: inline-flex;
          &.red {
            background-color: #ffecf4;
            color: #db2777;
          }
          &.purple {
            background-color: #ffecf4;
            color: #9333ea;
          }
          &.yellow {
            background-color: #ffecf4;
            color: #ca8a04;
          }
        }
      }
      .comments {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid #ebeef2;
        .user_img {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .chat_count {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 10px;
          line-height: 16px;
          font-weight: 800;
          color: #94a3b8;
        }
      }
      .date_time {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        font-weight: 500;
        padding-top: 12px;
        color: #94a3b8;
      }
      .ticket_datetime {
        display: flex;
      }
      .flex-parent-element {
        display: flex;
        width: 50%;
      }

      .flex-child-element {
        flex: 1;
        border: 2px solid blueviolet;
        margin: 10px;
      }

      .flex-child-element:first-child {
        margin-right: 20px;
      }
    }
  }

  .c-timeline {
    max-height: calc(100vh - 400px);
    overflow: auto;
  }
  .c-timeline_item {
    position: relative;
    display: flex;
    gap: 0.8rem;
  }
  .timeline_number {
    position: absolute;
    left: 95px;
    top: 3px;
    color: #fff;
    z-index: 99;
    font-weight: 900;
    font-size: 13px;
  }
  .c-timeline_item:last-child .c-timeline_content:before {
    display: none;
  }
  .c-timeline_content {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
  }
  .c-timeline_content:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: 0;
    height: 100%;
    width: 1px;
    border: 1px dashed rgba(204, 204, 204, 1);
  }
  .c-timeline_content:after {
    content: "";
    position: absolute;
    left: calc(0px - 11px);
    top: 0;
    width: 24px;
    height: 24px;
    background: rgba(97, 196, 84, 1);
    z-index: 1;
    border-radius: 50%;
  }
  .c-timeline_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
  }
  .c-timeline_desc {
    font-weight: 300;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    padding: 0.6em 0 0.6em 0;
    margin: 0;
    line-height: 18px;
  }
  time {
    text-align: left;
    flex: 0 0 85px;
    min-width: 0;
    overflow-wrap: break-word;
    padding-bottom: 1rem;
    font-weight: 300;
    font-size: 14px;
    color: rgba(50, 51, 56, 1);
  }
  time span {
    font-size: 12px;
  }
  .box {
    background: rgba(249, 249, 249, 1);
    padding: 15px 15px 15px 15px;
  }
  .completed {
    background: rgba(131, 194, 157, 0.2);
    width: 60px;
    height: 16px;
    color: rgba(104, 178, 102, 1);
    text-align: center;
    border-radius: 5px;
    line-height: 16px;
    font-weight: 600;
    font-size: 8px;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    color: rgba(50, 51, 56, 1);
    padding-left: 2.4em;
    padding-top: 1em;
  }
  .chat_step {
    display: flex;
    margin-top: 16px;
    font-size: 7px;
    text-align: right;
    line-height: 9px;
    font-weight: 200;
    color: rgba(25, 118, 210, 1);
  }
  .created_date {
    font-weight: 400;
    font-size: 12px;
    color: rgba(50, 51, 56, 1);
    padding: 0.6em 0 0.8em 3.4em;
  }
  .created_date span {
    color: rgba(148, 163, 184, 1);
    font-weight: 500;
  }
  .assign_by {
    font-size: 12px;
    font-weight: 500;
    padding: 0.6em 0.5em 0 3.4em;
    color: rgba(148, 163, 184, 1);
  }
  .icon_assignby {
    padding-left: 0 !important;
  }
  .name_rose {
    padding: 0.6em 0.5em 0 0.7em;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .text-right {
    padding-left: 3.4em !important;
  }
  .btn_view {
    border: 1px solid rgba(25, 118, 210, 1);
    color: rgba(25, 118, 210, 1);
    font-size: 14px;
    font-weight: 400;
    background: none;
    border-radius: 50px;
    height: 10px;
    padding: 1em 1.2em;
    margin-left: 0.8em;
  }
  .btn_view:hover {
    background: none;
  }
  .spacer_highmargin {
    border-top: 1px solid rgba(226, 232, 240, 1) !important;
    margin-top: 1.5em;
  }
  .add_ticket{
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
`;
