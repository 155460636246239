import React from "react";
import {Box} from "@mui/material";

type CompProps = {
    children: React.ReactNode;
}

const displayBoxStyle = {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    minHeight: "100px",
    width: "100%"
}

const innerBoxStyle = {
    textAlign: "center" as const,
    fontSize: "1.3em",
    fontStyle: "italic",
    color: "#555"
}

const CenteredText = ({children}: CompProps) => {
    return <Box sx={displayBoxStyle}>
        <Box sx={innerBoxStyle}>
            {children}
        </Box>
    </Box>
}

export default CenteredText;