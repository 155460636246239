import React, {useState} from "react"
import "./Contact.scss"
import {
    Box,
    Button,
    Step,
    StepButton,
    Stepper
} from "@mui/material";
import SectionContainer from "../../common/SectionContainer";
import {Carrier} from "../../interfaces/Carrier";
import {styled} from "@mui/system";
import {useHistory} from "react-router-dom";
import {SkinnyLoad} from "../../interfaces/Load";
import {ContactStep} from "./ContactStep";
import {AskQuestionsStep} from "./AskQuestionStep";
import {OutcomeViewStep} from "./OutcomeViewStep";
import {noAnswerFeedback} from "../../service/loads";
import {saveOffer} from "../../util/APIUtils";

export const TButton = styled(Button)`
  flex-grow: 1;
  margin-right: 15px;
`
const steps = ['Call Answered', 'Negotiate', 'Outcome'];

type PropTypes = {
    carrier: Carrier;
    load: SkinnyLoad;
    onContactComplete: () => void;
}

const ContactContainer = ({carrier, load, onContactComplete}: PropTypes) => {

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [loadDeclined, setLoadDeclined] = useState("none");

    const history = useHistory();

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const decline = (rate: string) => {
        if (parseInt(rate)) {
            saveOffer(load.id.toString(), carrier.id.toString(), parseInt(rate));
        }
        handleComplete();
        setLoadDeclined("yes");
        setActiveStep(2);
    }

    const accept = (rate: string) => {
        if (parseInt(rate)) {
            saveOffer(load.id.toString(), carrier.id.toString(), parseInt(rate));
        }
        handleComplete();
        setLoadDeclined("no");
        setActiveStep(2);
        //window.open("https://torch.shipwell.com/shipments/" + load.shipwellId, "_blank");
    }

    const noAnswer = () => {
        noAnswerFeedback(load.id, carrier.id)
            .then(() => {
                onContactComplete();
                history.push(`/open-loads/${load.id}`);
            });
    }

    const CurrentStep = (props: { stepNumber: number }) => {
        return props.stepNumber === 0 ? <ContactStep carrier={carrier}
                                                     load={load}
                                                     noAnswer={noAnswer}
                                                     handleComplete={handleComplete}/>
            : props.stepNumber === 1 ? <AskQuestionsStep decline={decline} accept={accept}/>
                : <OutcomeViewStep carrier={carrier} load={load} loadDeclined={loadDeclined} returnToCarrierList={returnToCarrierList}/>
    }

    const returnToCarrierList = () => {
        // let feedback = {reasons: ["no-answer"], decline: false, expiration: addHours(new Date(), 12)};
        // sendFeedback(load.id, carrier.id, feedback)
        //     .then(() => {
        //         history.push(`/open-loads/${load.id}`);
        //     });
        onContactComplete();
        history.push(`/open-loads/${load.id}`);
    }

    return (
        <SectionContainer className={"contact-container"}>
            <Box sx={{width: '100%', marginTop: '15px'}}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    <CurrentStep stepNumber={activeStep}/>
                </div>
            </Box>
        </SectionContainer>

    );
}

export default ContactContainer;
