import React, {useEffect} from 'react';
import '../Base.css';
import './Carriers.scss';

import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";

import CarrierSearchBox from "./CarrierSearchBox";
import {CarrierView} from "./CarrierView";
import {fetchCarrierByDot} from "../service/carriers";
import queryString from "query-string";

const Carriers = () => {

    const history = useHistory();

    let {path} = useRouteMatch();

    const { search } = useLocation()
    const dot = queryString.parse(search).dot;

    const setCarrier = (carrier) => {
        history.push(`/carriers/${carrier.id}`)
    }

    useEffect(() => {
        if(typeof dot === 'string') {
            fetchCarrierByDot(dot).then(json => {
                if(json.length > 0) {
                    history.push(`/carriers/${json[0].id}`)
                }
            });
        }
    },[dot, history]);


    return (
        <div className={"carrier-container"}>
            <Switch>
                <Route exact path={path}>
                    <CarrierSearchBox setCarrier={setCarrier}/>
                </Route>
                <Route path={`${path}/:carrierId`}>
                    <CarrierSearchBox setCarrier={setCarrier}/>
                    <CarrierView/>
                </Route>
            </Switch>
        </div>
    );

}

export default Carriers;
