import {Link, Paper, TextField, Typography} from "@mui/material";
import {RFPRequest} from "../interfaces/RFPRequest";
import React, {useEffect, useState} from "react";
import {Box, styled} from "@mui/system";
import Button from "@mui/material/Button";
import {deleteRFP, getRFPs, runRFP, updateRFP} from "../service/loads";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {getRFPAutomatedLoadsEnabled} from "../service/settings";
import DeleteIcon from '@mui/icons-material/Delete';
import GridOnIcon from '@mui/icons-material/GridOn';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";

const FormContainer = styled('div')`
  display: flex;
  flex-direction: row;
`

const RFPBox = styled(Box)`
  border: 1px grey;
  margin: 20px;
  padding: 10px;
`
const RFPButtonBox = styled('div')`
  margin-top: 28px;
  margin-left: 10px;
`

const RFPDateBox = styled('div')`
  margin-top: 10px;
`

const RFPTextField = styled(TextField)`
  margin: 10px;
`

const Instruction = styled(Typography)`
   font-weight: 500;
`
const SubInstruction = styled(Typography)`
   font-size: small;
`

type FormProps = {
    onChange: () => void
}

const RFPForm = ({onChange}: FormProps) => {

    const [name, setName] = useState('');
    const [shipper, setShipper] = useState('');
    const [dueDate, setDueDate] = useState(new Date());
    const [sheet, setSheet] = useState('');

    function saveAndView() {
        const requestRFP: RFPRequest = {
            dueDate: dueDate,
            sheetURL: sheet,
            shipper: shipper,
            name: name,
            status: "CREATED"
        }

        updateRFP(requestRFP).then((data => {
            onChange();
            clear();
        }))
    }

    const clear = () => {
        setName('');
        setShipper('');
        setDueDate(new Date());
        setSheet('')
    }

    const cancel = () => {
        clear();
    }


    const handleDateChange = (newValue: any | null) => {
        setDueDate(newValue);
    };

    return <div>
        <FormContainer>
            <RFPTextField name="named-input" id="named-input" label="Name" variant="outlined"
                          defaultValue={name}
                          value={name}
                          onChange={(evt) => setName(evt.target.value)}/>

            <RFPTextField name="named-input" id="named-input" label="Shipper" variant="outlined"
                          defaultValue={shipper}
                          value={shipper}
                          onChange={(evt) => setShipper(evt.target.value)}/>


        </FormContainer>
        <FormContainer>
            <RFPTextField name="named-input" id="named-input" label="Sheet URL" variant="outlined"
                          defaultValue={sheet}
                          value={sheet}
                          onChange={(evt) => setSheet(evt.target.value)}/>


            <RFPDateBox>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label="Due Date"
                        value={dueDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    />

                </LocalizationProvider>
            </RFPDateBox>

            <RFPButtonBox>
                <Button variant={"outlined"}
                        color={"success"}
                        sx={{marginRight: "10px"}}
                        onClick={(evt) => saveAndView()}>Add and Run</Button>

                <Button variant={"outlined"}
                        color={"error"}
                        onClick={(evt) => cancel()}>Cancel</Button>
            </RFPButtonBox>
        </FormContainer>
    </div>
}

type TableProps = {
    rows: RFPRequest[];
    onChange: () => void;
}

const RFPTable = ({rows, onChange}: TableProps) => {

    function formatDateFromString(date:string | undefined) {
        if (date) {
            return new Date(date).toLocaleDateString('en-us', {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        }
        return "";
    }

    const askAndDeleteRFP = (id: number | undefined) => {
        if (window.confirm("Really delete this RFP?")) {
            if (id) {
                deleteRFP(id).then(data => {
                    onChange();
                });
            }
        }
    }

    const reRunFlow = (id: number | undefined) => {
        if (id) {
            runRFP(id);
        }
    }

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'shipper', headerName: 'Shipper', width: 200 },
        { field: 'due', headerName: 'Due Date', width: 200,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography>{formatDateFromString(params?.row.dueDate)}</Typography>
            ),
        },
        { field: 'sheet', headerName: 'Sheet', width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button onClick={() => openInNewTab(params?.row.sheetURL)}>RFP Sheet</Button>
            ),
        },
        { field: 'run', headerName: 'Run', width: 70,
            renderCell: (params: GridRenderCellParams<number>) => (
                <DirectionsRunIcon onClick={() => reRunFlow(params?.row.id)}/>
            ),
        },
        { field: 'remove', headerName: 'Remove', width: 70,
            renderCell: (params: GridRenderCellParams<number>) => (
                <DeleteIcon onClick={() => askAndDeleteRFP(params?.row.id)}/>
            ),
        }
    ];

    return (
        <div>
    <Paper sx={{ height: 1000, width: '75%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
            sx={{ border: 0}}
            getRowId={(row: any) => row.id}
            autoHeight
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
        />
    </Paper>
        </div>
    );
}

const RFP = () => {

    const [rows, setRows] = useState<RFPRequest[]>();

    function getRFPList() {
        getRFPs().then(data => {
            setRows(data);
        })
    }

    useEffect(() => {
        getRFPList();
    }, []);

    if (!getRFPAutomatedLoadsEnabled())
        return null;

    return (
        <div className={"rfp-tools"}>
            <RFPBox>
                <Typography className={"integrations-title"} variant={'h6'}>
                    RFP Automation
                </Typography>
            </RFPBox>
            <RFPBox>
                <Instruction>1. Clone the RFP Template <Link target={'_blank'}
                    href={'https://docs.google.com/spreadsheets/d/1muEAk4A7Xn12d1xMfy01yvAnNu13f3Na_nOqReby3Kw/copy?gid=169078431#gid=169078431'}><GridOnIcon>here</GridOnIcon></Link> and copy and paste the new URL in Sheet URL below.</Instruction>
                <SubInstruction>
                    <Link target={'_blank'} href={'https://docs.google.com/document/d/1jCKAQorBVqxkEnbZ0m-7v1J71XroJNG6Wylvz-X7p_k/edit?usp=sharing'}>Google Sheet help is available.</Link>
                </SubInstruction>
            </RFPBox>
            <RFPBox>
                <Instruction className={"integrations-title"}>
                    2. Create an RFP Request
                </Instruction>
                <RFPForm onChange={getRFPList}></RFPForm>
            </RFPBox>
            <RFPBox>
                <Instruction className={"integrations-title"}>
                    Current RFPs
                </Instruction>
                {rows ?
                    <RFPTable onChange={getRFPList} rows={rows}></RFPTable>
                    :
                    <div>No RFPs</div>
                }
            </RFPBox>
        </div>
    );
}
export default RFP;
