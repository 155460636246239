import {API_BASE_URL} from "../constants";
import {torchFetch} from "../util/APIUtils";

const QUEUE_KEY = "events.currentQueue";

type UsageEventParams = {
    type: string;
    loadId?: number;
    carrierId?: number;
    laneId?: string;
    loadboardReason?:boolean;
    otherReason?:string;
    rateReason?:boolean;
    laneReason?:boolean;
    contactForOrigin?:boolean;
    contactForDestination?:boolean;
}

type UsageEvent = {
    type: string;
    occurredAt: Date;
    data: any;          // can be anything
}

// legacy
export const CARRIER                    = "carrier";
export const LOAD                       = "load";

// v2 events
export const JETPACK_FILTER_OPEN_LOADS              = "jetpack.open-loads.filter";        // context will be useful on this one
export const JETPACK_LOAD_SELECTED                  = "jetpack.open-loads.load.selected";
export const JETPACK_OPEN_LANE_MAP                  = "jetpack.open-loads.map";
export const JETPACK_OPEN_LANE_SWITCH_DIALOG        = "jetpack.open-loads.lane-switch";
export const JETPACK_OPEN_CARRIER_CONTACT_PANEL     = "jetpack.open-loads.contact";
export const JETPACK_CARRIER_SELECTED               = "jetpack.open-loads.carrier.selected";
export const JETPACK_CARRIER_DAT_SELECTED           = "jetpack.open-loads.carrier.dat-selected";
export const JETPACK_SHOW_DAT_RECOMMENDATIONS       = "jetpack.open-loads.dat-recommendations";
export const JETPACK_SHOW_CARRIER_PAST_ACTIVITIES   = "jetpack.open-loads.carrier.past-activities";
export const JETPACK_SHOW_CARRIER_PAST_LOADS        = "jetpack.open-loads.carrier.past-loads";
export const JETPACK_SHOW_CARRIER_NOTES             = "jetpack.open-loads.carrier.notes";
export const JETPACK_CARRIER_LOOKUP                 = "jetpack.open-loads.carrier.lookup";
export const JETPACK_CALL_CARRIER_INTENT            = "jetpack.open-loads.carrier.call";
export const JETPACK_EMAIL_CARRIER_INTENT           = "jetpack.open-loads.carrier.email";
export const JETPACK_BOOK_LOAD_INTENT               = "jetpack.open-loads.carrier.book";

export function sendLoadUsageEvent(loadId): void {
    postUsageEvent({
        type: LOAD,
        loadId: loadId,
    });
}

export function sendCarrierUsageEvent(carrierId): void {
    postUsageEvent({
        type: CARRIER,
        carrierId: carrierId,
    });
}

// eventually we should put the currently selected load and carrier into
// a recoil atom / context and just fetch it for the caller to make things
// simpler

export function postUsageEvent({type, loadId, carrierId, laneId}: UsageEventParams) {
    console.log(`[event] Adding event to queue for type ${type}`);
    addToEventQueue({
        type: type,
        occurredAt: new Date(),
        data: {
            loadId: loadId,
            carrierId: carrierId,
            laneId: laneId
        }
    })
}

export function postCarrierUsageEvent({type, loadId, carrierId, laneId, loadboardReason,
                                          rateReason, laneReason, contactForOrigin, contactForDestination }: UsageEventParams) {
    console.log(`[event] Adding event to queue for type ${type}`);
    addToEventQueue({
        type: type,
        occurredAt: new Date(),
        data: {
            loadId: loadId,
            carrierId: carrierId,
            laneId: laneId,
            loadboardReason:loadboardReason,
            rateReason:rateReason,
            laneReason:laneReason
        }
    })
}

/*
    Private functions to support managing and sending usage events below - Implementation meant to keep
    the details of how the persistence and sending hidden from callers
*/

/**
 * Adds a single event to existing event queue
 *
 * @param event
 */
function addToEventQueue(event: UsageEvent): void {
    addUsageEventsToQueue([event]);
}

/**
 * Adds the array of usage events to the existing event queue
 *
 * Does not look for duplicates. Adds regardless of current contents of the usage events queue.
 *
 * @param events
 */
function addUsageEventsToQueue(events: UsageEvent[]): void {
    const queue = getCurrentEventQueue();
    const merged = queue.concat(events);
    localStorage.setItem(QUEUE_KEY, JSON.stringify(merged));
}

function getCurrentEventQueue(): UsageEvent[] {
    return JSON.parse(getLocalStorageItem(QUEUE_KEY, "[]")) as UsageEvent[];
}

function clearEventQueue(): void {
    localStorage.setItem(QUEUE_KEY, "[]");
}

function getLocalStorageItem(key: string, defaultValue: string): string {
    return localStorage.getItem(key) || defaultValue;
}

setInterval(sendEvents, 10000);

const usageEventUrl = new URL(`${API_BASE_URL}/jetpack-usage`);

function sendEvents() {
    // zero out the queue data after pulling it.  If there is an error during the post which
    // is asynchronous then we'll put the data back in the queue and try to send later.
    const queueData = getCurrentEventQueue();
    clearEventQueue();

    if(queueData.length > 0) {
        torchFetch({
            url: usageEventUrl,
            method: "post",
            body: JSON.stringify(queueData)
        }).then(success => {/* do nothing */}, failure => {
            console.log(`Failed to send events to API, ${JSON.stringify(failure)}`)
            addUsageEventsToQueue(queueData);
        });
    }
}

