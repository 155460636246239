import React, {useEffect, useState} from "react";
import {getActiveWorkflowTasks, TaskWithOptions} from "./workflow";
import SectionContainer from "../common/SectionContainer";
import {styled} from "@mui/system";
import {List, ListItem, ListItemButton} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import {getTimeFormat} from "../service/time";
import Moment from "react-moment";

const TaskListItem = styled(ListItem)`
  border: 1px solid #999;
  border-radius: 10px;
  margin-bottom: 20px;
`

const TaskListItemButton = styled(ListItemButton)`
  border-radius: 9px;

  &.green {
    background-color: #3832;
  }

  &.yellow {
    background-color: #cfa30122;
  }

  &.red {
    background-color: #8332;
  }
`
const ButtonLayout = styled('div')`
  display: flex;
  flex-direction: column;
`

const TaskTitle = styled('div')`
  font-weight: bold;
  font-size: 18pt;
  color: #333;
  padding: 5px 0;
`

const TaskDue = styled('div')`
  color: #666;
  padding: 5px 0;

  display: flex;
  gap: 15px;

  & span.label {
    font-weight: bold;
    padding-right: 5px;
  }
`

const TLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`

const AddLink = styled(TLink)`
  font-size: 12pt;
  font-weight: normal;
  margin-left: 10px;
`

const Title = styled('div')`
  font-size: 14pt;
  font-weight: bold;
  color: #444;
  margin-bottom: 15px;
`

const Workflows = () => {

    const [workflowTasks, setWorkflowTasks] = useState<TaskWithOptions[]>([]);
    const history = useHistory();

    useEffect(() => {
        getActiveWorkflowTasks().then(setWorkflowTasks)
    }, []);

    const TitleElement = () => {
        return <Title>Active Workflows
            <AddLink to={"/workflow/loads-to-track"}>Add loads available for tracking</AddLink>
        </Title>
    }

    if (workflowTasks.length === 0) {
        return <SectionContainer title={<TitleElement/>}
                                 border={false}
                                 noContent={true}
                                 noContentMessage={"No Workflow Tasks"}/>
    }

    function openTask(task: TaskWithOptions) {
        history.push(`/workflow/${task.task.load.id}`);
    }

    function getTaskStatus(task: TaskWithOptions): string {
        const due = new Date(task.task.due);
        const overdue = new Date(task.task.overdue);
        const now = new Date();

        if (now > overdue) {
            return "red";
        }

        if (now < overdue && now > due) {
            return "yellow";
        }

        return "green";
    }

    return <SectionContainer title={<TitleElement/>} border={false}>
        <List>
            {workflowTasks.map(task =>
                <TaskListItem disablePadding key={task.task.id}>
                    <TaskListItemButton onClick={() => openTask(task)} className={getTaskStatus(task)}>
                        <ButtonLayout>
                            <TaskTitle>{task.task.label}</TaskTitle>
                            <TaskDue>
                                <div>
                                    <span className={"label"}>Due:</span>
                                    <Moment format={getTimeFormat()}>{task.task.due}</Moment>
                                    &nbsp;(<Moment fromNow>{task.task.due}</Moment>)
                                </div>
                                <div>
                                    <span className={"label"}>Past Due:</span>
                                    <Moment format={getTimeFormat()}>{task.task.overdue}</Moment>
                                    &nbsp;(<Moment fromNow>{task.task.overdue}</Moment>)
                                </div>
                            </TaskDue>
                        </ButtonLayout>
                    </TaskListItemButton>
                </TaskListItem>
            )}
        </List>
    </SectionContainer>
}

export default Workflows;