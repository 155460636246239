
/**
 * Convienence method for dealing with local storage and insuring that a value is always returned.
 *
 * @param key
 * @param defaultValue
 */
function getLocalStorageItem(key: string, defaultValue: string): string {
    if(localStorage.getItem(key)) {
        return localStorage.getItem(key) as string;
    } else {
        return defaultValue;
    }
}

function getBooleanLocalStorageItem(key: string, defaultValue: boolean): boolean {
    if(localStorage.getItem(key)) {
        return localStorage.getItem(key) === "true";
    } else {
        return defaultValue;
    }
}

function setBooleanLocalStorageItem(key: string, value: boolean) {
    localStorage.setItem(key, value ? "true" : "false");
}

export {
    getLocalStorageItem,
    getBooleanLocalStorageItem,
    setBooleanLocalStorageItem
};
