import React, {useEffect, useState} from "react";
import {Load} from "../interfaces/Load";
import {addLoadToTracking, getLoadsAvailableForTracking} from "./workflow";
import {Link} from "@mui/material";
import {styled} from "@mui/system";
import {getCityStateLabel, getDeliveryStop, getPickupStop} from "../util/load-utils";
import {useHistory} from "react-router-dom";

const TLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`

const ReturnLink = styled(TLink)`
  font-size: 12pt;
  font-weight: normal;
  margin-left: 10px;
`

const LoadList = styled('div')`
  padding: 20px;
`

const Title = styled('div')`
  font-size: 14pt;
  font-weight: bold;
  color: #444;
  margin-bottom: 15px;
`

const LoadListGrid = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "action ref-id load-state origin dest shipper";
  margin-bottom: 15px;
  column-gap: 15px;
`

const RefId = styled('div')`
  grid-area: ref-id;
`

const LoadState = styled('div')`
  grid-area: load-state;
`

const Origin = styled('div')`
  grid-area: origin;
`

const Dest = styled('div')`
  grid-area: dest;
`

const Shipper = styled('div')`
  grid-area: shipper;
`

const Action = styled('div')`
  grid-area: action;
  font-weight: bold;
`

const LoadsAvailableForWorkflow = () => {

    const [loads, setLoads] = useState<Load[]>([]);
    const history = useHistory();

    useEffect(() => {
        refreshLoads();
    }, []);

    function refreshLoads() {
        getLoadsAvailableForTracking().then(setLoads);
    }

    function addToTracking(load: Load) {
        addLoadToTracking(load.id).then(refreshLoads);
    }

    return <div>
        <LoadList>
            <Title>Loads available for workflow <ReturnLink onClick={() => history.push("/workflow")}>Return to Tracking Tasks</ReturnLink></Title>
            {loads.map(load => {
                return <LoadListGrid key={load.id}>
                    <RefId>{load.shipwellRefId}</RefId>
                    <LoadState>{load.loadState}</LoadState>
                    <Origin>{getCityStateLabel(getPickupStop(load))}</Origin>
                    <Dest>{getCityStateLabel(getDeliveryStop(load))}</Dest>
                    <Shipper>{load.shipper.name}</Shipper>
                    <Action><TLink onClick={() => addToTracking(load)}>Add</TLink></Action>
                </LoadListGrid>;
            })}
        </LoadList>
    </div>;
}

export default LoadsAvailableForWorkflow;