import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {TextField} from "@mui/material";
import ControlWrapper from "./ControlWrapper";
import {TaskField} from "../workflow";
import {FieldValueChanged} from "../Outcome";
import {DateTimePicker} from "@mui/x-date-pickers";

type FieldProps = {
    field: TaskField,
    onChange: FieldValueChanged,
    value: string
};

const WorkflowDateTime = ({field, onChange, value}: FieldProps) => {

    const handleChange = (newValue: Date | null) => {
        const newDate = newValue ? newValue.toISOString() : '';
        onChange(field.name, newDate);
    };

    return <ControlWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label={field.label}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    </ControlWrapper>;
}

export default WorkflowDateTime;