import {Load, SkinnyLoad, Stop} from "../interfaces/Load";

/**
 * Load label is origin city to destination city
 */
export function getLoadLabel(load: SkinnyLoad) {
    const first = load.stops[0].address;
    const last = load.stops[load.stops.length - 1].address;
    return `${first.city} ${first.state} - ${last.city} ${last.state}`.toLowerCase();
}

export function getPickupStop(load: SkinnyLoad): Stop {
    return load.stops[0];
}

export function getDeliveryStop(load: SkinnyLoad): Stop {
    return load.stops[load.stops.length - 1];
}

export function getCityStateLabel(stop: Stop): string {
    return `${stop.address.city}, ${stop.address.state}`;
}

export function getShortDescription(load: SkinnyLoad) {
    if (load.shortDescription) {
        const d = load.shortDescription;
        return d.charAt(0).toUpperCase() + d.slice(1)
    }

    if (load.loadDescription) {
        const lastComma = load.loadDescription.lastIndexOf(",");
        if (lastComma >= 0) {
            return load.loadDescription.substring(lastComma + 1).trim();
        } else {
            return load.loadDescription;
        }
    }

    return "N/A";
}

export function getLaneCities(load) {
    return [load.stops[0].address.city, load.stops[load.stops.length - 1].address.city];
}

/*export function loadMargin(load) {
    return (load.carrierCost / 100).toFixed(2)  (load.margin.toFixed(2)
}*/

/**
 * Return a currency string for the given value in cents - most of our financial data is
 * transmitted in cents since it's easier to deal with than decimals.
 */
export function formatCents(cents) {
    return "$" + (cents / 100).toFixed(2);
}

export function displayTime(isoTime, timeZone = 'America/Chicago', timeZoneDisplay = '') {
    timeZone = timeZone || 'America/Chicago'; // missing time zone blows the page up
    const time = new Date(isoTime).toLocaleString('en-US', {timeZone: timeZone});
    const tzDisplay = timeZoneDisplay.length > 0 ? " (" + timeZoneDisplay + ")" : '';
    return time + tzDisplay;
}

export function getLoadDeliveryTime(load: SkinnyLoad | Load) : Date {
    return new Date(load.stops[load.stops.length - 1].arrival);
}

export function userInitials(name) {
    return name ? name.split(' ').map(i => i.charAt(0)).join('').toUpperCase() : "N/A";
}

/* Translates the payload type into a more friendly readable name */
export function getLabelForPayloadType(payloadType: string) {
    if("DRY_VAN" === payloadType) {
        return "Dry Van";
    } else {
        return payloadType;
    }
}

export function getStatusLabel(status: string | undefined): string | undefined {
    if(status === "AT_DELIVERY") {
        return "At Delivery";
    } else if (status === "AT_PICKUP") {
        return "At Pickup";
    } else if (status === "DISPATCHED") {
        return "Dispatched";
    } else if (status === "IN_TRANSIT") {
        return "In Transit";
    } else if (status === "TENDERED") {
        return "Tendered";
    }
    return status;
}
