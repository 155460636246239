import React from "react";
import {DatRateForLoad} from "../interfaces/RateData";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {dateFormat} from "../service/time";
import {styled} from "@mui/system";

type Props = {
    rate: DatRateForLoad;
    level: "full" | "minimal";
};

const Rate = styled('span')`
  color: #333;
`

const LowRate = styled('span')`
  color: darkgreen;
  font-size: 0.9em;
  margin-right: 10px;
`

const HighRate = styled('span')`
  color: darkred;
  font-size: 0.9em;
`

const DatBreakout = styled('span')`
  font-size: 0.9em;
  color: #555;
  margin-left: 10px;
`

// couldn't figure out a way to do this other than from the examples
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const DatRate = ({rate, level}: Props) => {

    function moneyInDollars(money: number): string {
        return money.toFixed(2);
    }

    if(level === "minimal") {
        const tooltip = `Date: ${dateFormat(rate.created)} Fuel: $${rate.fuel} 
            Rate: $${rate.rate} Low: $${rate.rateLow} High $${rate.rateHigh}`;

        return <NoMaxWidthTooltip title={tooltip}>
            <div>${moneyInDollars(rate.rate + rate.fuel)}</div>
        </NoMaxWidthTooltip>
    }

    if(level === "full") {
        return <>
            <Tooltip title={`DAT Rate from ${dateFormat(rate.created)}`}>
                <div>
                    <Rate>${moneyInDollars(rate.rate + rate.fuel)}</Rate>
                    <DatBreakout>(Fuel: ${moneyInDollars(rate.fuel)} Rate:
                        ${moneyInDollars(rate.rate)})</DatBreakout>
                </div>
            </Tooltip>
            <div>
                <LowRate>${moneyInDollars(rate.fuel + rate.rateLow)}</LowRate>
                <HighRate>${moneyInDollars(rate.fuel + rate.rateHigh)}</HighRate>
            </div>
        </>
    }
    return <div></div>
}

export default DatRate;