import React, {useEffect, useState} from "react";
import {Facility, FacilityHour} from "../interfaces/Facility";
import {Stop} from "../interfaces/Load";
import MDEditor from "@uiw/react-md-editor";
import Loading from "../common/Loading";
import {dayOfWeek, dowAbbreviation} from "../util/general";
import ExternalLink from "../common/Links";
import {getFacilityHours} from "../facility/facility-services";

type Props = {
    stop: Stop;
    facility: Facility;
}

const FacilityDetails = ({stop, facility}: Props) => {

    const [facilityHours, setFacilityHours] = useState<FacilityHour[]>([]);
    const [facilityHoursLoading, setFacilityHoursLoading] = useState(false);
    const [note, setNote] = useState('');

    useEffect(() => {
        if (facility) {
            setFacilityHoursLoading(true);
            getFacilityHours(facility).then(fh => {
                setFacilityHours(fh);
                setFacilityHoursLoading(false);
            }).catch(err => {
                setFacilityHours([]);
                setFacilityHoursLoading(false);
            });
        }

        if(facility.internalNote && facility.internalNote.length > 0) {
            setNote(facility.internalNote);
        } else {
            setNote("*No facility notes have been created.*")
        }
    }, [facility]);



    function styleForPickupDay(dow: string): string {
        if (stop && dayOfWeek(stop.arrival.getDay()) === dow) {
            return "selected";
        } else {
            return "";
        }
    }


    return <>
        <div className={"facility-info-container"}>
            {facility.state === 'INCOMPLETE' ?
                <div className={"status-bar"}>
                    <div>Facility information has not been approved yet</div>
                </div>
                : null
            }

            <MDEditor.Markdown source={note} className={"markdown"}/>

            <div className={"hours"}>
                {facilityHoursLoading ? <Loading/>
                    : facilityHours.length === 0 ?
                        <div>Facility hours have not been set yet. Check with Shipper's Account Manager.</div>
                        : facilityHours.map(fh => <div className="hour-row" key={fh.id}>
                            <div
                                className={"day " + styleForPickupDay(fh.dayOfWeek)}>{dowAbbreviation(fh.dayOfWeek)}</div>
                            {fh.isOpen ? <div
                                    className={"open-close " + styleForPickupDay(fh.dayOfWeek)}>{fh.open} - {fh.close}</div>
                                : <div className={"open-close " + styleForPickupDay(fh.dayOfWeek)}>Closed</div>}
                        </div>)
                }
            </div>

            <div className={"facility-link"}>
                <ExternalLink href={`/facilities/${facility.id}`}>Facility Details &rarr;</ExternalLink>
            </div>
        </div>
    </>
}

export default FacilityDetails;