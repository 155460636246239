import {torchFetch} from "../util/APIUtils";
import {API_BASE_URL} from "../constants";
import {Territory} from "../interfaces/Territory";

export function getTerritories(): Promise<Territory[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/territory`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    });
}

export function getUserTerritory(): Promise<Territory> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/territory/user`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    });
}
