import React, {useEffect, useState} from "react";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from "@mui/material";
import { Facility } from "../interfaces/Facility";
import { Download } from "@mui/icons-material";
import { downloadFacilityHistoricalData, getFacilityHistoricalData } from "./facility-services";

// the outer container will handle the error if the update fails
type HistoricalDataProps = {
    facility: Facility;
}

const FacilityHistoricalData = ({facility}: HistoricalDataProps) => {

    const [alignment, setAlignment] = useState<string>("");
    const [historicalFilterData, setHistoricalFilterData] = useState({})
    // const [historicalDataWeek, setHistoricalDataWeek] = useState<HistoricalData[]>([])

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
        const [response1, response2, response3, response4, response5] = await Promise.all([
            getFacilityHistoricalData(facility.id, "DAY_OF_THE_WEEK"),
            getFacilityHistoricalData(facility.id, "MONTH"),
            getFacilityHistoricalData(facility.id, "QUARTER"),
            getFacilityHistoricalData(facility.id, "YEAR"),
            getFacilityHistoricalData(facility.id, "TIME_OF_THE_DAY")  
        ]);
        const newData = {}
        newData["DAY_OF_THE_WEEK"] = response1;
        newData["MONTH"] = response2;
        newData["QUARTER"] = response3;
        newData["YEAR"] = response4;
        newData["TIME_OF_THE_DAY"] = response5;
        setHistoricalFilterData(newData)
        setAlignment("DAY_OF_THE_WEEK")
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    
    }, [facility.id]);

    // useEffect(() => {

    //     getFacilityHistoricalData(facility.id, filterName)
    //     .then(data => {
    //         setHistoricalDataWeek(data);
    //         setAlignment(filterName);
    //         const newData = historicalFilterData
    //         newData[filterName] = data
    //         setHistoricalFilterData(newData)
    //         console.log(historicalFilterData)
    //     }).catch(error => console.error(error));
    // }, [facility,filterName]);

    const downloadExcel = () => {
        downloadFacilityHistoricalData(facility.id)
        .then(excelData => {
            const byteString = new TextDecoder().decode(excelData);
            const arrayBuffer = base64ToArrayBuffer(byteString)
            var byteArray = new Uint8Array(arrayBuffer);
            var a = window.document.createElement('a');   
            a.href = window.URL.createObjectURL(new Blob([byteArray], { type:'application/octet-stream' }));
            a.download = `Facility_List_${Date.now()}.xlsx`;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
        }).catch(error => console.error(error));
    }

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
    }

    return <>
        <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      disabled={alignment !== "" ? false : true}
      sx={{margin:2}}
    >
      <ToggleButton value="DAY_OF_THE_WEEK">Day Of the Week</ToggleButton>
      <ToggleButton value="MONTH">Month</ToggleButton>
      <ToggleButton value="QUARTER">Quarter</ToggleButton>
      <ToggleButton value="YEAR">Year</ToggleButton>
      <ToggleButton value="TIME_OF_THE_DAY">Time of the day</ToggleButton>
    </ToggleButtonGroup>

    <Tooltip   
        title={<Typography fontSize={15}>Download Facility Historical Data</Typography>}
        placement="top" 
    >
        <IconButton color="primary" aria-label="download">
            <Download onClick={downloadExcel}  />
        </IconButton>
    </Tooltip>


    <div className={"past-loads-for-lane"}>
                {/* {title ? <h4>Past Loads in Lane</h4> : null} */}
                <TableContainer>
                    <Table 
                        aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{alignment.split("_").join(" ")}</TableCell>
                                <TableCell>On Time/Delay</TableCell>
                                <TableCell>Avg Wait Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { historicalFilterData[alignment] ?
                                historicalFilterData[alignment].map((row) => (
                                <TableRow key={row.title}>
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{row.status === "On Time" ? <p style={{  color: "#008240", fontWeight: "bold" }}>{row.status}</p> : <p style={{  color: "#E87722", fontWeight: "bold" }}>{row.status}</p>}</TableCell>
                                <TableCell>{row.avgWaitTime}</TableCell>
                            </TableRow>
                            )): ""}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
    </>
}

export default FacilityHistoricalData;
