import React from "react";
import {styled} from "@mui/system";
import {TaskOption} from "./workflow";
import OutcomeOptionFields from "./OutcomeOptionFields";

type Props = {
    option: TaskOption;
    dataChangedHandler: (option: TaskOption, data: Map<string, string>) => void;  // probably needs a response type for error handling
}

const OutcomeLayout = styled('div')`
  display: flex;
  flex-direction: column;
`

const SimpleOutcomeForm = ({option, dataChangedHandler}: Props) => {

    function dataUpdatedHandler(updated: Map<string, string>) {
        dataChangedHandler(option, updated);
    }

    return <OutcomeLayout>
        <OutcomeOptionFields fields={option.fields} dataChangedHandler={dataUpdatedHandler}/>
    </OutcomeLayout>;
}

export default SimpleOutcomeForm;