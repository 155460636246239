import React, {useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import {formatPhoneNumber} from "../../util/carrier-utils";
import {Carrier} from "../../interfaces/Carrier";
import {TButton} from "./ContactContainer";
import {JETPACK_CALL_CARRIER_INTENT, JETPACK_EMAIL_CARRIER_INTENT, postUsageEvent} from "../../service/events";
import {SkinnyLoad} from "../../interfaces/Load";
import {sendOutreach} from "../../service/carriers";
import {LOADY_URL} from "../../constants";

type PropTypes = {
    handleComplete: () => void,
    noAnswer: () => void,
    carrier: Carrier,
    load: SkinnyLoad
}

export const ContactStep = ({handleComplete, noAnswer, carrier, load}: PropTypes) => {

    const [phoneNumberCopied, setPhoneNumberCopied] = useState(false);
    const [emailCopied, setEmailCopied] = useState(false);
    const [onboardingSent, setOnboardingSent] = useState(false);

    const EmailView = () => {
        if (carrier.primaryEmail) {
            return <div className={"contact-info"}>
                    <span className={"copyable"}
                          onClick={copyEmailToClipboard}>
                        {carrier.primaryEmail}
                    </span>
                <span className={"action"}>
                        <a href={`mailto:${carrier.primaryEmail}`}
                           onClick={sendEmailIntent}
                           target={"_blank"}
                           rel={"noreferrer"}>Send Email</a>
                    </span>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={emailCopied}
                    autoHideDuration={3000}
                    onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity="success">
                        Email Copied
                    </Alert>
                </Snackbar>
            </div>
        } else {
            return null;
        }
    }

    const PhoneView = () => {
        if (carrier.phoneNumber) {
            return <div className={"contact-info"}>
                    <span className={"copyable"}
                          onClick={copyPhoneToClipboard}>
                        {formatPhoneNumber(carrier.phoneNumber)}
                    </span>
                <span className={"action"}>
                        <a href={`tel:${carrier.phoneNumber}`} onClick={sendCallIntent}>Make Call</a>
                    </span>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={phoneNumberCopied}
                    autoHideDuration={3000}
                    onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity="success">
                        Phone Number Copied
                    </Alert>
                </Snackbar>
            </div>
        } else {
            return null;
        }
    }

    function copyPhoneToClipboard() {
        sendCallIntent();
        navigator.clipboard.writeText(formatPhoneNumber(carrier.phoneNumber)).then(() => {
            setPhoneNumberCopied(true);
        });
    }

    function copyLinkWithLoadToClipboard() {
        navigator.clipboard.writeText(LOADY_URL + `/load/${carrier.publicId}/${load.shipwellRefId}`);
    }

    function sendCallIntent() {
        postUsageEvent({
            type: JETPACK_CALL_CARRIER_INTENT,
            loadId: load.id,
            carrierId: carrier.id
        });
    }

    function copyEmailToClipboard() {
        sendEmailIntent();
        navigator.clipboard.writeText(carrier.primaryEmail).then(() => {
            setEmailCopied(true);
        });
    }

    function sendEmailIntent() {
        postUsageEvent({
            type: JETPACK_EMAIL_CARRIER_INTENT,
            loadId: load.id,
            carrierId: carrier.id
        });
    }

    const handleAlertClose = (event) => {
        console.log(event);
        setPhoneNumberCopied(false);
        setEmailCopied(false);
    }

    const sendOutreachEmail = () => {
        let carrierEmail = carrier.primaryEmail;
        console.log("sending outreach email to " + carrierEmail);
        if (window.confirm("Really send an Email to " + carrierEmail + " ?")) {
            sendOutreach(carrier.id, load.id).then(result => {
                setOnboardingSent(true);
            });
        }
    }

    function handleSMSStatusClose() {
        setOnboardingSent(false);
    }

    return <div className={"step-container"}>
        <div className={"initial-contact-container"}>
            <div className={"carrier-contact-control"}>
                <div className={"header"}>Contact Information</div>
                <PhoneView/>
                <EmailView/>
            </div>
            <div className={"feedback"}>
                <p>Did the Carrier Answer the Call?</p>
                <TButton variant={"outlined"} color={"secondary"} onClick={noAnswer}>No Answer</TButton>
                <TButton variant={"outlined"} color={"success"} onClick={handleComplete}>Yes</TButton>

            </div>
            <div className={"outreach-email"}>
                <TButton variant={"outlined"} color={"success"} onClick={sendOutreachEmail}>Send Outreach Email</TButton>
            </div>
            <div className={"outreach-link"}>
                <TButton variant={"outlined"} color={"success"} onClick={copyLinkWithLoadToClipboard}>Copy Loadboard Link</TButton>
            </div>
        </div>

        <Snackbar open={onboardingSent}
                  autoHideDuration={6000}
                  onClose={handleSMSStatusClose}
                  message={"Email sent to " + carrier.primaryEmail}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}/>

    </div>


}
