import React, {useEffect, useState} from "react";
import {Load} from "../interfaces/Load";
import {getAllWorkflowContextData, WorkflowContextEntry} from "./workflow";
import {styled} from "@mui/system";

const ContextEntry = styled('div')`
  color: darkblue;
  display: flex;
  flex-direction: row;
  flex-grow: 1fr 1fr *;

  &.deleted {
    color: darkred;
  }
`

type Props = {
    load: Load;
}

const WorkflowContextEntryList = ({load}: Props) => {

    const [entries, setEntries] = useState<WorkflowContextEntry[]>([]);

    useEffect(() => {
        getAllWorkflowContextData(String(load.id)).then(setEntries);
    }, [load.id]);

    return <div>
        <h4>Workflow Task Audit History</h4>
        {entries.map(entry => <ContextEntry key={entry.id} className={entry.retracted ? "deleted" : "normal"}>
            {entry.key} - {entry.value}
        </ContextEntry>)}
    </div>;
}

export default WorkflowContextEntryList;