import React, {useState} from "react";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import {useHistory, useLocation} from "react-router-dom";
import SectionContainer from "../common/SectionContainer";
import "./OpenLoads.scss";
import {Feedback, Load} from "../interfaces/Load";
import CarrierSearchBox from "../carrier/CarrierSearchBox";
import {useRecoilState} from "recoil";
import {suggestedCarrierState} from "./openloads.state";
import DatCarrierList from "./DatCarrierList";
import TorchCarrierList from "./TorchCarrierList";
import {
    postUsageEvent,
    JETPACK_SHOW_DAT_RECOMMENDATIONS,
    JETPACK_CARRIER_SELECTED,
    JETPACK_CARRIER_DAT_SELECTED
} from "../service/events";

type PropTypes = {
    load: Load;
    feedback?: Feedback[];
    carrierLookupPanel: boolean;
    carrierLookupFinished?: () => void;
}

const CarrierList = ({load, carrierLookupPanel, carrierLookupFinished}: PropTypes) => {

    const [source, setSource] = useState("recommended");
    const [, setSuggestedCarrier] = useRecoilState(suggestedCarrierState);

    const history = useHistory();
    const location = useLocation();

    const carrierSelected = (carrier: SuggestedCarrier) => {
        setSuggestedCarrier({isSet: true, value: carrier});
        const carrierId = carrier.carrier.id;

        postUsageEvent({
            type: JETPACK_CARRIER_SELECTED,
            loadId: load.id,
            carrierId: carrierId
        });

        history.push(location.pathname + `/carrier/${carrierId}`);
    }

    const handleDatCarrierSelected = (carrier: SuggestedCarrier) => {
        setSuggestedCarrier({isSet: true, value: carrier});
        const carrierId = carrier.carrier.id;

        postUsageEvent({
            type: JETPACK_CARRIER_DAT_SELECTED,
            loadId: load.id,
            carrierId: carrierId
        });
        history.push(location.pathname + `/carrier/${carrierId}`);
    }

    const switchSources = () => {
        if (source === "recommended") {
            postUsageEvent({
                type: JETPACK_SHOW_DAT_RECOMMENDATIONS,
                loadId: load.id
            });
            setSource("dat");
        } else {
            setSource("recommended");
        }
    }

    const CarriersHeader = () => {
        if (source === "recommended") {
            return <div className={"list-header carrier"}>
                <div className={"title"}>Recommended Carriers<span className={"alt-options"}
                                                                   onClick={switchSources}>Switch to DAT</span></div>
                <div className={"total-loads"}>Total Loads</div>
                <div className={"recent-loads"}>Recent Loads</div>
                <div className={"average-margin"}>Average Margin</div>
                <div className={"total-power-units"}>Power Units</div>
            </div>
        } else {
            return <div className={"list-header dat"}>
                <div className={"title"}>DAT Carriers<span className={"alt-options"}
                                                           onClick={switchSources}>Switch to Recommended</span></div>
                <div className={"total-loads"}>&nbsp;</div>
                <div className={"recent-loads"}>Last Updated</div>
                <div className={"average-margin"}>Location</div>
                <div className={"total-power-units"}>Distance</div>
            </div>;
        }
    }

    const setCarrier = (carrier) => {
        if (carrierLookupFinished) carrierLookupFinished();
        history.push(location.pathname + `/carrier/${carrier.id}`);
    }

    const CarrierLookup = () => {
        if (carrierLookupPanel) {
            return <SectionContainer title={"Carrier Lookup (DOT, MC or name)"}
                                     className={"carrier-lookup"}>
                <CarrierSearchBox setCarrier={setCarrier}/>
            </SectionContainer>
        }

        return null;
    }

    if (source === "recommended") {
        return <div className={"carrier-list-container"}>
            <CarrierLookup/>
            <SectionContainer title={<CarriersHeader/>} fullBleed={true}>
                <TorchCarrierList loadId={load.id} carrierSelected={carrierSelected}/>
            </SectionContainer>
        </div>;
    }

    if (source === "dat") {
        return <div className={"carrier-list-container"}>
            <CarrierLookup/>
            <SectionContainer title={<CarriersHeader/>} fullBleed={true}>
                <DatCarrierList load={load} carrierSelected={handleDatCarrierSelected}/>
            </SectionContainer>
        </div>;
    }

    return <div>Loading carrier lists now...</div>
}


export default CarrierList;
