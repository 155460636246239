import React from "react";

const RateDetails = ({rate}) => {

    let rateDetails = "rate-details";

    return (
        <div className={rateDetails}>
            <div className={"origin"}>
                <span className={"title"}>Origin</span>
                <span className={"value"}>{rate.originCity}, {rate.originState}</span>
            </div>
            <div className={"destination"}>
                <span className={"title"}>Destination</span>
                <span className={"value"}>{rate.destCity}, {rate.destState}</span>
            </div>
            <div className={"rate"}>
                <span className={"title"}>Rate</span>
                <span className={"value"}>{rate.rate.toFixed(2)}</span>
            </div>
            <div className={"rate-low"}>
                <span className={"title low"}>Low</span>
                <span className={"value"}>{rate.rateLow.toFixed(2)}</span>
            </div>
            <div className={"rate-high"}>
                <span className={"title high"}>High</span>
                <span className={"value"}>{rate.rateHigh.toFixed(2)}</span>
            </div>
            <div className={"contributors"}>
                <span className={"title"}>Reports (Contrib)</span>
                <span className={"value"}>{rate.reports} ({rate.contributors})</span>
            </div>
            <div className={"fuel"}>
                <span className={"title"}>Fuel</span>
                <span className={"value"}>{rate.fuelSurcharge}</span>
            </div>
            <div className={"mileage"}>
                <span className={"title"}>Mileage</span>
                <span className={"value"}>{rate.mileage}</span>
            </div>
        </div>
    );
}

export default RateDetails;
