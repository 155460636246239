import React, {useEffect, useState} from "react";
import {getTask, submitTask, TaskOption, TaskResponse, TaskWithOptions} from "./workflow";
import SectionContainer from "../common/SectionContainer";
import {useHistory, useParams} from "react-router-dom";
import CenteredText from "../common/CenteredText";
import {styled} from "@mui/system";
import OutcomeAccordion from "./OutcomeAccordion";
import WorkflowHistory from "./WorkflowHistory";
import TaskDetails from "./TaskDetails";

const TaskLayout = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-top: 20px;
  column-gap: 10px;
`

const OutcomeContainer = styled('div')`
  margin-bottom: 10px;
`

const PastTasksContainer = styled('div')`
`

const WorkflowView = () => {

    const [task, setTask] = useState<TaskWithOptions>();
    const [working, setWorking] = useState(false);
    const history = useHistory();

    const {loadId}: { loadId?: string | undefined } = useParams();

    useEffect(() => {
        if (loadId) {
            getTask(loadId).then(setTask);
        }
    }, [loadId]);

    function mapDataToTaskResponse(option: TaskOption, data: Map<string, string>): TaskResponse {
        return {
            name: option.name,
            responses: Array.from(data.entries())
                .map((e) => {
                    return {name: e[0], value: e[1]}
                })
        };
    }

    function submitTaskHandler(option: TaskOption, data: Map<string, string>) {
        if (loadId && task) {
            setWorking(true);
            submitTask(loadId, task.task.id, mapDataToTaskResponse(option, data))
                 .then(result => {
                     if (result.success) {
                        history.push("/workflow");
                    } else {
                         // need to set working to false and display the error message
                         setWorking(false);
                    }
                });
        }
    }

    return task ?
        <SectionContainer title={task.task.label} border={false}>
            <TaskLayout>
                <SectionContainer fullBleed={false}>
                    <OutcomeContainer>
                        <TaskDetails task={task}/>
                        <OutcomeAccordion options={task.options}
                                          submitTaskHandler={submitTaskHandler}
                                          working={working}/>
                        {/*<OutcomeExpanded options={task.options} submitTaskHandler={submitTaskHandler}/>*/}
                    </OutcomeContainer>
                </SectionContainer>
                <SectionContainer fullBleed={true}>
                    <PastTasksContainer>
                        <WorkflowHistory task={task}/>
                    </PastTasksContainer>
                </SectionContainer>
            </TaskLayout>
        </SectionContainer> :
        <SectionContainer>
            <CenteredText>There is no task for this load</CenteredText>
        </SectionContainer>
}

export default WorkflowView;