import React, {useEffect, useState} from "react";
import {Facility, FacilityHolidays, FacilityHour} from "../interfaces/Facility";
import {getFacilityHolidays, getFacilityHours} from "./facility-services";
import {Fab, Typography} from "@mui/material";
import FacilityHoursEdit from "./FacilityHoursEdit";
import CenteredText from "../common/CenteredText";
import EditIcon from "@mui/icons-material/Edit";

type HoursProps = {
    facility: Facility;
}

const FacilityHours = ({facility}: HoursProps) => {

    const [editHours, setEditHours] = useState(false);

    const [hours, setHours] = useState<FacilityHour[]>([]);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [holidays,setHolidays] = useState<FacilityHolidays[]>([]);

    useEffect(() => {
        getFacilityHours(facility).then(setHours);
        getFacilityHolidays(facility).then(setHolidays);
    }, [facility]);

    if (editHours) {
        return <FacilityHoursEdit facility={facility}
                                  setControlFinished={() => {
                                      setEditHours(false)
                                      getFacilityHours(facility).then(setHours);
                                      getFacilityHolidays(facility).then(setHolidays);
                                  }}/>
    }

    if (hours?.length === 0) {
        return <CenteredText>
            <div>No Facility Hours Added Yet</div>
            <div className={"clickable"} onClick={() => setEditHours(true)}>Add Some</div>
        </CenteredText>
    }

    function dayOfWeek(value: string) {
        let found = days?.find(day => day === (value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase()));
        return found ? found : "";
    }

    return <div className={"facility-hours-container"}>
        <div className={"display-hours"}>
            <div className={"hours"}>
                {hours.map(hour => {
                    return <div key={hour.id} className={"display-hour"}>
                        <div className={"day-of-week"}>{dayOfWeek("" + hour.dayOfWeek)}</div>
                        <div className={"operating-hours"}>
                            {hour.isOpen ?
                                <>
                                    <div className={"time"}>{hour.open}</div>
                                    -
                                    <div className={"time"}>{hour.close}</div>
                                </>
                                : <div className={"closed"}>Closed</div>
                            }
                        </div>
                    </div>
                })}
            </div>

            {holidays.length > 0 ? 
                <div className={"holidays"}>
                    <Typography variant="h6">Holiday Hours</Typography>

                    {holidays.map(holiday => {
                        return <div key={holiday.id} className={"display-hour"}>
                            <div className={"day-of-week"}>{holiday.dayOfYear}</div>
                            <div className={"operating-hours"}>
                                {holiday.isOpen ?
                                    <>
                                        <div className={"time"}>{holiday.open}</div>
                                        -
                                        <div className={"time"}>{holiday.close}</div>
                                    </>
                                    : <div className={"closed"}>Closed</div>
                                }
                            </div>
                        </div>
                    })}
                </div>
            : ''}

            {!editHours ?
                <Fab className={"edit-hours-icon"}
                     size={"small"}
                     aria-label={"Edit"}
                     color={"primary"}
                     onClick={() => setEditHours(true)}>
                    <EditIcon/>
                </Fab> : null}
        </div>
    </div>
}

export default FacilityHours;
