import React from 'react';
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/material";

type PropTypes = {
    message: string;
    timeout?: number;  // in milliseconds
    open: boolean;
    close: (event) => void
}

export const SuccessActionNotification = ({message, timeout = 3000, open, close}: PropTypes) => {

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={timeout}
            onClose={close}>
            <Alert onClose={close} severity="success">
                {message}
            </Alert>
        </Snackbar>
    );
}
