import React from "react";

type CallProps = {
    phone: string;
    children: React.ReactNode;
}
const CallTelLink = ({phone, children = "Make Call"}: CallProps) => {

    return <a href={`tel:${phone}`}>{children}</a>;
}

export default CallTelLink;