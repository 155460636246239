import {API_BASE_URL} from "../constants";
import {torchFetch} from "../util/APIUtils";
import {Load} from "../interfaces/Load";

export interface Task {
    id: string;
    created: Date | string;
    name: string;
    label: string;
    description: string;
    active: boolean;
    cancelled: boolean;
    outcome: string;
    due: Date | string;
    overdue: Date | string;
    ended: Date | string;
    note: string;
    load: Load;
}

export interface TaskFieldOptions {
    options: string[];
}

export interface TaskField {
    name: string;
    label: string;
    description: string;
    required: boolean;
    fieldType: "text" | "large-text" | "choice" | "multi-choice" | "date-time";
    options?: TaskFieldOptions;
}

export interface TaskOption {
    name: string;
    successful: boolean;
    fields: TaskField[];
}

export interface TaskWithOptions {
    task: Task;
    options: TaskOption[];
}

export interface TaskFieldResponse {
    name: string;
    value: string;
}

export interface TaskResponse {
    name: string;
    responses: TaskFieldResponse[];
}

export interface TaskSubmitResponse {
    success: boolean;
    result: string;
    message: string;
}

export interface Workflow {
    id: string;
    created: Date | string;
}

export interface WorkflowContextEntry {
    id: string,
    created: Date | string;
    workflow: Workflow;
    key: string;
    value: string;
    retracted: boolean;
}

// for now is just workflow tasks but we'll integrate others later
export function getActiveWorkflowTasks(): Promise<TaskWithOptions[]> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking`,
        method: 'get'
    });
}

export function getTask(loadId: string): Promise<TaskWithOptions> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking/${loadId}`,
        method: 'get'
    });
}

export function getPastTasks(loadId: string): Promise<Task[]> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking/${loadId}/past-tasks`,
        method: 'get'
    });
}

export function getAllWorkflowContextData(loadId: string): Promise<WorkflowContextEntry[]> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking/${loadId}/context-all`,
        method: 'get'
    });
}

export function getLoadsAvailableForTracking(): Promise<Load[]> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking/available-for-tracking`,
        method: 'get'
    });
}

export function addLoadToTracking(id: number) : Promise<TaskWithOptions> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking/${id}`,
        method: 'post'
    });
}

export function getCurrentWorkflowContext(loadId: string): Promise<Map<string, string>> {
    return new Promise<Map<string, string>>((resolve, reject) => {
        torchFetch({
            url: `${API_BASE_URL}/workflow/tracking/${loadId}/context-all`,
            method: 'get'
        }).then(data => {
            resolve(new Map(Object.entries(data)));
        });
    });
}

export function submitTask(loadId: string, taskId: string, body: TaskResponse): Promise<TaskSubmitResponse> {
    return torchFetch({
        url: `${API_BASE_URL}/workflow/tracking/${loadId}/task/${taskId}`,
        method: 'post',
        body: JSON.stringify(body)
    });
}