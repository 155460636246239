import {ShipwellEvent} from "../../interfaces/Event";
import TimelineEventCreated from "./TimelineEventCreated";
import ShipmentDataUpdated from "./ShipmentDataUpdated";
import StatusUpdate from "./StatusUpdate";
import ShipmentRepCreated from "./ShipmentRepCreated";
import CarrierAssigned from "./CarrierAssigned";
import EquipmentAssigned from "./EquipmentAssigned";
import ShipmentChargeLineItem from "./ShipmentChargeLineItem";
import ShipmentStopCreated from "./ShipmentStopCreated";
import ShipmentLineItemCreated from "./ShipmentLineItemCreated";
import ShipmentCreated from "./ShipmentCreated";

export const EventMapping = {
    'shipment.timeline_event.created': {
        label: 'Timeline Event Created',
        component: TimelineEventCreated
    },
    'shipment.stop.updated': {
        label: 'Shipment Stop Updated',
        component: ShipmentDataUpdated
    },
    'shipment.status.updated': {
        label: 'Shipment Status Updated',
        component: StatusUpdate
    },
    'shipment.metadata.updated': {
        label: 'Shipment Metadata Updated',
        component: ShipmentDataUpdated
    },
    'shipment.identifiers.pro_number.updated': {
        label: 'Shipment Pro Number Updated',
        component: ShipmentDataUpdated
    },
    'shipment.identifiers.bol_number.updated': {
        label: 'Shipment BOL Number Updated',
        component: ShipmentDataUpdated
    },
    'shipment.rep.created': {
        label: 'Shipment Rep Created',
        component: ShipmentRepCreated
    },
    'shipment.carrier_assigned': {
        label: 'Carrier Assigned',
        component: CarrierAssigned
    },
    'shipment.equipment_assigned': {
        label: 'Equipment Assigned',
        component: EquipmentAssigned
    },
    'shipment.charge_line_item.created': {
        label: 'Shipment Charge Line Item Created',
        component: ShipmentChargeLineItem
    },
    'shipment.charge_line_item.updated': {
        label: 'Shipment Charge Line Item Updated',
        component: ShipmentDataUpdated
    },
    'shipment.identifiers.purchase_order_number.updated': {
        label: 'Shipment PO Number Updated',
        component: ShipmentDataUpdated
    },
    'shipment.identifiers.pickup_number.updated': {
        label: 'Shipment Pickup Number Updated',
        component: ShipmentDataUpdated
    },
    'shipment.stop.created': {
        label: 'Shipment Stop Created',
        component: ShipmentStopCreated
    },
    'shipment.line_item.created': {
        label: 'Shipment Line Item Created',
        component: ShipmentLineItemCreated
    },
    'shipment.created': {
        label: 'Shipment Created',
        component: ShipmentCreated
    }
}

export type ShipwellEventProps = {
    event: ShipwellEvent;
    title?: string;
}

export type ShipwellDate = string | Date;

export type ShipwellDataState = {
    status: string;
    confirmed_departure?: ShipwellDate;
    confirmed_arrival_at?: ShipwellDate;
    trip_management_eta?: ShipwellDate;
    trip_management_eta_last_updated?: ShipwellDate;
    status_reason_code?: string;
    load_board_enabled?: boolean;
    pro_number?: string;
    max_buy_amount?: number;
    instructions?: string;
    bol_number?: string;
    purchase_order_number?: string;
    pickup_number?: string;
    unit_name?: string;
    category_id?: string;
    charge_code?: string;
    unit_amount?: number;
    unit_quantity?: number;
    appointment?: {
        type?: string;
        time_end?: string
        time_start?: string;
        date_start?: string;
        date_end?: string;
    };
}

export interface StatusUpdateDetails {
    id: string,
    new_data: {
        status: string
    },
    old_data: {
        status: string
    }
}

export interface TimelineEventDetails {
    event_type: string,
    custom_data: {
        shipwell_custom_data: {
            title: string;
        }
    }
    description: string;
}

export interface ShipmentDataUpdatedDetails {
    id: string;
    new_data: ShipwellDataState;
    old_data: ShipwellDataState;
}

export interface RepUpdatedDetails {
    role: string;
    user: {
        id: string;
    },
    resource_type: string;
}

export interface CarrierAssignedDetails {
    vendor_id: string;
    assigned_as_carrier_at: ShipwellDate;
}

export interface EquipmentAssignedDetails {
    driver: {
        email?: string;
        last_name?: string;
        first_name?: string;
        phone_number?: string;
    },
    trailer: {
        name?: string;
        description?: string;
        distance_traveled_miles?: number
    },
    power_unit: {
        name?: string;
        resource_type?: string;
        provider?: string;
        status?: string;
    }
}

export interface ChargeLineItemDetails {
    category: string;
    unit_name: string;
    charge_code?: string;
    unit_amount: number;
    resource_type: string;
    unit_quantity: number;

}

export interface StopCreatedDetails {
    status?: string;
    location: {
        city: string;
        type: string;
        line_1: string;
        line_2?: string;
        country: string;
        latitude?: number;
        longitude?: number;
        postal_code: string;
        phone_number?: string;
        state_or_province?: string;
    },
    appointment?: {
        type: string;
        time_end?: string;
        date_start?: string;
        time_start?: string;
        utc_offset?: number;
    },
    instructions?: string;
}

export interface LineItemCreatedDetails {
    description: string;
    package_weight: number;
    total_packages: number;
}

export interface LabelValuePair {
    label?: string;
    value: string;
}

function formatTime(time: string | Date): string {
    if (typeof time === 'string') {
        return new Date(time).toLocaleTimeString();
    }

    return time.toLocaleTimeString();
}

export function getShipwellDataStateEntries(dataState: ShipwellDataState): LabelValuePair[] {
    const results = [] as LabelValuePair[];

    if (dataState.confirmed_departure) {
        results.push({label: "Confirmed Departure", value: formatTime(dataState.confirmed_departure)});
    }

    if (dataState.instructions) {
        results.push({label: "Instructions", value: dataState.instructions});
    }

    if (dataState.confirmed_arrival_at) {
        results.push({label: "Confirmed Arrival", value: formatTime(dataState.confirmed_arrival_at)});
    }

    if (dataState.trip_management_eta) {
        results.push({label: "Trip ETA", value: formatTime(dataState.trip_management_eta)});
    }

    if (dataState.trip_management_eta_last_updated) {
        results.push({label: "Trip ETA Updated",
            value: formatTime(dataState.trip_management_eta_last_updated)});
    }

    if(dataState.status_reason_code) {
        results.push({label: 'Reason Code', value: dataState.status_reason_code});
    }

    if(dataState.status) {
        results.push({label: 'Status', value: dataState.status});
    }

    if(dataState.bol_number) {
        results.push({label: 'BOL Number', value: dataState.bol_number});
    }

    if(dataState.purchase_order_number) {
        results.push({label: 'PO Number', value: dataState.purchase_order_number})
    }

    if(dataState.pro_number) {
        results.push({label: 'Pro Number', value: dataState.pro_number});
    }

    if(dataState.pickup_number) {
        results.push({label: 'Pickup Number', value: dataState.pickup_number});
    }

    if(typeof dataState.load_board_enabled !== 'undefined') {
        results.push({label: 'Loadboard Enabled', value: dataState.load_board_enabled ? "true" : "false"});
    }

    if(dataState.max_buy_amount) {
        results.push({label: 'Max Buy Amount', value: dataState.max_buy_amount.toString()})
    }

    if(dataState.appointment && dataState.appointment.type) {
        results.push({label: 'Type', value: dataState.appointment.type});
    }

    if(dataState.appointment && dataState.appointment.date_start) {
        results.push({label: 'Date Start', value: dataState.appointment.date_start});
    }

    if(dataState.appointment && dataState.appointment.date_end) {
        results.push({label: 'Date End', value: dataState.appointment.date_end});
    }

    if(dataState.appointment && dataState.appointment.time_start) {
        results.push({label: 'Appointment Start', value: dataState.appointment.time_start});
    }

    if(dataState.unit_name) {
        results.push({label: 'Unit Name', value: dataState.unit_name});
    }

    if(dataState.category_id) {
        results.push({label: 'Category ID', value: dataState.category_id});
    }

    if(dataState.charge_code) {
        results.push({label: 'Charge Code', value: dataState.charge_code});
    }

    if(dataState.unit_amount) {
        results.push({label: 'Unit Amount', value: String(dataState.unit_amount)});
    }

    if(dataState.unit_quantity) {
        results.push({label: 'Unit Quantity', value: String(dataState.unit_quantity)});
    }

    if(dataState.appointment && dataState.appointment.time_end) {
        results.push({label: 'Appointment End', value: dataState.appointment.time_end});
    }
    return results;
}

export function getShipmentUpdatedDataDetails(event: ShipwellEvent): ShipmentDataUpdatedDetails {
    return JSON.parse(event.details)["1"] as ShipmentDataUpdatedDetails;
}

export function stopUpdatedDetailsHasUsefulInformation(event: ShipwellEvent): boolean {
    const entry = getShipmentUpdatedDataDetails(event);
    return getShipwellDataStateEntries(entry.old_data).length > 0 ||
        getShipwellDataStateEntries(entry.new_data).length > 0;
}
