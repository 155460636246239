import React, {useState} from "react";
import {Box, Button, Fade, TextareaAutosize} from "@mui/material";
import "./Carriers.scss"

type PropTypes = {
    noteControlOpen: boolean;
    addFn: (note:string) => void;
    cancelFn: () => void;
}

/*
    Unmanaged component
 */
const AddNote = ({addFn, cancelFn, noteControlOpen}: PropTypes) => {

    const [noteText, setNoteText] = useState<string>('');

    const updateNote = (event) => {
        setNoteText(event.target.value);
    }

    const addNote = (e) => {
        addFn(noteText);
        setNoteText('');
    }

    return (<Fade in={noteControlOpen} unmountOnExit={true} timeout={500}>
        <div className={"add-note-control"}>
            <TextareaAutosize className={"note-text-area"}
                              aria-label="New Note"
                              minRows={5}
                              value={noteText}
                              onChange={updateNote}
                              placeholder="Add a new carrier note"/>
            <Box component="span"
                 m={0}
                 className={"entry-box"}>
                <Button variant="outlined"
                        color="primary"
                        disabled={noteText.length < 3}
                        onClick={addNote}
                        sx={{marginRight: "10px"}}>Add Note
                </Button>
                <Button variant="outlined"
                        color="error"
                        onClick={cancelFn}>Cancel
                </Button>
            </Box>
        </div>
    </Fade>)
}

export default AddNote;