import React from 'react';

type PropTypes = {
    message: string
}

export const NoContent = ({message}: PropTypes) => {

    return <div className={"no-content"}><div className={"message"}>{message}</div></div>;
}
