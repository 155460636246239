import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import '../Base.css';
import './Tender.scss';
import queryString from 'query-string'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Alert, Fade, Snackbar, TablePagination, Tooltip} from "@mui/material";
import {getTenderedLoad, getTenderedLoads} from "../service/tendered";
import {EDILoadTender, EDILoadTenderWithUpdates, LoadTenderListItem} from "../interfaces/TenderedLoad";
import TenderLoadDetails from "./TenderLoadDetails";
import TenderRates from "./TenderRates";
import TenderFilterBar from "./TenderFilterBar";
import Loading from "../common/Loading";

const PREFIX = 'Tender';

const classes = {
    listItemSelected: `${PREFIX}-listItemSelected`,
    listItem: `${PREFIX}-listItem`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.listItemSelected}`]: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        paddingLeft: '8px'
    },

    [`& .${classes.listItem}`]: {
        border: '1px solid #fafafa',
        paddingLeft: '8px'
    }
}));

const TenderState = styled('span')`
  font-weight: bold;
  color: #555;
`

const Tender = () => {

    const location: Location | { state } = useLocation();
    const searchParams = queryString.parse(document.location.search);

    const filter = searchParams.filter as string || "";
    const days = parseInt((searchParams.days as string)||"1");

    const [loading, setLoading] = useState(true);
    const [errorText, setErrorText] = useState<string>();
    const [tasks, setTasks]: [tasks: LoadTenderListItem[], setTasks: Function] = useState([]);
    const [fullTaskList, setFullTaskList]: [fullTaskList: LoadTenderListItem[], setFullTaskList: Function] = useState([]);
    const [currentTask, setCurrentTask]: [currentTask: EDILoadTenderWithUpdates | undefined, setCurrentTask: Function] = useState();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [taskFilter, setTaskFilter] = useState("");
    const [dayOfWeekFilter, setDayOfWeekFilter] = useState<string[]>([]);
    const [shipperFilter, setShipperFilter] = useState<string[]>([]);
    const [queueFilter, setQueueFilter] = useState<string[]>([]);
    const [lookbackFilter, setLookbackFilter] = useState<string>('1');

    let {loadTenderId}: { loadTenderId?: string | undefined } = useParams();

    useEffect(() => {
        setLoading(true);
        getTenderedLoads(days).then(loads => {
            setLoading(false);
            setFullTaskList(loads);
        })
    }, [days, filter]);

    useEffect(() => {
        setErrorText(location.state?.error);
    }, [location.state?.error]);

    useEffect(() => {
        history.push({
            search: getQuery(Number.parseInt(lookbackFilter))
        });
    }, [history, filter, lookbackFilter]);

    useEffect(() => {

        const matchesState = (item: LoadTenderListItem, state: string): boolean => {
            const first = item.stopInfo[0].state;
            const last = item.stopInfo[item.stopInfo.length - 1].state;
            return first === state || last === state;
        }

        let tasksToFilter = fullTaskList;

        const taskFilterActive = taskFilter && taskFilter.length > 0;
        const dowFilterActive = dayOfWeekFilter.some(dow => dow); // active if some are true
        const shipperFilterActive = shipperFilter.some(s => s);
        const queueFilterActive = queueFilter.some(q => q);

        if (taskFilterActive) {
            const matchState = taskFilter.length === 2 && taskFilter.toUpperCase() === taskFilter;
            if (matchState) {
                tasksToFilter = tasksToFilter.filter(task => matchesState(task, taskFilter));
            } else {
                const filterStr = taskFilter.toLowerCase();
                tasksToFilter = tasksToFilter.filter(task =>
                    getLoadLabel(task).toLowerCase().includes(filterStr) ||
                    task.shipmentNumber?.toLowerCase().includes(filterStr))
            }
        }

        if (dowFilterActive) {
            tasksToFilter = tasksToFilter.filter(task => {
                let dow: number = new Date(task.stopInfo[0].stopTime).getDay();
                return dayOfWeekFilter.includes(String(dow));
            });
        }

        if(queueFilterActive) {
            tasksToFilter = tasksToFilter.filter(task => {
                return queueFilter.includes(task.tenderState);
            })
        }

        if(shipperFilterActive) {
            tasksToFilter = tasksToFilter.filter(task => {
                return shipperFilter.includes(task.shipperKey);
            });
        }

        setTasks(tasksToFilter);

        if (taskFilterActive || dowFilterActive || queueFilterActive || shipperFilterActive) {
            setPage(0);
        }
    }, [taskFilter, dayOfWeekFilter, fullTaskList, shipperFilter, queueFilter]);

    useEffect(() => {
        const id = loadTenderId ? parseInt(loadTenderId) : undefined;
        if (id) {
            getTenderedLoad(id).then(load => {
                setCurrentTask(load);
            })
        } else {
            setCurrentTask(undefined);
        }
    }, [loadTenderId]);

    function refreshList() {
        setLoading(true);
        getTenderedLoads(days).then(loads => {
            setLoading(false);
            setFullTaskList(loads);
        });
    }

    const getLoadLabel = (item: LoadTenderListItem) => {
        return item.stopInfo.length === 0 ? "No Stops" :
            item.stopInfo.length === 1 ? item.stopInfo[0].city :
                (item.stopInfo[0].city + " - " + item.stopInfo[item.stopInfo.length - 1].city);
    }

    const getLoadFacilityLabel = (item: LoadTenderListItem) => {
        return item.stopInfo.length === 0 ? "No Stops" :
            item.stopInfo.length === 1 ? item.stopInfo[0].facilityName :
                (item.stopInfo[0].facilityName + " - " + item.stopInfo[item.stopInfo.length - 1].facilityName);
    }

    const originAddr = (task: EDILoadTender) => {
        return (task.stops && task.stops.length > 0) ? task.stops[0].facilityName : "";
    }

    const destAddr = (task: EDILoadTender) => {
        return (task.stops && task.stops.length > 0) ? task.stops[task.stops.length - 1].facilityName : "";
    }

    function getQuery(days: number) {
        return queryString.stringify({days: days});
    }

    const switchToTenderLoadDetailsView = (loadTenderId: number) => {
        const query = getQuery(days);
        history.push(`/tender/${loadTenderId}?${query}`);
    }

    const ErrorView = () => {
        const handleAlertClose = () => {
            setErrorText(undefined);
        };

        if (errorText) {
            return <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={!!errorText}
                onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">{errorText}</Alert>
            </Snackbar>
        } else {
            return null;
        }
    }

    const taskListItems = tasks
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((task: LoadTenderListItem) => {
                return <ListItem button
                    key={task.loadTenderId}
                    className={task.loadTenderId.toString() === loadTenderId ? classes.listItemSelected : classes.listItem}
                    onClick={() => switchToTenderLoadDetailsView(task.loadTenderId)}>
                    <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 600}}
                             enterDelay={500}
                             title={getLoadFacilityLabel(task)}>
                        <ListItemText>
                            <div className={"tender-item"}>
                                <div className={"tender-title"}>
                                    <span>{getLoadLabel(task)}</span>
                                </div>
                                <div className={"tender-date-commodity"}>
                            <span className={"date-commodity-label"}>
                                <TenderState>{task.tenderState}</TenderState> {task.purpose}
                                {task.stopInfo["stopTime"]}
                            </span>
                                </div>
                            </div>
                        </ListItemText>
                    </Tooltip>
                </ListItem>;
            }
        );

    const TenderHeader = ({task}: { task: EDILoadTenderWithUpdates | undefined }) => {
        return task && task.tender.stops.length > 1 ? <div className={"tender-container-header"}>
                <span className={"city"}>{originAddr(task.tender)} - {destAddr(task.tender)}</span>
            </div>
            : null
    }

    const TenderViewContainer = ({task}: { task: EDILoadTenderWithUpdates | undefined }) => {

        if (task === null || typeof task === "undefined") {
            return (
                <div className={"no-task-placeholder"}>
                    <img alt={"Torch logo"} src={"/images/torch-flame-logo.png"}/>
                </div>);
        }

        return (
            <div className={"tender-view-container"}>
                <TenderHeader task={task}/>
                <TenderRates tender={task}/>
                <TenderLoadDetails task={task} doneWithTender={refreshList}/>
            </div>);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        (<Root>
            <ErrorView/>
            <div className={"tender-grid-container content-outer-container"}>
                <div className={"tender-filters"}>
                    <TenderFilterBar taskFilter={taskFilter}
                                     setTaskFilter={setTaskFilter}
                                     dayOfWeekFilter={dayOfWeekFilter}
                                     setDayOfWeekFilter={setDayOfWeekFilter}
                                     lookbackFilter={lookbackFilter}
                                     setLookbackFilter={setLookbackFilter}
                                     queueFilter={queueFilter}
                                     setQueueFilter={setQueueFilter}
                                     shipperFilter={shipperFilter}
                                     setShipperFilter={setShipperFilter}/>
                </div>
                <div className={"tender-list"}>
                    {loading ? <Loading /> :
                        <>
                            <List>
                                {taskListItems}
                            </List>
                            <TablePagination
                                labelRowsPerPage={"Rows"}
                                component="div"
                                count={tasks.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    }
                </div>
                <TenderViewContainer task={currentTask}/>
            </div>
        </Root>)
    );
}

export default Tender;
