import {SkinnyLoad} from "../interfaces/Load";
import {Tooltip} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React from "react";
import "./Tags.scss"

export const Tags = ({task}: { task: SkinnyLoad }) => {

    const TagView = ({tag}) => {
        return <Tooltip title={tag.name}>
            <FiberManualRecordIcon className={"load-tag tag-color-" + tag.color}/>
        </Tooltip>
    }

    if(task.tags && task.tags.length > 0) {
        return (<div className={"load-tag-list"}>
            {task.tags.map(tag => <TagView tag={tag} key={tag.id}/>)}
        </div>);
    }

    return null;
}
