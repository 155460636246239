import React, {useEffect, useState} from "react";
import {Facility} from "../interfaces/Facility";
import {getFacility, updateFacility} from "./facility-services";
import {useParams} from "react-router-dom";
import {styled} from "@mui/system";
import SectionContainer from "../common/SectionContainer";
import FacilityHours from "./FacilityHours";
import FacilityContacts from "./FacilityContacts";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Snackbar, Tab, Alert, AlertColor,} from "@mui/material";
import FacilityNote from "./FacilityNote";
import FacilityDetails from "./FacilityDetails";
import CompleteFacility from "./CompleteFacility";
import FacilityRatings from "./FacilityRatings";
import FacilityPastLoadsContainer from "./FacilityPastLoads";
import FacilityUpcomingLoadsContainer from "./FacilityUpcomingLoads";
import FacilityHistoricalData from "./FacilityHistoricalData";

const FacilityLayout = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "facility-active facility-active"
          "information stats";
  gap: 10px;
  margin: 10px;
`

const FacilityActive = styled('div')`
  grid-area: facility-active;
`

const FacilityInformationLayout = styled('div')`
  grid-area: information;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FacilityStatsLayout = styled('div')`
  grid-area: stats;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FacilityHome = () => {

    let {facilityId} = useParams<{ facilityId?: string }>();
    const [facility, setFacility] = useState<Facility>();
    const [tabValue, setTabValue] = useState("hours");

    // update feedback
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState<AlertColor>("success");
    const [ratingChanged, setRatingChanged] = useState<boolean>(false)

    useEffect(() => {
        if (facilityId) {
            getFacility(parseInt(facilityId)).then(f => {
                setFacility(f);
                setRatingChanged(false)
            });
        }
    }, [facilityId,ratingChanged]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function updateFacilityNote(note): void {
        if (facility) {
            facility.internalNote = note;
            updateFacility(facility)
                .then(f => {
                    setFacility(f);
                    setSnackbarMessage("Note updated");
                    setSnackbarType("success");
                    setSnackbarOpen(true);
                })
                .catch(err => {
                    const message = err.status ? `Server returned ${err.status}` : 'No status available'
                    setSnackbarMessage(`Failure updating note: ${message}`);
                    setSnackbarType("error");
                    setSnackbarOpen(true);
                });
        }
    }

    function updateFacilityStopNote(note): void {
        if (facility) {
            facility.stopNote = note;
            updateFacility(facility)
                .then(f => {
                    setFacility(f);
                    setSnackbarMessage("Stop note updated");
                    setSnackbarType("success");
                    setSnackbarOpen(true);
                })
                .catch(err => {
                    const message = err.status ? `Server returned ${err.status}` : 'No status available'
                    setSnackbarMessage(`Failure updating stop note: ${message}`);
                    setSnackbarType("error");
                    setSnackbarOpen(true);
                });
        }
    }

    function updateFacilityDetails() : void {
        setRatingChanged(true)
    }

    function handleSnackbarClose(event: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    function updateFacilityFn(f: Facility): Promise<Facility> {
        return new Promise<Facility>((resolve, reject) => {
            updateFacility(f).then(updated => {
                setFacility(updated);
                resolve(updated);
            }).catch(err => reject(err));
        });
    }

    // facility details     /       past loads
    // hours                /       performance?
    // notes / contacts
    if (facility) {
        return <div>
            <FacilityLayout>
                <FacilityActive>
                    <CompleteFacility facility={facility} facilityUpdated={() => console.log}/>
                </FacilityActive>

                <FacilityInformationLayout>
                    <FacilityDetails facility={facility} updateFacilityFn={updateFacilityFn}/>
                    <SectionContainer fullBleed={true}>
                        <TabContext value={tabValue}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label="Hours" value="hours"/>
                                    <Tab label="Stop Note" value="stop-note"/>
                                    <Tab label="Contacts" value="contacts"/>
                                    <Tab label="Ratings" value="ratings"/>
                                    <Tab label="Historical Data" value="historical-data"/>
                                </TabList>
                            </Box>
                            <TabPanel sx={{padding: 0, minHeight: "600px"}} value="stop-note">
                                <FacilityNote note={facility.internalNote}
                                              updateNote={updateFacilityNote}
                                              stopNote={facility.stopNote}
                                                  updateStopNote={updateFacilityStopNote} />
                            </TabPanel>
                            <TabPanel sx={{padding: 0, minHeight: "400px",maxHeight: "500px", overflow: "scroll"}} value="contacts">
                                <FacilityContacts facility={facility}/>
                            </TabPanel>
                            <TabPanel sx={{padding: 0, minHeight: "600px"}} value="hours">
                                <FacilityHours facility={facility}/>
                            </TabPanel>
                            <TabPanel sx={{padding: 0, minHeight: "600px"}} value="ratings">
                                <FacilityRatings facilityId={facility.id} updateFacilityDetails={updateFacilityDetails} />
                            </TabPanel>
                            <TabPanel sx={{padding: 0, minHeight: "400px", maxHeight: "500px", overflow: "scroll"}} value="historical-data">
                                <FacilityHistoricalData facility={facility}/>
                            </TabPanel>
                        </TabContext>
                    </SectionContainer>
                </FacilityInformationLayout>

                <FacilityStatsLayout>
                    <SectionContainer title={"Past Loads"}>
                        <FacilityPastLoadsContainer facilityId={facility.id} />
                    </SectionContainer>
                    <SectionContainer title={"Upcoming Load Data"}>
                        <FacilityUpcomingLoadsContainer facilityId={facility.id} />
                    </SectionContainer>
                </FacilityStatsLayout>
            </FacilityLayout>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity={snackbarType}
                       sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    }

    return <div>Facility Loading...</div>
}

export default FacilityHome;
