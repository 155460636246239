import React, {useEffect, useState} from 'react';
import {formatPhoneNumber} from "../util/general";
import {Address} from "../interfaces/Address";
import {Button, Chip, CircularProgress} from "@mui/material";
import {NEW} from "../constants/CarrierStatus";
import SectionContainer from "../common/SectionContainer";
import {styled} from "@mui/system";
import "./Carriers.scss";
import {Torchers} from "../common/Torchers";
import {Carrier} from "../interfaces/Carrier";
import {getLoadboardSetting, getLoadboardUsage} from "../service/carriers";
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadboardUsage} from "../interfaces/LoadboardUsage";

type AddressProp = {
    address: Address;
    title: string;
}

type PropTypes = {
    carrier: Carrier;
    refresh?: () => Promise<Carrier>;
}

const CarrierDetails = ({carrier, refresh}: PropTypes) => {

    const [refreshing, setRefreshing] = useState(false);

    const AddressDisplay = ({address}) => {
        return <div>
            <div>{address.streetOne}</div>
            <div>{address.city}, {address.state} {address.zipCode}</div>
            <div>({address.metro ? address.metro.label : 'No Metro Specified'})</div>
        </div>
    }

    const AddressPanel = ({address, title}: AddressProp) => {
        if (address) {
            return (<div className={"content-panel"}>
                <div className={"address-title"}>{title}</div>
                <AddressDisplay address={address}/>
            </div>);
        }

        return null;
    }

    const TChip = styled(Chip)`
    `

    function refreshCarrier() {
        if(refresh) {
            setRefreshing(true);
            refresh()
                .then(c => setRefreshing(false))
                .catch(c => setRefreshing(false));
        }
    }

    const Title = () => {
        const activityFilter = (message, carrier) => {
            return message.type === "CARRIER" && parseInt(message.content) === carrier.id;
        };
        return <div className={"carrier-title"}>
            <Torchers type={"mini"} task={carrier} applyFilter={activityFilter}/>
            <div className={"title"}>{carrier.name}</div>
            <TChip size="small"
                   color="primary"
                   variant={"outlined"}
                   label={carrier.status || NEW}/>
            <div className={"refresh"}>
                {refreshing ?
                    <CircularProgress size={"24px"}/> :
                    <RefreshIcon className={"active"} onClick={refreshCarrier}/>}
            </div>
        </div>
    }

    const LoadboardActivity = ({carrier}: PropTypes) => {

        const [usage, setUsage] = useState<LoadboardUsage>({query: 0, capacity: 0, general: 0, track: 0});
        const [, setEmail] = useState<string>("");

        useEffect(() => {
            getLoadboardUsage(carrier.id).then(rs => {
                setUsage(rs);
            });
            getLoadboardSetting(carrier.id).then(rs => {
                setEmail(rs.email);
            });

        }, [carrier.id]);

        function getMailLink(carrier: Carrier): string {
            return `mailto:${carrier.primaryEmail}?subject=${"Interest in loady"}&body=Try the Torch Loadboard: \n https://loady.torch-logistics.com/${carrier.publicId} \n Thanks!`;
        }

        return <div className={"loady-container"}>
            {
                usage.query === 0 ?
                    <span>Loady has not been used yet.</span> :
                    <span>
                        Loady checked {usage.query} time{usage.query > 1 ? "s" : ""}.&nbsp;
                        Capacity reported via Loady {usage.capacity} time{usage.capacity > 1 ? "s" : ""}.
                    </span>
            }
            <Button variant={"outlined"}
                    sx={{marginLeft: "15px"}}
                    href={getMailLink(carrier)}
                    size={"small"}>Email Loadboard</Button>
        </div>

    }


    return (
        <SectionContainer title={<Title/>} className={"carrier-details"}>
            <div className={"content-container"}>
                <div className={"content-panel"}>
                    <div><span className={"dot"}>DOT</span> {carrier.dotNumber}</div>
                    <div><span className={"mc"}>MC</span> {carrier.mcNumber}</div>
                    <div><span className={"phone"}>Phone Number</span> {formatPhoneNumber(carrier.phoneNumber)}
                    </div>
                    <div><span className={"email"}>Email</span> {carrier.primaryEmail}</div>
                    <div><span className={"status"}>Status</span> {carrier.status}</div>
                    <div><span className={"notes"}>Shipwell Notes</span> {carrier.notes}</div>
                </div>
                <AddressPanel address={carrier.mailingAddress} title={"Mailing Address"}/>
                <AddressPanel address={carrier.billingAddress} title={"Billing Address"}/>
            </div>
            <LoadboardActivity carrier={carrier}></LoadboardActivity>
        </SectionContainer>
    );
}

export default CarrierDetails;


