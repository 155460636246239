// function to encode the o/d into a consistent path that can be used
// to reconstitute the rate view.  Need to encode these because some
// states have punctuation in the name that doesn't work in an URL
//
export const encodeLocation = (origin, destination) => {
    return encodeURIComponent(origin.zipCode +
        "_" + origin.state +
        "_" + sp2un(origin.city) +
        "-" + destination.zipCode +
        "_" + destination.state +
        "_" + sp2un(destination.city));
}

export const decodeLocation = (path) => {
    // need some validation on this
    const locations = decodeURIComponent(path).split("-");
    return [parseToLocation(locations[0]), parseToLocation(locations[1])]
}

const parseToLocation = (path) => {
    const paths = path.split("_");
    return {
        zipCode: paths[0],
        state: paths[1],
        city: paths.slice(2, paths.length).join(" "),
        label: paths.slice(2, paths.length).join(" ") + ", " + paths[1]
    }
}

// space to underscore
const sp2un = (str) => {
    return str.split(' ').join('_');
}
