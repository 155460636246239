export function formatPhoneNumber(number: string):string {
    let idx = 0;
    // built for default format +1<areacode><number> with no other formatting and spaces
    if(number.startsWith("+1") && number.length > 10) {
        idx = 2;
    }

    let strToSlice = number.replace("(", "");
    strToSlice = strToSlice.replace(")", "");
    strToSlice = strToSlice.replace("-", "");
    strToSlice = strToSlice.replace(" ", "");

    let areaCode = strToSlice.slice(idx,idx + 3);
    let exchange = strToSlice.slice(idx + 3,idx + 6);
    let subscriber = strToSlice.slice(idx + 6,idx + 10);
    return `(${areaCode}) ${exchange}-${subscriber}`
}

export function formatPhoneNumberNoParens(number: string):string {
    let idx = 0;
    // built for default format +1<areacode><number> with no other formatting and spaces
    if(number.startsWith("+1") && number.length > 10) {
        idx = 2;
    }

    let strToSlice = number.replace("(", "");
    strToSlice = strToSlice.replace(")", "");
    strToSlice = strToSlice.replace("-", "");
    strToSlice = strToSlice.replace(" ", "");

    let areaCode = strToSlice.slice(idx,idx + 3);
    let exchange = strToSlice.slice(idx + 3,idx + 6);
    let subscriber = strToSlice.slice(idx + 6,idx + 10);
    return `${areaCode}-${exchange}-${subscriber}`
}

export function findAndFormatPhoneNumber(str: string | undefined): string | undefined {
    if(!str) {
        return str;
    }

    const regex = /[+]?\d{10}/
    let phoneMatch = str.match(regex);
    if(phoneMatch && phoneMatch[0]) {
        const phone = formatPhoneNumberNoParens(phoneMatch[0]);
        return str.replace(phoneMatch[0], phone);
    }
    return str;
}