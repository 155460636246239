import React from "react";
import {Box} from "@mui/material";

type PropTypes = {
    minHeight?: string;  // css compatible
    children: React.ReactNode;
}

const NoContentPanel = ({children, minHeight = "100px"}: PropTypes) => {

    const noContentStyles = {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        minHeight: minHeight,
        width: "100%"
    }

    const noContentMessageStyles = {
        textAlign: "center" as const,
        fontSize: "1.3em",
        fontStyle: "italic",
        color: "#555"
    }

    return <Box sx={noContentStyles}>
        <Box sx={noContentMessageStyles}>
            {children}
        </Box>
    </Box>;
}

export default NoContentPanel;