import React, {useEffect, useState} from "react";
import {Alert, Button, Checkbox, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, InputAdornment, InputLabel, MenuItem, Rating, Select, Snackbar, TextField, Tooltip, TooltipProps, Typography, tooltipClasses} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {AppointmentType, Facility, FacilityType} from "../interfaces/Facility";
import SectionContainer from "../common/SectionContainer";
import BooleanText from "../common/BooleanText";
import Loading from "../common/Loading";
import { getAppointmentTypes, getFacilityTypes, getShippers } from "./facility-services";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Shipper } from "../interfaces/Load";
import { Box, Stack } from "@mui/system";
import { styled } from "@mui/styles";

type DetailsProps = {
    facility: Facility;
    updateFacilityFn: (Facility) => Promise<Facility>;
}

interface SelectMultiField {
    id: number;
    name: string;
    created? : string;
    shipwellId? : string;
    key?: string;
  }

const STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
    'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD',
    'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

const PickUpTypes = [{name:'Live', pickupId:0}, {name:'Drop', pickupId:1}, {name:'Live & Drop', pickupId:2}]

const DeliveryTypes = [{name:'Live', deliveryId:0}, {name:'Drop', deliveryId:1}, {name:'Live & Drop', deliveryId:2}]

const AcceptedEquipmentTypes = [{name:'Dry Van Only', acceptedEquipmentId:0}, {name:'Dry Van', acceptedEquipmentId:1}, {name:'Reefer', acceptedEquipmentId:2}, {name:'Reefer Only', acceptedEquipmentId:3}, {name:'Flatbed', acceptedEquipmentId:4}, {name:'Straight Truck', acceptedEquipmentId:5}, {name:'Others', acceptedEquipmentId:6}]

const FacilityDetails = ({facility, updateFacilityFn}: DetailsProps) => {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState(facility.name);
    const [street, setStreet] = useState(facility.address?.streetOne);
    const [city, setCity] = useState(facility.address?.city);
    const [state, setState] = useState(facility.address?.state);
    const [zipCode, setZipCode] = useState(facility.address?.zipCode);
    const [dock, setDock] = useState(facility.dock);
    const [shippers, setShippers] = useState<Shipper[]>();
    const [multiSelectFields, setMultiSelectFields] = useState<SelectMultiField[]>(facility.shippers.length > 0 ? facility.shippers : [{id:0, name: ''}]);
    const [appointmentTypeValues, setAppointmentTypeValues] = useState<AppointmentType[]>([]);
    const [facilityType, setFacilityType] = useState<FacilityType | null>(facility.facilityType !== null ? facility.facilityType : null);
    const [facilityTypeValues, setFacilityTypeValues] = useState<FacilityType[]>([]);
    const [appointmentType, setAppointmentType] = useState<AppointmentType | null>(facility.appointmentType !== null ? facility.appointmentType : null);
    const [pickUpType, setPickUpType] = useState<string | number | null>(facility.pickUpType ? facility.pickUpType.pickupId : null);
    const [deliveryType, setDeliveryType] = useState<string | number | null>(facility.deliveryType ? facility.deliveryType.deliveryId : null);
    // const [acceptedEquipment, setAcceptedEquipment] = useState<AcceptedEquipmentType[] | null>(facility.acceptedEquipmentList ? facility.acceptedEquipmentList : null);
    const [acceptedEquipmentValues, setAcceptedEquipmentValues] = useState<string[]>(facility.acceptedEquipmentList ? facility.acceptedEquipmentList.map(item => item.name): []);

    const [facilityActive, setFacilityActive] = useState(facility.state === 'ACTIVE');
    // const [otherValue, setOtherValue] = useState<string>("")

    // Error Handling
    const [open, setOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    // const [isError,setIsError] = useState<boolean>(false);
    const [nameErrorText, setNameErrorText] = useState("");
    const [streetOneErrorText, setStreetOneErrorText] = useState("");
    const [cityErrorText, setCityErrorText] = useState("");
    const [stateErrorText, setStateErrorText] = useState("");
    const [zipCodeErrorText, setZipCodeErrorText] = useState("");
    const [shipperErrorText, setShipperErrorText] = useState("");
    const [facilityErrorText, setFacilityErrorText] = useState("");
    const [appointmentErrorText, setAppointmentErrorText] = useState("");
    const [acceptedEquipmentErrorText, setAcceptedEquipmentErrorText] = useState("");
    const [individualRating] = useState(facility.separateRatingCount ? facility.separateRatingCount : [])

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: 'black',
          },
        [`& .${tooltipClasses.tooltip}`]: {
          color: 'rgba(0, 0, 0, 0.87)',
        // //   fontSize: theme.typography.pxToRem(12),
          border: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: '#fff',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '10px',
        padding: '20px'
        },
      }));

    useEffect(() => {
        getShippers().then(shippers => {
            setShippers(shippers);
        })

        getFacilityTypes().then(facilityTypes => {
            setFacilityTypeValues(facilityTypes)
        })

        getAppointmentTypes().then(appointments => {
            setAppointmentTypeValues(appointments);
        })


    },[])

    // useEffect(() => {
    //     if(facility.acceptedEquipment && facility.acceptedEquipment !== null && facility.acceptedEquipment.acceptedEquipmentId > AcceptedEquipmentTypes.length-1) {
    //         handleOtherTextChange(facility.acceptedEquipment.name)
    //     }
    // },[facility])    

    const handleShipperChange = (id: number, name: string) => {
        const updatedFields = multiSelectFields.map((field) =>
          field.id === id ? { ...field, name } : field
        );
        setMultiSelectFields(updatedFields);
    };

    const handleAddField = () => {
        const newField: SelectMultiField = { id: multiSelectFields.length, name: '' };
        setMultiSelectFields([...multiSelectFields, newField]);
    };

    const handleRemoveField = (id: number) => {
        const updatedFields = multiSelectFields.filter((field) => field.id !== id);
        setMultiSelectFields(updatedFields);
    };

    const handleFacilityChange = (name: string) => {
        const updatedFields = facilityTypeValues.filter((field) => field.name === name);
        setFacilityType(updatedFields[0])
    }

    const handleAppointmentChange = (name: string) => {
        const updatedFields = appointmentTypeValues.filter((field) => field.name === name);
        setAppointmentType(updatedFields[0])
    }

    const handleAcceptedInputChange = (e) => {
        const {value} = e.target;
        setAcceptedEquipmentValues(typeof value === 'string' ? value.split(',') : value);
    };

    // const handleOtherTextChange = (value: string) => {
    //     const newId = AcceptedEquipmentTypes.length
    //     const newValue = {name: value,acceptedEquipmentId: newId}
    //     AcceptedEquipmentTypes.push(newValue)
    //     setAcceptedEquipment(newId);
    //     setOtherValue("")
    // };

    if (edit && loading) {
        return <Loading/>
    }

    function saveChanges() {
        const getShipperFromId = multiSelectFields.map((field) =>  {
            if (field && shippers) {
                return shippers?.find(shipper => shipper.name === field.name);
            }
            return undefined;
        })
        const shipperNotExist = getShipperFromId.filter(shipper => shipper === undefined).length > 0

        if (!name) {
            setNameErrorText("Please enter name of Facility");
        } else {
            setNameErrorText("");
        }
        if (!street) {
            setStreetOneErrorText("Please enter Street Details");
        } else {
            setStreetOneErrorText("");
        }
        if (!city) {
            setCityErrorText("Please enter City Details");
        } else {
            setCityErrorText("");
        }
        if (!state) {
            setStateErrorText("Please enter State Details");
        } else {
            setStateErrorText("");
        }
        if (!zipCode) {
            setZipCodeErrorText("Please enter Zip Code Details");
        } else {
            setZipCodeErrorText("");
        }
        if (shipperNotExist) {
            setShipperErrorText("One of shipper value is missing");
        } else {
            setShipperErrorText("");
        }
        if (!facilityType) {
            setFacilityErrorText("Please select Facility Type");
        } else {
            setFacilityErrorText("");
        }
        if (!appointmentType) {
            setAppointmentErrorText("Please select Appointment Type");
        } else {
            setAppointmentErrorText("");
        }
        if (acceptedEquipmentValues.length > 0) {
            setAcceptedEquipmentErrorText("");
        } else {
            setAcceptedEquipmentErrorText("Please select Accepted Equipment Type");
        }

        // Checking any empty fields
        if(!name || !street || !city || !state || !zipCode || 
        shipperNotExist || !facilityType || !appointmentType || acceptedEquipmentValues.length <= 0) {
            setErrorMessage("Some Fields values are missing!!!");
            setOpen(true);
        } else {
            // const acceptedEquipmentData = AcceptedEquipmentTypes.filter(item => acceptedEquipmentValues.includes(item.name))
            // const acceptedEquipmentReturnData = acceptedEquipmentData.map(itemA => {
            //     const matchingItem = facility.acceptedEquipmentList && facility.acceptedEquipmentList.find(itemB => (
            //       itemB.name === itemA.name && itemB.acceptedEquipmentId === itemA.acceptedEquipmentId
            //     ));
            //     return matchingItem || itemA;
            //   });
            //   console.log(acceptedEquipmentReturnData);
            setLoading(true);
            facility.name = name;
            facility.address.streetOne = street;
            facility.address.city = city;
            facility.address.state = state;
            facility.address.zipCode = zipCode;
            facility.shippers = getShipperFromId;
            facility.dock = dock;
            facility.facilityType = facilityType;
            facility.appointmentType = appointmentType;
            facility.pickUpType = pickUpType !== null ? PickUpTypes[pickUpType]: null;
            facility.deliveryType = deliveryType !== null ? DeliveryTypes[deliveryType]: null;
            facility.acceptedEquipmentList = AcceptedEquipmentTypes.filter(item => acceptedEquipmentValues.includes(item.name))
            if (facility.state === 'INCOMPLETE' && facilityActive) {
                facility.state = 'ACTIVE';
            }
    
            updateFacilityFn(facility).then(f => {
                setLoading(false);
                setEdit(false);
            }).catch(err => {
                setLoading(false);
                // put up an error on the top of the screen
            });
        }


    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setErrorMessage('');
    };

    if (edit) {
        return <SectionContainer title={"Edit Facility Details"}>
            <div className={"facility-details-edit-layout"}>
                <TextField label={"Name"}
                           variant={"outlined"}
                           value={name}
                           size={"small"}
                           fullWidth={true}
                           onBlur={() => name === "" ? setNameErrorText("Please enter name of Facility") : setNameErrorText("")}
                           error={!!nameErrorText}
                           helperText={nameErrorText}
                           onChange={(evt) => setName(evt.target.value)}/>

                <TextField label={"Street"}
                           variant={"outlined"}
                           value={street}
                           size={"small"}
                           fullWidth={true}
                           onBlur={() => street === "" ? setStreetOneErrorText("Please enter Street Details") : setStreetOneErrorText("")}
                        error={!!streetOneErrorText}
                        helperText={streetOneErrorText}
                           onChange={(evt) => setStreet(evt.target.value)}/>

                <TextField label={"City"}
                           variant={"outlined"}
                           value={city}
                           size={"small"}
                           fullWidth={true}
                           onBlur={() => city === "" ? setCityErrorText("Please enter City Details") : setCityErrorText("")}
                            error={!!cityErrorText}
                            helperText={cityErrorText}
                           onChange={(evt) => setCity(evt.target.value)}/>
                           

                <div className={"form-row"}>
                    <FormControl sx={{marginTop:1}}>
                        <InputLabel id="state">State</InputLabel>
                        <Select value={state}
                                size={"small"}
                                onChange={(evt) => setState(evt.target.value)}
                                label="State"
                                onBlur={() => state === "" ? setStateErrorText("Please enter State Details") : setStateErrorText("")}
                                error={!!stateErrorText}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '150px',
                                            '& .MuiMenuItem-root': {
                                                padding: 1,
                                            },
                                        },
                                    },
                                }}>
                            {STATES.map(st => <MenuItem key={st} value={st}>{st}</MenuItem>)}
                        </Select>
                        <FormHelperText style={{color: '#d32f2f'}}>{stateErrorText}</FormHelperText>
                    </FormControl>
                    
                    <FormControl sx={{marginTop:1}}>
                        <TextField label={"Postal Code"}
                                variant={"outlined"}
                                value={zipCode}
                                size={"small"}
                                fullWidth={true}
                                onBlur={() => zipCode === "" ? setZipCodeErrorText("Please enter Zip Code Details") : setZipCodeErrorText("")}
                                error={!!zipCodeErrorText}
                                helperText={zipCodeErrorText}
                                onChange={(evt) => setZipCode(evt.target.value)}/>
                    </FormControl>

                </div>

                <div className={"form-row"}>
                    <FormControl sx={{marginTop:1}}>
                        <InputLabel id="facilityType">Facility Type</InputLabel>
                        <Select value={facilityType ? facilityType.name : ''}
                                size={"small"}
                                onChange={(evt) => handleFacilityChange(evt.target.value)}
                                id="facilityType"
                                name="facilityType"
                                label="Facility Type"
                                error={!!facilityErrorText}
                                onBlur={() => facilityType ? facilityType.name === "" ? setFacilityErrorText("Please select Facility Type") : setFacilityErrorText("") : setFacilityErrorText("Please select Facility Type") }
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '150px',
                                            '& .MuiMenuItem-root': {
                                                padding: 1,
                                            },
                                        },
                                    },
                                }}>
                            {facilityTypeValues.map(st => <MenuItem key={st.id} value={st.name}>{st.name}</MenuItem>)}
                        </Select>
                        <FormHelperText style={{color: '#d32f2f'}}>{facilityErrorText}</FormHelperText>
                    </FormControl>
                    
                    <FormControl sx={{marginTop:1}}>
                        <InputLabel id="appointmentType">Appointment Type</InputLabel>
                        <Select value={appointmentType ? appointmentType.name : ''}
                                size={"small"}
                                onChange={(evt) => handleAppointmentChange(evt.target.value)}
                                
                                id="appointmentType"
                                name="appointmentType"
                                label="Appointment Type"
                                error={!!appointmentErrorText}
                                onBlur={() => appointmentType ? appointmentType.name === "" ? setAppointmentErrorText("Please select Appointment Type") : setAppointmentErrorText("") :  setAppointmentErrorText("Please select Appointment Type")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '150px',
                                            '& .MuiMenuItem-root': {
                                                padding: 1,
                                            },
                                        },
                                    },
                                }}>
                            {appointmentTypeValues.map(st => <MenuItem key={st.id} value={st.name}>{st.name}</MenuItem>)}
                        </Select>
                        <FormHelperText style={{color: '#d32f2f'}}>{appointmentErrorText}</FormHelperText>
                    </FormControl>
                </div>

                <div className={"form-row"}>
                    <FormControl sx={{marginTop:1}}>
                        <InputLabel id="pickUpType">Pick Up Type</InputLabel>
                        <Select value={pickUpType}
                                size={"small"}
                                onChange={(evt) => setPickUpType(evt.target.value)}
                                id="pickUpType"
                                name="pickUpType"
                                label="Pick Up Type"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '150px',
                                            '& .MuiMenuItem-root': {
                                                padding: 1,
                                            },
                                        },
                                    },
                                }}>
                            {PickUpTypes.map(st => <MenuItem key={st.pickupId} value={st.pickupId}>{st.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    
                    <FormControl sx={{marginTop:1}}>
                        <InputLabel id="pickUpType">Delivery Type</InputLabel>
                        <Select value={deliveryType}
                                size={"small"}
                                onChange={(evt) => setDeliveryType(evt.target.value)}
                                id="deliveryType"
                                name="deliveryType"
                                label="Delivery Type"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '150px',
                                            '& .MuiMenuItem-root': {
                                                padding: 1,
                                            },
                                        },
                                    },
                                }}>
                            {DeliveryTypes.map(st => <MenuItem key={st.deliveryId} value={st.deliveryId}>{st.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>

                <div className={"form-row"}>
                    <FormControl sx={{marginTop:1}}>
                        <InputLabel id="acceptedEquipment">Accepted Equipment</InputLabel>
                        <Select value={acceptedEquipmentValues}
                                size={"small"}
                                onChange={(evt) => handleAcceptedInputChange(evt)}
                                id="acceptedEquipment"
                                name="acceptedEquipment"
                                label="Accepted Equipment"
                                multiple
                                error={!!acceptedEquipmentErrorText}
                                onBlur={() => acceptedEquipmentValues.length > 0 ? setAcceptedEquipmentErrorText("") : setAcceptedEquipmentErrorText("Please select Accepted Equipment")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '150px',
                                            '& .MuiMenuItem-root': {
                                                padding: 1,
                                            },
                                        },
                                    },
                                }}>
                            {AcceptedEquipmentTypes.map(st => 
                            <MenuItem key={st.acceptedEquipmentId} value={st.name}>
                                    <Checkbox checked={acceptedEquipmentValues.indexOf(st.name) > -1} />
                                    {st.name}
                             </MenuItem>
                            )}
                        </Select>
                        <FormHelperText style={{color: '#d32f2f'}}>{acceptedEquipmentErrorText}</FormHelperText>
                    </FormControl>
                    
                    {/* <FormControl sx={{marginTop:1}}>
                        {acceptedEquipment !== '' && acceptedEquipment !== null && AcceptedEquipmentTypes[acceptedEquipment].name === 'Others' && (
                            <TextField
                            size={"small"}
                            className={"add-other-value"}
                            label="Add Other Value"
                            value={otherValue}
                            onChange={(e) => setOtherValue(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                                className={"select-client"}
                                                color="primary"
                                                startIcon={<AddCircle className={"icon-size"} />}
                                                onClick={(e) => handleOtherTextChange(otherValue)}
                                            ></Button>
                                    </InputAdornment>
                                ),
                            }}
                            >
                            </TextField>
                        )}
                </FormControl> */}
                </div>

                <div className={"form-row"}>
                    <FormControl sx={{marginTop:1}}>
                        {shippers ?
                        <div>
                            <InputLabel id="shipper-label">Client</InputLabel>
                            {multiSelectFields.map((field) => (
                            <div key={field.id}>
                            <Select
                                labelId="shipper-label"
                                sx={{marginBottom: "15px", width:"100%"}}
                                label="Client"
                                value={field.name}
                                onChange={(e) => handleShipperChange(field.id, e.target.value as string)}
                                error={!!shipperErrorText}
                                onBlur={() => field.name === "" ? setShipperErrorText("Please select Shipper") : setShipperErrorText("")}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <Button
                                            className={"select-client"}
                                            color="primary"
                                            startIcon={<AddCircle className={"icon-size"} />}
                                            onClick={handleAddField}
                                        >
                                        </Button>
                                        {multiSelectFields.length > 1 ?
                                        <Button
                                            className={"select-client"}
                                            color="secondary"
                                            startIcon={<RemoveCircle className={"icon-size"} />}
                                            onClick={() => handleRemoveField(field.id)}
                                        >
                                        </Button> : ""}
                                    </InputAdornment>
                                    
                                }
                            >
                                <MenuItem value="" selected={true}>
                                    <em>Select a Client</em>
                                </MenuItem>
                                {shippers?.map(option => {
                                    return (
                                        <MenuItem key={option.id} value={option.name}>
                                            {option.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            </div>
                            ))}
                            <FormHelperText style={{color: '#d32f2f'}}>{shipperErrorText}</FormHelperText>
                        </div>
                        : <></>
                    }
                    </FormControl>
                </div>

                <FormGroup>
                    <FormControlLabel label="Has Dock"
                                      control={<Checkbox
                                          checked={dock}
                                          onChange={evt => setDock(evt.target.checked)}/>}/>
                </FormGroup>

                {facility.state === 'INCOMPLETE' ?
                    <FormGroup>
                        <FormControlLabel label="Mark Facility Active (Can not be undone once saved)"
                                          control={<Checkbox
                                              checked={facilityActive}
                                              onChange={evt => setFacilityActive(evt.target.checked)}/>}/>
                    </FormGroup>
                    : null
                }

                <div className={"action-bar"}>
                    <Button variant={"outlined"}
                            color={"secondary"}
                            onClick={() => setEdit(false)}>Cancel</Button>
                    <Button variant={"outlined"}
                            color={"primary"}
                            onClick={saveChanges}>Update</Button>
                </div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>
        </SectionContainer>
    }

    return <SectionContainer title={facility.name} className={"facility-details"}>
        <Stack direction="row" className={"ratings"} spacing={2}>
            {/* <div className="row"> */}
                <Box
                sx={{
                    width: 300,
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                {facility.rating && facility.rating > 0 ?
                <>
                {facility.rating}
                <Rating
                    name="text-feedback"
                    value={facility.rating}
                    readOnly
                    precision={0.5} 
                />
                </>
                : '' }
                <HtmlTooltip
                    title={
                    <React.Fragment>
                            {facility.rating && facility.rating > 0 ?
                            <Stack direction="row" className={"ratings"} spacing={1}>
                            <Rating
                                name="text-feedback"
                                value={facility.rating}
                                readOnly
                                precision={0.5} 
                            />
                            <Typography variant="h6">{facility.rating} out of 5</Typography>
                            </Stack>
                            : '' }
                            {/* <Typography color="inherit"variant="body2" marginTop={1}>12 Rating</Typography> */}
                            <Typography color="inherit"variant="body2" marginTop={1}>{facility.totalNumberOfRatings && facility.totalNumberOfRatings > 0 ? facility.totalNumberOfRatings + " Rating" :  ''}  </Typography>
                            {individualRating.map((single) => {
                                return <Stack marginTop={1} direction="row" className={"ratings"} spacing={1}>
                                <Typography color="#007185" variant="h6">{single.rating} star</Typography>
                                <Rating
                                    name="text-feedback"
                                    value={single.rating}
                                    readOnly
                                />
                                <Typography variant="subtitle1">{single.count} Ratings</Typography>
                                </Stack>
                            })}
                    </React.Fragment>
                    }
                >
                <Typography sx={{ ml: 1 }}>{facility.totalNumberOfRatings && facility.totalNumberOfRatings > 0 ? facility.totalNumberOfRatings + " Rating" :  ''} {facility.waitTime && facility.waitTime !== '' ? "| " + facility.waitTime  : '' }</Typography>
                </HtmlTooltip>
                </Box>
        </Stack>
        
        <div className={"facility-details-layout"}>
            <div className={"location"}>
                <div className={"label"}>Client</div>
                <div>{facility.shippers.length > 0 ? facility.shippers.map(o => o.name).join(','): null}</div>
                <div className={"label"}>Address</div>
                <div>
                    <div>{facility.address.streetOne}</div>
                    <div>{facility.address.city}, {facility.address.state} {facility.address.zipCode}</div>
                </div>
            </div>
            <div className={"drop-down"}>
                <div className={"label"}>Pick Up Type</div>
                <div>{facility.pickUpType ? facility.pickUpType.name : null}</div>
                <div className={"label"}>Delivery Type</div>
                <div>{facility.deliveryType ? facility.deliveryType.name : null}</div>
                <div className={"label"}>Accepted Equipment</div>
                <div>{facility.acceptedEquipmentList && facility.acceptedEquipmentList.length > 0  ? facility.acceptedEquipmentList.map(o => o.name).join(',') : null}</div>
            </div>
            <div className={"variables"}>
                <div className={"label"}>Facility Type</div>
                <div>{facility.facilityType ? facility.facilityType.name : ''}</div>
                <div className={"label"}>Appointment Type</div>
                <div>{facility.appointmentType ? facility.appointmentType.name : ''}</div>
            </div>
            <div className={"options"}>
                <div className={"label"}>Status</div>
                <div>{facility.state}</div>
                <div className={"label"}>Dock</div>
                <div><BooleanText value={facility.dock}/></div>
            </div>
        </div>
        <Fab className={"edit-facility-icon"}
             size={"small"}
             aria-label={"Edit"}
             color={"primary"}
             onClick={() => setEdit(true)}>
            <EditIcon/>
        </Fab>
    </SectionContainer>
}

export default FacilityDetails;
