import {getBooleanLocalStorageItem, setBooleanLocalStorageItem} from "../util/local-storage-utils";

const SETTINGS_MILITARY_TIME_KEY = "settings.display.militaryTime";
const SETTINGS_CONTINUING_LOADS_ENABLED_KEY = "settings.display.continuingLoads";
const SETTINGS_RFP_AUTOMATED_ENABLED_KEY = "settings.display.rfp.automated";
const SETTINGS_TOUR_AUTOMATED_ENABLED_KEY = "settings.display.tour.enabled";

// consider putting these in recoil

export function getMilitaryTimeDisplay(): boolean {
    return getBooleanLocalStorageItem(SETTINGS_MILITARY_TIME_KEY, false);
}

export function setMilitaryTimeDisplay(setting: boolean): void {
    setBooleanLocalStorageItem(SETTINGS_MILITARY_TIME_KEY, setting)
}

export function getContinuingLoadsEnabled(): boolean {
    return getBooleanLocalStorageItem(SETTINGS_CONTINUING_LOADS_ENABLED_KEY, false);
}

export function setContinuingLoadsEnabled(setting: boolean): void {
    setBooleanLocalStorageItem(SETTINGS_CONTINUING_LOADS_ENABLED_KEY, setting);
}

export function setRFPAutomatedLoadsEnabled(setting: boolean): void {
    setBooleanLocalStorageItem(SETTINGS_RFP_AUTOMATED_ENABLED_KEY, setting);
}

export function getRFPAutomatedLoadsEnabled(): boolean {
   return getBooleanLocalStorageItem(SETTINGS_RFP_AUTOMATED_ENABLED_KEY, false);
}

export function setTourAutomatedLoadsEnabled(setting: boolean): void {
    setBooleanLocalStorageItem(SETTINGS_TOUR_AUTOMATED_ENABLED_KEY, setting);
}

export function getTourAutomatedLoadsEnabled(): boolean {
    return getBooleanLocalStorageItem(SETTINGS_TOUR_AUTOMATED_ENABLED_KEY, false);
}
