import {BaseFacilityContact, Facility, FacilityContact, FacilityHour, FacilityType, AppointmentType, AllRatings, FacilityHolidays, FacilityPastUpcomingLoads, HistoricalData} from "../interfaces/Facility";
import {Load, Shipper, Stop} from "../interfaces/Load";
import {API_BASE_URL} from "../constants";
import {requestBytes, saveFacility, torchFetch} from "../util/APIUtils";
import {getAllLoadsWithoutFacilities, getLoad} from "../service/loads";
import {Pageable} from "../service/base";

export interface PageableFacilityList {
    content: Facility[];
    totalElements: number;  // total elements available
    pageable: Pageable;
}

// GET /facility
export function getFacilities(status: string, page: number, pageSize: number): Promise<PageableFacilityList> {
    return searchFacilities('', null, null, status, page, pageSize);
}

export function searchFacilities(query:string, shipper:Shipper|null, state:string|null, status: string, page: number, pageSize: number): Promise<PageableFacilityList> {
    const shipperParam = shipper ? `&shipper=${shipper.id}` : ''
    const stateParam = state ? `&state=${state}` : ''
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/facility/search?q=${query}&status=${status}${shipperParam}${stateParam}&page=${page}&size=${pageSize}`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    })
}

// GET Facility Data for Excel
export function downloadFacilityData(query:string, shipper:Shipper|null, state:string|null,status: string): Promise<any>{
    const shipperParam = shipper ? `&shipper=${shipper.id}` : ''
    const stateParam = state ? `&state=${state}` : ''
    return requestBytes({
        url: API_BASE_URL + `/facility/facility-excel/generate?q=${query}&status=${status}${shipperParam}${stateParam}` ,
        method: 'get',
        responseType: 'blob'
    })
}

// Post Facility Data of Excel
export function uploadFacilityData(uploadedFile: string | ArrayBuffer | null): Promise<any>{
    return torchFetch({
        url: API_BASE_URL + `/facility/facility-excel/submit`,
        method: 'post',
        responseType: 'blob',
        body: uploadedFile
    });
}

// GET /facility/{id}
export function getFacility(id: number): Promise<Facility> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/facility/${id}`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    })
}

export function getFacilityLoad(id: number): Promise<Load> {
    return getLoad(id);
}

// GET /load?filter=need_facility
export function getLoadsWithoutFacilities(): Promise<Load[]> {
    return getAllLoadsWithoutFacilities();
}

// GET /facility?shipper={shipper_id}&location={lat,long}&radius={x}
export function getNearbyFacilities(radiusMeters: number): Promise<Facility[]> {
    return Promise.resolve([]);
}

// POST /facility
export function createFacility(stop: Record<string, string> | Stop, f: Facility): Promise<Facility> {
   return saveFacility(f);
}

// PUT /facility/{f.id}
export function updateFacility(f: Facility): Promise<Facility> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}`,
        method: 'put',
        body: JSON.stringify(f)
    });
}

// GET /facility/{f.id}/contact
export function getFacilityContacts(f: Facility): Promise<FacilityContact[]> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: API_BASE_URL + `/facility/${f.id}/contact`,
            method: 'get'
        }).then(json => {
            resolve(json.content);
        });;
    })
}

// POST /facility/{f.id}/contact
export function createFacilityContact(f: Facility, contact: BaseFacilityContact): Promise<FacilityContact> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/contact`,
        method: 'post',
        body: JSON.stringify(contact)
    });
}

// PUT /facility/{f.id}/contact/{contact.id}
export function updateFacilityContact(f: Facility, contact: FacilityContact): Promise<FacilityContact> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/contact/${contact.id}`,
        method: 'put',
        body: JSON.stringify(contact)
    });
}

// GET /facility/{f.id}/hour
export function getFacilityHours(f: Facility): Promise<FacilityHour[]> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/hour`,
        method: 'get'
    });
}

// POST /facility/{f.id}/hour
export function createFacilityHour(f: Facility, hour: FacilityHour): Promise<FacilityHour> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/hour`,
        method: 'post',
        body: JSON.stringify(hour)
    });
}

export function saveFacilityHour(f: Facility, hour: FacilityHour): Promise<FacilityHour> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/contact`,
        method: 'put',
        body: JSON.stringify(hour)
    });
}

// need to update this take an array on put without the id
// PUT /facility/{f.id}/hour/{hour.id}
export function saveFacilityHours(f: Facility, facilityHours: FacilityHour[]): Promise<FacilityHour[]> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/hours`,
        method: 'put',
        body: JSON.stringify(facilityHours)
    });
}

// GET /facility/{f.id}/holidays
export function getFacilityHolidays(f: Facility): Promise<FacilityHolidays[]> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/holiday`,
        method: 'get'
    });
}

// need to update this take an array on put without the id
// PUT /facility/{f.id}/holiday/
export function saveFacilityHolidays(f: Facility, multiHoliday: FacilityHolidays[]): Promise<FacilityHour[]> {
    return torchFetch({
        url: API_BASE_URL + `/facility/${f.id}/holidays`,
        method: 'put',
        body: JSON.stringify(multiHoliday)
    });
}

// GET /facility
export function getShippers(): Promise<Shipper[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/shipper`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    })
}

// GET /facility
export function getStates(): Promise<string[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/state`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    })
}

// GET Appointment Types
export function getAppointmentTypes(): Promise<AppointmentType[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/facility/appointment_types`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    })
}

// GET Facility Types
export function getFacilityTypes(): Promise<FacilityType[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/facility/types`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    })
}

// GET Facility Ratings
export function getAllFacilityRatings(facilityId: number): Promise<AllRatings[]> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: API_BASE_URL + `/facility/rating/${facilityId}`,
            method: 'get'
        }).then(json => {
            resolve(json);
        });;
    })
}

// GET Loads for rating
export function getLoads(facilityId: number): Promise<string[]> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: API_BASE_URL + `/facility/load-ids/${facilityId}`,
            method: 'get'
        }).then(json => {
            resolve(json);
        });;
    })
}

export function addUpdateFacilityRating(ratings: AllRatings, editDataAvailable): Promise<AllRatings> {
    let method;
    editDataAvailable? method = "put" : method = "post"
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/facility/rating/submit_rating`);
        torchFetch({
            url: url,
            method: method,
            body: JSON.stringify(ratings)
        }).then(json => {
            resolve(json);
        });
    })
}

// GET Facility Loads
export function getFaciliityLaods(facilityId: number, loadType: string): Promise<FacilityPastUpcomingLoads[]> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: API_BASE_URL + `/facility/loads/${facilityId}?q=${loadType} `,
            method: 'get'
        }).then(json => {
            resolve(json);
        });;
    })
}

// GET Facility Historical Data
export function getFacilityHistoricalData(facilityId: number, alignment: string): Promise<HistoricalData[]> {
    return new Promise((resolve, reject) => {
        torchFetch({
            url: API_BASE_URL + `/facility/historical-data/${facilityId}?q=${alignment}`,
            method: 'get'
        }).then(json => {
            resolve(json);
        });;
    })
}

// GET Facility Historical Data for Excel
export function downloadFacilityHistoricalData(facilityId: number): Promise<any>{
    return requestBytes({
        url: API_BASE_URL + `/facility/historical-data/download/${facilityId}` ,
        method: 'get',
        responseType: 'blob'
    })
}

// need a Load|Stop pair to find nearby facilities since the shipper
// is part of the facility and the only way to get that is via the Load
//

/*const facilitiesState = atom<Facility[]>({
    key: 'facilities',
    default: []
});

const facilitiesMapState = atom<Map<number, Facility>> ( {
   key: 'facilities',
   default: new Map<number, Facility>()
});*/

