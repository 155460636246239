import {atom} from "recoil";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import {SkinnyLoad} from "../interfaces/Load";
import {Address} from "../interfaces/Address";

// currentLane: LaneDetails (atom)
/*
interface LaneDetails {
    laneKey: string;   // ex: zip_zip
    pastLoads: SkinnyLoad[];
    showRouteMap: boolean;
    routeMapAvailable: boolean;
}
*/

export const showRouteMapState = atom({
    key: 'showRouteMap',
    default: false
});

export const routeMapAvailableState = atom({
    key: 'routeMapAvailable',
    default: false
});

export interface SettableValue<T> {
    isSet: boolean;
    value?: T;
}

export const suggestedCarrierState = atom<SettableValue<SuggestedCarrier>>({
    key: 'suggestedCarrier',
    default: {isSet: false}
});

export const pastLoadsState = atom<SkinnyLoad[]>({
    key: 'pastLoads',
    default: []
});

export function setupLaneStats(origin: Address, destination: Address) {
    //const [,setPastLoads] = useRecoilState(pastLoadsState);
    //loadsByLane(origin, destination).then(loads => set)
}