import React, {useState} from "react";
import {List, ListItemButton, ListItemText} from "@mui/material";
import "./Settings.scss"
import Typography from "@mui/material/Typography";
import SettingsTasks from "./SettingsTasks";
import SettingsEdi from "./SettingsEdi";
import SettingsGeneral from "./SettingsGeneral";

interface SettingsOption {
    id: string;
    title: string;
    subTitle: string;
    container: JSX.Element;
}

const SettingsMain = () => {

    const [selected, setSelected] = useState("general");

    const settingsOptions: SettingsOption[] = [{
        id: "general",
        title: "General",
        subTitle: "General Settings",
        container: <SettingsGeneral/>
    }, {
        id: "tasks",
        title: "Tasks",
        subTitle: "Load Procurement Task Options",
        container: <SettingsTasks/>
    }, {
        id: "edi",
        title: "EDI",
        subTitle: "EDI View Filters",
        container: <SettingsEdi/>
    }];

    const settingsContainer = () => {
        return settingsOptions.find(opt => opt.id === selected)?.container;
    }

    return (
        <div className={"settings-container"}>
            <div className={"settings-list"}>
                <Typography variant="h6" className={"title"} noWrap>
                    Settings
                </Typography>
                <List>
                    {
                        settingsOptions.map(opt =>
                            <ListItemButton
                                key={opt.id}
                                selected={opt.id === selected}
                                className={"list-item"}
                                onClick={() => setSelected(opt.id)}>
                                <ListItemText primary={opt.title}
                                              secondary={opt.subTitle}/>
                            </ListItemButton>
                        )
                    }
                </List>
            </div>
            <div className={"settings-content"}>
                {settingsContainer()}
            </div>
        </div>
    )
}

export default SettingsMain;
