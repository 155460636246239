import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={6.29415}
      cy={6.29415}
      r={5.54415}
      stroke="#94A3B8"
      strokeWidth={1.5}
    />
    <rect
      x={10.5278}
      y={11.3418}
      width={1.15124}
      height={3.7593}
      rx={0.575622}
      transform="rotate(-45 10.5278 11.3418)"
      fill="#94A3B8"
    />
  </svg>
);
export default SVGComponent;
