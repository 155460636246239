import React from "react";
import {Facility} from "../interfaces/Facility";
import {Alert} from "@mui/lab";

type FacilityProps = {
    facility: Facility;
    facilityUpdated: () => void;
}

const CompleteFacility = ({facility, facilityUpdated}: FacilityProps) => {

    if(facility.state === 'INCOMPLETE') {
        return  <Alert severity="error">Facility has not been marked as active.  When facility information is
            complete, edit details and mark as active.</Alert>
    }

    return null;
}

export default CompleteFacility;