import React, {useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {dateFormat} from "../service/time";
import {CarrierActivity} from "../interfaces/Carrier";

type PropTypes = {
    activities: CarrierActivity[];
}

const CarrierActivities = ({activities}: PropTypes) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return activities ? <TableContainer>
        <Table className={"activities-list"} aria-label={"Load Rate Offers"}>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Activity</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {activities.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(activity => {
                    return <TableRow key={activity.load.id}>
                        <TableCell>{dateFormat(activity.instant)}</TableCell>
                        <TableCell>{activity.message}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
        <TablePagination
            count={activities.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{borderBottom: 0}}
        />
    </TableContainer> : <div>No Recent Activity</div>;
}

export default CarrierActivities;
