import React from "react";
import {styled} from "@mui/system";
import {Carrier} from "../interfaces/Carrier";
import {Link} from "react-router-dom";
import {Load} from "../interfaces/Load";
import {SHIPWELL_URL} from "../constants";

const A = styled('a')`
  text-decoration: none;
`

const StdLink = styled(Link)`
  text-decoration: none;
`

export const ExternalLink = (props) => {
    return <A href={props.href} target={"_blank"} rel="noreferrer">{props.children}</A>;
}

type PropTypes = {
    shipwellId: string;
    url?: string;
    children: React.ReactNode;
}

/**
 * Creates a link to a Shipwell shipment page. Requires the shipwell ID
 * at a minimum.
 *
 * @param shipwellId Required parameter - the Shipwell ID
 * @param children
 * @param hostname
 * @param protocol
 * @constructor
 */
export const TorchShipmentExternalLink = ({
                                              shipwellId,
                                              children,
                                              url = SHIPWELL_URL
                                          }: PropTypes) => {

    const completeUrl = `${url}/shipments/${shipwellId}`;
    return <A href={completeUrl} target={"_blank"} rel="noreferrer">{children}</A>;
}

type SearchExternalLinkParams = {
    query: string;
    url?: string;
    children: React.ReactNode;
}

export const TorchShipmentSearchExternalLink = ({
                                                    query,
                                                    children,
                                                    url = SHIPWELL_URL
                                                }: SearchExternalLinkParams) => {
    const completeUrl = `${url}/dashboard?q=${query}`;
    return <A href={completeUrl} target={"_blank"} rel="noreferrer">{children}</A>;
}

type CarrierLinkParams = {
    carrier?: Carrier;
    carrierId?: number;
    children: React.ReactNode;
}

export const CarrierLink = ({carrierId, carrier, children}: CarrierLinkParams) => {
    let id: number | undefined;
    if (carrier) {
        id = carrier.id;
    }

    if (carrierId) {
        id = carrierId;
    }

    if (id) {
        return <StdLink to={"/carriers/" + id}>{children}</StdLink>
    }

    return null;
}

type LoadLinkParams = {
    load?: Load;
    loadId?: number;
    children: React.ReactNode;
    openLoadView?: boolean;
}

export const LoadLink = ({load, loadId, children, openLoadView = false}: LoadLinkParams) => {
    let id: number | undefined;
    if (load) {
        id = load.id;
    }

    if (loadId) {
        id = loadId;
    }

    if (id && !openLoadView) {
        return <StdLink to={"/loads/" + id}>{children}</StdLink>
    }

    if (id) {
        return <StdLink to={"/open-loads/" + id}>{children}</StdLink>
    }

    return null;
}


export default ExternalLink;