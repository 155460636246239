import React from "react";
import {styled} from "@mui/system";
import BidSearch from "./BidSearch";

const Container = styled('div')`
  padding: 20px;
  height: 100%;
`

const Bids = () => {
    return <Container>
        <BidSearch/>
    </Container>;
}

export default Bids;
