import React from "react";
import {ShipwellEventProps, StatusUpdateDetails} from "./types";
import {EventTitle} from "./EventTypeDispatcher";
import PastCurrentData from "./PastCurrentData";

const StatusUpdate = ({event}: ShipwellEventProps) => {
    const data = JSON.parse(event.details)["1"] as StatusUpdateDetails;

    return <div>
        <EventTitle>Shipment Status Updated</EventTitle>
        <PastCurrentData current={[{value: data.new_data.status}]}
                         past={[{value: data.old_data.status}]} />
    </div>
}

export default StatusUpdate;