import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import Loading from "../common/Loading";
import {Link} from "react-router-dom";
import {styled} from "@mui/system";
import {Load} from "../interfaces/Load";
import {queryLoads} from "../service/loads";
import DesktopLoadListExpanding from "./DesktopLoadListExpanding";
import Typography from "@mui/material/Typography";

const QueryControl = styled('div')`
  display: flex;
  align-items: center;
`
const QueryResults = styled('div')`
 margin-bottom:10px;
`

const InProgressLoads = styled(DesktopLoadListExpanding)`
 padding-top:10px;
 margin-top:10px;
`

const NoResults = styled(Typography)`
  color:red;
`

const ViewDetails = styled('div')`
  flex-grow: 3;
  text-align: end;
`

const ViewLabel = styled('span')`
  font-weight: bold;
  color: #555;
  margin-left: 15px;
`

const LoadSearch = () => {

    const [query, setQuery] = useState('');
    const [loads, setLoads] = useState<Load[]>();
    const [loading, setLoading] = useState(false);

    function handleQueryChange(e) {
        setQuery(e.target.value);
    }

    function searchForLoad() {
        setLoading(true);
        queryLoads(query).then(l => {
            setLoads(l);
            setLoading(false);
        }).catch(_ => setLoading(false));
    }

    function LoadList() {
        if (loading) {
            return <Loading/>
        }

        if (query !== '' && loads && loads.length === 0) {
            return <div>
                <NoResults> No loads found for query '{query}'</NoResults>
                <InProgressLoads loading={loading}></InProgressLoads>
            </div>
        }

        if (loads && loads.length > 0) {
            return <>
                {loads.map(load => {
                    return <div key={load.id}>
                        <Link to={`/loads/${load.id}`}>Open</Link> {load.name}
                    </div>
                })}
            </>
        }
        return <InProgressLoads loading={loading}></InProgressLoads>
    }

    return <>
        <QueryControl>
            <TextField label="Ref ID, Shipment Id, etc."
                       variant="outlined"
                       size={"small"}
                       value={query}
                       onChange={handleQueryChange}
                       sx={{width: "50ch"}}/>
            <Button variant="outlined"
                    sx={{padding: "6px 20px", marginLeft: "15px"}}
                    onClick={searchForLoad}>Search</Button>
            { (!loads || loads.length === 0 || query === '') ?
                <ViewDetails><ViewLabel>Current View:</ViewLabel> In Progress Loads</ViewDetails>
             :
                <></>}
        </QueryControl>
        <QueryResults>
            <LoadList/>
        </QueryResults>
    </>;
}

export default LoadSearch;
