import React from "react";
import {RepUpdatedDetails, ShipwellEventProps} from "./types";
import {EventTitle} from "./EventTypeDispatcher";

const ShipmentRepCreated = ({event, title}: ShipwellEventProps) => {
    const details = JSON.parse(event.details)["1"] as RepUpdatedDetails;

    return <div>
        <EventTitle>{title}</EventTitle>
        <div>{details.role} (See Details)</div>
    </div>
}

export default ShipmentRepCreated;