import { MenuItem } from "@mui/material";

const SortMenu = () => {
   
    return (
        <>
           <MenuItem>Appointment Reschedule</MenuItem>
           <MenuItem>High Margin Loads</MenuItem>
           <MenuItem>Low margin override</MenuItem>
           <MenuItem>Load Status Changes and Tracking</MenuItem>
           <MenuItem>Load closures</MenuItem>
           <MenuItem>Tender Acceptance / Rejection</MenuItem>
           <MenuItem>Carrier Compliance</MenuItem>
           <MenuItem>Carrier Review</MenuItem>
           <MenuItem>Account Billing</MenuItem>
           <MenuItem>Accounts Receivable</MenuItem>
           <MenuItem>Accessorial Additions</MenuItem>
           <MenuItem>Claims</MenuItem>
           <MenuItem>Double Brokering Investigations</MenuItem>
           <MenuItem>Customer Sales</MenuItem>
       </>
    )
}
export default SortMenu;