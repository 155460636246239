import {
    AcceptanceRates,
    EDIDeclineCode,
    EDILoadTenderResponse,
    EDILoadTenderWithUpdates,
    EDIRemark
} from "../interfaces/TenderedLoad";
import {API_BASE_URL} from "../constants";
import {torchFetch} from "../util/APIUtils";

export function getTenderedLoads(daysBack: number): Promise<EDILoadTenderWithUpdates[]> {
    const url = new URL(`${API_BASE_URL}/edi/load-tender?daysBack=${daysBack}`);
    return torchFetch({url: url});
}


export function getAllTenderedLoads(): Promise<EDILoadTenderWithUpdates[]> {
    const url = new URL(`${API_BASE_URL}/edi/load-tender/all`);
    return torchFetch({url: url});
}

export function getTenderedLoad(loadId: number): Promise<EDILoadTenderWithUpdates> {
    const url = new URL(`${API_BASE_URL}/edi/load-tender/${loadId}`);
    return torchFetch({url: url});
}


export function getAcceptanceRate(awardId: number): Promise<AcceptanceRates> {
    const url = new URL(`${API_BASE_URL}/acceptance-rates/${awardId}`);
    return torchFetch({url: url});
}

export function getRejectionCodes(shipperId: string): Promise<EDIDeclineCode[]> {
    const url = new URL(`${API_BASE_URL}/edi/decline-codes/${shipperId}`);
    return torchFetch({url: url});
}

export function acceptRejectTenderedLoad(loadId: number, accept: boolean, declineCodes: EDIDeclineCode[]): Promise<void> {
    const url = new URL(`${API_BASE_URL}/edi/accept-decline/${loadId}`);
    const remarks: EDIRemark[] = declineCodes.map((code: EDIDeclineCode, order: number): EDIRemark => {
        return {order, messageOne: code.key, messageTwo: code.value};
    });
    const response: EDILoadTenderResponse = {accept, remarks}

    return torchFetch({
        url: url,
        method: "put",
        body: JSON.stringify(response)
    });
}

export function disregardTenderedLoad(loadId: number): Promise<void> {
    const url = new URL(`${API_BASE_URL}/edi/disregard/${loadId}`);

    return torchFetch({
        url: url,
        method: "put"
    });
}
