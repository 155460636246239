import React from "react";
import {TextField} from "@mui/material";
import ControlWrapper from "./ControlWrapper";
import {TaskField} from "../workflow";
import {FieldValueChanged} from "../Outcome";

type FieldProps = {
    field: TaskField,
    onChange: FieldValueChanged,
    value: string
};

const WorkflowLargeTextField = ({field, onChange, value}: FieldProps) => {

    function changeHandler(evt) {
        onChange(field.name, evt.target.value);
    }

    return <ControlWrapper>
        <TextField id={field.name}
                   label={field.label}
                   variant={"outlined"}
                   multiline
                   fullWidth
                   rows={2}
                   value={value}
                   required={field.required}
                   onChange={changeHandler}/>
    </ControlWrapper>;
}

export default WorkflowLargeTextField;