import React, {useState} from "react";
import {TaskField} from "./workflow";
import {styled} from "@mui/system";
import WorkflowTextField from "./controls/WorkflowTextField";
import WorkflowLargeTextField from "./controls/WorkflowLargeTextField";
import WorkflowChoice from "./controls/WorkflowChoice";
import WorkflowDateTime from "./controls/WorkflowDateTime";

type OptionProps = {
    fields: TaskField[];
    dataChangedHandler: (data: Map<string, string>) => void;
    padded?: boolean;
};

const FieldsContainer = styled('div')`
  &.padded {
    margin-top: 10px;
  }
`

const Field = styled('div')`
  width: 90%;
  
  &.padded {
    margin: 15px;
  }
`
const OutcomeOptionFields = ({fields, dataChangedHandler, padded = false}: OptionProps) => {

    // needed to set the default values for each field type.  maybe in the future have
    // a way to provide these in the properties
    const [values, setValues] = useState<Map<string, string>>(new Map(fields.map(f => {
        return [f.name, f.fieldType === "date-time" ? new Date().toISOString() : ''];
    })));

    const fieldTypeLookup = {
        "text": WorkflowTextField,
        "large-text": WorkflowLargeTextField,
        "choice": WorkflowChoice,
        "multi-choice": WorkflowChoice,
        "date-time": WorkflowDateTime
    }

    // set it locally and then passing it along.
    // not really happy with this since we duplicate data but it works
    // could have outer container pass down data as well though.  will
    // come back to this after it works.
    function fieldChangeHandler(name, value) {
        const newValues = new Map(values).set(name, value);
        dataChangedHandler(newValues);
        setValues((values) => newValues);
    }

    return <FieldsContainer className={padded ? "padded" : ""}>
        {fields.map(field => {
            const FieldComp = fieldTypeLookup[field.fieldType]
            return <Field key={field.name} className={padded ? "padded" : ""}>
                <FieldComp field={field}
                           value={values.get(field.name) || ''}
                           onChange={fieldChangeHandler}/>
            </Field>
        })}
    </FieldsContainer>
}

export default OutcomeOptionFields;