import React from "react";
import ExternalLink from "./Links";
import {styled} from "@mui/system";
import {Box} from "@mui/material";

const RNBox = styled(Box)`
    a {
      color: #fff;
    }
`
const ReleaseNotesLink = () => {

    const url = "https://docs.google.com/document/d/1CKwaUb9x3-4AB96UuA9TmfZm0DlejgBiId2uInELRGk/edit?usp=sharing";

    return <RNBox>
        <ExternalLink href={url}>Release Notes</ExternalLink>
    </RNBox>;
}

export default ReleaseNotesLink;