import React, {useEffect, useState} from "react";
import {DisplayFacilityHolidays, Facility, FacilityHolidays, FacilityHour} from "../interfaces/Facility";
import {getFacilityHolidays, getFacilityHours, saveFacilityHolidays, saveFacilityHours} from "./facility-services";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, Typography} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";

type HoursProps = {
    facility: Facility;
    setControlFinished: () => void;
}

// having a custom type makes it easier to deal with on the front-end since open/close times
// need to be Date objects for TimePicker to make use of them
type DisplayFacilityHour = {
    id?: number;
    dayOfWeek: string;
    open: Date;
    close: Date;
    isOpen: boolean;
}

const DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const FacilityHoursEdit = ({facility, setControlFinished}: HoursProps) => {

    const [hours, setHours] = useState<FacilityHour[]>([]);
    const [displayHours, setDisplayHours] = useState<DisplayFacilityHour[]>([]);
    const [holidays, setHolidays] = useState<FacilityHolidays[]>([]);
    const [displayHolidays, setDisplayHolidays] = useState<DisplayFacilityHolidays[]>([]);

    useEffect(() => {
        getFacilityHours(facility).then(setHours);
        getFacilityHolidays(facility)
        .then(listOfHolidays => {
            const defaultTime = defaultTiming();
            const filledDaysOfYear = new Array<DisplayFacilityHolidays>(listOfHolidays.length);
            for (let i = 0; i < listOfHolidays.length; i++) {
                filledDaysOfYear[i] = {
                    id: listOfHolidays[i].id,
                    dayOfYear: listOfHolidays[i].dayOfYear,
                    open: timeToDateString(listOfHolidays[i].open),
                    close: listOfHolidays ? timeToDateString(listOfHolidays[i].close) : defaultTime,
                    isOpen: listOfHolidays ? listOfHolidays[i].isOpen : true
                };
            }
            setDisplayHolidays(filledDaysOfYear);
            setHolidays(listOfHolidays);
        }).catch(err => {
            console.log(err)
            // put up an error on the top of the screen
        });
    }, [facility]);

    // once hours are loaded then they need to be converted to the TimePicker friendlier
    // DisplayFacilityHour type and also fill in defaults if the hours haven't been
    // set on a facility
    useEffect(() => {
        const defaultTime = defaultTiming();
        const filledDaysOfWeek = new Array<DisplayFacilityHour>(7);
        for (let i = 0; i < 7; i++) {
            //Server side Java-DaysOfWeek starts at 1
            const found = hours.find(hour => hour.dayOfWeek === DAYS[i])
            filledDaysOfWeek[i] = {
                id: i,
                dayOfWeek: DAYS[i],
                open: found ? timeToDateString(found.open) : defaultTime,
                close: found ? timeToDateString(found.close) : defaultTime,
                isOpen: found ? found.isOpen : true
            };
        }
        setDisplayHours(filledDaysOfWeek);
    }, [hours]);

    // given a time string such as 00:00:00 convert it to a valid JavaScript Date since the
    // time picker uses Date
    function timeToDateString(time: string): Date {
        const date = new Date(Date.now());
        date.setHours(Number(time.substring(0, 2)));
        date.setMinutes(Number(time.substring(3, 5)));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    // given the output of the time picker it needs to get converted back before it goes
    // up to the server during an edit.
    function dateToTimeString(dt: Date): string {
        return `${pad(dt.getHours())}:${pad(dt.getMinutes())}:${pad(dt.getSeconds())}`
    }

    // convenience function when converting the Date back to a time string
    function pad(num: number, numZeros: number = 2): string {
        return ('0000' + num).slice(-numZeros);
    }

    const setOpen = (dow: number, openTime: Date) => {
        displayHours[dow].open = openTime;
        setDisplayHours(Array.from(displayHours));
    }

    const setClose = (dow: number, closeTime: Date) => {
        displayHours[dow].close = closeTime;
        setDisplayHours(Array.from(displayHours));
    }

    function setClosedOrOpen(dow: number, isOpen: boolean) {
        displayHours[dow].isOpen = isOpen;
        setDisplayHours(Array.from(displayHours));
    }

    const addNewHoliday = () => {
        const defaultTime = defaultTiming();
        const filledDaysOfHoliday = {
            id: displayHolidays.length,
            dayOfYear: "",
            open: defaultTime,
            close: defaultTime,
            isOpen: true
        };
        setDisplayHolidays(holiday => [...holiday, filledDaysOfHoliday]);
    }

    const defaultTiming = () => {
        const defaultTime = new Date(Date.now());
        defaultTime.setHours(0);
        defaultTime.setMinutes(0);
        defaultTime.setSeconds(0);
        return defaultTime;
    }
 
    const handleRemoveField = (id: number | undefined) => {
        const updatedFields = displayHolidays.filter((field) => field.id !== id);
        const updatedHolidayFields = holidays.filter((field) => field.id !== id);
        setDisplayHolidays(updatedFields);
        setHolidays(updatedHolidayFields);
    };

    const setOpenHoliday = (dow: number, openTime: Date) => {
        displayHolidays[dow].open = openTime;
        setDisplayHolidays(Array.from(displayHolidays));
    }

    const setCloseHoliday = (dow: number, closeTime: Date) => {
        displayHolidays[dow].close = closeTime;
        setDisplayHolidays(Array.from(displayHolidays));
    }

    function setClosedOrOpenHoliday(dow: number, isOpen: boolean) {
        displayHolidays[dow].isOpen = isOpen;
        setDisplayHolidays(Array.from(displayHolidays));
    }


    const updateFieldChanged = index => e => {
        let newArr = [...displayHolidays]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].dayOfYear = e.target.value; // replace e.target.value with whatever you want to change it to
        setDisplayHolidays(newArr);
      }

    const saveChanges = () => {
        // Extract the time component from the Date objects so that the server can process them properly
        for (let idx = 0; idx < 7; idx++) {
            if(!hours[idx]) {
                hours[idx] = {} as FacilityHour;
                hours[idx].dayOfWeek = DAYS[idx]
            }
            hours[idx].open = dateToTimeString(displayHours[idx].open);
            hours[idx].close = dateToTimeString(displayHours[idx].close);
            hours[idx].isOpen = displayHours[idx].isOpen;
        }

        for (let idx = 0; idx < displayHolidays.length; idx++) {
            if(!holidays[idx]) {
                holidays[idx] = {} as FacilityHolidays;
            }
            holidays[idx].dayOfYear = displayHolidays[idx].dayOfYear
            holidays[idx].open = dateToTimeString(displayHolidays[idx].open);
            holidays[idx].close = dateToTimeString(displayHolidays[idx].close);
            holidays[idx].isOpen = displayHolidays[idx].isOpen;
        }
        saveFacilityHours(facility, hours).then((results) => {
            setHours(results);
            setControlFinished();
        });
        saveFacilityHolidays(facility,holidays).then((results) => {
            setHours(results);
            setControlFinished();
        });
    }

    return <div className={"facility-hours-container"}>

        {displayHours.map((hour, idx) => {
            return <div key={hour.id} className={"edit-hours"}>
                {/* need to map these hours into something presentable */}
                <div className={"day-of-week"}>
                    {DAYS[idx]}
                </div>
                <div className={"open-picker"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            disabled={!hour.isOpen}
                            ampm={false}
                            inputFormat="HH:mm:ss"
                            label="Open"
                            value={hour.open}
                            views={['hours', 'minutes']}
                            onChange={(dt) => {
                                if (dt && dt instanceof Date) setOpen(idx, dt)
                            }}
                            renderInput={(params) => <TextField {...params} size={"small"}/>}
                        />
                    </LocalizationProvider>
                </div>
                <div className={"close-picker"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            disabled={!hour.isOpen}
                            ampm={false}
                            inputFormat="HH:mm:ss"
                            label="Close"
                            value={hour.close}
                            views={['hours', 'minutes']}
                            onChange={(dt) => {
                                if (dt && dt instanceof Date) setClose(idx, dt);
                            }}
                            renderInput={(params) => <TextField {...params} size={"small"}/>}
                        />
                    </LocalizationProvider>
                </div>
                {
                    <div className={"closed"}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={!hour.isOpen}
                                                                 onChange={(evt) => setClosedOrOpen(idx, !evt.target.checked)}/>}
                                              label="Closed"/>
                        </FormGroup>
                    </div>
                }
            </div>
        })}

        <div className={"form-row"}>
            <FormControl sx={{marginTop:2}}>
                <div className={"margin-left"}>
                    <Typography variant="h6" sx={{marginLeft:2}}>Holiday Hours</Typography>
                        {displayHolidays.map((field,index) => (
                            <div key={index} className={"edit-hours"}>
                                <div className={"day-of-week"}>
                                    <TextField 
                                        error={field.dayOfYear === ""}
                                        required
                                        id="standard-basic" 
                                        value={field.dayOfYear} 
                                        label="Holiday Name" 
                                        size={"small"}
                                        variant="outlined"
                                        onChange={updateFieldChanged(index)} />
                                </div>
                                <div className={"open-picker"}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            disabled={!field.isOpen}
                                            ampm={false}
                                            inputFormat="HH:mm:ss"
                                            label="Open"
                                            value={field.open}
                                            views={['hours', 'minutes']}
                                            onChange={(dt) => {
                                                if (dt && dt instanceof Date) setOpenHoliday(index, dt)
                                            }}
                                            renderInput={(params) => <TextField {...params} size={"small"}/>}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className={"close-picker"}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            disabled={!field.isOpen}
                                            ampm={false}
                                            inputFormat="HH:mm:ss"
                                            label="Close"
                                            value={field.close}
                                            views={['hours', 'minutes']}
                                            onChange={(dt) => {
                                                if (dt && dt instanceof Date) setCloseHoliday(index, dt);
                                            }}
                                            renderInput={(params) => <TextField {...params} size={"small"}/>}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {
                                    <div className={"closed"}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={!field.isOpen}
                                                                                onChange={(evt) => setClosedOrOpenHoliday(index, !evt.target.checked)}/>}
                                                            label="Closed"/>
                                        </FormGroup>
                                    </div>
                                }
                                <div>
                                    <Button
                                        className={"select-client"}
                                        color="secondary"
                                        startIcon={<Delete className={"icon-size"} />}
                                        onClick={(e) => handleRemoveField(field.id)}
                                    ></Button>
                                </div>
                            </div>
                        ))
                            }
                    <div>
                        <Button
                            sx={{marginLeft:2}}
                            className={"select-client"}
                            color="secondary"
                            startIcon={<AddCircle className={"icon-size"} />}
                            onClick={(e) => addNewHoliday()}
                        >Add Holiday</Button>
                    </div>
                </div>
            </FormControl>
        </div>


        <div className={"action-bar"}>
            <Button color={"secondary"}
                    variant={"outlined"}
                    onClick={setControlFinished}>Cancel</Button>

            <Button color={"primary"}
                    variant={"outlined"}
                    onClick={saveChanges}>Save Changes</Button>
        </div>
    </div>;

}

export default FacilityHoursEdit;
