import React from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import ControlWrapper from "./ControlWrapper";
import {TaskField} from "../workflow";
import {FieldValueChanged} from "../Outcome";

type FieldProps = {
    field: TaskField,
    onChange: FieldValueChanged,
    value: string
};

const WorkflowChoice = ({field, onChange, value}: FieldProps) => {

    const handleChange = (event: SelectChangeEvent) => {
        onChange(field.name, event.target.value as string);
    };

    if (field.options) {
        return <ControlWrapper>
            <Box sx={{minWidth: 120}}>
                <FormControl fullWidth>
                    <InputLabel id={field.label}>{field.label}</InputLabel>
                    <Select
                        labelId={field.label}
                        id={"field-" + field.label}
                        value={value}
                        label={field.label}
                        onChange={handleChange}>
                        {field.options.options.map(option => <MenuItem key={option}
                                                                       value={option}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </ControlWrapper>;
    }

    return <div>No options provided for choice field {field.name}</div>
}

export default WorkflowChoice;