import React from "react";
import classes from './events.module.css';
import {LineItemCreatedDetails, ShipwellEventProps} from "./types";

const ShipmentLineItemCreated = ({event, title}: ShipwellEventProps) => {

    const details = JSON.parse(event.details)["1"] as LineItemCreatedDetails;

    return <div>
        <div className={classes.eventTitle}>{title}</div>
        <div>{details.description} ({details.total_packages} * {details.package_weight})</div>
    </div>
}

export default ShipmentLineItemCreated;