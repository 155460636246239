import React from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab} from "@mui/material";
import {TaskWithOptions} from "./workflow";
import PastTasks from "./PastTasks";
import WorkflowContextEntryList from "./WorkflowContextEntryList";
import WorkflowContext from "./WorkflowContext";

type HistoryProps = {
    task: TaskWithOptions;
}

const WorkflowHistory = ({task}: HistoryProps) => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return <TabContext value={value}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Past" value="1"/>
                <Tab label="Audit" value="2"/>
                <Tab label="Current" value="3"/>
            </TabList>
        </Box>
        <TabPanel value="1">
            <PastTasks task={task.task}/>
        </TabPanel>
        <TabPanel value="2">
            <WorkflowContextEntryList load={task.task.load}/>
        </TabPanel>
        <TabPanel value="3">
            <WorkflowContext load={task.task.load}/>
        </TabPanel>
    </TabContext>
}

export default WorkflowHistory;