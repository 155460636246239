import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import Moment from "react-moment";
import React, {useEffect, useState} from "react";
import {getLaneCities, getLoadDeliveryTime} from "../util/load-utils";
import {Load, LoadWithStats} from "../interfaces/Load";
import {NoContent} from "../common/NoContent";
import {formatCentsRate} from "../util/rate-util";
import DatRate from "../common/DatRate";
import {Carrier} from "../interfaces/Carrier";
import {fetchPastCarrierLoads} from "../service/carriers";
import Loading from "../common/Loading";

type PropTypes = {
    carrier: Carrier;
    showRecentLoads: (boolean) => void;
}

const SEVEN_DAYS_AGO = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

const CarrierLoads = ({carrier, showRecentLoads}: PropTypes) => {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalLoadCount, setTotalLoadCount] = useState(0);
    const [loads, setLoads] = useState<LoadWithStats[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchPastCarrierLoads(carrier.id, {pageNumber: page, pageSize: pageSize})
            .then(pageOfLoads => {
                setLoads(pageOfLoads.content);
                setTotalLoadCount(pageOfLoads.totalElements);
                setLoading(false);

                if(page === 0) {
                    showRecentLoads(pageOfLoads.content
                        .filter(load => getLoadDeliveryTime(load.load).getTime() > SEVEN_DAYS_AGO.getTime())
                        .length > 0);
                }
            })
            .catch(reason => {
                // need to display the error still
                setLoading(false);
            });
    }, [carrier, page, pageSize, showRecentLoads]);

    const LaneLabel = ({load}) => {
        const cities = getLaneCities(load);
        return <a href={`https://torch.shipwell.com/shipments/${load.shipwellId}`} target={"_blank"} rel="noreferrer">
            {cities[0] + " - " + cities[1]}
        </a>;
    }

    const getMarginLabel = (load: Load) => {
        if (load) {
            if (load.margin === "NaN") {
                return <div className={"margin-na"}>No Rev/Costs</div>;
            } else {
                return (
                    <div className={"margin-" + load.marginLevel}>
                        ${(load.lineHaulRate / 100).toFixed(2)} ({parseFloat(load.margin).toFixed(1)}%)
                    </div>
                );
            }
        } else {
            return "No Load";
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    }

    if(loading) {
        return <Loading />
    }

    return loads && loads.length > 0 ? <TableContainer>
        <Table className={"load-list"}>
            <TableHead>
                <TableRow>
                    <TableCell>Lane</TableCell>
                    <TableCell>Pickup Date</TableCell>
                    <TableCell>Rate (Margin)</TableCell>
                    <TableCell>Max Buy</TableCell>
                    <TableCell>DAT Rate</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loads.map(l => (
                    <TableRow key={l.load.id}>
                        <TableCell><LaneLabel load={l.load}/></TableCell>
                        <TableCell><Moment format={"MMM D"}>{l.load.stops[0].arrival}</Moment></TableCell>
                        <TableCell className={"margin-" + l.load.marginLevel}>{getMarginLabel(l.load)}</TableCell>
                        <TableCell>{l.load.maxBuy ? formatCentsRate(l.load.maxBuy) : "N/A"}</TableCell>
                        <TableCell>{l.datRate ? <DatRate rate={l.datRate} level={"minimal"}/> : "N/A"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <TablePagination
            count={totalLoadCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{borderBottom: 0}}
        />
    </TableContainer> : <NoContent message={"No Past Loads"} />
}

export default CarrierLoads;