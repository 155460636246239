import { API_TICKET_BASE_URL } from "../constants";
import { torchFetch } from "../util/APIUtils";
type Auths = any;
type Users = any;
type Tickets = any;

// Authentication 
export function getAuthentication(): Promise<Auths> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_TICKET_BASE_URL + `/ticket`);
        torchFetch({url: url,method:'post'}).then(json => {
            resolve(json);
        });
    });    
}

// Create new ticket
export function createNewTicket(ticketData): Promise<Tickets> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_TICKET_BASE_URL + `/ticket/create`);
        torchFetch({url: url,method:'post',body:JSON.stringify(ticketData)}).then(json => {
            resolve(json);
        });
    });
}

// Fetch data from Load id
export function getLoadById(id:string): Promise<Users> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_TICKET_BASE_URL + `/load/${id}`);
        torchFetch({url: url}).then(json => {
            resolve(json);
        });
    });
}

// Fetch all tickets
export function getAllTicket(): Promise<Tickets> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_TICKET_BASE_URL + `/ticket/getAll`);
        torchFetch({url: url,method:'post'}).then(json => {
            resolve(json);
        });
    });
}

// Fetch all users
export function getAllUsers(): Promise<Users> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_TICKET_BASE_URL + `/ticket/getAllUsers`);
        torchFetch({url: url,method:'post'}).then(json => {
            resolve(json);
        });
    });
}