import React from "react";

type Props = {
    value: boolean;
    text?: "bool" | "yesno";
}

const BooleanText = ({value, text = "yesno"}: Props) => {

    if(text === "yesno") {
        return <>{value ? "Yes": "No"}</>
    }

    return <>{value ? "True": "False"}</>
}

export default BooleanText;