import {Button, Card, CardContent, Fab, Rating, Typography} from "@mui/material";
import { Stack } from "@mui/system";
import { Person2Outlined } from "@mui/icons-material";
import { AllRatings } from "../interfaces/Facility";
import AddIcon from "@mui/icons-material/Add";

type Ratings = {
    allRatings: AllRatings[];
    ratingTitle: string;
    isEditable: boolean;
    editUserRatings: (number) => void;
    addUserRatings: () => void;
}

const FacilityAllRatings = ({allRatings, ratingTitle, isEditable, editUserRatings,addUserRatings}: Ratings) => {

    return <div className={"facility-all-ratings"}> 
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5">{ratingTitle}</Typography>
            </CardContent>
                {allRatings.map((rating: AllRatings, index) => {
                    return <CardContent key={rating.id}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Person2Outlined  className="icon-size" /> 
                        <Typography 
                            variant="h6"
                        > 
                        {rating.userEmailId}</Typography>
                    </Stack>
                        {rating.ratingCount !== 0 ? 
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Rating name="no-value" 
                                value={rating.ratingCount}
                                readOnly
                                size="small"
                                precision={0.5} 
                                />
                                <Typography variant="subtitle1" gutterBottom>
                                    {/* {rating.ratingFeedback},  */}
                                    {rating.ratingFeedback.toString()}
                                    {/* Limited Dock Doors, Very Slow Turnaround Time */}
                                </Typography>
                            </Stack>
                        : ''}
                        {rating.waitTime !== '' ?
                        <Typography variant="subtitle2" gutterBottom>
                                Avg Wait Time: {rating.waitTime}  &nbsp; Load ID: {rating.loadId}
                            </Typography> : ''}
                        <Typography variant="body2" gutterBottom>
                            Reviewed on {rating.updatedDt && new Date(rating.updatedDt).toLocaleDateString("en-US", {
                                day: "numeric",month: "long",year: "numeric"})}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            {rating.additionalNotes}
                        </Typography>
                        {isEditable ? 
                        <div className={"action-bar"}>
                                <Button variant={"outlined"}
                                        onClick={() => editUserRatings(rating.id)}
                                >Edit</Button>
                        </div>
                        : '' }
                    </CardContent>
                })} 
            {/* : ''} */}
            
            
        </Card>
        {isEditable ? 
            <div className={"action-bar"}>
                <Fab 
                    // className={"action-bar"}
                    size={"small"}
                    aria-label={"Add"}
                    color={"primary"}
                    onClick={addUserRatings}
                >
                    <AddIcon/>
                </Fab>
            </div>
        : '' }
    </div>
}

export default FacilityAllRatings;