import React from "react";
import {styled} from '@mui/material/styles';

import {formatRate, getMargin} from "../util/rate-util";
import {LinearProgress} from "@mui/material";
import {formatCents} from "../util/load-utils";
import "./Tender.scss"
import RateChartBundle from "../rates/RateChartBundle";
import {RateWithHistorical} from "../interfaces/RateData";
import "./TenderLaneRateInfo.scss";

const PREFIX = 'TenderLaneRateInfo';

const classes = {
    root: `${PREFIX}-root`,
    bar: `${PREFIX}-bar`
};

const Root = styled('div')(() => ({
    [`& .${classes.root}`]: {
        height: 10,
        borderRadius: 5,
    },

    [`& .${classes.bar}`]: {
        borderRadius: 5
    }
}));

const TenderLaneRateInfo = ({rate, cost, miles}: {rate: RateWithHistorical, cost: number, miles: number}) => {
    const moneyClass = () => {
        const margin = getMargin(rate, cost);
        switch(true) {
            case margin >= 0.10: return "amount rate-green";
            case margin >= 0.05: return "amount rate-yellow";
            default: return "amount rate-red";
        }
    }

    if (rate) {

        const price = rate.rate.rate + rate.rate.fuelSurcharge;
        const priceHigh = rate.rate.rateHigh + rate.rate.fuelSurcharge;
        const priceLow = rate.rate.rateLow + rate.rate.fuelSurcharge;
        const priceRatio = (price / priceHigh) * 100;

        return (
            <Root className={"tender-rate-info"}>
                <div className={"current-rate-container"}>
                    <div className={"label"}>Rate</div>
                    <div className={"current-rate"}>
                        <div className={"recommended-rate"}>{formatRate(price)}</div>
                        <div className={"rate-bar"}>
                            <LinearProgress variant="determinate" value={priceRatio} />
                        </div>
                        <div className={"rate-high"}>{formatRate(priceHigh)}</div>
                        <div className={"rate-low"}>{formatRate(priceLow)}</div>
                    </div>
                </div>
                <div className={"money-on-load"}>
                    <div className={"label"}>On Load</div>
                    <div className={moneyClass()}>{formatCents(cost)}</div>
                    {/*<div>{formatCents(cost / miles)}/mile</div>*/}
                </div>

                <div className={"tender-rate-historical-container"}>
                    <div className={"label"}>Historical Rate for Lane</div>
                    <RateChartBundle current={rate.rate}
                                     historical={rate.historical}
                                     height={130}
                                     loading={false}
                                     showDetails={false}
                                     showTitles={false}
                                     showCurrent={false}
                                     showAmount={false}/>
                </div>
            </Root>
        );
    }

    return null;
}

export default TenderLaneRateInfo;
