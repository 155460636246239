import React, { useState, useEffect } from "react";
import {
  Avatar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  Grid,
  Menu,
  Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreHorizIcon from "../assets/svg/ThreeDotsIcon";
import AddIcon from "../assets/svg/PlusIconWithBorder";
import CalendarIcon from "../assets/svg/CalendarIcon";
import SearchIcon from "../assets/svg/SearchIcon";
import ChatIcon from "@mui/icons-material/Chat";
import { TicketMainContainer } from "../tickets/TicketStyled";
import Textarea from "@mui/joy/Textarea";
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  // Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../tickets/Ticket.scss";
import { blue } from "@mui/material/colors";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import SortMenu from "../tickets/SortMenu";
import TicketCancel from "./TicketCancel";
import {
  getAuthentication,
  getLoadById,
  getAllTicket,
  createNewTicket,
  getAllUsers,
} from "./ticket-service";
import { useFormik } from "formik";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Format Date to save ticket
const formatDateSave = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Format Time to save ticket
const formatTimeSave = (inputTime) => {
  const date = new Date(inputTime);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}.${minutes}`;
};

// Format Date to show ticket
const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

// Format Time to show ticket
const formatTime = (decimalTime) => {
  const dateObj = new Date(decimalTime);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

const TicketAll = () => {
  // GET TORCH LOAD DETAIL FROM LOAD ID
  const getLoadId = (event) => {
    formik.handleChange(event);
    getLoadById(event.target.value)
      .then((response) => {
        formik.setFieldValue("carrier_mc", response.carrier.mcNumber);
        formik.setFieldValue("torch_customer", response.shipper.name);
      })
      .catch((error) => {
        console.log("ERROR", error.message);
      });
  };

  // GET AUTHENTICATE
  const getAuthenticate = () => {
    getAuthentication()
      .then((response) => {
        console.log("RESPONSE_AUTHENTICATE", response);
      })
      .catch((error) => {
        console.log("ERROR", error.message);
      });
  };

  // GET ALL TICKET
  const fetchAllTicket = () => {
    getAllTicket()
      .then((response) => {
        
        const sortedData = response.result.sort((a, b) => b.id - a.id); console.log("ERROR", sortedData);
        setTicketData(sortedData);
      })
      .catch((error) => {
        console.log("ERROR", error.message);
      });
  };

  // GET ALL USERS
  const fetchAllUsers = () => {
    getAllUsers()
      .then((response) => {
        setTicketUsers(response.result);
      })
      .catch((error) => {
        console.log("ERROR", error.message);
      });
  };

  useEffect(() => {
    getAuthenticate();
    fetchAllTicket();
    fetchAllUsers();
  }, []);

  const [severity, setSeverity] = useState("");
  const [status, setStatus] = useState("");
  const [openNew, setNewOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [cancelTicketPopup, setCancelTicketPopup] = useState(null);
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [ticketUsers, setTicketUsers] = useState([]);

  const currentDate = new Date();
  const handleNewOpen = () => {
    setNewOpen(true);
  };
  const handleNewClose = () => {
    setNewOpen(false);
    formik.resetForm();
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const severityChange = (event) => {
    setSeverity(event.target.value);
  };
  const statusChange = (event) => {
    setStatus(event.target.value);
  };

  const categorys = [
    {
      value: "customer_opr",
      label: "Customer operations",
      key: "Customer operations",
    },
    { value: "load_opr", label: "Load operations", key: "Load operations" },
    {
      value: "carrier_opr",
      label: "Carrier opertaions",
      key: "Carrier opertaions",
    },
  ];

  const requestTypes = [
    { value: "pickup", label: "Pick Up Only", key: "Pick Up Only" },
    { value: "delivery", label: "Delivery Only", key: "Delivery Only" },
    {
      value: "pickup_delivery",
      label: "Pick Up and Delivery",
      key: "Pick Up and Delivery",
    },
  ];

  const prioritys = [
    { value: "high", label: "High", key: "High" },
    { value: "low", label: "Low", key: "Low" },
    { value: "medium", label: "Medium", key: "Medium" },
    { value: "critical", label: "Critical", key: "Critical" },
  ];

  const workflows = [
    {
      key: "Appointment Reschedule",
      value: "Appointment Reschedule",
      label: "Appointment Reschedule",
    },
    {
      key: "High Margin Loads",
      value: "High Margin Loads",
      label: "High Margin Loads",
    },
    {
      key: "Low margin override",
      value: "Low margin override",
      label: "Low margin override",
    },
    {
      key: "Load Status Changes and Tracking",
      value: "Load Status Changes and Tracking",
      label: "Load Status Changes and Tracking",
    },
    { key: "Load closures", value: "Load closures", label: "Load closures" },
    {
      key: "Tender Acceptance / Rejection",
      value: "Tender Acceptance / Rejection",
      label: "Tender Acceptance / Rejection",
    },
    {
      key: "Carrier Compliance",
      value: "Carrier Compliance",
      label: "Carrier Compliance",
    },
    { key: "Carrier Review", value: "Carrier Review", label: "Carrier Review" },
    {
      key: "Account Billing",
      value: "Account Billing",
      label: "Account Billing",
    },
    {
      key: "Accounts Receivable",
      value: "Accounts Receivable",
      label: "Accounts Receivable",
    },
  ];

  const handleDetailOpen = () => {
    setOpenDetail(true);
  };
  const handleDetailClose = () => {
    setOpenDetail(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => setCancelTicketPopup(event.currentTarget);
  const handleClose = () => setCancelTicketPopup(null);
  const open = Boolean(cancelTicketPopup);
  const popOver = open ? "simple-popover" : undefined;
  const handleChange = (event) => {
    setSelectedRequestType(event.target.value);
    formik.setFieldValue("request_type", event.target.value);
  };

  const handleStartDateChange = (date) => {
    const currentDate = new Date();

    if (date < currentDate) {
      setStartDate(currentDate);
    } else {
      setStartDate(date);
    }
    validateDates(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    validateDates(startDate, date);
  };

  const validateDates = (start, end) => {
    if (!start && !end) {
      setError("Both start and end dates are required");
    } else if (!start) {
      setError("Start date is required");
    } else if (!end) {
      setError("End date is required");
    } else if (start > end) {
      setError("End date must be greater than start date");
    } else {
      setError("");
    }
  };

  const formik = useFormik({
    initialValues: {
      category: "",
      workflow: "",
      torch_load_id: "",
      torch_customer: "Torch Customer",
      carrier_mc: "Carrier MC",
      request_type: "",
      priority: "",
      assign_to: "",
      comment: "",
      deliveryDate: null,
      deliveryTime: null,
      pickupDate: null,
      pickupTime: null,
      delvDate: null,
      picupDelTime: null,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Category is required"),
      workflow: Yup.string().required("Workflow is required"),
      torch_load_id: Yup.string().required("Torch Load Id is required"),
      priority: Yup.string().required("Priority is required"),
      comment: Yup.string().required("Comment is required"),
      // request_type: Yup.string().required("Request Type is required"),
      // selectedDate: Yup.date().required('Pickup Date is required'),
      // selectedTime: Yup.date().required('Pickup Time is required'),
      // deliveryDate: Yup.date().required('Delivery Date is required'),
      // deliveryTime: Yup.date().required('Delivery Time is required'),
      // pickupDate:  Yup.date().required('Pickup Date is required'),
      // pickupTime:  Yup.date().required('Pickup Time is required'),
      // delvDate: Yup.date().required('Delivery Date is required'),
      // picupDelTime: Yup.date().required('Pickup Time is required')
    }),
    onSubmit: (values) => {
      const newTicketPayload = {
        params: {
          category: values.category,
          ticket_operation: values.workflow,
          load_id: values.torch_load_id,
          carrier_mc: values.carrier_mc,
          request_type: values.request_type,
          priority: values.priority,
          comment: values.comment,
          requested_date_pickup: formatDateSave(values.pickupDate),
          requested_time_pickup: formatTimeSave(values.pickupTime),
          requested_date_delivery: formatDateSave(values.deliveryDate),
          requested_time_delivery: formatTimeSave(values.deliveryTime),
        },
      };
      createNewTicket(newTicketPayload).then((response) => {
        fetchAllTicket();
        handleNewClose();
        formik.resetForm();
      });
    },
  });

  const requestTypeSelected = () => {
    switch (selectedRequestType) {
      case "pickup":
        return (
          <div className="parent_row mt10">
            <div className="child">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="pickupDate"
                  name="pickupDate"
                  label="Pick Up Date"
                  inputVariant="outlined"
                  className="min250"
                  value={formik.values.pickupDate}
                  minDate={currentDate}
                  onChange={(date) => {
                    formik.setFieldValue("pickupDate", date);
                  }}
                  error={
                    formik.touched.pickupDate &&
                    Boolean(formik.errors.pickupDate)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {formik.touched.pickupDate && formik.errors.pickupDate && (
                <div className="error">{formik.errors.pickupDate}</div>
              )}
            </div>
            <div className="child mrDate">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  id="pickupTime"
                  name="pickupTime"
                  label="Pick Time"
                  inputVariant="outlined"
                  className="min250"
                  clearable
                  ampm={false}
                  value={formik.values.pickupTime}
                  onChange={(time) => {
                    formik.setFieldValue("pickupTime", time);
                  }}
                  error={
                    formik.touched.pickupTime &&
                    Boolean(formik.errors.pickupTime)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {formik.touched.pickupTime && formik.errors.pickupTime && (
                <div className="error">{formik.errors.pickupTime}</div>
              )}
            </div>
          </div>
        );
      case "delivery":
        return (
          <div className="parent_row mt10">
            <div className="child">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="deliveryDate"
                  name="deliveryDate"
                  label="Delivery Date"
                  inputVariant="outlined"
                  className="min250"
                  value={formik.values.deliveryDate}
                  minDate={currentDate}
                  onChange={(dateDel) => {
                    formik.setFieldValue("deliveryDate", dateDel);
                  }}
                  error={
                    formik.touched.deliveryDate &&
                    Boolean(formik.errors.deliveryDate)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {formik.touched.deliveryDate && formik.errors.deliveryDate && (
                <div className="error">{formik.errors.deliveryDate}</div>
              )}
            </div>
            <div className="child mrDate">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  id="deliveryTime"
                  name="deliveryTime"
                  label="Delivery Time"
                  inputVariant="outlined"
                  className="min250"
                  clearable
                  ampm={false}
                  value={formik.values.deliveryTime}
                  onChange={(time) => {
                    formik.setFieldValue("deliveryTime", time);
                  }}
                  error={
                    formik.touched.deliveryTime &&
                    Boolean(formik.errors.deliveryTime)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {formik.touched.deliveryTime && formik.errors.deliveryTime && (
                <div className="error">{formik.errors.deliveryTime}</div>
              )}
            </div>
          </div>
        );
      case "pickup_delivery":
        return (
          <div>
            <div className="parent_row mt10">
              <div className="child">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    id="pickupDate"
                    name="pickupDate"
                    label="Pick Up Date"
                    inputVariant="outlined"
                    className="min250"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={startDate}
                  />
                </LocalizationProvider>
              </div>
              <div className="child mrDate">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    id="pickupTime"
                    name="pickupTime"
                    label="Pick Up Time"
                    inputVariant="outlined"
                    className="min250"
                    clearable
                    ampm={false}
                    value={formik.values.pickupTime}
                    onChange={(time) => {
                      formik.setFieldValue("pickupTime", time);
                    }}
                    error={
                      formik.touched.pickupTime &&
                      Boolean(formik.errors.pickupTime)
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.pickupTime && formik.errors.pickupTime && (
                  <div className="error">{formik.errors.pickupTime}</div>
                )}
              </div>
            </div>
            <div className="parent_row mt15">
              <div className="child">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    id="delvDate"
                    name="delvDate"
                    label="Delivery Date"
                    inputVariant="outlined"
                    className="min250"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={startDate}
                  />
                </LocalizationProvider>
                {error && <div className="error">{error}</div>}
              </div>
              <div className="child mrDate">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    id="picupDelTime"
                    name="picupDelTime"
                    label="Delivery Time"
                    inputVariant="outlined"
                    className="min250"
                    clearable
                    ampm={false}
                    value={formik.values.picupDelTime}
                    onChange={(picDelTime) =>
                      formik.setFieldValue("picupDelTime", picDelTime)
                    }
                    error={
                      formik.touched.picupDelTime &&
                      Boolean(formik.errors.picupDelTime)
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.picupDelTime && formik.errors.picupDelTime && (
                  <div className="error">{formik.errors.picupDelTime}</div>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <TicketMainContainer>
      <div className="ticket_board_header">
        <h1>Tickets Board</h1>
        <div className="search_section">
          <div className="search_input">
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              size="small"
            />
            <SearchIcon className="search_icon" />
          </div>
          <FormControl>
            <InputLabel id="severity-simple-select-label">Severity</InputLabel>
            <Select
              labelId="severity-simple-select-label"
              id="severity-simple-select"
              value={severity}
              label="Severity"
              size="small"
              sx={{ minWidth: 134, maxWidth: 134 }}
              onChange={severityChange}
            >
              <MenuItem sx={{ fontSize: 12 }} value="High">
                High
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Low">
                Low
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Medium">
                Medium
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Critical">
                Critical
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="status-simple-select-label">Status</InputLabel>
            <Select
              labelId="status-simple-select-label"
              id="status-simple-select"
              value={status}
              label="Status"
              size="small"
              sx={{ minWidth: 150 }}
              onChange={statusChange}
            >
              <MenuItem sx={{ fontSize: 12 }} value="New / Unassigned">
                New / Unassigned
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Accepted">
                Accepted
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Received">
                Received
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Issue">
                Issue
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Completed">
                Completed
              </MenuItem>
              <MenuItem sx={{ fontSize: 12 }} value="Declined">
                Declined
              </MenuItem>
            </Select>
          </FormControl>
          <IconButton aria-label="sort">
            <FilterListIcon
              variant="contained"
              onClick={handleOpenMenu}
              aria-controls="simple-menu"
              aria-haspopup="true"
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <SortMenu></SortMenu>
            </Menu>
          </IconButton>
          <Button
            startIcon={<AddCircleOutlineOutlinedIcon />}
            variant="contained"
            onClick={handleNewOpen}
          >
            New Ticket
          </Button>
        </div>
      </div>
      <div className="ticket_container_wrapper">
        <div
          className={openDetail ? "ticket_container open" : " ticket_container"}
        >
          {ticketData.map((card, index) => (
            <div className="card" key={index} onClick={handleDetailOpen}>
              <div className="ticket_status">
                {card.priority === "high" && (
                  <p className="status status_high">
                    <span className="dot dot_high"></span> {card.priority}{" "}
                  </p>
                )}
                {card.priority === "medium" && (
                  <p className="status status_medium">
                    <span className="dot dot_medium"></span> {card.priority}{" "}
                  </p>
                )}
                {card.priority === "low" && (
                  <p className="status status_low">
                    <span className="dot dot_low"></span> {card.priority}{" "}
                  </p>
                )}
                {card.priority === "critical" && (
                  <p className="status status_critical">
                    <span className=" dot dot_critical"></span> {card.priority}{" "}
                  </p>
                )}
                <MoreHorizIcon />
              </div>
              <p className="ticket_heading">{card.ticket_operation}</p>
              <div className="ticket_id">
                <div>
                  {" "}
                  {card.carrier_mc} {card.load_id}
                </div>
                <div className="red">New / Unassigned</div>
                {/* {card.steptaken === "New / Unassigned" && (
                  <div className="red">{card.steptaken}</div>
                )}
                {card.steptaken === "Accepted" && (
                  <div className="purple">{card.steptaken}</div>
                )}
                {card.steptaken === "Declined" && (
                  <div className="yellow">{card.steptaken}</div>
                )} */}
              </div>
              <div className="comments">
                <div className="user_img">
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                  />
                  <AddIcon />
                </div>
                <div className="chat_count">
                  <ChatIcon />
                  <span>4</span>
                </div>
              </div>
              <div className="date_time">
                <CalendarIcon />
                <span>
                  {formatDate(card.create_date)} |{" "}
                  {formatTime(card.create_date)}
                </span>
              </div>
            </div>
          ))}
        </div>
        {/* Start Ticket detail */}
        <div className={openDetail ? "ticket_detail open" : "ticket_detail"}>
          <Grid xs={6}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography variant="h6" sx={{ ml: 5, mt: 2 }}>
                  High Margin Loads
                </Typography>
              </Grid>
              <Grid item xs={2} onClick={handleDetailClose}>
                <IconButton aria-label="Close">
                  <CloseIcon sx={{ fontSize: "18px", mt: 1 }} />
                </IconButton>
              </Grid>
            </Grid>
            <div className="created_date">
              <span>Created:</span> 08/18/2023 15:02
            </div>
            <Grid container spacing={8}>
              <Grid item>
                <Typography variant="body1" className="assign_by">
                  Assigned By:
                </Typography>
              </Grid>
              <Grid item className="icon_assignby">
                <Avatar
                  sx={{ width: 32, height: 32, borderRadius: "50%" }}
                  alt="Sandra Rose"
                />{" "}
              </Grid>
              <Grid item className="icon_assignby">
                <Typography variant="body1" className="name_rose">
                  Sandra Rose
                </Typography>
              </Grid>
              <Grid item className="text-right">
                <IconButton
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid #ccc",
                    padding: "8px",
                  }}
                  aria-label="Close"
                  onClick={handleClick}
                >
                  <CloseIcon sx={{ fontSize: 16 }}  />
                </IconButton>
              </Grid>
              <Grid item className="icon_assignby" onClick={handleEditOpen}>
                <IconButton
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid #ccc",
                    padding: "10px",
                    ml: 1,
                  }}
                  aria-label="Close"
                >
                  <BorderColorIcon sx={{ fontSize: 13 }} />
                </IconButton>
              </Grid>
              <Grid item className="icon_assignby">
                <Button variant="contained" className="btn_view">
                  View Details
                </Button>
              </Grid>
            </Grid>

            <h2 className="spacer_highmargin">Activity Performed</h2>
            <ol className="c-timeline">
              <li className="c-timeline_item">
                <div className="timeline_number">6</div>
                <div className="c-timeline_content">
                  <Grid
                    container
                    className="box"
                    sx={{ borderRadius: 1, width: 355 }}
                  >
                    <Grid xs={11}>
                      <h3 className="c-timeline_title">Ticket created</h3>
                      <p className="c-timeline_desc">
                        CSR submits ticket for time change.
                      </p>
                      <div className="completed">Completed</div>
                    </Grid>
                    <Grid xs={1}>
                      <div className="user_img">
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 28,
                            height: 28,
                            fontSize: "0.8rem",
                          }}
                        >
                          HP
                        </Avatar>
                      </div>
                      <div className="chat_step">
                        <ChatIcon />
                        <span></span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <time>
                  08/18/2023 <br /> <span>15:02</span>{" "}
                </time>
              </li>
              <li className="c-timeline_item">
                <div className="timeline_number">5</div>
                <div className="c-timeline_content">
                  <Grid
                    container
                    className="box"
                    sx={{ borderRadius: 1, width: 355 }}
                  >
                    <Grid xs={11}>
                      <h3 className="c-timeline_title">Notification Request</h3>
                      <p className="c-timeline_desc">
                        CSR submits ticket for time change.
                      </p>
                      <div className="completed">Completed</div>
                    </Grid>
                    <Grid xs={1}>
                      <div className="user_img">
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 28,
                            height: 28,
                            fontSize: "0.8rem",
                          }}
                        >
                          HP
                        </Avatar>
                      </div>
                      <div className="chat_step">
                        <ChatIcon />
                        <span></span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <time>
                  08/18/2023 <br /> <span>15:02</span>{" "}
                </time>
              </li>
              <li className="c-timeline_item">
                <div className="timeline_number">4</div>
                <div className="c-timeline_content">
                  <Grid
                    container
                    className="box"
                    sx={{ borderRadius: 1, width: 355 }}
                  >
                    <Grid xs={11}>
                      <h3 className="c-timeline_title">Acknowledge</h3>
                      <p className="c-timeline_desc">
                        CSR submits ticket for time change.
                      </p>
                      <div className="completed">Completed</div>
                    </Grid>
                    <Grid xs={1}>
                      <div className="user_img">
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 28,
                            height: 28,
                            fontSize: "0.8rem",
                          }}
                        >
                          HP
                        </Avatar>
                      </div>
                      <div className="chat_step">
                        <ChatIcon />
                        <span></span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <time>
                  08/18/2023 <br /> <span>15:02</span>{" "}
                </time>
              </li>
              <li className="c-timeline_item">
                <div className="timeline_number">3</div>
                <div className="c-timeline_content">
                  <Grid
                    container
                    className="box"
                    sx={{ borderRadius: 1, width: 355 }}
                  >
                    <Grid xs={11}>
                      <h3 className="c-timeline_title">Ticket created</h3>
                      <p className="c-timeline_desc">
                        CSR submits ticket for time change.
                      </p>
                      <div className="completed">Completed</div>
                    </Grid>
                    <Grid xs={1}>
                      <div className="user_img">
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 28,
                            height: 28,
                            fontSize: "0.8rem",
                          }}
                        >
                          HP
                        </Avatar>
                      </div>
                      <div className="chat_step">
                        <ChatIcon />
                        <span></span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <time>
                  08/18/2023 <br /> <span>15:02</span>{" "}
                </time>
              </li>
              <li className="c-timeline_item">
                <div className="timeline_number">2</div>
                <div className="c-timeline_content">
                  <Grid
                    container
                    className="box"
                    sx={{ borderRadius: 1, width: 355 }}
                  >
                    <Grid xs={11}>
                      <h3 className="c-timeline_title">Ticket created</h3>
                      <p className="c-timeline_desc">
                        CSR submits ticket for time change.
                      </p>
                      <div className="completed">Completed</div>
                    </Grid>
                    <Grid xs={1}>
                      <div className="user_img">
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 28,
                            height: 28,
                            fontSize: "0.8rem",
                          }}
                        >
                          HP
                        </Avatar>
                      </div>
                      <div className="chat_step">
                        <ChatIcon />
                        <span></span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <time>
                  08/18/2023 <br /> <span>15:02</span>{" "}
                </time>
              </li>
              <li className="c-timeline_item">
                <div className="timeline_number">1</div>
                <div className="c-timeline_content">
                  <Grid
                    container
                    className="box"
                    sx={{ borderRadius: 1, width: 355 }}
                  >
                    <Grid xs={11}>
                      <h3 className="c-timeline_title">Ticket created</h3>
                      <p className="c-timeline_desc">
                        CSR submits ticket for time change.
                      </p>
                      <div className="completed">Completed</div>
                    </Grid>
                    <Grid xs={1}>
                      <div className="user_img">
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 28,
                            height: 28,
                            fontSize: "0.8rem",
                          }}
                        >
                          HP
                        </Avatar>
                      </div>
                      <div className="chat_step">
                        <ChatIcon />
                        <span></span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <time>
                  08/18/2023 <br /> <span>15:02</span>{" "}
                </time>
              </li>
            </ol>
          </Grid>
        </div>
        {/* End Ticket Detail */}
      </div>

      <BootstrapDialog
        className="addticket-cont"
        onClose={handleNewClose}
        aria-labelledby="customized-dialog-title"
        open={openNew}
      >
        <DialogTitle className="add_ticket" id="customized-dialog-title">
          Create New Ticket
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleNewClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div className="parent_row">
              <div className="child">
                <FormControl
                  className="min250"
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Category
                  </InputLabel>
                  <Select
                    id="category"
                    name="category"
                    label="Select an option"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helpertext={
                      formik.touched.category && formik.errors.category
                    }
                  >
                    {categorys.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.category && formik.errors.category && (
                    <div className="error">{formik.errors.category}</div>
                  )}
                </FormControl>
              </div>
              <div className="child">
                <FormControl
                  className="min250"
                  variant="outlined"
                  sx={{ ml: 1 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Workflow
                  </InputLabel>
                  <Select
                    id="workflow"
                    name="workflow"
                    label="Select an option"
                    value={formik.values.workflow}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.workflow && Boolean(formik.errors.workflow)
                    }
                    helpertext={
                      formik.touched.workflow && formik.errors.workflow
                    }
                  >
                    {workflows.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.workflow && formik.errors.workflow && (
                    <div className="error">{formik.errors.workflow}</div>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="parent_row mt10">
              <div className="child">
                <TextField
                  label="Torch Load ID"
                  variant="outlined"
                  id="torch_load_id"
                  name="torch_load_id"
                  value={formik.values.torch_load_id}
                  sx={{ mr: 1 }}
                  onChange={getLoadId}
                  className="min250"
                  error={
                    formik.touched.torch_load_id &&
                    Boolean(formik.errors.torch_load_id)
                  }
                  helpertext={
                    formik.touched.torch_load_id && formik.errors.torch_load_id
                  }
                />
              </div>
              <div className="child">
                {" "}
                <TextField
                  id="outlined-basic"
                  value={formik.values.carrier_mc}
                  name="carrier_mc"
                  className="min250"
                  variant="outlined"
                  sx={{ ml: 1 }}
                />
              </div>
            </div>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
              <TextField
                id="outlined-basic"
                value={formik.values.torch_customer}
                className="min250"
                variant="outlined"
                name="torch_customer"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Request Type
              </InputLabel>
              <Select
                id="request_type"
                name="request_type"
                label="Select an option"
                value={selectedRequestType}
                onChange={handleChange}
                error={
                  formik.touched.request_type &&
                  Boolean(formik.errors.request_type)
                }
                helpertext={
                  formik.touched.request_type && formik.errors.request_type
                }
              >
                {requestTypes.map((request) => (
                  <MenuItem key={request.key} value={request.value}>
                    {request.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.request_type && formik.errors.request_type && (
                <div className="error">{formik.errors.request_type}</div>
              )}
            </FormControl>
            <div className="parent_row">{requestTypeSelected()}</div>
            <div className="parent_row">
              <div className="child">
                <FormControl
                  className="min250"
                  variant="outlined"
                  sx={{ mr: 1, mt: 2 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Priority
                  </InputLabel>
                  <Select
                    id="priority"
                    name="priority"
                    label="Select an option"
                    value={formik.values.priority}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.priority && Boolean(formik.errors.priority)
                    }
                    helpertext={
                      formik.touched.priority && formik.errors.priority
                    }
                  >
                    {prioritys.map((ele) => (
                      <MenuItem key={ele.key} value={ele.value}>
                        {ele.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.priority && formik.errors.priority && (
                    <div className="error">{formik.errors.priority}</div>
                  )}
                </FormControl>
              </div>
              <div className="child">
                <FormControl
                  className="min250"
                  variant="outlined"
                  sx={{ ml: 1, mt: 2 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Assign To
                  </InputLabel>
                  <Select
                    id="assign_to"
                    name="assign_to"
                    label="Assign To"
                    value={formik.values.assign_to}
                    onChange={formik.handleChange}
                  >
                    {ticketUsers.map((user) => (
                      <MenuItem key={user.name} value={user.name}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="ticket_textarea">
              <Textarea
                minRows={3}
                placeholder="Additional Comment"
                sx={{ mt: 2 }}
                id="comment"
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helpertext={formik.touched.comment && formik.errors.comment}
              />
              {formik.touched.comment && formik.errors.comment && (
                <div className="error">{formik.errors.comment}</div>
              )}
            </div>
            <Box sx={{ mt: 2 }} className="center-btn">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                type="reset"
                variant="contained"
                className="btn-cancel"
                onClick={handleNewClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        className="addticket-cont"
        onClose={handleEditClose}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontSize: 24 }}
          id="customized-dialog-title"
        >
          Edit Ticket
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="parent_row">
            <div className="child">
              <FormControl className="min250" variant="outlined" sx={{ mr: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Category"
                >
                  <MenuItem value="Customer operations">
                    Customer operations
                  </MenuItem>
                  <MenuItem value="Load operations">Load operations</MenuItem>
                  <MenuItem value="Carrier opertaions">
                    Carrier opertaions
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="child">
              <FormControl className="min250" variant="outlined" sx={{ ml: 1 }}>
                <InputLabel id="demo-simple-select-label">Workflow</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Workflow"
                >
                  {workflows.map((card, index) => (
                    <MenuItem value={card.key}>{card.value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="Torch Load ID"
              variant="outlined"
            />
          </FormControl>
          <div className="parent_row">
            <div className="child">
              <TextField
                id="outlined-basic"
                className="min250"
                variant="outlined"
                sx={{ mr: 1 }}
              />
            </div>
            <div className="child">
              <TextField
                id="outlined-basic"
                className="min250"
                variant="outlined"
                sx={{ mr: 1 }}
              />
            </div>
          </div>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Request Type</InputLabel>
            <Select
              label="Request Type"
              value={selectedRequestType}
              onChange={handleChange}
            >
              <MenuItem value="Pick Up Only">Pick Up Only</MenuItem>
              <MenuItem value="Delivery Only">Delivery Only</MenuItem>
              <MenuItem value="Pick Up and Delivery">
                Pick Up and Delivery
              </MenuItem>
            </Select>
          </FormControl>
          <div className="parent_row">{requestTypeSelected()}</div>
          <div className="parent_row">
            <div className="child">
              <FormControl
                className="min250"
                variant="outlined"
                sx={{ mr: 1, mt: 2 }}
              >
                <InputLabel>Select Priority</InputLabel>
                <Select label="Select Priority">
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Critical">Critical</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="child">
              <FormControl
                className="min250"
                variant="outlined"
                sx={{ ml: 1, mt: 2 }}
              >
                <InputLabel>Select Priority</InputLabel>
                <Select label="Assign To">
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="ticket_textarea">
            <Textarea
              minRows={3}
              placeholder="Additional Comment"
              sx={{ mt: 2 }}
            />
          </div>
          <Box sx={{ mt: 1 }} className="center-btn">
            <Button variant="contained">Submit</Button>
            <Button
              variant="contained"
              className="btn-cancel"
              onClick={handleNewClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      <TicketCancel open={open} popOver={popOver} handleClose={handleClose} />
    </TicketMainContainer>
  );
};

export default TicketAll;
