import { useEffect, useState } from "react";
import NoContentPanel from "../common/NoContentPanel";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import {LoadLink, TorchShipmentExternalLink } from "../common/Links";
import Moment from "react-moment";
import Loading from "../common/Loading";
import RocketIcon from '@mui/icons-material/Rocket';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import styled from "@emotion/styled";
import { getFaciliityLaods } from "./facility-services";
import { FacilityPastUpcomingLoads } from "../interfaces/Facility";

type FacilityId = {
    facilityId: number;
}

const RefId = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
  display: flex;
  align-items: center;
  column-gap: 5px;
`
const FacilityPastLoadsContainer = ({facilityId} : FacilityId) => {
    const [loads, setLoads] = useState<FacilityPastUpcomingLoads[]>([]);
    
    useEffect(() => {
        getFaciliityLaods(facilityId, "PAST_LOADS").then(setLoads);
    },[facilityId])

    if (loads && loads.length === 0) {
        return <NoContentPanel>No Past Loads</NoContentPanel>
    }

    if (loads && loads.length > 0) {
        return (
            <div className={"past-loads-carrier"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Carrier</TableCell>
                            <TableCell>Pickup Date</TableCell>
                            <TableCell>Delivery Date</TableCell>
                            <TableCell>Links</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loads.map(l => (
                            <TableRow key={l.shipwellId}>
                                <TableCell className={"carrier-name"}>
                                    {l.carrierName}
                                </TableCell>
                                <TableCell>
                                        <Moment format={"MMM D"}>{new Date(l.pickupDate)}</Moment>
                                </TableCell>
                                <TableCell>
                                        <Moment format={"MMM D"}>{new Date(l.deliveryDate)}</Moment>
                                </TableCell>
                                <TableCell align="left">
                                    <RefId>
                                        <div>
                                            <LoadLink loadId={l.loadId}>
                                                <Tooltip title={"JetPack Load View"}>
                                                    <RocketIcon fontSize={"small"}/>
                                                </Tooltip>
                                            </LoadLink>
                                        </div>
                                        <div>
                                            <TorchShipmentExternalLink shipwellId={l.shipwellId}>
                                                <Tooltip title={"Shipwell Load View"}>
                                                    <DirectionsBoatIcon fontSize={"small"}/>
                                                </Tooltip>
                                            </TorchShipmentExternalLink>
                                        </div>
                                        <div>{l.shipwellRefId}</div>
                                    </RefId>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>);
    }

    if (loads && loads.length === 0) {
        return <NoContentPanel>No Recent Activity</NoContentPanel>;
    }

    return (<div className={"loading"}>
        <Loading />
    </div>)
}

export default FacilityPastLoadsContainer;