import React, {useEffect, useState} from "react";
import {getSkinnyOpenLoads} from "../service/loads";
import {LoadLane, LoadPriority, SkinnyLoad} from "../interfaces/Load";
import LoadCard from "./LoadCard";
import LoadFilter from "./LoadFilter";
import {useHistory} from "react-router-dom";
import {getLoadLabel} from "../util/load-utils";
import "./OpenLoads.scss";
import {LoadSearchFilter} from "./types";
import LaneCard from "./LaneCard";
import {differenceInHours} from "date-fns";
import {useRecoilState} from "recoil";
import {showRouteMapState} from "./openloads.state";
import {Territory} from "../interfaces/Territory";
import {getTerritories, getUserTerritory} from "../service/territories";
import {JETPACK_LOAD_SELECTED, postUsageEvent} from "../service/events";
import Loading from "../common/Loading";
import Typography from "@mui/material/Typography";

type PropTypes = {
    loadSearchFilter?: LoadSearchFilter;
    onLoadSelected?: (load:SkinnyLoad) => void;
}

/**
 * The entry point into open-loads that displays the lanes that represent the user's open loads
 * (for now this is all of them but future wise this probably starts to become more focused)
 *
 * @param loadSearchFilter
 * @param onLoadSelected
 * @constructor
 */
const OpenLoads = ({loadSearchFilter, onLoadSelected}: PropTypes) => {

    const [openLoads, setOpenLoads] = useState<SkinnyLoad[]>([]);
    const [filteredLoads, setFilteredLoads] = useState<SkinnyLoad[]>([]);
    const [territories, setTerritories] = useState<Territory[]>([]);
    const [userTerritory, setUserTerritory] = useState<Territory>();
    const [territoryFilter, setTerritoryFilter] = useState<number[]>([]);
    const [loadFilter, setLoadFilter] = useState("");
    const [dayOfWeekFilter, setDayOfWeekFilter] = useState<string[]>([]);

    const [filteredLanes, setFilteredLanes] = useState<LoadLane[]>([]);

    const [,setShowRouteMap] = useRecoilState(showRouteMapState);

    const history = useHistory();

    useEffect(() => {
        getTerritories().then(territories => {
            setTerritories(territories)
        })
        getUserTerritory().then(territory => {
            if (territory) {
                setUserTerritory(territory);
                setTerritoryFilter([territory.id]);
            }
        })
        getSkinnyOpenLoads().then(loads => {
            setOpenLoads(loads);
        })
    }, []);

    function lanePriority(loads: SkinnyLoad[]): LoadPriority {
        const dates = loads.map(load => new Date(load.stops[0].arrival));
        dates.sort((a, b) => a.getTime() - b.getTime());
        const now = new Date();
        const howLong = differenceInHours(dates[0], now);
        if (howLong < 24) {
            return "high";
        } else if (howLong < 48) {
            return "med";
        } else {
            return "low";
        }
    }

    useEffect(() => {

        const createLane = (loads: SkinnyLoad[]): LoadLane => {
            const example = loads[0];
            return {
                loads: loads,
                laneId: laneKeyForTask(example),
                payloadType: example.payloadType,
                priority: lanePriority(loads),
                loadCount: loads.length,
                shortDescription: example.shortDescription
            };
        };

        let tempLoads = openLoads;

        const loadFilterActive = loadFilter?.length > 0;
        const dowFilterActive = dayOfWeekFilter.some(dow => dow);
        const territoryFilterActive = territoryFilter.some(id => id);

        if (loadFilterActive) {
            const matchState = loadFilter.length === 2 && loadFilter.toUpperCase() === loadFilter;
            if (matchState) {
                tempLoads = tempLoads.filter(task => matchesState(task, loadFilter));
            } else {
                const filterStr = loadFilter.toLowerCase();
                tempLoads = tempLoads.filter(load =>
                    getLoadLabel(load).toLowerCase().includes(filterStr) ||
                    load.shipwellRefId?.toLowerCase().includes(filterStr) ||
                    load.shipwellId?.toLowerCase().includes(filterStr));
            }
        }

        if (dowFilterActive) {
            tempLoads = tempLoads.filter(task => {
                let dow: number = new Date(task.stops[0].arrival).getDay();
                return dayOfWeekFilter.includes(String(dow));
            });
        }

        if (territoryFilterActive) {
            tempLoads = tempLoads.filter(task => {
                const state = task.stops[0].address.state;
                const includedStates = territories.filter(t => territoryFilter.includes(t.id)).map(t => t.regions).flat();
                return includedStates.includes(state);
            });
        }

        // map by lane
        let loadsByLane:{[key: string]: SkinnyLoad[]} = {};
        for (let task of tempLoads) {
            const key = laneKeyForTask(task);
            const forLane = loadsByLane[key] || [];
            forLane.push(task);
            loadsByLane[key] = forLane;
        }
        const lanes: LoadLane[] = Object.values(loadsByLane).map(tasks => createLane(tasks));

        setFilteredLoads(tempLoads);
        setFilteredLanes(lanes);

    }, [territories, userTerritory, openLoads, loadFilter, dayOfWeekFilter, territoryFilter])

    function laneKeyForTask(task: SkinnyLoad) {
        const origin = task.stops[0].address.zipCode;
        const dest = task.stops[task.stops.length-1].address.zipCode;
        const payload = task.payloadType
        return origin + "-" + dest + "-" + payload;
    }

    const matchesState = (load: SkinnyLoad, state: string): boolean => {
        const first = load.stops[0].address;
        const last = load.stops[load.stops.length - 1].address;
        return first.state === state || last.state === state;
    }

    const LoadCards = () => {
        return (<>
            {
                filteredLoads != null && filteredLoads.length > 0 ?
                    filteredLoads.map((load: SkinnyLoad) => <LoadCard key={load.id} load={load} selected={selectLoad}/>)
                    :
                    filteredLoads != null && filteredLoads.length === 0 ?
                        <Typography>No loads available</Typography>
                        :
                        <Loading></Loading>
            }
        </>);
    }

    const LaneCards = () => {
        return (<>
            {
                filteredLanes != null && filteredLanes.length > 0 ?
                    filteredLanes.map((lane: LoadLane) => <LaneCard key={lane.laneId} lane={lane} selected={selectLoad}/>)
                    :
                    <Loading></Loading>
            }
        </>);
    }

    const selectLoad = (load: SkinnyLoad) => {
        // if a map is currently viewed, this is going to switch the lane so the map
        // needs to be hidden.  don't really like that the map view needs to be reset
        // here but until a better way is implemented to manage the map visibility state
        // this is where it will be managed
        setShowRouteMap(false);

        postUsageEvent({
            type: JETPACK_LOAD_SELECTED,
            loadId: load.id
        });

        if(onLoadSelected) {
            onLoadSelected(load);
        } else {
            history.push(`/open-loads/${load.id}`)
        }
    }

    function laneOrLoadCards(showLanes: boolean) {
        return showLanes ? <LaneCards/> : <LoadCards/>;
    }

    return (<div className={"load-selection-outer"}>
        <div className={"content"}>
            <LoadFilter territories={territories}
                        territoryFilter={territoryFilter}
                        setTerritoryFilter={setTerritoryFilter}
                        taskFilter={loadFilter}
                        setTaskFilter={setLoadFilter}
                        dayOfWeekFilter={dayOfWeekFilter}
                        setDayOfWeekFilter={setDayOfWeekFilter}/>
            <div className={"load-list"}>
                {laneOrLoadCards(true)}
            </div>
        </div>
    </div>)
}

export default OpenLoads;
