import React from "react";
import {styled} from '@mui/material/styles';
import {InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {Territory} from "../interfaces/Territory";

type PropTypes = {
    territories: Territory[];
    territoryFilter: number[];
    setTerritoryFilter: (territories: number[]) => void;
    taskFilter: string;
    setTaskFilter: (task: string) => void;
    dayOfWeekFilter: string[];
    setDayOfWeekFilter: (dayOfWeek: string[]) => void
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-right: 25px;
`

const StyledToggleButton = styled(ToggleButton)`
  width: 100%;
  padding: 16px 13px;
`

/*
    Similar text field filter as the main page

    Any other options?
 */
const LoadFilter = ({territories, territoryFilter, setTerritoryFilter, taskFilter, setTaskFilter, dayOfWeekFilter, setDayOfWeekFilter}: PropTypes) => {

    const toggleDayFilter = (event, days: string[]) => {
        setDayOfWeekFilter(days);
    };

    const toggleTerritoryFilter = (event, territories: number[]) => {
        setTerritoryFilter(territories);
    };

    return (<div className={"load-filter-panel"}>
        <TextField className={"filter-entry"}
                   variant={"outlined"}
                   InputProps={{
                       endAdornment:
                           <InputAdornment position="start">
                               <Tooltip title={<div>Searching with Filter:
                                   <br/>&#8226;&nbsp;Enter (2) CAPITAL letters to search by STATE.
                                   <br/>&#8226;&nbsp;Enter anything else to search by ShipwellId,
                                   <br/>RefId, OR City (ignores case)

                               </div>}>
                                   <HelpIcon color="primary" />
                               </Tooltip>
                           </InputAdornment>
                   }}
                   onChange={(e) => {
                       setTaskFilter(e.target.value);
                   }}
                   value={taskFilter}
                   label={"Filter Loads by State, City, or Id"}
                   aria-label="Name or Id"
                   autoFocus={true}/>

        <StyledToggleButtonGroup
            value={territoryFilter}
            onChange={toggleTerritoryFilter}
            aria-label="text formatting"
            size="small">
            {
                territories.map((territory: Territory) =>
                    <StyledToggleButton value={territory.id} aria-label={territory.name} key={territory.id}>
                        {
                            territory.name.replace(" ", "\u00A0") // non-break space
                        }
                    </StyledToggleButton>)
            }
        </StyledToggleButtonGroup>

        <StyledToggleButtonGroup
            value={dayOfWeekFilter}
            onChange={toggleDayFilter}
            aria-label="text formatting"
            size="small">
            <StyledToggleButton value="1" aria-label="Monday">
                Mon
            </StyledToggleButton>
            <StyledToggleButton value="2" aria-label="Tuesday">
                Tue
            </StyledToggleButton>
            <StyledToggleButton value="3" aria-label="Wednesday">
                Wed
            </StyledToggleButton>
            <StyledToggleButton  value="4" aria-label="Thursday">
                Thu
            </StyledToggleButton>
            <StyledToggleButton value="5" aria-label="Friday">
                Fri
            </StyledToggleButton>
            <StyledToggleButton value="6" aria-label="Saturday">
                Sat
            </StyledToggleButton>
            <StyledToggleButton value="0" aria-label="Sunday">
                Sun
            </StyledToggleButton>
        </StyledToggleButtonGroup>
    </div>);
}

export default LoadFilter;
