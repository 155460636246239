import React, {useEffect, useState} from 'react';
import {Load} from "../interfaces/Load";
import {getEdiSourcesForLoad} from "../service/loads";
import {EdiSource} from "../interfaces/Edi";
import Loading from "../common/Loading";
import {Link, List, ListItem, ListItemButton} from "@mui/material";
import {Box, styled} from "@mui/system";
import {getTimeFormat} from "../service/time";
import Moment from "react-moment";

interface Props {
    load: Load;
}

interface ListItemProps {
    edi: EdiSource;
}

const RawEdi = styled('div')`
  color: #555;
  font-family: monospace;
  white-space: pre;
`

const EdiLink = styled(Link)`
  margin-right: 10px;
  text-decoration: none;
`

const EdiDocuments = ({load}: Props) => {

    const [ediSources, setEdiSources] = useState<EdiSource[]>([]);

    useEffect(() => {
        getEdiSourcesForLoad(load.id).then(setEdiSources);
    }, [load.id]);

    const EdiListItem = ({edi}: ListItemProps) => {
        const [rawVisible, setRawVisible] = useState(false);

        return <ListItem>
            <ListItemButton>
                <Box sx={{display: 'block'}}>
                    <div>{rawVisible ?
                        <EdiLink onClick={() => setRawVisible(false)}>Hide</EdiLink> :
                        <EdiLink onClick={() => setRawVisible(true)}>Show</EdiLink>}
                        {edi.type} - <Moment format={getTimeFormat()}>{edi.created}</Moment>
                    </div>
                    {rawVisible ? <RawEdi>{edi.raw}</RawEdi> : null}
                </Box>
            </ListItemButton>
        </ListItem>
    }

    if (ediSources) {
        return (
            <List>
                {ediSources.map(es => <EdiListItem edi={es} key={es.id}/>)}
            </List>
        );
    }

    return <div><Loading/></div>;
}

export default EdiDocuments;