import React from "react";
import {EquipmentAssignedDetails, ShipwellEventProps} from "./types";
import {EventTitle} from "./EventTypeDispatcher";
import classes from './events.module.css';

const EquipmentAssigned = ({event, title}: ShipwellEventProps) => {
    const details = JSON.parse(event.details)["1"] as EquipmentAssignedDetails;

    return <div>
        <EventTitle>{title}</EventTitle>
        {details.power_unit?.name ? <div>
            <span className={classes.unitName}>Power Unit Name</span>
            <span className={classes.value}>{details.power_unit.name}</span>
        </div> : null}
        {details.driver?.phone_number ? <div>
            <span className={classes.unitName}>Driver Phone Number</span>
            <span className={classes.value}>{details.driver.phone_number}</span>
        </div> : null}
    </div>
}

export default EquipmentAssigned;