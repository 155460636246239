
export interface Pageable {
    pageSize: number;
    pageNumber: number;
}

export type Page<T> = Pageable & {
    content: T;
    totalElements: number;
}

export function pageOf(pageNumber: number, pageSize: number): Pageable {
    return {
        pageNumber: pageNumber,
        pageSize: pageSize
    };
}

export function changePage(page: Pageable, newPage: number) {
    return {
        pageNumber: newPage,
        pageSize: page.pageSize
    };
}

export function changePageSize(page: Pageable, pageSize: number) {
    return {
        pageNumber: 0,
        pageSize: pageSize
    };
}

export function emptyPage<T>(): Page<T> {
    return {
        content: [] as T,
        totalElements: 0,
        pageNumber: 0,
        pageSize: 0
    }
}

export function pageChangeHandler(page: Pageable,
                                  pageNumber: number,
                                  setter: (Pageable) => void) {
    setter(changePage(page, pageNumber));
}

export function pageSizeChangeHandler(page: Pageable,
                                      pageSize: number,
                                      setter: (Pageable) => void) {
    setter(changePageSize(page, pageSize));
}