import React from "react";
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {CarrierLink} from "../common/Links";

const PREFIX = 'Loads';

const classes = {
    table: `${PREFIX}-table`
};

const Root = styled('div')({
    [`& .${classes.table}`]: {
        width: '100%'
    }
});

const Loads = ({loads, title = "Past Loads in Lane"}) => {
    const LoadCost = ({load}) => {
        if (load) {
            if (load.margin === "NaN") {
                return <Root className={"margin-na"}>No Rev/Costs</Root>;
            } else {
                return (
                    <div className={"margin-" + load.marginLevel}>
                        ${(load.lineHaulRate / 100).toFixed(2)} ({load.margin.toFixed(2)}%)
                    </div>
                );
            }
        } else {
            return null;
        }
    }

    const LoadRow = ({load}) => {
        const carrierInfo = load.carrier ?
            (<CarrierLink carrierId={load.carrier.id} >{load.carrier.name}</CarrierLink>) : null;
        const shipperName = load.shipper ? load.shipper.name : 'N/A';

        return <TableRow key={load.id}>
            <TableCell><a href={"https://torch.shipwell.com/shipments/" + load.shipwellId} target={"_blank"} rel="noopener noreferrer">{load.shipwellRefId}</a></TableCell>
            <TableCell>{shipperName}</TableCell>
            <TableCell>{carrierInfo}</TableCell>
            <TableCell><LoadCost load={load}/></TableCell>
            <TableCell>{new Date(load.awardTime).toLocaleDateString()}</TableCell>
        </TableRow>;
    }



    if (Array.isArray(loads) && loads.length > 0) {
        return (
            <div className={"past-loads-for-lane"}>
                {title ? <h4>Past Loads in Lane</h4> : null}
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Shipwell Ref</TableCell>
                                <TableCell>Customer</TableCell>
                                <TableCell>Carrier</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loads.map((row) => (
                                <LoadRow load={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>)
    } else {
        return null;
    }
}

export default Loads;
