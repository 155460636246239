import {API_BASE_URL} from "../constants";
import {torchFetch} from "../util/APIUtils";
import {Carrier, CarrierActivity, CarrierNote, CarrierSurvey} from "../interfaces/Carrier";
import {Feedback, Lane, LoadRateOffer, LoadWithStats} from "../interfaces/Load";
import {CarrierSource} from "../interfaces/CarrierSource";
import {LoadboardSetting} from "../interfaces/LoadboardSetting";
import {LoadboardUsage} from "../interfaces/LoadboardUsage";
import {CapacityWithLocation} from "../interfaces/Capacity";
import {Page, Pageable} from "./base";

/**
 * Searches carriers for dot/mc/name
 *
 * @param query
 */
export function searchCarriers(query): any {
    let locationUrl = new URL(`${API_BASE_URL}/carrier`);
    locationUrl.searchParams.append("q", query);

    return torchFetch({url: locationUrl, method: 'get'});
}

// this also now returns load with stats
export function fetchPastCarrierLoads(carrierId: number, page: Pageable): Promise<Page<LoadWithStats[]>> {
    let url = `${API_BASE_URL}/carrier/${carrierId}/loads?page=${page.pageNumber}&size=${page.pageSize}`;
    // should find a way to package this up - think this can be wrapped in a function since the paged
    // content should all be pretty similar
    return new Promise((resolve, reject) => {
        torchFetch({
            url: url,
            method: 'get'
        }).then(data => {
            resolve({
                content: data.content,
                totalElements: data.totalElements,
                pageNumber: data.number,
                pageSize: data.size
            });
        }).catch(reason => reject(reason));
    });
}


export function getCarrierCapacityHistory(carrierId: number, page: Pageable): Promise<Page<CapacityWithLocation[]>> {
    const url = new URL(`${API_BASE_URL}/carrier/${carrierId}/capacity?page=${page.pageNumber}&size=${page.pageSize}`);
    return new Promise((resolve, reject) => {
        torchFetch({
            url: url
        }).then(data => {
            resolve({
                content: data.content,
                totalElements: data.totalElements,
                pageNumber: data.number,
                pageSize: data.size
            })
        }).catch(reason => reject(reason));
    });
}

export function refreshCarrier(carrierId: number): Promise<Carrier> {
    const url = new URL(`${API_BASE_URL}/shipwell-debug/carrier-details/${carrierId}`);
    return torchFetch({url: url, method: 'get'});
}

export function fetchCarrier(carrierId): Promise<Carrier> {
    let locationUrl = new URL(`${API_BASE_URL}/carrier/${carrierId}`);
    return torchFetch({url: locationUrl, method: 'get'});
}

export function fetchCarrierByDot(dot: string): Promise<Carrier[]> {
    let locationUrl = new URL(`${API_BASE_URL}/carrier?dot=${dot}`);
    return torchFetch({url: locationUrl, method: 'get'});
}

export function fetchCarrierSurvey(carrierId): Promise<CarrierSurvey> {
    let url = new URL(`${API_BASE_URL}/carrier/${carrierId}/survey`);
    return torchFetch({url: url, method: 'get'});
}

export function updateCarrierSurvey(carrierId: number, survey: CarrierSurvey): Promise<CarrierSurvey> {
    return torchFetch({
        url: new URL(`${API_BASE_URL}/carrier/${carrierId}/survey`),
        method: 'post',
        body: JSON.stringify(survey)
    });
}

export function fetchCarrierPreferredLanes(carrierId): any {
    let url = new URL(`${API_BASE_URL}/carrier/${carrierId}/preferred-lanes`);
    return torchFetch({url: url, method: 'get'});
}

export function updateCarrierPreferredLanes(carrierId: number, lanes: Lane[]): Promise<Lane[]> {
    let url = new URL(`${API_BASE_URL}/carrier/${carrierId}/preferred-lanes`);
    return torchFetch({url: url, method: 'post', body: JSON.stringify(lanes)})
}

export function getRecentCarrierActivity(carrierId) : Promise<CarrierActivity[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/procurement/activity/carrier/${carrierId}`);
        torchFetch({url: url}).then(json => {
            resolve(json as CarrierActivity[]);
        });
    })
}

export function fetchCarrierLoadActivity(carrierId): Promise<CarrierActivity[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/carrier/${carrierId}/load-activity`);
        torchFetch({url: url}).then(json => {
            resolve(json as CarrierActivity[]);
        });
    })
}

export function fetchCarrierRateOffers(carrierId): Promise<LoadRateOffer[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/carrier/${carrierId}/rate-offers`);
        torchFetch({url: url}).then(json => resolve(json.content as LoadRateOffer[]));
    });
}

export function fetchCarrierDeclines(carrierId): Promise<Feedback[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/carrier/${carrierId}/load-feedback`);
        torchFetch({url: url}).then(json => resolve(json.content as Feedback[]));
    });
}

export function fetchCarrierNotes(carrierId): Promise<CarrierNote[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(`${API_BASE_URL}/carrier/${carrierId}/notes`);
        torchFetch({url: url}).then(json => resolve(json.content as CarrierNote[]));
    });
}

export function addCarrierNote(carrierId: number, note: string): Promise<CarrierNote> {
    return torchFetch({
        url: new URL(`${API_BASE_URL}/carrier/${carrierId}/note`),
        method: 'post',
        body: JSON.stringify({note: note})
    });
}

export function fetchCarrierSources(): Promise<CarrierSource[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/procurement/carrier/carrier-source`);
        torchFetch({url: url}).then(json => {
            resolve(json as CarrierSource[]);
        });
    })
}

export function fetchCarrierSource(id:string): Promise<CarrierSource[]> {
    return new Promise((resolve, reject) => {
        const url = new URL(API_BASE_URL + `/procurement/carrier/carrier-source/${id}`);
        torchFetch({url: url}).then(json => {
            resolve(json as CarrierSource[]);
        });
    })
}

export function saveLoadboardSetting(carrierId: number, loadboardSetting:LoadboardSetting): Promise<void> {
    return torchFetch({
        url: new URL(`${API_BASE_URL}/carrier/${carrierId}/loadboard-settings`),
        method: 'put',
        body: JSON.stringify(loadboardSetting)
    });
}

export function getLoadboardSetting(carrierId: number): Promise<LoadboardSetting> {
    return torchFetch({
        url: new URL(`${API_BASE_URL}/carrier/${carrierId}/loadboard-settings`)
    });
}

export function getLoadboardUsage(carrierId: number): Promise<LoadboardUsage> {
    return torchFetch({
        url: new URL(`${API_BASE_URL}/carrier/loadboard/${carrierId}`)
    });
}

export function sendOutreach(carrierId: number, loadId:number): Promise<LoadboardUsage> {
    return torchFetch({
        url: new URL(`${API_BASE_URL}/carrier/${carrierId}/outreach/${loadId}`)
    });
}




