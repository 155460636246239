import React, {useState} from "react";
import "./Overlay.scss";
import Loading from "./Loading";
import {useAbortableEffect} from "../util/hooks";

type Props = {
    active: boolean;
    loading?: boolean;
    width?: string;
    delay?: number;
    children: React.ReactNode;
}

/**
 * Wrap a component with this component, and it will provide a transparent
 * overlay div when active is set to true.  This component absorbs all
 * mouse events, so it effectively hides the elements underneath it.

 * @param children Child components that should still be displayed
 * @param active Enable the overlay
 * @param loading Enable the display of the <Loading /> component in the overlay.
 * Default is true.
 * @param delay The number of milliseconds the overlay will wait to activate
 * once active is changed to true. Default is 250.
 * @param width How wide to make the loading value.  This is passed to the <Loading />
 * component. Default is 150px.
 * @constructor
 */
const LoadingOverlay = ({children, active, loading = true, width = "150px", delay = 250}: Props) => {

    const [_active, _setActive] = useState(false);

    useAbortableEffect((status) => {
        setTimeout(() => {
            if(!status.aborted) {
                _setActive(active);
            }
        }, delay);
    }, [delay, active]);

    if (!_active) {
        return <>{children}</>;
    }

    return <div className={"overlay"}>
        {children}
        <div className={"dimmed"}>{loading ? <Loading width={width}/> : null}</div>
    </div>;
}

export default LoadingOverlay;