import React, {useEffect, useState} from "react";
import {getPastTasks, Task} from "./workflow";
import CenteredText from "../common/CenteredText";
import {timeFormat} from "../util/time";
import {styled} from "@mui/system";

const TaskContainer = styled('div')`
  margin-bottom: 20px;
`
const Title = styled('div')`
  font-weight: bold;
  font-size: 13pt;
  color: #333;
  
  &.SUCCESSFUL {
    color: #6d6;
  }
`

type Props = {
    task: Task;
}

const PastTasks = ({task}: Props) => {

    const [tasks, setTasks] = useState<Task[]>([]);

    useEffect(() => {
        getPastTasks(String(task.load.id)).then(setTasks);
    }, [task.load.id]);

    if(tasks.length === 0) {
        return <>
            <CenteredText>No Historical Tasks</CenteredText>
        </>;
    }

    return <>
        {
            tasks.map(task => {
                return <TaskContainer key={task.id}>
                    <Title className={task.outcome}>{task.label}</Title>
                    <div>Due: {timeFormat(task.due)}</div>
                    <div>Completed: {timeFormat(task.ended)}</div>
                    <div>Outcome: {task.note}</div>
                </TaskContainer>;
            })
        }
    </>
}

export default PastTasks;