import React, {useEffect, useState} from "react";
import {Carrier} from "../interfaces/Carrier";
import {CapacityWithLocation} from "../interfaces/Capacity";
import {emptyPage, Page, Pageable, pageChangeHandler, pageOf, pageSizeChangeHandler} from "../service/base";
import {getCarrierCapacityHistory} from "../service/carriers";
import NoContentPanel from "../common/NoContentPanel";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography} from "@mui/material";
import {dateFormat} from "../service/time";
import LoadingOverlay from "../common/LoadingOverlay";

type Props = {
    carrier: Carrier;
}

const CapacityHistory = ({carrier}: Props) => {

    const [capacity, setCapacity] = useState<Page<CapacityWithLocation[]>>(emptyPage());
    const [page, setPage] = useState<Pageable>(pageOf(0, 5));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>();

    useEffect(() => {
        setLoading(true);
        getCarrierCapacityHistory(carrier.id, page)
            .then(c => {
                setCapacity(c);
                setError(null);
                setLoading(false);
            })
            .catch(err => {
                setCapacity(emptyPage());
                setError(err);
                setLoading(false);
            });
    }, [carrier, page])

    if (error) {
        return <NoContentPanel minHeight={"400px"}>
            Error retrieving capacity history: {JSON.stringify(error)}
        </NoContentPanel>
    }

    if (capacity.totalElements === 0) {
        return <NoContentPanel minHeight={"400px"}>
            <Typography variant={"h5"}>No Capacity for this Carrier</Typography>
            <Typography variant={"body1"}>Ask if they have an e-mail distribution for
                their capacity or a</Typography>
            <Typography variant={"body1"}>website that lists where they have equipment available?</Typography>
        </NoContentPanel>
    }

    return <LoadingOverlay active={loading}>
        <Table sx={{tableLayout: "fixed"}}>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Notes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {capacity.content.map(c =>
                    <TableRow key={c.id}>
                        <TableCell>{dateFormat(c.start)}</TableCell>
                        <TableCell>{c.count}</TableCell>
                        <TableCell>{c.city}, {c.state}</TableCell>
                        <TableCell>{c.note}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
        <TablePagination
            count={capacity.totalElements}
            page={page.pageNumber}
            onPageChange={(e, num) => pageChangeHandler(page, num, setPage)}
            rowsPerPage={page.pageSize}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={(e) => pageSizeChangeHandler(page, parseInt(e.target.value, 10), setPage)}
            sx={{borderBottom: 0}}
        />
    </LoadingOverlay>;
}

export default CapacityHistory;