import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React, {useEffect, useState} from "react";
import "../common/Common.scss";
import {LoadWithStats} from "../interfaces/Load";
import {TablePagination} from "@mui/material";
import LoadDescription from "../common/LoadDescription";
import NoContentPanel from "../common/NoContentPanel";
import {dateFormat} from "../service/time";
import DatRate from "../common/DatRate";
import {formatCentsRate} from "../util/rate-util";
import {fetchPastCarrierLoads} from "../service/carriers";
import {Carrier} from "../interfaces/Carrier";
import Loading from "../common/Loading";

type LoadListProps = {
    carrier: Carrier;
}

type LoadParams = {
    loadWithStats: LoadWithStats
}

/*
    Plan to add ability to set which columns to display so this can be used across multiple
    places in the app

 */
export const LoadList = ({carrier}: LoadListProps) => {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalLoadCount, setTotalLoadCount] = useState(0);
    const [loads, setLoads] = useState<LoadWithStats[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchPastCarrierLoads(carrier.id, {pageNumber: page, pageSize: pageSize})
            .then(pageOfLoads => {
                setLoads(pageOfLoads.content);
                setTotalLoadCount(pageOfLoads.totalElements);
                setLoading(false);
            })
            .catch(reason => {
                // need to display the error still
                setLoading(false);
            });
    }, [carrier, page, pageSize]);

    const LoadCost = ({loadWithStats}: LoadParams) => {
        const load = loadWithStats.load;
        if (load) {
            if (load.margin === "NaN") {
                return <div className={"margin-na"}>No Rev/Costs</div>;
            } else {
                return (
                    <div className={"margin-" + load.marginLevel}>
                        ${(load.lineHaulRate / 100).toFixed(2)} ({parseFloat(load.margin).toFixed(2)}%)
                    </div>
                );
            }
        } else {
            return null;
        }
    }

    const handlePageChange = (event, newPage) => {
        console.log(`newPage is ${newPage}`);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageSize(parseInt(event.target.value, 10));
    };

    const LoadRow = ({loadWithStats}: LoadParams) => {
        const load = loadWithStats.load;
        const shipperName = load.shipper ? load.shipper.name : 'N/A';

        return (
            <TableRow key={load.id}>
                <TableCell><a href={"https://torch.shipwell.com/shipments/" + load.shipwellId} target={"_blank"}
                              rel="noopener noreferrer">{load.shipwellRefId}</a></TableCell>
                <TableCell><LoadDescription load={load}/></TableCell>
                <TableCell>{shipperName}</TableCell>
                <TableCell><LoadCost loadWithStats={loadWithStats}/></TableCell>
                <TableCell>{load.maxBuy ? formatCentsRate(load.maxBuy) : "N/A"}</TableCell>
                <TableCell>{loadWithStats.datRate ?
                    <DatRate rate={loadWithStats.datRate} level={"minimal"}/> : "N/A"}</TableCell>
                <TableCell>{dateFormat(load.stops[0].arrival)}</TableCell>
            </TableRow>
        );
    }

    if(loading) {
        return <Loading />
    }

    if (loads && loads.length > 0) {
        return (
            <div>
                <TableContainer>
                    <Table aria-label="List of Loads">
                        <TableHead>
                            <TableRow>
                                <TableCell>Shipwell Load</TableCell>
                                <TableCell>Lane</TableCell>
                                <TableCell>Shipper</TableCell>
                                <TableCell>Line Haul Cost</TableCell>
                                <TableCell>Max Buy</TableCell>
                                <TableCell>DAT Rate</TableCell>
                                <TableCell>Pickup Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loads.map((load) => (
                                <LoadRow loadWithStats={load} key={load.load.id}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component={"div"}
                    count={totalLoadCount}
                    page={page}
                    rowsPerPageOptions={[5, 10, 20]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={handlePageChange}
                    rowsPerPage={pageSize}/>
            </div>);
    }

    // maybe an empty list in the future vs. nothing
    return <div className={"carrier-loads"}>
        <NoContentPanel>No Past Loads</NoContentPanel>
    </div>;
}

export default LoadList;
