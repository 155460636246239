import {Button} from "@mui/material";
import {useState} from "react";
import RateItem from "./RateItem";
import {styled} from "@mui/system";

const RateBox = styled('div')`

`
const RateItemBox = styled('div')`
  border: #0a6fdb;
  border-style: solid;
  border-width: 1px;
  padding:5px;
  margin-top: 10px;
`


const AddButton  = styled('div')`
  margin-top: 20px;
`

const Rates = () => {

    const[rates, setRates] = useState([0]);

    const addRate = () => {
        setRates([...rates, rates.length + 1]);
        return false;
    }

    return (
        <RateBox>
            { rates.map( rateIndex =>
            <RateItemBox key={rateIndex}>
                <RateItem></RateItem>
            </RateItemBox>
            )
            }
            <AddButton>
                <Button  onClick={ () => addRate()}>Add Rate</Button>
            </AddButton>
        </RateBox>
    )

}

export default Rates;
