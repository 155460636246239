import React, {useEffect, useState} from "react";
import {Facility, FacilityContact, FacilityHour} from "../interfaces/Facility";
import {Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import {createFacilityContact, updateFacilityContact} from "./facility-services";
import Loading from "../common/Loading";

type Props = {
    facility: Facility;
    contact?: FacilityContact;
    workingDays: FacilityHour[];
    // contactAvailabilities: {id: number, dayOfWeek: string; shift: string}[];
    shifts: {id: number, shift: string,timing: string}[];
    completed: () => void;
}

export default function AddOrEditFacilityContact({facility, contact, workingDays,shifts, completed}: Props) {

    const [firstName, setFirstName] = useState(contact ? contact.firstName : "");
    const [lastName, setLastName] = useState(contact ? contact.lastName : "");

    const [phone, setPhone] = useState(contact ? contact.phone : "");
    const [phoneError, setPhoneError] = useState(false);

    const [email, setEmail] = useState(contact ? contact.email : "");
    const [emailError, setEmailError] = useState(false);

    const [role, setRole] = useState(contact ? contact.role: "");

    const [accountAssociated, setAccountAssociated] = useState(contact ? contact.accountAssociated : "");

    const [formDisabled, setFormDisabled] = useState(false);
    const [busy, setBusy] = useState(false);

    const [workingArray, setWorkingArray] = useState<{ id?: number,dayOfWeek: string, shift: string, isWorking?: boolean  }[]>([])

    const [isDefault, setIsDefault] = useState<boolean>(contact?.isDefault ? contact.isDefault === true ? true : false : false);
    // const shifts = [{id:0, shift: "FIRST", timing: "(7am-3pm)"}, {id:1, shift: "SECOND", timing: "(3pm- 11pm)"}, {id:2, shift: "THIRD", timing: "(11pm-7am)"}]

    useEffect(() => {
        const final = workingDays.filter(d => d.isOpen).map(day => {
            const availability = contact?.contactAvailabilities && contact.contactAvailabilities.find(item => item.dayOfWeek === day.dayOfWeek && day.isOpen );
            if (availability) {
            return { id:  availability.id ,dayOfWeek: day.dayOfWeek, shift: availability.shift, isWorking: true };
            } else {
            return { dayOfWeek: day.dayOfWeek, shift: "", isWorking: false };
            }
        });
        
        setWorkingArray(final)
    }, [workingDays,contact]);

    function setWorkingDays(dow: number, isWorking: boolean) {
        workingArray[dow].isWorking = isWorking;
        workingArray[dow].shift = '';
        setWorkingArray(Array.from(workingArray));
    }

    function setWorkingDaysShift(dow: number, shift: string) {
        workingArray[dow].shift = shift;
        setWorkingArray(Array.from(workingArray));
    }

    

    function cancel() {
        completed();
    }

    function save() {
        console.log(isDefault);
        setFormDisabled(true);
        setBusy(true);

        const target = contact ? structuredClone(contact) : {} as FacilityContact
        target.firstName = firstName;
        target.lastName = lastName;
        target.phone = phone;
        target.email = email;
        target.role = role;
        target.accountAssociated = accountAssociated;
        target.contactAvailabilities = workingArray.filter(day => (day.shift !== '' && delete day.isWorking));
        target.isDefault = isDefault;
        if(contact) {
            updateFacilityContact(facility, target).then(fc => {
                completed();
            }).catch(err => {
                // post some error alert;
                completed();
            });
        } else {
            createFacilityContact(facility, {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                role: role,
                accountAssociated: accountAssociated,
                contactAvailabilities: workingArray.filter(day => (day.shift !== '' && delete day.isWorking)),
                isDefault : isDefault
            }).then(result => {
                completed();
                setFormDisabled(false);
                // need to tell parent they can refresh - or maybe they
                // decide to when the control open says it is done
                // getFacilityContacts(facility).then(setContacts);
            })
        }
    }

    function canSubmit(): boolean {
        return !phoneError && !emailError;
    }

    if(busy) {
        return <Loading />
    }

    return <div className={"edit-container"}>
        <div className={"form-row"}>
            <TextField id="contact-first-name"
                       label="First Name"
                       variant="outlined"
                       value={firstName}
                       disabled={formDisabled}
                       onChange={(evt) => setFirstName(evt.target.value)}
                       required={true}
                       size={"small"}
                       fullWidth/>

            <TextField id="contact-last-name"
                       label="Last Name"
                       variant="outlined"
                       value={lastName}
                       disabled={formDisabled}
                       onChange={(evt) => setLastName(evt.target.value)}
                       required={true}
                       size={"small"}
                       fullWidth/>
        </div>

        <div className={"form-row"}>
            <TextField id="contact-email"
                       label="E-mail address"
                       variant="outlined"
                       value={email}
                       onChange={(evt) => setEmail(evt.target.value)}
                       disabled={formDisabled}
                       onBlur={() => setEmailError(email.length > 0 && !isEmail(email))}
                       error={emailError}
                       helperText={emailError ? "Not Valid Email" : ''}
                       size={"small"}
                       fullWidth/>

            <TextField id="contact-phone"
                       label="Phone Number"
                       variant="outlined"
                       value={phone}
                       onChange={(evt) => setPhone(evt.target.value)}
                       disabled={formDisabled}
                       error={phoneError}
                       onBlur={() => setPhoneError(phone.length > 0 && !isMobilePhone(phone))}
                       helperText={phoneError ? "Not Valid Phone" : ''}
                       size={"small"}
                       fullWidth/>
        </div>

        <div className={"form-row"}>
            <TextField id="contact-role"
                       label="Role"
                       variant="outlined"
                       value={role}
                       onChange={(evt) => setRole(evt.target.value)}
                       disabled={formDisabled}
                       size={"small"}
                       fullWidth/>

            <FormControl>
                <InputLabel id="shippers">Account Associated</InputLabel>
                <Select 
                        value={accountAssociated}
                        size={"small"}
                        onChange={(evt) => setAccountAssociated(evt.target.value)}
                        id="shippers"
                        name="shippers"
                        label="Account Associated"
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: '100px',
                                    '& .MuiMenuItem-root': {
                                        padding: 1,
                                    },
                                },
                            },
                        }}>
                    {facility.shippers.map(st => <MenuItem key={st.id} value={st.name}>{st.name}</MenuItem>)}
                </Select>
            </FormControl>
        </div>

        <div className={"form-row"}>

            <FormControlLabel
                control={<Checkbox  
                            checked={isDefault}
                            disabled={contact?.isDefault ? contact.isDefault === true ? true : false : false} 
                            onChange={(evt) => setIsDefault(evt.target.checked)}/>}
                label="Set As Default"
            />
        </div>

        <div>
            {workingArray.map((day, index) => {
                return <div className="working-days">
                
                <div className={"day-of-week"}>
                    <Typography className={"day"}><Checkbox checked={day.isWorking}
                                                                                onChange={(evt) => setWorkingDays(index, evt.target.checked)}/>{day.dayOfWeek}</Typography>
                    {day.isWorking ? 
                        <FormControl>
                            <InputLabel id="shifts">Shift</InputLabel>
                            <Select 
                                    sx={{
                                        width: '30%',
                                    }}
                                    value={day.shift}
                                    size={"small"}
                                    onChange={(evt) => setWorkingDaysShift(index,evt.target.value as string)}
                                    id="shifts"
                                    name="shifts"
                                    label="Shifts"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: '100px',
                                                '& .MuiMenuItem-root': {
                                                    padding: 1,
                                                },
                                            },
                                        },
                                    }}>
                                {shifts.map(st => <MenuItem key={st.id} value={st.shift}>{st.shift} {st.timing}</MenuItem>)}
                            </Select>
                        </FormControl>
                    : ""}
                </div>
                </div>
            })}
        </div>

        <div className={"action-bar"}>
            <Button variant={"outlined"}
                    color={"secondary"}
                    size={"small"}
                    disabled={formDisabled}
                    onClick={cancel}>Cancel</Button>
            <Button variant={"outlined"}
                    color={"primary"}
                    size={"small"}
                    disabled={formDisabled || !canSubmit()}
                    onClick={save}>{contact ? "Update" : "Create"}</Button>
        </div>
    </div>
}