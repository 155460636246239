import React, {useEffect, useState} from 'react';
import {addCarrierNote, fetchCarrierNotes} from "../service/carriers";
import {Carrier, CarrierNote} from "../interfaces/Carrier";
import {Card, CardContent, Link, Tooltip} from "@mui/material";
import {dateFormat} from "../service/time";
import {SuccessActionNotification} from "../common/SuccessActionNotification";
import AddNote from "./AddNote";
import {styled} from "@mui/system";

type PropTypes = {
    carrier: Carrier
}

const NotesContainer = styled('div')`
  padding: 15px;
`

const NoteCard = styled(Card)`
  margin: 15px 0;
`

const TLink = styled(Link)`
  cursor: pointer;
`

const NoteDate = styled('span')`
  color: #777;
`

export const CarrierNotes = ({carrier}: PropTypes) => {
    const [notes, setNotes] = useState<CarrierNote[]>();
    const [noteSaved, setNoteSaved] = useState<boolean>(false);
    const [noteControlOpen, setNoteControlOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchCarrierNotes(carrier.id).then(notes => setNotes(notes));
    }, [carrier]);

    const addNote = (note) => {
        addCarrierNote(carrier.id, note)
            .then(note => {
                setNoteSaved(true);
                fetchCarrierNotes(carrier.id)
                    .then(notes => setNotes(notes))
            });
    }

    const openNoteControl = (evt) => {
        setNoteControlOpen(true);
        evt.preventDefault();
    }

    const NoteCards = () => {
        return notes && notes.length > 0 ?
            <>{notes.map(note => {
                return (<NoteCard key={note.id}>
                    <CardContent sx={{backgroundColor: "#fafafa"}}>
                        <Tooltip title={note.user.name}><NoteDate>{dateFormat(note.created)}</NoteDate></Tooltip> - {note.note}
                    </CardContent>
                </NoteCard>);
            })}</> : null;
    }

    return (<NotesContainer>
        <TLink onClick={openNoteControl}>Add New Carrier Note</TLink>
        <AddNote noteControlOpen={noteControlOpen} addFn={addNote} cancelFn={() => setNoteControlOpen(false)}/>
        <NoteCards/>
        <SuccessActionNotification message={"Note Saved"}
                                   open={noteSaved}
                                   close={() => setNoteSaved(false)}/>
    </NotesContainer>);
}
