import {torchFetch} from "../util/APIUtils";
import {API_BASE_URL} from "../constants";
import {Bid} from "../interfaces/Bids";


export function queryBids(query: string): Promise<Bid[]> {
    return torchFetch({url: `${API_BASE_URL}/bids?q=${query}`});
}

export function getBids(): Promise<Bid[]> {
    return torchFetch({url: `${API_BASE_URL}/integrations/bid`});
}

export function getBidsWithPage(page:number, pageSize:number): Promise<Bid[]> {
    return torchFetch({url: `${API_BASE_URL}/integrations/bid?page=${page}&size=${pageSize}`});
}

export function getBid(id:string): Promise<Bid> {
    return torchFetch({url: `${API_BASE_URL}/integrations/bid/${id}`});
}

