import {CircularProgress, Fade, FormControl, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import React, {useState} from "react"
import Button from "@mui/material/Button";
import {TenderRejectDialog} from "./TenderRejectDialog";
import {useHistory} from "react-router-dom";
import "./TenderLoadDetails.scss";
import {EDIDeclineCode, EDILoadTender, EDILoadTenderWithUpdates, EDIStop} from "../interfaces/TenderedLoad";
import Divider from "@mui/material/Divider";
import {acceptRejectTenderedLoad, disregardTenderedLoad} from "../service/tendered";
import {displayTime, formatCents} from "../util/load-utils";
import {CANCELLATION} from "../constants/LoadTenderPurpose";
import {TorchShipmentSearchExternalLink} from "../common/Links";

type Props = {
    task: EDILoadTenderWithUpdates;
    doneWithTender: () => void;
}
const TenderLoadDetails = ({task, doneWithTender}: Props) => {
    const history = useHistory();

    const [declineDialogOpen, setRejectDialogOpen] = useState(false);
    const [selectedLoad, setSelectedLoad] = useState<EDILoadTender>();
    const [loading, setLoading] = useState(false);

    function acceptTender() {
        setLoading(true);
        acceptRejectTenderedLoad(task.tender.id, true, [])
            .then(() => {
                switchToTenderView();
                doneWithTender();
            })
            .catch((e) => makeErrorText(e).then(text => {
                switchToTenderView(text);
                doneWithTender();
            }));
    }

    function confirmReject(declineCodes: EDIDeclineCode[]) {
        setRejectDialogOpen(false);
        setLoading(true);

        acceptRejectTenderedLoad(task.tender.id, false, declineCodes)
            .then(() => {
                switchToTenderView();
                doneWithTender();
            })
            .catch((e: Response) => makeErrorText(e).then(text => {
                switchToTenderView(text);
                doneWithTender();
            }));
    }

    function rejectTenderDialog() {
        setRejectDialogOpen(true);
    }

    async function makeErrorText(response: Response) {
        let body = await response.text();
        return `An error occurred during accept/reject shipment ${task.tender.id}. Status: ${response.status} ${response.statusText}. Body: ${(body)}`;
    }

    function switchToTenderView(errorText?: string|undefined) {
        setLoading(false);
        history.push({pathname: "/tender/", search: document.location.search, state: {error: errorText}});
    }

    function updateSelected(value: EDILoadTender) {
        return setSelectedLoad(value);
    }

    function displayDiff(displayed: string, current: string) {
        if (displayed === current) {
            return <div className={"value-text"}>{displayed}</div>;
        } else {
            return <Tooltip title={`current: ${current}`} placement="bottom">
                <div className={"difference-text"} color={"primary"}>{displayed}</div>
            </Tooltip>
        }
    }

    const formatAddress = (stop: EDIStop) => {
        if (!stop) {
            return "STOP NOT SPECIFIED"
        }
        if (stop.streetTwo) {
            return `${stop.streetOne}, ${stop.streetTwo}, ${stop.city} ${stop.state}, ${stop.postalCode}`;
        } else {
            return `${stop.streetOne}, ${stop.city} ${stop.state}, ${stop.postalCode}`;
        }
    }

    const formatContact = (stop: EDIStop) => {
        if (!stop) {
            return "STOP NOT SPECIFIED"
        }
        return `${stop.contactName} (${stop.contactNumber})`;
    }

    function disregardTender() {
        disregardTenderedLoad(task.tender.id)
            .then(() => switchToTenderView())
            .catch((e) => makeErrorText(e).then(text => switchToTenderView(text)));
    }

    const RemarksAndNotes = ({displayedTender}) => {
        if(displayedTender?.remarks?.length > 0 || displayedTender?.mappingNotes?.length > 0) {
            return <>
                <Divider sx={{marginTop: "15px", marginBottom: "15px"}} variant="middle"/>
                <Remarks displayedTender={displayedTender}/>
                <MappingNotes displayedTender={displayedTender}/>
                <Divider sx={{marginTop: "15px", marginBottom: "15px"}} variant="middle"/>
            </>
        } else {
            return null;
        }
    }

    const Remarks = ({displayedTender}) => {
        if(displayedTender?.remarks?.length > 0) {
            return <div className={"remarks"}>
                <div className={"label"}>Load Remarks</div>
                {
                    displayedTender.remarks.map(remark => {
                        return <div className={"remark"} key={remark.id}>
                            {remark.messageOne ? remark.messageOne : ""}
                            {remark.messageTwo ? remark.messageTwo : ""}
                        </div>
                    })
                }
            </div>;
        }

        return null;
    }

    const MappingNotes = ({displayedTender}) => {
        if(displayedTender?.mappingNotes?.length > 0) {
            return <>
                <div className={"label"}>Notes</div>
                {displayedTender.mappingNotes}
            </>
        }

        return null;
    }

    if (task) {
        const displayed: EDILoadTender = selectedLoad || task.tender;
        const current: EDILoadTender = task.tender;
        const displayedOrigin = displayed.stops[0];
        const displayedDest = displayed.stops[displayed.stops.length - 1];
        const currentOrigin = current.stops[0];
        const currentDest = current.stops[displayed.stops.length - 1]

        return <>
            <div className={"tender-load-details"}>
                <FormControl className={"load-updates-select"}>
                    <InputLabel id="load-updates-input-label">Load Tenders</InputLabel>
                    <Select
                        labelId="load-updates-input-label"
                        id="load-updates-select"
                        label={"Load Tenders"}
                        value={selectedLoad||task.updates[task.updates.length - 1]}
                        onChange={event => updateSelected(event.target.value as EDILoadTender)}>
                        {task.updates
                            .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
                            .map(update => <MenuItem key={update.id} value={update as any}>
                            {displayTime(update.created)} - {update.purpose} ({update.status.replace("_", " ")})
                        </MenuItem>)}
                    </Select>
                </FormControl>

                <div className={"current-tender"}><span className={"label"}>Current:</span> {displayTime(current.created)} - {current.purpose}</div>

                <div className={"current-shipper"}><span className={"label"}>Shipper:</span>  {current.shipper.name}</div>

                <div className={"numbers"}>
                    <div className={"shipment-id-number"}>
                        <div className={"label"}>Shipment Id</div>
                        <TorchShipmentSearchExternalLink query={displayed.shipmentIdNumber}>
                            {displayDiff(displayed.shipmentIdNumber, current.shipmentIdNumber)}
                        </TorchShipmentSearchExternalLink>
                    </div>
                    <div className={"tender_id_number"}>
                        <div className={"label"}>LoadTender Id</div>
                        {displayDiff(`${displayed.id}`, `${current.id}`)}
                    </div>
                </div>

                <div className={"transaction"}>
                    <div className={"transaction-type"}>
                        <div className={"label"}>Transaction Type</div>
                        {displayDiff(displayed.transactionType?.toString(), current.transactionType?.toString())}
                    </div>
                    <div className={"transaction-amount"}>
                        <div className={"label"}>Transaction Amount</div>
                        {displayDiff(displayed.transactionAmount ? formatCents(displayed.transactionAmount) : "--",
                            current.transactionAmount ? formatCents(current.transactionAmount) : "--")}
                    </div>
                </div>

                <div className={"details"}>
                    <div className={"shipper"}>
                        <div className={"label"}>Load Type</div>
                        {displayDiff(displayed.payloadType, current.payloadType)}
                    </div>
                    <div className={"purpose"}>
                        <div className={"label"}>Purpose</div>
                        {displayDiff(displayed.purpose, current.purpose)}
                    </div>
                    <div className={"special-services"}>
                        <div className={"label"}>Special Services</div>
                        {displayDiff(displayed.specialServices, current.specialServices)}
                    </div>
                </div>

                {displayed.stops[0]?.city ?
                <div className={"addresses"}>
                    <div className={"pickup"}>
                        <div className={"label"}>Pickup</div>
                        {displayDiff(displayedOrigin?.facilityName, currentOrigin?.facilityName)}
                        {displayDiff(formatAddress(displayedOrigin), formatAddress(currentOrigin))}
                        {displayDiff(displayTime(displayedOrigin?.stopTime, displayedOrigin?.timeZoneId),
                            displayTime(currentOrigin?.stopTime, currentOrigin?.timeZoneId))}
                        {displayDiff(formatContact(displayedOrigin), formatContact(currentOrigin))}
                    </div>
                    <div className={"dropoff"}>
                        <div className={"label"}>Dropoff</div>
                        {displayDiff(displayedDest?.facilityName, currentDest?.facilityName)}
                        {displayDiff(formatAddress(displayedDest), formatAddress(currentDest))}
                        {displayDiff(displayTime(displayedDest?.stopTime, displayedDest?.timeZoneId),
                            displayTime(currentDest?.stopTime, currentDest?.timeZoneId))}
                        {displayDiff(formatContact(displayedDest), formatContact(currentDest))}
                    </div>
                    <div className={"stuff"}>
                        <div className={"label"}>Load</div>
                        {displayDiff(displayed.weight + " Pounds", current.weight + " Pounds")}
                        {displayDiff(displayed.stops?.length + " Stops", current.stops.length + " Stops")}
                    </div>
                </div> : null }
                <RemarksAndNotes displayedTender={displayed} />

                <div className={"buttons"}>
                    <div className={"spinner"}>
                        <Fade
                            in={loading}
                            style={{transitionDelay: loading ? '500ms' : '0ms',}}
                            unmountOnExit>
                            <CircularProgress />
                        </Fade>
                    </div>
                    <Button disabled={loading} variant="contained" color="primary"
                            onClick={() => acceptTender()}>Accept Load</Button>
                    <Button disabled={loading || current.purpose === CANCELLATION} variant="contained" color="secondary"
                            onClick={() => rejectTenderDialog()}>Reject Load</Button>
                    <Tooltip title={"Take no action, and remove from list"}>
                        <Button variant="contained" color="secondary" onClick={() => disregardTender()}>Disregard</Button>
                    </Tooltip>
                </div>

            </div>

            <TenderRejectDialog open={declineDialogOpen}
                                handleConfirm={() => (codes) => confirmReject(codes)}
                                handleCancel={() => setRejectDialogOpen(false)}/>
        </>;

    } else {
        return <div className={"tender-load-details"}>Task Load Placeholder</div>
    }
}
export default TenderLoadDetails;
