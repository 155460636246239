import React, {useEffect, useState} from "react";
import {LoadLane, SkinnyLoad} from "../interfaces/Load";
import {
    Alert,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Collapse,
    LinearProgress,
    Link,
    Typography
} from "@mui/material";
import {getDeliveryStop, getPickupStop, getShortDescription} from "../util/load-utils";
import {TruckType} from "../common/TruckType";
import {Tags} from "../common/Tags";
import {differenceInCalendarDays, startOfDay} from "date-fns";
import {Capacity} from "../interfaces/Capacity";
import {fetchWeatherForLoad, getLoadCapacity} from "../service/loads";
import {useHistory} from "react-router-dom";
import {Weather} from "../interfaces/Weather";
import Button from "@mui/material/Button";
import {Box} from "@mui/system";


type PropTypes = {
    lane: LoadLane,
    selected: ((load: SkinnyLoad) => void)
}

const LaneCard = ({lane, selected}: PropTypes) => {

    type LoadsByDayProps = {
        loads: SkinnyLoad[]
    }

    // this component is generating warnings around missing key properties even though they
    // are set
    const LoadsByDay = ({loads}: LoadsByDayProps) => {
        const dayStart = startOfDay(new Date());
        const labels = ["SU","M","T","W","TH","F","SA"];
        const todayDow = dayStart.getDay();
        let buckets: number[] = [0,0,0,0,0,0,0];
        let past = 0;

        loads.forEach(load => {
            let between = differenceInCalendarDays(new Date(load.stops[0].arrival), new Date());

            if (between < 0) {
                past++;
            } else {
                buckets[between] += 1;
            }
        });

        let result: any[] = [<span className={"day-container"} key={"od"}><span className={"day overdue"}>OD </span><span className={past > 0 ? "number high" : "number "}>{past}</span></span>];
        for (let i = 0; i < 7; i++) {
            const priority = i < 2 ? "high" : i < 3 ? "med" : "low";
            result.push(<span className={"day-container"} key={i}><span className={"day"}>{labels[(todayDow + i) % 7] + " "}</span>
                <span className={buckets[i] > 0 ? "number " + priority : "number"}>{buckets[i]}</span></span>);
        }

        return <div className={"loads-by-day"}>{result.reduce((prev, curr) => [prev, ' ', curr])}</div>;
    }

    const LoadLabel = () => {
        const pickup = getPickupStop(lane.loads[0]).address;
        const delivery = getDeliveryStop(lane.loads[0]).address;
        return <>
            <span className={"city"}>{pickup.city.toLowerCase()}</span>
            &nbsp;
            <span className={"state"}>{pickup.state}</span>
            &nbsp;&#8594;&nbsp;
            <span  className={"city"}>{delivery.city.toLowerCase()}</span>
            &nbsp;
            <span className={"state"}>{delivery.state}</span>
        </>
    }

    const LoadCapacity = () => {
        const history = useHistory();
        const[capacities, setCapacities] = useState<Capacity[]>();
        useEffect( () => {
            getLoadCapacity(lane.loads[0].id).then((capacities) => {
                setCapacities(capacities);
            });
        }, []);

        const goToLane  = (loadId:number, carrierId) => {
            history.push(`/open-loads/${loadId}/carrier/${carrierId}`);
        }

        return <>
        {
            capacities && capacities.length > 0 ?
            <Typography>Recent Capacity(Last 5 days)</Typography>
            :
            <><LinearProgress/></>
        }
            {capacities?.slice(0, 5).map(capacity =>
                <div>
                    <Link onClick={ () => goToLane(lane.loads[0].id, capacity.carrier.id)}>{capacity.carrier.name}</Link>
                </div>
            )}
        </>
    }

    type WeatherProps = {
        stopIndex:number;
    }

    const LoadWeather = ({stopIndex}: WeatherProps) => {
        const[weather, setWeather] = useState<Weather>();
        const [expanded, setExpanded] = React.useState(false);
        const handleExpandClick = () => {
            setExpanded(!expanded);
        };

        useEffect( () => {
            fetchWeatherForLoad(lane.loads[0].stops[stopIndex].address.state).then((weather) => {
                setWeather(weather);
            });
        }, [stopIndex]);

        return <>
            {
                weather && weather.features && weather.features.length > 0 ?
                    <div>
                        <Card>
                            <Typography><Alert severity="error">{stopIndex === 0 ? "Pickup Alert:" : "Dropoff Alert:"} {weather.title}</Alert></Typography>
                            <CardActions>
                                <Button
                                    onClick={handleExpandClick}
                                >
                                    { expanded ?  "Hide Alerts" : "Show " + weather.features.length + " Weather Alerts"}
                                </Button>
                            </CardActions>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography paragraph>
                                        {
                                            weather.features.map(feat =>
                                                <Box sx={{marginBottom:5}}>
                                                    <Typography>{feat.properties.description}</Typography>
                                                </Box>
                                            )
                                        }
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </div>
                :
                    <></>
            }
        </>
    }

    return (<Card className={"load-card " + lane.priority}>
        <CardActionArea onClick={() => selected(lane.loads[0])}>
            <CardContent className={"card-content"}>
                <div className={"title"}>
                    <span className={"count"}>{lane.loads.length}</span>
                    <span className={"lane-name"}><LoadLabel /></span>
                </div>
                <div className={"daily-breakdown"}>
                    <LoadsByDay loads={lane.loads} />
                </div>
                <div className={"lane-breakdown"}>
                    <Tags task={lane.loads[0]}/>
                    <span className={"miles"}>{lane.loads[0].miles} Miles</span>
                    {
                        lane.loads[0].miles < 650 ?
                            <span className={"short-haul-label"}>Short Haul</span>
                            : null
                    }
                    <span className={"load-description"}>{getShortDescription(lane.loads[0])}</span>
                    <span className={"truck-type"}><TruckType task={lane.loads[0]}/></span>
                </div>
            </CardContent>
        </CardActionArea>
        <CardActionArea>
            <CardContent className={"card-content"}>
            <div>
                <LoadCapacity></LoadCapacity>
            </div>
            </CardContent>
        </CardActionArea>
        <CardActionArea>
            <CardContent className={"card-content"}>
                <div>
                    <LoadWeather stopIndex={0}></LoadWeather>
                    <LoadWeather stopIndex={1}></LoadWeather>
                </div>
            </CardContent>
        </CardActionArea>
    </Card>)
}

export default LaneCard;
