import React from "react";
import Box from "@mui/material/Box";

type PropTypes = {
    title?: React.ReactNode;
    children?: React.ReactNode;
    noContent?: boolean;
    fullBleed?: boolean;
    border?: boolean;
    noContentMessage?: string;
    className?: string;
}

const SectionContainer = ({
                              title,
                              noContent = false,
                              noContentMessage,
                              fullBleed = false,
                              border = true,
                              className,
                              children
                          }: PropTypes) => {

    const styledStyles = {
        border: `${border ? '1px solid #ccc' : '0'}`,
        borderRadius: "5px",
        padding: `5px ${fullBleed ? '0' : '16px'}`
    }

    const titleStyles = {
        fontSize: "1.3em",
        fontWeight: "bold",
        color: "#444",
        padding: `5px ${fullBleed && border ? '16px' : '0'} 0 ${fullBleed && border ? '16px' : '0'}`
    }

    const noContentStyles = {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        minHeight: "100px",
        width: "100%"
    }

    const noContentMessageStyles = {
        textAlign: "center" as const,
        fontSize: "1.3em",
        fontStyle: "italic",
        color: "#555"
    }

    return <Box sx={styledStyles} className={className}>
        {title ? <Box sx={titleStyles}>{title}</Box> : null}
        {
            noContent ? <Box sx={noContentStyles}>
                <Box sx={noContentMessageStyles}>
                    {noContentMessage ? noContentMessage : 'No Information Available'}
                </Box>
            </Box> : null
        }
        {children}
    </Box>;
}

export default SectionContainer;
