import React from "react";
import {ChargeLineItemDetails, ShipwellEventProps} from "./types";
import classes from './events.module.css';

const ShipmentChargeLineItem = ({event, title}: ShipwellEventProps) => {

    const details = JSON.parse(event.details)["1"] as ChargeLineItemDetails;

    return <div>
        <div className={classes.eventTitle}>{title}</div>
        <div>
            <span className={classes.unitName}>{details.unit_name}</span>
            <span className={classes.label}>Quantity:</span>
            <span className={classes.value}>{details.unit_quantity}</span>
            <span className={classes.label}>Unit Amount:</span>
            <span className={classes.value}>{details.unit_amount}</span>
        </div>
    </div>

}

export default ShipmentChargeLineItem;