import React from "react";
import {Breadcrumbs, Button, Link, Typography} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link as RouterLink} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Load} from "../interfaces/Load";
import {getLoadLabel} from "../util/load-utils";
import {Carrier} from "../interfaces/Carrier";

const Wrapper = styled('div')`
  border-radius: 5px;
  padding: 0;
  //margin: 0 0 10px 0;
  grid-area: breadcrumbs;
  align-self: center;

  display: flex;
`

const TBreadcrumbs = styled(Breadcrumbs)`
  flex-grow: 1;
  align-items: center;
  text-transform: capitalize;
`

type PropTypes = {
    load: Load;
    carrier: Carrier | undefined;
    onContactCarrier?: () => void;
    contactCarrierOpen: boolean;
    onCarrierLookup?: () => void;
    carrierLookupOpen: boolean;
}

const ProcurementBreadcrumbs = ({load,
                                    carrier,
                                    onContactCarrier,
                                    contactCarrierOpen,
                                    onCarrierLookup,
                                    carrierLookupOpen}: PropTypes) => {

    const baseUrl = '/open-loads';
    const loadUrl = `${baseUrl}/${load.id}`;

    const breadcrumbs = [
        <Link
            key={1}
            component={RouterLink}
            to={baseUrl}
            underline={"hover"}
            color={"inherit"}>
            Open Loads
        </Link>,
        <Link
            key={2}
            component={RouterLink}
            to={loadUrl}
            underline={"hover"}
            color={"inherit"}>
            {getLoadLabel(load)}
        </Link>
    ];

    if (carrier) {
        breadcrumbs.push(<Typography key="3" color="text.primary">
            {carrier.name}
        </Typography>);
    }

    return <Wrapper>
        <TBreadcrumbs
            separator={<NavigateNextIcon fontSize="small"/>}
            aria-label="breadcrumb"
            sx={{
                '& .MuiBreadcrumbs-ol': {
                    lineHeight: '36px',
                }
            }}>
            {breadcrumbs}
        </TBreadcrumbs>
        <div className={"contact-links"}>
            {onContactCarrier && carrier ?
                <Button variant={"outlined"}
                        disabled={contactCarrierOpen}
                        onClick={() => onContactCarrier()}>
                    Contact Carrier
                </Button>
                : null}

            {onCarrierLookup && !carrier ?
                <Button variant={"outlined"}
                        color={"secondary"}
                        onClick={() => onCarrierLookup()}>
                    {carrierLookupOpen ? "Hide Carrier Lookup" : "Carrier Lookup" }
                </Button> : null}
        </div>
    </Wrapper>;
}

export default ProcurementBreadcrumbs;
