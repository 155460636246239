import React, {useState} from "react";
import {Box, Button, TextareaAutosize, Typography} from "@mui/material";

// the outer container will handle the error if the update fails
type NoteProps = {
    note?: string;
    updateNote: (string) => void;
    stopNote: string;
    updateStopNote: (string) => void;
}

const FacilityNote = ({note,stopNote, updateNote,updateStopNote}: NoteProps) => {

    const [value, setValue] = useState(note);
    const [stopNotevalue, setStopNoteValue] = useState(stopNote);

    const saveStopNote = () => {
        updateStopNote(stopNotevalue);
    }

    const saveNote = () => {
        updateNote(value);
    }

    //         <MDEditor.Markdown source={value} style={{whiteSpace: 'pre-wrap'}}/>
    return <>
    <div className={"facility-notes-container"}>
        <Typography variant="h5">External Note</Typography>
        <Box sx={{margin: "15px 0"}}>The  note is added to the stop when this facility is assigned to a 
        load. It is typically used for instructions about the facility or other information that the 
        driver needs to know.</Box>
        <TextareaAutosize value={stopNotevalue} onChange={evt => setStopNoteValue(evt.target.value)} minRows={12}/>
        <div className={"action-bar"}>
            <Button variant={"outlined"}
                    onClick={saveStopNote}>Save</Button>
        </div>
    </div>
    <div className={"facility-notes-container"}>
        <Typography variant="h5">Internal Note</Typography>
        <Box sx={{margin: "15px 0"}}>This an internal note about the facility. 
        It is meant to keep internal information about the facility that Torch team members can use to determine how to best pickup/deliver goods.</Box>
        {/* <MDEditor
            height={450}
            className={"markdown-editor"}
            value={value}
            onChange={(v) => setValue(v || '')}
        /> */}
        <TextareaAutosize value={value} onChange={evt => setValue(evt.target.value)} minRows={12}/>
        <div className={"action-bar"}>
            <Button variant={"outlined"}
                    onClick={saveNote}>Save</Button>
        </div>
    </div>;
    </>
}

export default FacilityNote;
