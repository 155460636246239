import React from "react";
import '../Base.css';

interface AccountProps {

}

class Account extends React.Component {

    constructor(props:AccountProps) {
        super(props);
        this.state = {
            name: "Account Name"
        };

        // This binding is necessary to make `this` work in the callback
        // this.handleDateChange = this.handleDateChange.bind(this);
    }

    render() {
        return (<h1>Account</h1>);
    }
}

export default Account;
