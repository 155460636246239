import React, {useState} from 'react';
import {Carrier} from "../interfaces/Carrier";

type PropTypes = {
    carrier: Carrier
}

export const CarrierLevel = ({carrier}: PropTypes) => {

    const [level] = useState<string>('NA');

    return <div className={"carrier-level"}>
        <div className={"title"}>Current Level</div>
        <div className={"level"}>{level}</div>
        <div className={"help"}>
            <a href={"http://torch3pl.com"} target={"_blank"} rel="noreferrer">What's This Mean?</a>
        </div>
    </div>
}
