import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import '../Base.css';
import '../common/Tags.scss'

import {socketEvent$} from "../service/socket";
import Avatar from '@mui/material/Avatar';
import {deepOrange} from '@mui/material/colors';
import {AvatarGroup, Tooltip} from "@mui/material";

const PREFIX = 'Torchers';

const classes = {
  root: `${PREFIX}-root`,
  orange: `${PREFIX}-orange`,
  avatarBox: `${PREFIX}-avatarBox`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  [`& .${classes.orange}`]: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    fontSize: "1.1rem"
  },

  [`& .${classes.avatarBox}`]: {
    margin: "6px"
  }
}));

export const Torchers = ({task, applyFilter, type = "normal"}) => {

  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    const subscription = socketEvent$.subscribe(value => {
      if (value.dataArray && applyFilter(value, task)) {
        console.log("Received message type:" + value.type + " id" + value.content + " task id: " + task.id)
        setMessages(value.dataArray);
      }
    });

    return function cleanup() {
      console.log("Cleanup socket");
      if (subscription) {
        subscription.unsubscribe();
      }
      return;
    }
  }, [task, applyFilter]);

  const getInitials = (name) => {
    if (name) {
      let initials = name.split(' ');
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    }
  }

  if (messages && messages.length > 0) {

    if (type === 'mini') {
      return <>
        <div className={"task-torcher-container"}>
        {messages && messages.map((message, index) => (
            <Tooltip key={message} title={message}>
              <div className={"task-torcher-" + index} style={{height: (100/messages.length) + "%" }} >&nbsp;</div>
            </Tooltip>
        ))}
        </div>
      </>;
    }

    return (
      <div className={"task-rate-torchers"}>
        <div className={classes.root}>
          <AvatarGroup max={10} className={classes.avatarBox}>
            {messages && messages.map((message) => (
              <Tooltip key={message} title={message}>
                <Avatar alt={message} className={classes.orange}>{getInitials(message)}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        </div>
      </div>
    );

  } else {
    return (<Root></Root>);
  }
}
