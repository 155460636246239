import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {FormValues, Shipper, Stop} from "../interfaces/Load";
import {
    Alert,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {Box, styled} from "@mui/system";
import {createFacility, getAppointmentTypes, getFacilityTypes, getShippers} from "./facility-services";
import {SelectMultiField, AppointmentType, FacilityType, PickUpType, DeliveryType, AcceptedEquipmentType, Facility} from "../interfaces/Facility";
import {getLoad} from "../service/loads";
import Avatar from "@mui/material/Avatar";
import {AddCircle, RemoveCircle} from "@mui/icons-material";


const defaultValues = {
    appointmentType:"",
    facilityType: "",
    dock:false,
    pickUpType: "",
    deliveryType: "",
    acceptedEquipment: [],
};

const FacilityContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 600px;
`


const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 400px;
  border: #8C8C8C thin solid;
  margin: 10px;
  padding: 5px;

`


const AddressTextField = styled(TextField)`
  margin: 15px 0;
`

const ContactTextField = styled(TextField)`
  margin: 15px 0;
`

const FacilityCreate = () => {

    const history = useHistory();
    const location = useLocation();
    const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

    const loadId = query.get("load");
    const stopId = query.get("stop");

    const [, setOriginalStop] = useState<Stop>();
    const [stop, setStop] = useState<Record<string, string>>();
    const [shippers, setShippers] = useState<Shipper[]>();
    const [formValues, setFormValues] = useState<FormValues>(defaultValues);
    const [appointmentValues, setAppointmentValues] = useState<AppointmentType[]>([]);
    const [facilityValues, setFacilityValues] = useState<FacilityType[]>([]);
    const [multiSelectFields, setMultiSelectFields] = useState<SelectMultiField[]>([{ id: 0, value: '' }]);
    const [pickUpTypeValues] = useState<PickUpType[]>([{name:'Live', pickupId:0}, {name:'Drop', pickupId:1}, {name:'Live & Drop', pickupId:2}]);
    const [deliveryTypeValues] = useState<DeliveryType[]>([{name:'Live', deliveryId:0}, {name:'Drop', deliveryId:1}, {name:'Live & Drop', deliveryId:2}]);
    const [acceptedEquipmentValues] = useState<AcceptedEquipmentType[]>([{name:'Dry Van Only', acceptedEquipmentId:0}, {name:'Dry Van', acceptedEquipmentId:1}, {name:'Reefer', acceptedEquipmentId:2}, {name:'Reefer Only', acceptedEquipmentId:3}, {name:'Flatbed', acceptedEquipmentId:4}, {name:'Straight Truck', acceptedEquipmentId:5}, {name:'Others', acceptedEquipmentId:6}]);

    // Error Handling
    const [open, setOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    // const [isError,setIsError] = useState<boolean>(false);
    const [nameErrorText, setNameErrorText] = useState("");
    const [streetOneErrorText, setStreetOneErrorText] = useState("");
    const [cityErrorText, setCityErrorText] = useState("");
    const [stateErrorText, setStateErrorText] = useState("");
    const [zipCodeErrorText, setZipCodeErrorText] = useState("");
    const [shipperErrorText, setShipperErrorText] = useState("");
    const [facilityErrorText, setFacilityErrorText] = useState("");
    const [appointmentErrorText, setAppointmentErrorText] = useState("");
    const [acceptedEquipmentErrorText, setAcceptedEquipmentErrorText] = useState("");

    useEffect(() => {
        if (loadId && stopId) {
            getLoad(parseInt(loadId)).then(l => {
                const s = l.stops.find(stop => stop.id === parseInt(stopId));
                setOriginalStop(s);
                const rec = {
                    name: `${l.shipper.name} - ${l.shortDescription}`,
                    streetOne: s ? s.address.streetOne : '',
                    city: s ? s.address.city : '',
                    state: s ? s.address.state : '',
                    zipCode: s ? s.address.zipCode : '',
                    dock: "false",   // these don't seem to set the checkboxes as expected
                    facilityType: "",
                    pickUpType: "",
                    deliveryType: "",
                    acceptedEquipment: ""
                }
                setStop(rec);
                setFormValues({appointmentType:"", dock:false, facilityType: "", pickUpType: "", deliveryType: "",acceptedEquipment: []})
            });
        } else {
            const emptyStop = {
                name: '',
                streetOne: '',
                city: '',
                state: '',
                zipCode: '',
                dock: "false",   // these don't seem to set the checkboxes as expected
                appointment: "true",  // these don't seem to set the checkboxes as expected
                facilityType: "",
                pickUpType: "",
                deliveryType: "",
                acceptedEquipment: ""
            }
            setStop(emptyStop);

            getShippers().then(shippers => {
                setShippers(shippers);
            })

            getAppointmentTypes().then(appointments => {
                setAppointmentValues(appointments);
            })

            getFacilityTypes().then(facilityTypes => {
                setFacilityValues(facilityTypes)
            })

            setFormValues({appointmentType:"",dock:false, facilityType: "", pickUpType: "", deliveryType: "", acceptedEquipment: []})
        }
    }, [loadId, stopId]);

    function updateValue(key: string, value: string): void {
        if (stop) {
            stop[key] = value;
        }
        setStop(stop);
    }

    const handleShipperChange = (id: number, value: string) => {
        const updatedFields = multiSelectFields.map((field) =>
          field.id === id ? { ...field, value } : field
        );
        setMultiSelectFields(updatedFields);
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: typeof value === 'string' ? value.split(',') : value,
        });
    };

    // const handleOtherTextChange = (value: string) => {
        // const newId = acceptedEquipmentValues.length
        // const newValue = {name: value,acceptedEquipmentId: newId}
        // const selection = "acceptedEquipment"
        // setAcceptedEquipmentValues(acceptedEquipmentValues.concat(newValue))
        // setFormValues({
        //     ...formValues,
        //     [selection]: JSON.stringify(newId),
        // });
        // setOtherValue("")
    // };

    const handleCheckChange = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleAddField = () => {
        const newField: SelectMultiField = { id: multiSelectFields.length, value: '' };
        setMultiSelectFields([...multiSelectFields, newField]);
      };

    const handleRemoveField = (id: number) => {
        const updatedFields = multiSelectFields.filter((field) => field.id !== id);
        setMultiSelectFields(updatedFields);
      };

    function saveAndView(goToFacility = true): void {
        const emptyStop: Stop = {
            address: {
                id: -1,
                streetOne: "",
                city: "",
                state: "",
                zipCode: ""
            },
            arrival: new Date(),
            created: new Date(),
            departure: new Date(),
            dropoff: false,
            id: 0,
            pickup: false,
            stopCount: 0,
            timeZoneId: ""
        }

        const getShipperFromId = multiSelectFields.map((field) =>  {
            if (field && shippers) {
                return shippers?.find(shipper => shipper.id === +field.value);
            }
            return undefined;
        })

        const facilityValueFromList = facilityValues.find(facility => facility.id === parseInt(formValues.facilityType));
        const appointmentValueFromList = appointmentValues.find(appointment => appointment.id === parseInt(formValues.appointmentType));
        const shipperNotExist = getShipperFromId.filter(shipper => shipper === undefined).length > 0
        if(stop) {
            if (!stop["name"]) {
                setNameErrorText("Please enter name of Facility");
            } else {
            setNameErrorText("");
            }
            if (!stop["streetOne"]) {
                setStreetOneErrorText("Please enter Street Details");
            } else {
                setStreetOneErrorText("");
            }
            if (!stop["city"]) {
                setCityErrorText("Please enter City Details");
            } else {
                setCityErrorText("");
            }
            if (!stop["state"]) {
                setStateErrorText("Please enter State Details");
            } else {
                setStateErrorText("");
            }
            if (!stop["zipCode"]) {
                setZipCodeErrorText("Please enter Zip Code Details");
            } else {
                setZipCodeErrorText("");
            }
            if (shipperNotExist) {
                setShipperErrorText("One of shipper value is missing");
            } else {
                setShipperErrorText("");
            }
            if (!facilityValueFromList) {
                setFacilityErrorText("Please select Facility Type");
            } else {
                setFacilityErrorText("");
            }
            if (!appointmentValueFromList) {
                setAppointmentErrorText("Please select Appointment Type");
            } else {
                setAppointmentErrorText("");
            }
            if (formValues.acceptedEquipment.length > 0) {
                setAcceptedEquipmentErrorText("");
            } else {
                setAcceptedEquipmentErrorText("Please select Accepted Equipment Type");
            }
            if(!stop["name"] || !stop["streetOne"] || !stop["city"] || !stop["state"] || !stop["zipCode"] || shipperNotExist || !facilityValueFromList || !appointmentValueFromList || formValues.acceptedEquipment.length <= 0) {
                setErrorMessage("Some Fields values are missing!!!");
                setOpen(true);
            } else {
                const facility: Facility = {
                    id: -1,
                    name: stop ? stop["name"] : "",
                    shippers: getShipperFromId,
                    facilityType: facilityValueFromList ? facilityValueFromList : null,
                    appointmentType: appointmentValueFromList ? appointmentValueFromList : null,
                    dock: formValues.dock,
                    address: {
                        id: -1,
                        streetOne: stop ? stop["streetOne"] : "",
                        city: stop ? stop["city"] : "",
                        state: stop ? stop["state"] : "",
                        zipCode: stop ? stop["zipCode"] : ""
                    },
                    internalNote: "",
                    stopNote: "",
                    state: 'INCOMPLETE',
                    pickUpType: pickUpTypeValues[formValues.pickUpType],
                    deliveryType: deliveryTypeValues[formValues.deliveryType],
                    acceptedEquipmentList: acceptedEquipmentValues.filter(item => formValues.acceptedEquipment.includes(item.name))
                    // acceptedEquipment : []
                }
        
                createFacility(stop ? stop : emptyStop, facility).then(newFacility => {
                    if (goToFacility) {
                        history.push(`/facilities/${newFacility.id}`);
                    } else {
                        history.push(`/facilities`);
                    }
                }).catch((e: Response) => makeErrorText(e).then(text => {
                    setErrorMessage(text);
                    setOpen(true);
                }));

            }
        }
        
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setErrorMessage('');
    };

    async function makeErrorText(response: Response) {
        let body: any = await response.json();
        return `An error occurred during creating facility. Error Message: ${(body.error)}`;
    }

    function save(): void {
        saveAndView(false);
    }

    function cancel() {
        history.goBack();
    }

    const addContact = () => {

    }

    if (stop) {
        return <div className={"edit-facility-container"}>
            <Typography variant="h4">New Facility</Typography>
            <FacilityContainer>
            <FormContainer>
                <AddressTextField required name="named-input" id="named-input" label="Name" variant="outlined"
                                  defaultValue={stop.name}
                                //   error={stop.name === ""}
                                //   helperText={stop.name === "" ? 'Empty!' : ' '}
                                onBlur={() => stop.name === "" ? setNameErrorText("Please enter name of Facility") : setNameErrorText("")}
                                error={!!nameErrorText}
                                helperText={nameErrorText}
                                  onChange={(evt) => updateValue("name", evt.target.value)}/>
                <AddressTextField required id="street-address-input" label="Street Address" variant="outlined"
                                  defaultValue={stop.streetOne}
                                  onBlur={() => stop.streetOne === "" ? setStreetOneErrorText("Please enter Street Details") : setStreetOneErrorText("")}
                                  error={!!streetOneErrorText}
                                  helperText={streetOneErrorText}
                                  onChange={(evt) => updateValue("streetOne", evt.target.value)}/>
                <AddressTextField required id="city-input" label="City" variant="outlined"
                                  defaultValue={stop.city}
                                  onBlur={() => stop.city === "" ? setCityErrorText("Please enter City Details") : setCityErrorText("")}
                                  error={!!cityErrorText}
                                  helperText={cityErrorText}
                                  onChange={(evt) => updateValue("city", evt.target.value)}/>
                <AddressTextField required helperText={"Must be 2 characters"} id="state-input" label="State" variant="outlined"
                                  defaultValue={stop.state}
                                  onBlur={() => stop.state === "" ? setStateErrorText("Please enter State Details") : setStateErrorText("")}
                                  error={!!stateErrorText}
                                //   helperText={stateErrorText}
                                  onChange={(evt) => updateValue("state", evt.target.value)}/>
                <AddressTextField required id="zipCode-input" label="Zip Code" variant="outlined"
                                  defaultValue={stop.zipCode}
                                  onBlur={() => stop.zipCode === "" ? setZipCodeErrorText("Please enter Zip Code Details") : setZipCodeErrorText("")}
                                  error={!!zipCodeErrorText}
                                  helperText={zipCodeErrorText}
                                  onChange={(evt) => updateValue("zipCode", evt.target.value)}/>

                {shippers ?
                    <div>
                        <InputLabel id="shipper-label">Client</InputLabel>
                        {multiSelectFields.map((field) => (
                        <div key={field.id}>
                        <Select
                            labelId="shipper-label"
                            sx={{marginBottom: "15px", width:"100%"}}
                            error={!!shipperErrorText}
                            onBlur={() => field.value === "" ? setShipperErrorText("Please select Shipper") : setShipperErrorText("")}
                            value={field.value}
                            onChange={(e) => handleShipperChange(field.id, e.target.value as string)}
                            endAdornment={
                                <InputAdornment position="start">
                                    <Button
                                        className={"select-client"}
                                        color="primary"
                                        startIcon={<AddCircle className={"icon-size"} />}
                                        onClick={handleAddField}
                                    >
                                    </Button>
                                    {multiSelectFields.length > 1 ?
                                    <Button
                                        className={"select-client"}
                                        color="secondary"
                                        startIcon={<RemoveCircle className={"icon-size"} />}
                                        onClick={() => handleRemoveField(field.id)}
                                    >
                                    </Button> : ""}
                                </InputAdornment>
                                
                              }
                        >
                            <MenuItem value="" selected={true}>
                                <em>Select a Client</em>
                            </MenuItem>
                            {shippers?.map(option => {
                                return (
                                    <MenuItem value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {/* <FormHelperText style={{color: '#d32f2f'}}>{shipperErrorText}</FormHelperText> */}
                        </div>
                        ))}
                        <FormHelperText style={{color: '#d32f2f'}}>{shipperErrorText}</FormHelperText>
                    </div>
                    : <></>
                }

                <FormControl sx={{marginTop:3}}>
                    <InputLabel id="facilityType">Facility Type</InputLabel>
                    <Select
                        id="facilityType"
                        name="facilityType"
                        value={formValues.facilityType}
                        label="Facility Type"
                        onChange={(e,value) => handleInputChange(e)}
                        error={!!facilityErrorText}
                        onBlur={() => formValues.facilityType === "" ? setFacilityErrorText("Please select Facility Type") : setFacilityErrorText("")}
                    >
                        {facilityValues?.map(option => {
                            return (
                                <MenuItem value={option.id}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText style={{color: '#d32f2f'}}>{facilityErrorText}</FormHelperText>
                </FormControl>

                <FormControl sx={{marginTop:3}}>
                    <InputLabel id="appointmentType">Appointment Type</InputLabel>
                    <Select
                        id="appointmentType"
                        name="appointmentType"
                        label="Appointment Type"
                        value={formValues.appointmentType}
                        onChange={handleInputChange}
                        error={!!appointmentErrorText}
                        onBlur={() => formValues.appointmentType === "" ? setAppointmentErrorText("Please select Appointment Type") : setAppointmentErrorText("")}
                    >
                        {appointmentValues?.map(option => {
                            return (
                                <MenuItem value={option.id}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText style={{color: '#d32f2f'}}>{appointmentErrorText}</FormHelperText>
                </FormControl>

                <FormControl sx={{marginTop:3}}>
                    <InputLabel id="pickUpType">Pick Up Type</InputLabel>
                    <Select
                        id="pickUpType"
                        name="pickUpType"
                        label="Pick Up Type"
                        value={formValues.pickUpType}
                        onChange={handleInputChange}
                    >
                        {pickUpTypeValues?.map(option => {
                            return (
                                <MenuItem value={option.pickupId}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop:3}}>
                    <InputLabel id="deliveryType">Delivery Type</InputLabel>
                    <Select
                        id="deliveryType"
                        name="deliveryType"
                        label="Delivery Type"
                        value={formValues.deliveryType}
                        onChange={handleInputChange}
                    >
                        {deliveryTypeValues?.map(option => {
                            return (
                                <MenuItem value={option.deliveryId}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop:3}}>
                    <InputLabel id="acceptedEquipment">Accepted Equipment</InputLabel>
                    <Select
                        id="acceptedEquipment"
                        name="acceptedEquipment"
                        label="Accepted Equipment"
                        value={formValues.acceptedEquipment}
                        multiple
                        error={!!acceptedEquipmentErrorText}
                        onBlur={() => formValues.acceptedEquipment.length > 0 ? setAcceptedEquipmentErrorText("") : setAcceptedEquipmentErrorText("Please select Accepted Equipment")}
                        // input={<OutlinedInput label="Tag" />}
                        // renderValue={(selected) => selected.join(', ')}
                        onChange={handleInputChange}
                    >
                        {acceptedEquipmentValues?.map(option => {
                            return (
                                <MenuItem key={option.acceptedEquipmentId} value={option.name}>
                                    <Checkbox checked={formValues.acceptedEquipment.indexOf(option.name) > -1} />
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText style={{color: '#d32f2f'}}>{acceptedEquipmentErrorText}</FormHelperText>
                </FormControl>

                {/* {(formValues.acceptedEquipment !== "") &&  acceptedEquipmentValues[formValues.acceptedEquipment].name === 'Others' && (
                    <TextField
                    className={"add-other-value"}
                    label="Add Other Value"
                    value={otherValue}
                    onChange={(e) => setOtherValue(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                        className={"select-client"}
                                        color="primary"
                                        startIcon={<AddCircle className={"icon-size"} />}
                                        onClick={(e) => handleOtherTextChange(otherValue)}
                                    ></Button>
                            </InputAdornment>
                        ),
                    }}
                    >
                    </TextField>
                )} */}

                <FormGroup sx={{marginBottom: "15px"}}>
                    <FormControl>

                        <FormControlLabel control={<Checkbox name="dock"
                                                             value={formValues.dock}
                                                             checked={formValues.dock}
                                                             onChange={handleCheckChange}/>}
                                          label="Dock"/>
                    </FormControl>
                </FormGroup>

                <div className={"action-container"}>
                    <Button variant={"outlined"}
                            color={"success"}
                            sx={{marginRight: "10px"}}
                            onClick={(evt) => saveAndView()}>Save and View</Button>

                    <Button variant={"outlined"}
                            color={"primary"}
                            sx={{marginRight: "10px"}}
                            onClick={(evt) => save()}>Save</Button>

                    <Button variant={"outlined"}
                            color={"error"}
                            onClick={(evt) => cancel()}>Cancel</Button>
                </div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </FormContainer>
            <FormContainer>
                <Typography variant="h5">Contacts</Typography>
                <Box>
                <Chip avatar={<Avatar>M</Avatar>} label="Avatar" />
                <Chip avatar={<Avatar>S</Avatar>} label="Avatar" />
                </Box>
                <ContactTextField  required label="First Name" variant="outlined"></ContactTextField>
                <ContactTextField  required label="Last Name" variant="outlined"></ContactTextField>
                <ContactTextField  required label="Email" variant="outlined"></ContactTextField>
                <ContactTextField  required label="Phone" variant="outlined"></ContactTextField>
                <ContactTextField  required label="Role" variant="outlined"></ContactTextField>

                <Box>
                <Button variant={"outlined"}
                        color={"primary"}
                        onClick={(evt) => addContact()}>Add Contact</Button>
                <Button variant={"outlined"}
                        color={"error"}
                        sx={{marginLeft:1}}
                        onClick={(evt) => addContact()}>Delete Contact</Button>
                </Box>
            </FormContainer>
            </FacilityContainer>
        </div>
    }

    return <div className={"edit-facility-container"}>
        <h1>Add Facility - No Stop</h1>
    </div>
}

export default FacilityCreate;
