import React from "react";
import {Load} from "../interfaces/Load";

type Props = {
    load: Load;
}

const WorkflowContext = ({load}:Props) => {

    return <div>Workflow Context!</div>;
}

export default WorkflowContext;