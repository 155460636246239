import React, {useEffect, useState} from "react";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import {List, ListItemButton, ListItemText} from "@mui/material";
import {Torchers} from "../common/Torchers";
import Moment from "react-moment";
import {datCarriersForLoadId} from "../service/loads";
import {Load} from "../interfaces/Load";
import {FeatureIcons, ReasonIcons, ReminderIcons} from "./CarrierIcons";

type ParamTypes = {
    load: Load;
    carrierSelected: (SuggestedCarrier) => void;
}

const DatCarrierList = ({load, carrierSelected}: ParamTypes) => {

    const [datCarriers, setDatCarriers] = useState<SuggestedCarrier[]>([]);

    useEffect(() => {
        datCarriersForLoadId(load.id).then(carriers => {
            setDatCarriers(carriers)
        });
    }, [load]);

    const activityFilter = (message, carrier) => {
        return message.type === "CARRIER" && parseInt(message.content) === carrier.id;
    };

    return <List className={"carrier-list"}>
        {datCarriers?.map((carrier, idx) => {
            return (
                <ListItemButton key={carrier.id}
                                onClick={(evt) => carrierSelected(carrier)}>
                    <ListItemText>
                        <div className={"list-item dat"}>
                            <div className={"torchers"}>
                                <Torchers type={"mini"} task={carrier.carrier}
                                          applyFilter={activityFilter}/>
                            </div>

                            <div className={"carrier-name"}>{carrier.carrier.name}</div>

                            <div className={"carrier-info"}>
                                {`${carrier.comments}`}
                            </div>
                            <div className={"carrier-icons"}>
                                <FeatureIcons suggestedCarrier={carrier}/>
                                <ReasonIcons suggestedCarrier={carrier}/>
                                <ReminderIcons suggestedCarrier={carrier}/>
                            </div>
                            <div className={"not-in-use"}>
                                <div className={"value"}>&nbsp;</div>
                            </div>
                            <div className={"last-updated"}>
                                <div className={"std-value"}><Moment fromNow>{carrier.postingLastUpdated}</Moment></div>
                            </div>
                            <div className={"location"}>
                                <div className={"std-value"}>{carrier.originCity}, {carrier.originState}</div>
                            </div>
                            <div className={"distance-origin"}>
                                <div className={"std-value"}>{carrier.originDistance}</div>
                            </div>
                        </div>
                    </ListItemText>
                </ListItemButton>);
        })}
    </List>

}

export default DatCarrierList;