import React from "react";
import {RateWithHistorical} from "../interfaces/RateData";
import {Load} from "../interfaces/Load";
import "./LaneRate.scss";
import {formatRate, getMargin} from "../util/rate-util";
import SectionContainer from "../common/SectionContainer";

type PropTypes = {
    rate: RateWithHistorical | undefined;
    load: Load | undefined;
}

const LaneRate = ({load, rate}: PropTypes) => {

    if (load && rate) {
        const MoneyOnLoad = () => {
            if (load.maxBuy) {
                return <>
                    <div className={"on-load-label"}>Max Buy</div>
                    <div className={`on-load-value ${moneyClass()}`}>
                        {formatRate(Number(load.maxBuy) / 100)}
                    </div>
                </>;
            } else {
                return <>
                    <div className={"on-load-label"}>On Load</div>
                    <div className={`on-load-value ${moneyClass()}`}>
                        {formatRate(Number(load.customerCost) / 100)}
                    </div>
                </>;
            }
        }

        const moneyClass = () => {
            const margin = getMargin(rate, parseInt(load.customerCost));
            switch (true) {
                case margin >= 0.10:
                    return "margin-good";
                case margin >= 0.05:
                    return "margin-medium";
                default:
                    return "margin-bad";
            }
        }

        const price = rate.rate.rate + rate.rate.fuelSurcharge;
        const priceHigh = rate.rate.rateHigh + rate.rate.fuelSurcharge;
        const priceLow = rate.rate.rateLow + rate.rate.fuelSurcharge;

        return <SectionContainer title={"Load Financials"} className={"lane-panel"}>
            <div className={"lane-rate"}>
                <MoneyOnLoad/>

                <div className={"dat-rate-label"}>Rate</div>
                <div className={"dat-rate-value"}>
                    <div className={"price"}>{formatRate(price)}</div>
                    <div className={"price-high"}>{formatRate(priceHigh, false)}</div>
                    <div className={"price-low"}>{formatRate(priceLow, false)}</div>
                </div>

                <div className={"miles-label"}>Miles</div>
                <div className={"miles-value"}>{load.miles}</div>

                <div className={"per-mile-label"}>Per Mile</div>
                <div className={"per-mile-value"}>{formatRate(price / load.miles)}</div>
            </div>
        </SectionContainer>
    }

    return <SectionContainer title={"Load Financials"} noContent={true} noContentMessage={"No Load / Rate Data"}/>
}

export default LaneRate;
