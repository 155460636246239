import React, {useState} from "react";
import {List, ListItemButton, ListItemText} from "@mui/material";
import {Torchers} from "../common/Torchers";
import {FeatureIcons, ReasonIcons, ReminderIcons} from "./CarrierIcons";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import {isFuture} from "date-fns";
import {getCarriersForLoad} from "../service/loads";
import {useAbortableEffect} from "../util/hooks";
import Loading from "../common/Loading";

type ComponentParams = {
    loadId: number;
    carrierSelected: (carrier: SuggestedCarrier) => void;
}

const TorchCarrierList = ({loadId, carrierSelected}: ComponentParams) => {

    const [carriers, setCarriers] = useState<SuggestedCarrier[]>();
    const [carriersLoading, setCarriersLoading] = useState(false);

    // Carrier sorting by feedback
    const sortCarriersFromFeedback = (carriers: SuggestedCarrier[]) => {
        const shouldMoveToBottom = sc => sc.feedback.some(f => sc.carrier.id === f.carrier.id &&
            f.expiration && isFuture(new Date(f.expiration)));

        let topCarriers: SuggestedCarrier[] = []
        let bottomCarriers: SuggestedCarrier[] = [];

        carriers.forEach(sc => shouldMoveToBottom(sc) ? bottomCarriers.push(sc) : topCarriers.push(sc));
        return topCarriers.concat(bottomCarriers);
    }

    useAbortableEffect((status) => {
        if (loadId) {
            setCarriersLoading(true);
            getCarriersForLoad(loadId.toString())
                .then(carriers => {
                    if (!status.aborted) {
                        setCarriers(sortCarriersFromFeedback(carriers));
                        setCarriersLoading(false);
                    }
                });
        }
    }, [loadId]);

    const activityFilter = (message, carrier) => {
        return message.type === "CARRIER" && parseInt(message.content) === carrier.id;
    };

    if(carriersLoading) {
        return <Loading minHeight={"400px"} />;
    }

    return <List className={"carrier-list"}>
        {carriers?.map((carrier, idx) => {
            return (
                <ListItemButton key={carrier.carrier.id}
                                onClick={() => carrierSelected(carrier)}>
                    <ListItemText>
                        <div className={"list-item carrier"}>
                            <div className={"torchers"}>
                                <Torchers type={"mini"} task={carrier.carrier}
                                          applyFilter={activityFilter}/>
                            </div>

                            <div className={"carrier-name"}>{carrier.carrier.name}</div>

                            <div className={"carrier-info"}>
                                {`${carrier.carrier.mailingAddress?.city}, ${carrier.carrier.mailingAddress?.state}`}
                            </div>
                            <div className={"carrier-icons"}>
                                <FeatureIcons suggestedCarrier={carrier}/>
                                <ReasonIcons suggestedCarrier={carrier}/>
                                <ReminderIcons suggestedCarrier={carrier}/>
                            </div>
                            <div className={"total-loads"}>
                                <div className={"value"}>{carrier.torchLoads}</div>
                            </div>
                            <div className={"recent-loads"}>
                                <div className={"value"}>{carrier.recentLoads}</div>
                            </div>
                            <div className={"average-margin"}>
                                <div
                                    className={"value"}>{Math.round((carrier.averageMargin || 0) * 100) / 10 + "%"}</div>
                            </div>
                            <div className={"total-power-units"}>
                                <div className={"value"}>{carrier.totalPowerUnits}</div>
                            </div>
                        </div>
                    </ListItemText>
                </ListItemButton>);
        })}
    </List>
}

export default TorchCarrierList;