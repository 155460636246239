// import { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Popover,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import CloseIcon from "@mui/icons-material/Close";

const TicketCancel = (props) => {
  const { handleClose ,open,popOver} = props;

  return (
    <>
      <Popover
        className="pop_over"
        id={popOver}
        open={open}
        // cancelTicketPopup={cancelTicketPopup}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorPosition={{ top: 315, left: 1300 }}
        PaperProps={{ style: { width: "264px" } }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Close Tickets
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 6,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ fontSize: 18 }} />
          </IconButton>
          <FormControl fullWidth>
            <InputLabel id="select-label">Reason for Canceling</InputLabel>
            <Select label="Reason for Canceling">
              <MenuItem value="option1">Option 1</MenuItem>
              <MenuItem value="option2">Option 2</MenuItem>
              <MenuItem value="option3">Option 3</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Textarea minRows={3} placeholder="Comment" sx={{ mt: 2 }} />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Submit
            </Button>
          </FormControl>
          <FormControl fullWidth>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
          </FormControl>
        </DialogContent>
      </Popover>
    </>
  );
};

export default TicketCancel;
