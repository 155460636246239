import React from "react"
import {styled} from "@mui/system";

type LoadingParams = {
    width?: string;
    minHeight?: string;
}

const LoadingSvg = ({width}: LoadingParams) => {
    return (
        <svg height="200" style={{"margin": "auto", "background": "0 0", "display": "block", "shapeRendering": "auto"}}
             width={width} preserveAspectRatio="xMidYMid" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#281300"
                    strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
                <animateTransform attributeName="transform" type="rotate" dur="1.3157894736842106s"
                                  repeatCount="indefinite"
                                  keyTimes="0;1" values="0 50 50;360 50 50"/>
            </circle>
            <circle cx="50" cy="50" r="23" strokeWidth="8" stroke="#e26c00"
                    strokeDasharray="36.12831551628262 36.12831551628262" strokeDashoffset="36.128" fill="none"
                    strokeLinecap="round">
                <animateTransform attributeName="transform" type="rotate" dur="1.3157894736842106s"
                                  repeatCount="indefinite"
                                  keyTimes="0;1" values="0 50 50;-360 50 50"/>
            </circle>
        </svg>);
}

/**
 * Loading component that will expand to fill its containing div
 */
const Loading = ({width = "150px", minHeight = "150px"}: LoadingParams) => {

    const LoadingParent = styled('div')`
      width: 100%;
      height: 100%;
      min-height: ${minHeight};

      display: flex;
      justify-content: center;
      align-items: center;

      opacity: 75%;
    `

    return <LoadingParent>
        <LoadingSvg width={width}/>
    </LoadingParent>;
}

export default Loading;