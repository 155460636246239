import React from 'react';
import {formatPhoneNumber} from "../util/general";
import {Address} from "../interfaces/Address";
import {Chip} from "@mui/material";
import {NEW} from "../constants/CarrierStatus";
import SectionContainer from "../common/SectionContainer";
import {styled} from "@mui/system";
import "../carrier/Carriers.scss";
import {Torchers} from "../common/Torchers";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import Moment from "react-moment";
import {findAndFormatPhoneNumber} from "../util/carrier-utils";

type AddressProp = {
    address: Address;
    title: string;
}

type PropTypes = {
    suggestedCarrier: SuggestedCarrier;
}

const CarrierDetails = ({suggestedCarrier}: PropTypes) => {

    const carrier = suggestedCarrier.carrier;

    const AddressDisplay = ({address}) => {
        return <div>
            <div>{address.streetOne}</div>
            <div>{address.city}, {address.state} {address.zipCode}</div>
            <div>({address.metro ? address.metro.label : 'No Metro Specified'})</div>
        </div>
    }

    const AddressPanel = ({address, title}: AddressProp) => {
        if (address) {
            return (<div className={"content-panel"}>
                <div className={"address-title"}>{title}</div>
                <AddressDisplay address={address}/>
            </div>);
        }

        return null;
    }

    const TChip = styled(Chip)`
    `

    const Title = () => {
        const activityFilter = (message, carrier) => {
            return message.type === "CARRIER" && parseInt(message.content) === carrier.id;
        };
        return <div className={"carrier-title"}>
            <Torchers type={"mini"} task={carrier} applyFilter={activityFilter}/>
            <div className={"title"}>{carrier.name}</div>
            <TChip size="small"
                   color="primary"
                   variant={"outlined"}
                   label={carrier.status || NEW}/>
        </div>
    }

    const DatSuggested = () => {
        return <div className={"content-container suggested"}>
            <div className={"content-panel"}>
                <div className={"panel-title"}>Suggested Carrier</div>
                <div>
                    <span className={"source"}>Reason</span>
                    <span className={"suggested-value"}>DAT Equipment Search</span>
                </div>
                <div>
                    <span className={"generic"}>Callback Information</span>
                    <span className={"suggested-value callback"}>{findAndFormatPhoneNumber(suggestedCarrier.callbackDetails)}</span>
                </div>
                <div>
                    <span className={"generic"}>Current Location</span>
                    <span className={"suggested-value"}>{suggestedCarrier.originCity}, {suggestedCarrier.originState}
                        &nbsp;({suggestedCarrier.originDistance} Miles)
                    </span>
                </div>
                <div><span className={"generic"}>Last Updated</span> <span className={"suggested-value"}><Moment fromNow>{suggestedCarrier.postingLastUpdated}</Moment></span></div>
                {suggestedCarrier.comments ? <div><span className={"generic"}>Comments</span> <span className={"suggested-value"}>{suggestedCarrier.comments}</span></div> : null }
            </div>
        </div>
    }

    const TorchSuggestedTitle = () => {
        let reasonText = "Past Torch Carrier";
        if (suggestedCarrier.reason === "past_load_in_lane") {
            reasonText = "Carrier has delivered load(s) in this lane.";
        } else if (suggestedCarrier.reason === "past_load_from_orig") {
            reasonText = "Carrier has delivered load(s) from the same origin.";
        } else if (suggestedCarrier.reason === "preferred_carrier") {
            reasonText = "Preferred carrier program designation";
        } else if(suggestedCarrier.reason === "carrier_geo_obs") {
            reasonText = "Observed near the origin";
        }
        return <span className={"suggested-value"}>{reasonText}</span>;
    }

    const TorchSuggested = () => {
        return <div className={"content-container"}>
            <div className={"content-panel"}>
                <div className={"panel-title"}>Suggested Carrier</div>
                <div><span className={"source"}>Reason(s)</span><TorchSuggestedTitle /></div>
                <div><span className={"source"}>Past Loads (recent / total / margin)</span>
                    <span className={"suggested-value"}>
                        {suggestedCarrier.recentLoads} / {suggestedCarrier.torchLoads} ({Math.round((suggestedCarrier.averageMargin || 0) * 100) / 10 + "%"})
                    </span>
                </div>
                <div><span className={"generic"}>Power Units</span> <span className={"suggested-value"}>{suggestedCarrier.totalPowerUnits}</span></div>
            </div>
        </div>
    }

    return (
        <SectionContainer title={<Title/>} className={"carrier-details"}>
            <div>
                <div>
                    <div className={"content-container"}>
                        <div className={"content-panel"}>
                            <div><span className={"dot"}>DOT</span> {carrier.dotNumber}</div>
                            <div><span className={"mc"}>MC</span> {carrier.mcNumber}</div>
                            <div><span className={"phone"}>Phone Number</span> {formatPhoneNumber(carrier.phoneNumber)}
                            </div>
                            <div><span className={"status"}>Status</span> {carrier.status}</div>
                            <div><span className={"notes"}>Shipwell Notes</span> {carrier.notes}</div>
                        </div>
                        <AddressPanel address={carrier.mailingAddress} title={"Mailing Address"}/>
                        <AddressPanel address={carrier.billingAddress} title={"Billing Address"}/>
                    </div>
                </div>
                {suggestedCarrier.source === "torch" ? <TorchSuggested /> : <DatSuggested /> }
            </div>
        </SectionContainer>
    );
}

export default CarrierDetails;
