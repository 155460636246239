import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={15} stroke="#EBEEF2" strokeWidth={2} />
    <path
      d="M16.6666 12.6668H15.3333V15.3335H12.6666V16.6668H15.3333V19.3335H16.6666V16.6668H19.3333V15.3335H16.6666V12.6668ZM15.9999 9.3335C12.3199 9.3335 9.33325 12.3202 9.33325 16.0002C9.33325 19.6802 12.3199 22.6668 15.9999 22.6668C19.6799 22.6668 22.6666 19.6802 22.6666 16.0002C22.6666 12.3202 19.6799 9.3335 15.9999 9.3335ZM15.9999 21.3335C13.0599 21.3335 10.6666 18.9402 10.6666 16.0002C10.6666 13.0602 13.0599 10.6668 15.9999 10.6668C18.9399 10.6668 21.3333 13.0602 21.3333 16.0002C21.3333 18.9402 18.9399 21.3335 15.9999 21.3335Z"
      fill="#94A3B8"
    />
  </svg>
);
export default SVGComponent;