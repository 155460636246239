import React, {EffectCallback, FC, useEffect, useRef, useState} from "react";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import {SkinnyLoad} from "../interfaces/Load";

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

type PropTypes = {
    load: SkinnyLoad
}

// source of inspiration:
// https://codesandbox.io/embed/github/googlemaps/js-samples/tree/sample-react-map
// https://developers.google.com/maps/documentation/javascript/react-map
// https://jsfiddle.net/u9no8te4/
//
// probably going to be too expensive - but it works and can use it
//
const GoogleRouteMap = ({load}: PropTypes) => {

    // AIzaSyBqb2IH-VYq0hSW5-cVSZuubkDgCAtRf50
    return (
        <div style={{width: "100%", height: "300px"}}>
            <div style={{display: "flex", height: "100%"}}>
                <Wrapper apiKey={"AIzaSyBqb2IH-VYq0hSW5-cVSZuubkDgCAtRf50"} render={render}>
                    <Map style={{flexGrow: "1", height: "100%"}}
                         load={load}/>
                </Wrapper>
            </div>
        </div>
    );
}

interface MapProps extends google.maps.MapOptions {
    load: SkinnyLoad;
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
}

const Map: FC<MapProps> = ({
                               load,
                               onClick,
                               onIdle,
                               style,
                               ...options
                           }) => {

    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            const originAddr = load.stops[0].address;
            const destAddr = load.stops[load.stops.length - 1].address;

            if (originAddr.latitude && destAddr.latitude) {
                const originPosition = new google.maps.LatLng(originAddr.latitude, originAddr.longitude);
                const destPosition = new google.maps.LatLng(destAddr.latitude, destAddr.longitude);

                const createdMap = new window.google.maps.Map(ref.current, {},)
                setMap(createdMap);
                const dirService = new google.maps.DirectionsService();
                const dirDisplay = new google.maps.DirectionsRenderer({map: createdMap});

                dirService.route({
                    origin: originPosition,
                    destination: destPosition,
                    avoidTolls: false,
                    avoidHighways: false,
                    travelMode: google.maps.TravelMode.DRIVING
                }).then((directionsResult) => {
                    dirDisplay.setDirections(directionsResult);
                    createdMap.setCenter(directionsResult.routes[0].bounds.getCenter());
                });
            }
        }
    }, [ref, map, load.stops]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (<>
        <div ref={ref} style={style}/>
    </>);
};

/*
const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a: any, b: any) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
        ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        // TODO extend to other types

        // use fast-equals for other objects
        return deepEqual(a, b);
    }
);
*/


function useDeepCompareMemoize(value: any) {
    const ref = useRef();

/*    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }*/

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback: EffectCallback,
    dependencies: any[]) {
    useEffect(callback, [callback, ...dependencies.map(useDeepCompareMemoize)]);
}

export default GoogleRouteMap;
