import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import Tooltip from "@mui/material/Tooltip";
import {Assessment, Block, Mouse, PhoneMissed, Recommend} from "@mui/icons-material";
import {format, formatDistance, isFuture} from "date-fns";
import React from "react";

type ReasonIconParam = {
    suggestedCarrier: SuggestedCarrier;
}

const ReasonIcons = ({suggestedCarrier}: ReasonIconParam) => {
    const formatReason = (reason: string) => {
        if (reason === "past_load_in_lane") {
            return "Carrier has delivered load(s) in this lane.";
        } else if (reason === "past_load_from_orig") {
            return "Carrier has delivered load(s) from the same origin.";
        } else if (reason === "preferred_carrier") {
            return "Preferred carrier program designation";
        } else if(reason === "carrier_geo_obs") {
            return "Observed near the origin"
        } else if(reason === "capacity") {
            return "Reported available capacity for this origin on the pickup date"
        } else {
            return reason;
        }
    };

    return (suggestedCarrier.reason) ?
        <Tooltip key={suggestedCarrier.id + suggestedCarrier.reason}
                 placement={"top"}
                 className={"feedback-icon"}
                 title={formatReason(suggestedCarrier.reason)}>
            {suggestedCarrier.reason === "preferred_carrier" ? <Recommend fontSize={"small"} htmlColor={"#494"}/> :
                <Assessment fontSize="small" htmlColor="#494"/>}
        </Tooltip>
        : null;
}

type FeatureIconParam = {
    suggestedCarrier: SuggestedCarrier;
}

const FeatureIcons = ({suggestedCarrier}: FeatureIconParam) => {
    return suggestedCarrier.datRat ?
        <Tooltip title={"Carrier has posted on DAT."}>
            <Mouse sx={{width: 16, height: 16}} htmlColor={"#0046df"}/>
        </Tooltip>
        : null;
}

type ReminderIconParam = {
    suggestedCarrier: SuggestedCarrier;
}

const ReminderIcons = ({suggestedCarrier}: ReminderIconParam) => {
    return <>
        {suggestedCarrier.feedback?.map(f => {
            if (f.type === "NO_ANSWER" && f.expiration && isFuture(new Date(f.expiration))) {
                return <Tooltip key={f.id}
                                placement={"top"}
                                className={"feedback-icon"}
                                title={`Carrier did not answer ${formatDistance(new Date(), new Date(f.created))} ago.`}>
                    <PhoneMissed fontSize="small" color="secondary"/>
                </Tooltip>

            } else if (f.type === "WARNING") {
                return <Tooltip key={f.id}
                                placement={"top"}
                                className={"feedback-icon"}
                                title={`${f.user?.name || 'A Torcher'} said "${f.notes}" on ${format(new Date(f.created), 'MM/dd/yyyy')}`}>
                    <Block fontSize="small" color="warning"/>
                </Tooltip>
            }

            return null;
        })}</>;
}

export {
    ReasonIcons,
    FeatureIcons,
    ReminderIcons
}
