import React from "react";
import {styled} from "@mui/system";
import {LabelValuePair} from "./types";

type Props = {
    past: LabelValuePair[];
    current: LabelValuePair[];
}

type EventTimeDisplayProps = {
    label?: string;
    value: string;
}


const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Column = styled('div')`
  color: #555;
`

const PastTitle = styled('div')`
  color: #8b000080;
  font-weight: bold;
`

const CurrentTitle = styled('div')`
  color: #02302080;
  font-weight: bold;
`

type ListItemProps = {
    entries: LabelValuePair[];
}

const ValueLabel = styled('span')`
  white-space: nowrap;
  color: #333;
  margin-right: 10px;
  font-size: 0.9em;
`

const Value = styled('span')`
  font-style: italic;
  color: #333;
  font-size: 0.9em;
`

const NoData = styled('div')`
  white-space: nowrap;
  font-style: italic;
  font-size: 0.9em;
`

const PastCurrentData = ({past, current}: Props) => {

    const EventTimeLabel = ({label, value}: EventTimeDisplayProps) => {
        return <div>
            {label ? <ValueLabel>{label}</ValueLabel> : ''}
            <Value>{value}</Value>
        </div>
    }

    const ListItems = ({entries}:ListItemProps) => {

        if (entries.length === 0) {
            return <NoData>No Data Present</NoData>
        }

        return <>{
            entries.map((s, idx) =>
                <EventTimeLabel key={idx} label={s.label} value={s.value}/>)
        }</>
    }

    return <Container>
        <Column>
            <PastTitle>Previous</PastTitle>
            <ListItems entries={past} />
        </Column>
        <Column>
            <CurrentTitle>Current</CurrentTitle>
            <ListItems entries={current} />
        </Column>
    </Container>
}

export default PastCurrentData;