import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import "./CarrierSourceList.scss";
import {fetchCarrierSource, fetchCarrierSources} from "../service/carriers";
import {CarrierSource} from "../interfaces/CarrierSource";
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import {green, red} from "@mui/material/colors";
import torchLogo from '../images/torch-logo.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {socketEvent$} from "../service/socket";
import {LocalShipping, PhoneInTalk} from "@mui/icons-material";
import {styled} from "@mui/system";

const ColumnHeader = styled(TableCell)({
    textAlign: "left",
    fontSize: "0.9rem",
    whiteSpace: "nowrap"
})

const Content = styled(TableCell)({
    textAlign: "left"
})

const HeaderContent = styled('div')({})

const CarrierSourceList = () => {

    const [carrierSourceLists, setCarrierSourceLists] = useState<CarrierSource[]>();
    const [carrierSources, setCarrierSources] = useState<CarrierSource[]>();

    useEffect(() => {
        fetchCarrierSources().then(sources => setCarrierSourceLists(sources));
    }, []);


    useEffect(() => {
        const subscription = socketEvent$.subscribe(carrierSourceEvent => {
            if (carrierSourceEvent && carrierSourceEvent.type === "CARRIER_SOURCE_UPDATE" && carrierSourceEvent.content) {
                //TODO: Do we need a carrierSourceStatus object?
                let update = JSON.parse(carrierSourceEvent.content);
                setCarrierSources((oldFullList) => {
                    return oldFullList?.map(carrier => {
                        if (carrier.dotNumber === parseInt(update.dotNumber)) {
                            carrier.actedOn = true;
                        }
                        return carrier;
                    })
                });
            }
        });

        return function cleanup() {
            if (subscription) {
                subscription.unsubscribe();
            }
            return;
        }
    }, [carrierSources]);


    const onSearchChange = (event, values) => {
        if (values && values.description) {
            fetchCarrierSource(values.description).then(sources => setCarrierSources(sources));
        } else {
            setCarrierSources([]);
        }
    }

    return (
        <div className={"carrier-source-list"}>
            <div className={"carrier-search-box"}>
                <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={carrierSourceLists!}
                    getOptionLabel={(option) => option.description}
                    defaultValue={carrierSourceLists ? carrierSourceLists[0] : undefined}
                    onChange={onSearchChange}
                    fullWidth={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Carrier source lists"
                            placeholder="Select a carrier source list"

                        />
                    )}
                />
            </div>
            <div className={"carrier-results-table"}>
                <TableContainer component={'div'} sx={{width: "100%"}}>
                    <Table size="medium" aria-label="Carrier Sources">
                        <TableHead>
                            <TableRow>
                                <ColumnHeader>&nbsp;</ColumnHeader>
                                <ColumnHeader>Carrier Name</ColumnHeader>
                                <ColumnHeader><Tooltip
                                    title={"Seen on DAT in the past"}><HeaderContent>DAT</HeaderContent></Tooltip></ColumnHeader>
                                <ColumnHeader><Tooltip title={"How many loads they've run for Torch in the past"}>
                                    <div>Past Loads</div>
                                </Tooltip></ColumnHeader>
                                <ColumnHeader>DOT</ColumnHeader>
                                <ColumnHeader>City</ColumnHeader>
                                <ColumnHeader>State</ColumnHeader>
                                <ColumnHeader>Zipcode</ColumnHeader>
                                <ColumnHeader>Power Units</ColumnHeader>
                                <ColumnHeader>&nbsp;</ColumnHeader>
                                <ColumnHeader>Feedback Form</ColumnHeader>
                                <ColumnHeader>Carrier Profile</ColumnHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {carrierSources?.map((row) => (
                                <TableRow
                                    key={row.carrierName}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <Content>
                                        {row.torchCarrier && (
                                            <img className={"carrier-icon"} src={torchLogo} alt=""/>
                                        )}
                                    </Content>
                                    <Content component="th" scope="row">
                                        {row.carrierName}
                                    </Content>
                                    <Content>{row.datRat ? "Yes" : "No"}</Content>
                                    <Content>{row.torchLoads}</Content>
                                    <Content>{row.dotNumber}</Content>
                                    <Content>{row.city}</Content>
                                    <Content>{row.state}</Content>
                                    <Content>{row.zipCode}</Content>
                                    <Content>{row.totalPower}</Content>
                                    <Content>
                                        {row.actedOn && (
                                            <CheckCircleIcon sx={{color: red[500]}}/>
                                        )}
                                        {row.called && (
                                            <PhoneInTalk sx={{color: green[500]}}/>
                                        )}

                                    </Content>
                                    <Content><Link target="_blank" href={row.feedbackUrl}>
                                        <OpenInBrowserIcon/>
                                    </Link>
                                    </Content>
                                    <Content>
                                        <Link target={"_carrier_profile"} href={`/carriers?dot=${row.dotNumber}`}>
                                            <LocalShipping/>
                                        </Link>
                                    </Content>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default CarrierSourceList;
