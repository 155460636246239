import React from "react";
import RateChart from "./RateChart";
import RateDetails from "./RateDetails";
import {addDays, subDays} from "date-fns";

const RateChartBundle = ({current, historical, loading, height, showDetails = true, showTitles = true, showCurrent = true, showAmount = true}) => {
    historical = historical === undefined ? [] : historical;

    if (historical.length > 0 && !historical[0].fuelAdded) {
        /*
         * Adding the fuelSurcharge in because that's the typical view...don't want to compute this on the server
         * yet since it can be easily subtracted / added back in.
         */
        historical.forEach((d) => {
            d.rate += d.fuelSurcharge;
            d.rateLow += d.fuelSurcharge;
            d.rateHigh += d.fuelSurcharge;
            d.range = [d.rate - d.rateHigh, d.rateLow - d.rate];
            d.timeIndex = Date.parse(d.date);
            d.fuelAdded = true;
        });

        if (current && current.rate) {
            current.date = new Date();
            current.rate += current.fuelSurcharge;
            current.rateLow += current.fuelSurcharge;
            current.rateHigh += current.fuelSurcharge;
            current.range = [
                current.rate - current.rateHigh,
                current.rateLow - current.rate
            ];
            current.timeIndex = new Date().getTime();
        }
    }

    if (historical.length > 0) {
        /*
         * Domains control the x and y axis ranges.  For our use auto doesn't work well since the chart needs
         * a little padding on the time range to look correct and the Y axis needs to be controlled to not have much
         * unused white space beyond the error bars.
         */
        const minY = Math.min(...historical.map((r) => r.rateLow), current.rateLow);
        const maxY = Math.max(...historical.map((r) => r.rateHigh), current.rateHigh);
        const domainY = [minY - (minY * 0.05), maxY + (maxY * 0.05)];

        const historicalDomainX = [
            subDays(Math.min(...historical.map((r) => r.timeIndex)), 10).getTime(),
            addDays(Math.max(...historical.map((r) => r.timeIndex)), 10).getTime()
        ];

        const currentDomainX = [
            subDays(current.timeIndex, 2).getTime(),
            addDays(current.timeIndex, 2).getTime()
        ]

        let rateChartBundle = "rate-chart-bundle";
        let rateChartBundleChart = "rate-chart-bundle-chart";
        let historicalClassName = "rate-chart";
        let rateClassName = "current-rate-chart";

        let title = null;
        let currentTitle = null;
        if (showTitles) {
            title = `Lane: ${current.originCity}, ${current.originState} - ${current.destCity}, ${current.destState}`;
            currentTitle = "Current";
        }

        return (
            <div className={rateChartBundle}>
                <div className={rateChartBundleChart}>
                    <div className={historicalClassName}>
                        <RateChart
                            height={height}
                            data={historical}
                            domainX={historicalDomainX}
                            domainY={domainY}
                            header={title}
                            showTickY={showAmount}
                        />
                    </div>
                    {showCurrent ?
                        <div className={rateClassName}>
                            <RateChart
                                height={height}
                                data={[current]}
                                showTickY={false}
                                showTickX={true}
                                domainX={currentDomainX}
                                domainY={domainY}
                                axisLineY={false}
                                header={currentTitle}
                            />
                        </div> : null
                    }
                </div>
                {showDetails ? <RateDetails rate={current}/> : null}
            </div>
        );
    } else {
        return null;
    }
}

export default RateChartBundle;
