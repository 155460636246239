import React, {useState} from "react";
import {getTourAutomatedLoadsEnabled} from "../service/settings";
import {Graph} from "react-d3-graph";
import {Box, styled} from "@mui/system";
import Typography from "@mui/material/Typography";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Slider} from "@mui/material";
import {TourData} from "../interfaces/Tour";
import {getTours} from "../service/loads";
import Loading from "../common/Loading";
//import {getLoadLabel} from "../util/load-utils";

// graph payload (with minimalist structure)
//TODO: replace with real data...
const data = {
    nodes: [{id: "URBY35"}, {id: "6MPBZN"}, {id: "XHU4DW"}, {id: "GMVYSF"}/*, {id:"LLBEAN"}*/],
    links: [
        {source: "URBY35", target: "6MPBZN", weightLabel:"100 miles"},
        {source: "6MPBZN", target: "GMVYSF",  weightLabel:"100 miles"},
        {source: "6MPBZN", target: "URBY35",  weightLabel:"100 miles"},
        {source: "URBY35", target: "XHU4DW",  weightLabel:"100 miles"},
        {source: "GMVYSF", target: "URBY35",  weightLabel:"100 miles"},
    ],
};

// the graph configuration, just override the ones you need
const tourConfig = {
    nodeHighlightBehavior: true,
    node: {
        color: "#FF6B01",
        size: 150,
        highlightStrokeColor: "blue",
    },
    link: {
        highlightColor: "lightblue",
        renderLabel:true,
        labelProperty:"weightLabel"
    },
};

const onClickNode = (nodeId) => {
    window.open(`/loads/${nodeId}`, '_blank');
};

const onClickLink = (source, target) => {
    window.alert(`Clicked link between ${source} and ${target}`);
};

const TourBox = styled(Box)`
  border: 1px grey;
  margin: 10px;
  padding: 5px;
`

const SliderTitle = styled(Typography)`
  margin-left: 5px;
`

const GraphBox = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 10px;
  width: 75%;
  border: #777777 solid 2px;
`

const ControlBox = styled('div')`
  display: flex;
  flex-direction: row;
  border: 1px grey;
  margin: 5px;
  padding: 10px;
`

const marks = [
    {
        value: 10,
        label: '0',
    },
    {
        value: 1000,
        label: '1k miles',
    },
    {
        value: 2000,
        label: 'U.S.',
    }
];


const Tour = () => {
    const [radius, setRadius] = useState(1000);
    const [continuationSearchRadius, setContinuationSearchRadius] = useState(1000);
    const [age, setAge] = useState('12');
    const [tours, setTours] = useState<TourData[]>();
    const [loading, setLoading] = useState(false);

    if (!getTourAutomatedLoadsEnabled())
        return null;

    const handleRadiusChange = (event: any, newValue: any) => {
        setRadius(newValue);
    };

    const handleContinuationRadiusChange = (event: any, newValue: any) => {
        setContinuationSearchRadius(newValue);
    };


    const valueLabelFormat = (value: number) => {
        return marks.findIndex((mark) => mark.value === value) + 1;
    }

    const onSubmit = (data: any) => {
        setLoading(true);
        getTours(radius, continuationSearchRadius, age).then(data => {
            if (data.length > 0) {
                setTours(data);
            }
            setLoading(false);
        });
    }

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };


    return (
        <div className={"rfp-tools"}>
            <TourBox>
                <Typography className={"integrations-title"} variant={'h6'}>
                    Tours
                </Typography>
            </TourBox>
            <TourBox>
                <ControlBox>
                    <SliderTitle>Origin radius: </SliderTitle>
                    <Slider sx={{marginRight: 5, width: 300}}
                            min={10} max={2000}
                            aria-label="Origin Radius"
                            value={radius}
                            valueLabelFormat={valueLabelFormat}
                            marks={marks}
                            onChangeCommitted={onSubmit}
                            onChange={handleRadiusChange}/>

                    <SliderTitle>Continuation radius: </SliderTitle>
                    <Slider sx={{marginRight: 5, width: 300}}
                            min={10} max={2000}
                            aria-label="Continuing Radius"
                            value={continuationSearchRadius}
                            valueLabelFormat={valueLabelFormat}
                            marks={marks}
                            onChangeCommitted={onSubmit}
                            onChange={handleContinuationRadiusChange}/>

                    <FormControl sx={{marginRight: 2, minWidth: 120}} size="small">
                        <InputLabel id="age-select-small">Days</InputLabel>
                        <Select
                            labelId="age-select-small"
                            id="age-select-small"
                            value={age}
                            label="Days"
                            onChange={handleChange}
                        >
                            {
                                Array.from(Array(30).keys()).map(menuId =>
                                    <MenuItem value={menuId}>{menuId}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </ControlBox>
            </TourBox>
            <TourBox>
                <Typography>Query is {age} day(s) forward, {radius} miles away, with continuing {continuationSearchRadius} miles. </Typography>
            </TourBox>
            {loading ? <Loading></Loading> :
            <TourBox>
                {(tours ? tours : [data, data]).map((tour, index) => (
                    <GraphBox>
                        <Typography>Tour {'test ' + index} : {age} day(s) forward, {radius} miles away, with continuing {continuationSearchRadius} miles.</Typography>
                        <Graph
                            id={'graph-' + index} // id is mandatory
                            data={tour ? tour : data} //No
                            config={tourConfig}
                            onClickNode={onClickNode}
                            onClickLink={onClickLink}
                        />
                    </GraphBox>
                ))}
            </TourBox>
            }
        </div>
    );
}
export default Tour;
