import React from "react";
import {styled} from "@mui/system";

const StyledContainer = styled('div')`
      margin: 15px 0;
    `

type Props = {
    children: React.ReactNode;
}

const ControlWrapper = ({children}:Props) => {
    return <StyledContainer>{children}</StyledContainer>
}

export default ControlWrapper;