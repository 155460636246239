import React, {useEffect, useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import {Carrier, CarrierActivity} from "../interfaces/Carrier";
import {CarrierNotes} from "./CarrierNotes";
import SectionContainer from "../common/SectionContainer";
import {Load} from "../interfaces/Load";
import {useAbortableEffect} from "../util/hooks";
import {fetchCarrierLoadActivity} from "../service/carriers";
import CarrierActivities from "./CarrierActivities";
import CarrierLoads from "./CarrierLoads";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {JETPACK_SHOW_CARRIER_PAST_ACTIVITIES, JETPACK_SHOW_CARRIER_PAST_LOADS, postUsageEvent} from "../service/events";
import CapacityHistory from "../carrier/CapacityHistory";
import {CarrierLoadboardSettings} from "../carrier/CarrierLoadboardSettings";


type PropTypes = {
    carrier: Carrier;
    load: Load;
}

const SEVEN_DAYS_AGO = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

const CarrierIntel = ({carrier, load}: PropTypes) => {

    const [value, setValue] = React.useState('1');
    const [activities, setActivities] = useState<CarrierActivity[]>([]);

    const [recentActivities, setRecentActivities] = useState(false);
    const [recentLoads, setRecentLoads] = useState(false);

    useAbortableEffect((status) => {
        fetchCarrierLoadActivity(carrier.id).then(activities => {
            if (!status.aborted) setActivities(activities)
        });
    }, [carrier]);

    useEffect(() => {
        setRecentActivities(activities
            .filter(activity => new Date(activity.instant).getTime() > SEVEN_DAYS_AGO.getTime())
            .length > 0);
    }, [activities]);

    const handleChange = (event, newValue) => {
        if(newValue === "2") {
            postUsageEvent({
                type: JETPACK_SHOW_CARRIER_PAST_ACTIVITIES,
                loadId: load.id,
                carrierId: carrier.id
            });
        } else if(newValue === "3") {
            postUsageEvent({
                type: JETPACK_SHOW_CARRIER_PAST_LOADS,
                loadId: load.id,
                carrierId: carrier.id
            });
        }
        setValue(newValue);
    };

    return (
        <SectionContainer className={"carrier-intel"} fullBleed={true}>
            <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Notes" value="1"/>
                    {recentActivities ?
                        <Tab sx={{minHeight: "auto"}} icon={<NewReleasesIcon fontSize="small"/>} iconPosition={"end"} label={"Activities"} value="2" /> :
                        <Tab label={"Activities"} value="2"/>}
                    {recentLoads ?
                        <Tab sx={{minHeight: "auto"}} icon={<NewReleasesIcon fontSize="small"/>} iconPosition={"end"} label={"Loads"} value="3" /> :
                        <Tab label={"Loads"} value="3"/>}
                    <Tab label="Loadboard" value="4"/>
                    <Tab label="Capacity" value="5"/>
                </TabList>
                <TabPanel sx={{padding: 0}} value="1" className={"tab-panel"}>
                    <CarrierNotes carrier={carrier}/>
                </TabPanel>
                <TabPanel sx={{padding: 0}} value="2">
                    <CarrierActivities activities={activities}/>
                </TabPanel>
                <TabPanel sx={{padding: 0}} value="3">
                    <CarrierLoads carrier={carrier} showRecentLoads={(r) => setRecentLoads(r)}/>
                </TabPanel>
                <TabPanel value="4">
                    <CarrierLoadboardSettings carrier={carrier} load={load}></CarrierLoadboardSettings>
                </TabPanel>
               <TabPanel value="5">
                   <CapacityHistory carrier={carrier}></CapacityHistory>
               </TabPanel>
            </TabContext>
        </SectionContainer>
    );
}

export default CarrierIntel;
