import React, {useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {torchFetch} from "../util/APIUtils";
import {API_BASE_URL} from "../constants";
import {Autocomplete} from "@mui/material";

const LocationAutocomplete = ({label, onChange, defaultValue}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const debounceWait = 750;

    // allows the component to receive an assigned/default value when the caller
    // wants to set an initial state.  Needs to have a field with a label property
    const [val,setVal]=useState({label: ''})

    // when a default value is passed in that is valid, meaning it has a zip code
    // and label, it will set this option as the only option in the drop down as
    // well as the selected value for the component
    useEffect(() => {
        if(defaultValue && defaultValue.zipCode) {
            setOptions([defaultValue]);
            setVal(defaultValue);
        }
    }, [defaultValue]);

    const queryPlaces = q => {
        setOpen(true);
        q = q ? q.trim() : '';
        if (q.length >= 3) {
            setLoading(true);

            let locationUrl = new URL(`${API_BASE_URL}/lookup/location`);
            locationUrl.searchParams.append("q", q);

            torchFetch({
                url: locationUrl,
                method: 'get'
            }).then(json => {
                setLoading(false);
                setOptions(json);
            });
        } else {
            setOptions([]);
            setOpen(false);
            setLoading(false);
        }
    }

    const delayedSearchValue = useMemo(() => debounce(v => queryPlaces(v), debounceWait), []);

    const inputChange = event => {
        delayedSearchValue(event.target.value);
    }

    return <Autocomplete
        value={val}
        id={label + "-" + new Date().getTime() + "-id"}
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        onChange={(event, newValue) => {
            if (onChange) {
                onChange(newValue);
            }
        }}
        getOptionSelected={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        options={options}
        loading={loading}
        filterOptions={(options, state) => options}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
                onChange={inputChange}
            />
        )}
    />;
}

export default LocationAutocomplete;
