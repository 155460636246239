import React, {useEffect, useState} from 'react';
import {fetchCarrierLoadActivity} from "../service/carriers";
import {Carrier, CarrierActivity} from "../interfaces/Carrier";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {dateFormat} from "../service/time";
import SectionContainer from "../common/SectionContainer";
import LoadDescription from "../common/LoadDescription";
import Loading from "../common/Loading";
import NoContentPanel from "../common/NoContentPanel";

type PropTypes = {
    carrier: Carrier
}

const PAGE_SIZE: number = 5;

export const CarrierLoadActivity = ({carrier}: PropTypes) => {
    const [loading, setLoading] = useState(true);
    const [activities, setActivities] = useState<CarrierActivity[]>();
    const [page, setPage] = useState(0);

    useEffect(() => {
        fetchCarrierLoadActivity(carrier.id).then(activities => {
            setActivities(activities);
            setLoading(false);
        });

    }, [carrier]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    if (loading) {
        return <Loading/>
    }

    if (activities && activities.length > 0) {
        return (
            <SectionContainer fullBleed={true}
                              border={false}
                              className={"carrier-load-activities"}>
                <TableContainer>
                    <Table className={"activities-list"} aria-label={"Load Rate Offers"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Lane</TableCell>
                                <TableCell>Activity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activities.slice(page * PAGE_SIZE, (page * PAGE_SIZE) + PAGE_SIZE).map(activity => {
                                return <TableRow key={activity.load.id}>
                                    <TableCell>{dateFormat(activity.instant)}</TableCell>
                                    <TableCell><LoadDescription load={activity.load}/></TableCell>
                                    <TableCell>{activity.message}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component={"div"}
                    count={activities.length}
                    page={page}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPageChange={handlePageChange}
                    rowsPerPage={PAGE_SIZE}/>
            </SectionContainer>
        );
    }

    return (<NoContentPanel>No Past Activities</NoContentPanel>);
}
