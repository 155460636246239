import {Button, Card, CardActions, CardContent, Link, Typography} from "@mui/material";
import "./Integrations.scss"
import {ACCESS_TOKEN, API_BASE_URL} from "../constants";
import {makeStyles} from "@mui/styles";

const Integrations = () => {

    const useStyles = makeStyles(() => ({
        buttonList: {
            marginLeft: "5px"
        }
    }));

    const classes = useStyles();

    return (
        <div className={"integrations-list"}>
            <Typography className={"integrations-title"} variant="h6">
                Integrations
            </Typography>
            <Card>
                <CardContent>
                    <Typography>
                       Convoy Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/integrations/convoy/json?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Download JSON</Button>
                    <Button
                        href={API_BASE_URL + "/integrations/convoy/pushToConvoy?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        variant="contained"
                        component={Link}
                        target={"_blank"}
                        size="small">Push JSON</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        FourKites Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/integrations/fourkites/csv?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Download CSV</Button>
                    <Button href={API_BASE_URL + "/integrations/fourkites/?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                                size="small">Push to FourKites</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        Trimble Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/integrations/trimble/csv?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Download CSV</Button>
                    <Button href={API_BASE_URL + "/integrations/trimble/?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                            size="small">Push to Trimble</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        DAT Rate Contribution Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/integrations/dat-rate-contrib?lookback=1&torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Last 24 Hours</Button>
                    <Button href={API_BASE_URL + "/integrations/dat-rate-contrib?lookback=5&torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                            size="small">Last 5 Days</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        AVRL Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        target={"_blank"}
                        href={API_BASE_URL + "/integrations/avrl/tnt?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Down Loads</Button>
                    <Button disabled={true} size="small">Push to AVRL</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        UBER Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/integrations/uber/json?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Download JSON</Button>
                    <Button
                        href={API_BASE_URL + "/integrations/uber/pushToUber?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        variant="contained"
                        component={Link}
                        target={"_blank"}
                        size="small">Push JSON</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        AB API Integration
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/integrations/bid/json?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        variant="contained"
                        component={Link}
                        target={"_blank"}
                        size="small">Download Bid JSON</Button>
                    <Button
                        href={API_BASE_URL + "/integrations/bid/run?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        variant="contained"
                        component={Link}
                        target={"_blank"}
                        size="small">Run Bid Collection</Button>
                    <Button
                        href={API_BASE_URL + "/integrations/bid/reprice?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        variant="contained"
                        component={Link}
                        target={"_blank"}
                        size="small">Reset Torch Bid Rates</Button>
                    <Button
                        href={API_BASE_URL + "/integrations/bid/autobid?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        variant="contained"
                        component={Link}
                        target={"_blank"}
                        size="small">Run Autobid</Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Typography>
                        Financials
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonList}>
                    <Button
                        href={API_BASE_URL + "/invoice/csv?torch_auth=" + localStorage.getItem(ACCESS_TOKEN)}
                        size="small">Download CSV</Button>
                </CardActions>
            </Card>
        </div>
    );
}
export default Integrations;
