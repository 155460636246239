import React from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab} from "@mui/material";
import LoadList from "./LoadList";
import {CarrierNotes} from "./CarrierNotes";
import {CarrierLoadActivity} from "./CarrierLoadActivity";
import {CarrierLoadboardSettings} from "./CarrierLoadboardSettings";
import {Carrier} from "../interfaces/Carrier";
import SectionContainer from "../common/SectionContainer";
import {styled} from "@mui/system";
import {useHistory, useLocation} from "react-router-dom";
import CapacityHistory from "./CapacityHistory";

type Props = {
    carrier: Carrier
}

const Panel = styled(TabPanel)`
  padding: 0;
`

// any way to abstract some of this out to a "Torch Tab Container" component?
const CarrierDataTabContainer = ({carrier}: Props) => {

    const {hash} = useLocation();
    const [value, setValue] = React.useState(hash ? hash.substring(1) : 'past-loads');
    const history = useHistory();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        history.push("#" + newValue);
    };

    return <SectionContainer fullBleed={true}>
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Past Loads" value="past-loads"/>
                        <Tab label="Activities" value="activities"/>
                        <Tab label="Notes" value="notes"/>
                        <Tab label="Loadboard" value="loadboard"/>
                        <Tab label="Capacity" value="capacity"/>
                    </TabList>
                </Box>
                <Panel value="past-loads"><LoadList carrier={carrier}/></Panel>
                <Panel value="notes"><CarrierNotes carrier={carrier}/></Panel>
                <Panel value="activities"><CarrierLoadActivity carrier={carrier}/></Panel>
                <Panel value="loadboard"><CarrierLoadboardSettings carrier={carrier}/></Panel>
                <Panel value="capacity"><CapacityHistory carrier={carrier} /></Panel>
            </TabContext>
        </Box>
    </SectionContainer>;
}

export default CarrierDataTabContainer;