import React from "react";
import {styled} from '@mui/material/styles';
import {InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

type PropTypes = {
    taskFilter: string;
    setTaskFilter: (task: string) => void;
    dayOfWeekFilter: string[];
    setDayOfWeekFilter: (dayOfWeek: string[]) => void;
    lookbackFilter: string;
    setLookbackFilter: (lookback: string) => void;
    queueFilter: string[];
    setQueueFilter: (queue: string[]) => void;
    shipperFilter: string[];
    setShipperFilter: (shipper: string[]) => void;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-left: 15px;
  height: 56px;
`

const StyledToggleButton = styled(ToggleButton)`
  width: 100%;
  padding: 16px 13px;
`

const DOW_FILTER_ENABLED = false;

const TenderFilterBar = ({
                             taskFilter,
                             setTaskFilter,
                             dayOfWeekFilter,
                             setDayOfWeekFilter,
                             lookbackFilter,
                             setLookbackFilter,
                             queueFilter,
                             setQueueFilter,
                             shipperFilter,
                             setShipperFilter
                         }: PropTypes) => {

    const toggleDayFilter = (event, days: string[]) => {
        console.log(days);
        setDayOfWeekFilter(days);
    };

    return (<div className={"load-filter-panel"}>
        <TextField className={"filter-entry-2"}
                   variant={"outlined"}
                   InputProps={{
                       endAdornment:
                           <InputAdornment position="start">
                               <Tooltip title={<div>Searching with Filter:
                                   <br/>&#8226;&nbsp;Enter (2) CAPITAL letters to search by STATE.
                                   <br/>&#8226;&nbsp;Enter anything else to search by ShipwellId,
                                   <br/>RefId, OR City (ignores case)

                               </div>}>
                                   <HelpIcon color="primary"/>
                               </Tooltip>
                           </InputAdornment>
                   }}
                   onChange={(e) => {
                       setTaskFilter(e.target.value);
                   }}
                   value={taskFilter}
                   label={"Filter Loads by State, City, or Id"}
                   aria-label="Name or Id"
                   autoFocus={true}
                   sx={{width: "400px"}}/>
        {DOW_FILTER_ENABLED ? <StyledToggleButtonGroup
            value={dayOfWeekFilter}
            onChange={toggleDayFilter}
            aria-label="text formatting"
            size="small">
            <StyledToggleButton value="1" aria-label="Monday">Mon</StyledToggleButton>
            <StyledToggleButton value="2" aria-label="Tuesday">Tue</StyledToggleButton>
            <StyledToggleButton value="3" aria-label="Wednesday">Wed</StyledToggleButton>
            <StyledToggleButton value="4" aria-label="Thursday">Thu</StyledToggleButton>
            <StyledToggleButton value="5" aria-label="Friday">Fri</StyledToggleButton>
            <StyledToggleButton value="6" aria-label="Saturday">Sat</StyledToggleButton>
            <StyledToggleButton value="0" aria-label="Sunday">Sun</StyledToggleButton>
        </StyledToggleButtonGroup> : null}
        <StyledToggleButtonGroup size="small"
                                 value={queueFilter}
                                 onChange={(e, v) => setQueueFilter(v)}>
            <StyledToggleButton value={"OPERATIONS"} aria-label={"Ops"}>Ops</StyledToggleButton>
            <StyledToggleButton value={"ACCOUNTS"} aria-label={"Accounts"}>Accounts</StyledToggleButton>
            <StyledToggleButton value={"PROCESSING"} aria-label={"Processing"}>Processing</StyledToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup size="small"
                                 value={shipperFilter} onChange={(e, v) => setShipperFilter(v)}>
            <StyledToggleButton value={"AB"} aria-label={"AB"}>AB</StyledToggleButton>
            <StyledToggleButton value={"TARGET"} aria-label={"Target"}>Target</StyledToggleButton>
            <StyledToggleButton value={"USPS"} aria-label={"USPS"}>USPS</StyledToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup size="small"
                                 value={lookbackFilter}
                                 onChange={(e, v) => setLookbackFilter(v)}
                                 exclusive={true}>
            <StyledToggleButton value={"1"} aria-label={"1"}>1</StyledToggleButton>
            <StyledToggleButton value={"2"} aria-label={"2"}>2</StyledToggleButton>
            <StyledToggleButton value={"7"} aria-label={"7"}>7</StyledToggleButton>
            <StyledToggleButton value={"14"} aria-label={"14"}>14</StyledToggleButton>
        </StyledToggleButtonGroup>
    </div>);
}

export default TenderFilterBar;
