import React from "react";
import {EventMapping, ShipwellEventProps} from "./types";
import {styled} from "@mui/system";

export const EventTitle = styled('span')`
  font-weight: bold;
  color: #333;
`

const EventTypeDispatcher = ({event}: ShipwellEventProps) => {

    const eventName = event.eventName;
    const mapping = EventMapping[eventName];

    if(mapping) {
        return mapping.component({
            event: event,
            title: mapping.label
        });
    }

    return <>{eventName}</>
}

export default EventTypeDispatcher;