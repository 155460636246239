import {SkinnyLoad, SuggestedLoad} from "../interfaces/Load";
import SectionContainer from "../common/SectionContainer";
import {styled} from "@mui/system";
import {useHistory, useParams} from "react-router-dom";
import {Tooltip} from "@mui/material";
import {getContinuingLoadsEnabled} from "../service/settings";

type Props = {
    loads: SuggestedLoad[];
}

const LoadContainer = styled('div')`
  padding-bottom: 8px;
`

const LoadItem = styled('div')`
  display: grid;
  grid-template-columns: auto 110px 110px;
  grid-template-areas: "lane distance time";
  padding-top: 8px;
`

const LoadDesc = styled('div')`
  grid-area: lane;
  cursor: pointer;
`

const City = styled('span')`
  text-transform: capitalize;
`

const Distance = styled('div')`
  grid-area: distance;
  text-align: right;
`

const Time = styled('div')`
  grid-area: time;
  text-align: right;
`

const A = styled('a')`
  text-decoration: none;
`

/*
    This component depends on the URL structure being /open-loads/{loadId}/carrier/{carrierId}
    for it to function properly.  If the URL path changes or it's used somewhere else you will
    need to take that into account.
 */
const SuggestedLoads = ({loads}: Props) => {

    const history = useHistory();
    const {carrierId}: { carrierId?: string | undefined } = useParams();

    function getUrlForLoad(load: SkinnyLoad) {
        if (carrierId) {
            return `/open-loads/${load.id}/carrier/${carrierId}`;
        } else {
            return `/open-loads/${load.id}`;
        }
    }

    if (loads.length === 0 || !getContinuingLoadsEnabled()) {
        return null;
    }

    return <SectionContainer title={"Continuing Loads"} className={"lane-panel"}>
        <LoadContainer>
            {loads.map(load => {
                    const stops = load.skinnyLoad.stops;
                    const swapUrl = getUrlForLoad(load.skinnyLoad);

                    const date = new Date(stops[0].arrival).toLocaleDateString("en-us", {
                        month: 'long',
                        day: 'numeric',
                        timeZone: stops[0].timeZoneId
                    });

                    const time = new Date(stops[0].arrival).toLocaleTimeString("en-us", {
                        timeStyle: "short",
                        timeZone: stops[0].timeZoneId
                    });

                    const arrival = `${date} at ${time}`;

                    return <LoadItem>
                        <LoadDesc>
                            <Tooltip title={`${load.skinnyLoad.loadDescription} (${load.skinnyLoad.shipwellRefId})`}>
                                <div><A href={swapUrl} onClick={(e) => {
                                    history.push(swapUrl);
                                    e.preventDefault();
                                }}>
                                    <City>{stops[0].address.city.toLowerCase()}</City> {stops[0].address.state}
                                    &nbsp;-&nbsp;
                                    <City>{stops[stops.length - 1].address.city.toLowerCase()}</City> {stops[stops.length - 1].address.state}
                                </A></div>
                            </Tooltip>
                        </LoadDesc>
                        <Distance>{load.distance} Miles</Distance>
                        <Time>
                            <Tooltip title={`Load pickup is currently scheduled for ${arrival}`}>
                                <div>{load.time / 3600} Hours</div>
                            </Tooltip>
                        </Time>
                    </LoadItem>
                }
            )}
        </LoadContainer>
    </SectionContainer>
}

export default SuggestedLoads;