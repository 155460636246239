import React, {useEffect, useState} from "react";
import {
    IconButton,
    Link,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Loading from "../common/Loading";
import {LoadDisplayItem} from "../interfaces/LoadDisplayItem";
import {getOtherSuggestedLoads} from "../service/lane";
import {SkinnyLoad, Stop, SuggestedLoad} from "../interfaces/Load";
import {getSkinnyInProgressLoads} from "../service/loads";
import {LocationSearchingTwoTone} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import {LoadLink, TorchShipmentExternalLink} from "../common/Links";
import {getStatusLabel} from "../util/load-utils";
import RocketIcon from '@mui/icons-material/Rocket';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Moment from "react-moment";
import {getTimeFormat} from "../service/time";

type ListProps = {
    loading: boolean;
}

type ExpandProp = {
    children: any;
    key: number;
    expandComponent: any;
    otherProps?: any;
    row: LoadDisplayItem;
}

const Continue = styled(Typography)`
  padding-top: 5px;
  font-weight: bold;
`

const StopLocation = styled('div')`
  overflow: clip;
  text-transform: capitalize;
`
const ContinueExpand = styled('div')`
  width: 100%;
`

const NoContinue = styled(Typography)`
  font-weight: bold;
`

const CustomTableCell = styled(TableCell)`
  text-align: left;
  padding: 12px 0;
`

const PaperTable = styled('div')`
  margin-top: 20px;
`

const RefId = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
  display: flex;
  align-items: center;
  column-gap: 5px;
`

const CarrierName = styled('div')`
  text-transform: capitalize;
`

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-bottom: 10px;
`

const StyledToggleButton = styled(ToggleButton)`
  width: 100%;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
`

const ExpandableTableRow = ({children, expandComponent, otherProps, row}: ExpandProp) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [continuing, setContinuing] = React.useState<SuggestedLoad[]>();

    function formatDateAndTime(d: Date): string {
        return d.toLocaleString('en-us', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    function getContinuationDescription(dropoffTime: Date, tour: SuggestedLoad) {
        return <>
            Load <Link
            href={`/loads/${tour?.skinnyLoad.id}`}>{tour?.skinnyLoad.shipwellRefId}'s</Link> pick-up(to {tour?.skinnyLoad.stops[1].address.city}, {tour?.skinnyLoad.stops[1].address.state} )
            is {tour.distance} miles away from this drop-off.
            Scheduled for pick-up {formatDateAndTime(new Date(+dropoffTime + tour.time))}. ({tour.time / 3600} hours
            later).
        </>;
    }

    function handleExpand() {
        setIsExpanded(!isExpanded);
        //Testing ...
        //row.loadInternalId = 36940212;
        getOtherSuggestedLoads(row.loadInternalId).then(result => {
                if (result) {
                    setContinuing(result);
                }
            }
        )
    }

    return (
        <>
            <TableRow {...otherProps}>
                <CustomTableCell padding="checkbox">
                    <IconButton onClick={() => handleExpand()} disabled={!continuing || continuing.length === 0}>
                        {isExpanded ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    </IconButton>
                </CustomTableCell>
                {children}
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <CustomTableCell padding="checkbox"/>
                    <CustomTableCell colSpan={8}>
                        <ContinueExpand>
                            {continuing?.length === 0 ?
                                <NoContinue>No loads continuing from here...yet! Check back with us.</NoContinue> :
                                continuing?.map((tour, index) => {
                                    return <div>
                                        <Continue>{index !== 0 ? " or " : ""}Continue on with:</Continue>
                                        <Typography paragraph>{getContinuationDescription(row.dropoffTime, tour)}
                                        </Typography>
                                    </div>
                                })
                            }
                        </ContinueExpand>
                    </CustomTableCell>
                </TableRow>
            )}
        </>
    );
};

const DesktopLoadListExpanding = ({loading}: ListProps) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState<LoadDisplayItem[]>([]);
    const [filteredRows, setFilteredRows] = useState<LoadDisplayItem[]>([]);
    const [statusFilters, setStatusFilters] = useState<string[]>([]);

    useEffect(() => {
        getSkinnyInProgressLoads().then(results => {
            let id = 0;
            const mappedLoads = results.map(mappedLoad => {
                const destIdx = mappedLoad.stops.length - 1;
                return {
                    pickupTime: new Date(mappedLoad.stops[0].arrival),
                    dropoffTime: new Date(mappedLoad.stops[destIdx].arrival),
                    numStops: mappedLoad.stops.length,
                    originCity: mappedLoad.stops[0].address.city.toLowerCase() + ", " + mappedLoad.stops[0].address.state.toUpperCase(),
                    destCity: mappedLoad.stops[1].address.city.toLowerCase() + ", " + mappedLoad.stops[destIdx].address.state.toUpperCase(),
                    miles: mappedLoad.miles,
                    payload: mappedLoad.payloadType === "DRY_VAN" ? "Dry Van" : "Reefer",
                    description: mappedLoad.shortDescription.toLowerCase(),
                    id: id++,
                    loadInternalId: mappedLoad.id,
                    status: mappedLoad.loadState || "UNKNOWN",
                    tracked: mappedLoad.tracked,
                    refId: mappedLoad.shipwellRefId,
                    shipwellId: mappedLoad.shipwellId,
                    stops: possibleStops(mappedLoad),
                    carrierName: getCarrierName(mappedLoad),
                    shipperName: mappedLoad.shipperName
                }
            })
            setRows(mappedLoads);
        });
    }, []);


    useEffect(() => {
        const statuses = ['TENDERED', 'DISPATCHED', 'AT_PICKUP', 'IN_TRANSIT', 'AT_DELIVERY']
        const statusFiltered = statusFilters.length === 0 ? rows : rows.filter(l => statusFilters.includes(l.status));

        const sortedFiltered = statusFiltered.sort((first, second) => {
            const firstIdx = String(statuses.indexOf(first.status));
            const firstTime = new Date(first.stops[0].arrival).getTime();

            const secondIdx = String(statuses.indexOf(second.status));
            const secondTime = new Date(second.stops[0].arrival).getTime();

            return firstIdx.localeCompare(secondIdx) || firstTime - secondTime;
        });

        setFilteredRows(sortedFiltered);
    }, [statusFilters, rows]);

    function getCarrierName(load: SkinnyLoad): string | undefined {
        if (load.carrierName === load.operatingCarrierName) {
            return load.carrierName?.toLowerCase();
        } else {
            return `${load.operatingCarrierName?.toLowerCase()} + (via Convoy)`;
        }
    }

    function possibleStops(load: SkinnyLoad): Stop[] {
        // only the first stop is possible for these states
        if (load.loadState === 'TENDERED' || load.loadState === 'DISPATCHED') {
            return [load.stops[0]];
        }

        // any pickup oriented stops are possible
        if (load.loadState === 'AT_PICKUP') {
            return load.stops.filter(s => s.pickup);
        }

        // all but the first are possible
        if (load.loadState === 'IN_TRANSIT') {
            return load.stops.slice(1);
        }

        if (load.loadState === 'AT_DELIVERY') {
            return load.stops.filter(s => s.dropoff);
        }

        return load.stops;
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return <><Loading/></>
    }

    interface HeadCell {
        disablePadding: boolean;
        show?: boolean;
        id: string;
        label: string;
        numeric: boolean;
    }

    const headCells: readonly HeadCell[] = [
        {
            id: 'expand',
            numeric: false,
            disablePadding: false,
            label: ''
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status'
        },
        {
            id: 'stop_times',
            numeric: false,
            disablePadding: false,
            label: 'Stop Times'
        },
        {
            id: 'shipperName',
            numeric: false,
            disablePadding: true,
            label: 'Shipper Name'
        },
        {
            id: 'carrierName',
            numeric: false,
            disablePadding: true,
            label: 'Carrier Name'
        },
        {
            id: 'refId',
            numeric: false,
            disablePadding: true,
            label: 'Ref ID'
        },
        {
            id: 'originCity',
            numeric: false,
            disablePadding: true,
            label: 'Origin'
        },
        {
            id: 'destCity',
            numeric: false,
            disablePadding: true,
            label: 'Destination'
        }
    ];
    type StopTimesProps = { stops: Stop[] };
    const StopTimes = ({stops}: StopTimesProps) => {

        return <>
            {
                stops.map((s, idx) => (<div key={idx}>
                    <Tooltip title={`Stop ${s.stopCount + 1} is a ${s.pickup ? 'Pick' : 'Drop'}`}>
                        <div>
                            <Moment format={getTimeFormat()} tz={s.timeZoneId}>
                                {s.arrival}
                            </Moment>
                        </div>
                    </Tooltip>
                </div>))
            }
        </>
    }

    return <PaperTable>
        <StyledToggleButtonGroup size={"small"}
                                 value={statusFilters}
                                 onChange={(e, v) => setStatusFilters(v)}>
            <StyledToggleButton value={"TENDERED"}>Tendered</StyledToggleButton>
            <StyledToggleButton value={"DISPATCHED"}>Dispatched</StyledToggleButton>
            <StyledToggleButton value={"AT_PICKUP"}>At Pickup</StyledToggleButton>
            <StyledToggleButton value={"IN_TRANSIT"}>In Transit</StyledToggleButton>
            <StyledToggleButton value={"AT_DELIVERY"}>At Delivery</StyledToggleButton>
        </StyledToggleButtonGroup>
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <CustomTableCell
                                key={headCell.id}
                                align={'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}>
                                {headCell.label}
                            </CustomTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredRows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row => (
                            <ExpandableTableRow
                                row={row}
                                key={row.loadInternalId}
                                expandComponent={
                                    <CustomTableCell colSpan={8}></CustomTableCell>}
                            >
                                <CustomTableCell align="left">{row.tracked ?
                                    <Typography>
                                        <Tooltip title={"Tracking available"}>
                                            <LocationSearchingTwoTone fontSize={"small"} sx={{
                                                color: green[500],
                                                paddingBottom: "2px",
                                                paddingRight: "5px",
                                                verticalAlign: 'middle'
                                            }}/>
                                        </Tooltip>
                                        {getStatusLabel(row.status)}
                                    </Typography>
                                    :
                                    <Typography className={"tracked"}>
                                        <Tooltip title={"Tracking not available"}>
                                            <LocationSearchingTwoTone sx={{
                                                color: red[500],
                                                paddingBottom: "2px",
                                                paddingRight: "5px",
                                                verticalAlign: 'middle'
                                            }}/>
                                        </Tooltip>
                                        {getStatusLabel(row.status)}
                                    </Typography>
                                }
                                </CustomTableCell>
                                <CustomTableCell>
                                    <StopTimes stops={row.stops}/>
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    {row.shipperName}
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    <CarrierName>{row.carrierName}</CarrierName>
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    <RefId>
                                        <div>
                                            <LoadLink loadId={row.loadInternalId}>
                                                <Tooltip title={"JetPack Load View"}>
                                                    <RocketIcon fontSize={"small"}/>
                                                </Tooltip>
                                            </LoadLink>
                                        </div>
                                        <div>
                                            <TorchShipmentExternalLink shipwellId={row.shipwellId}>
                                                <Tooltip title={"Shipwell Load View"}>
                                                    <DirectionsBoatIcon fontSize={"small"}/>
                                                </Tooltip>
                                            </TorchShipmentExternalLink>
                                        </div>
                                        <div>{row.refId}</div>
                                    </RefId>
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    <StopLocation>
                                        {row.originCity || 'Unavailable'}
                                    </StopLocation>
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    <StopLocation>
                                        {row.destCity || 'Unavailable'}
                                    </StopLocation>
                                </CustomTableCell>
                            </ExpandableTableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </PaperTable>
}

export default DesktopLoadListExpanding;
