import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CarrierDetails from './CarrierDetails';
import {fetchCarrier, refreshCarrier} from "../service/carriers";
import {Carrier} from "../interfaces/Carrier";
import {CarrierLevel} from "./CarrierLevel";
import "./Carriers.scss";
import CarrierDataTabContainer from "./CarrierDataTabContainer";
import {styled} from "@mui/system";

const CarrierViewLayout = styled('div')`
  display: grid;
  gap: 10px;
  margin-top: 10px;
  grid-template-columns: 5fr 1fr;
  grid-template-areas:
          "details level"
          "tabs tabs";
`

const Details = styled(CarrierDetails)`
  grid-area: details;
`

const Level = styled(CarrierLevel)`
  grid-area: level;
`

const TabContainer = styled('div')`
  grid-area: tabs;
  min-height: 750px;
`

export const CarrierView = () => {

    let {carrierId} = useParams<{ carrierId: string }>();

    const [carrier, setCarrier] = useState<Carrier>();

    useEffect(() => {
        if(carrierId) {
            fetchCarrier(carrierId).then(json => setCarrier(json));
        }
    }, [carrierId]);

    function requestRefreshCarrier(): Promise<Carrier> {
        if(carrier) {
            return refreshCarrier(carrier.id);
        } else {
            return new Promise((resolve, reject) => {
                reject("No carrier is setup.");
            });
        }
    }

    if (carrier) {
        return (
            <CarrierViewLayout className={"carrier-view"}>
                <Details carrier={carrier} refresh={requestRefreshCarrier}/>
                <Level carrier={carrier}/>
                <TabContainer>
                    <CarrierDataTabContainer carrier={carrier}/>
                </TabContainer>
            </CarrierViewLayout>
        );
    }

    return null;
}
