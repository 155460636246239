import React, {useState} from "react";
import {RateWithHistorical} from "../interfaces/RateData";
import {useAbortableEffect} from "../util/hooks";
import {getOtherSuggestedLoads, getRate} from "../service/lane";
import LoadStops from "./LoadStops";
import LaneRate from "./LaneRate";
import LaneActivity from "./LaneActivity";
import {Load, SkinnyLoad, SuggestedLoad} from "../interfaces/Load";
import GoogleRouteMap from "./GoogleRouteMap";
import {useRecoilState} from "recoil";
import {routeMapAvailableState, showRouteMapState} from "./openloads.state";
import SuggestedLoads from "./SuggestedLoads";

type PropType = {
    loadsInLane: SkinnyLoad[];
    load: Load;
}

const LaneContainer = ({load, loadsInLane}: PropType) => {
    const [rate, setRate] = useState<RateWithHistorical>();
    const [suggestedLoads, setSuggestedLoads] = useState<SuggestedLoad[]>([]);
    const [showRouteMap] = useRecoilState(showRouteMapState);
    const [, setRouteMapAvailable] = useRecoilState(routeMapAvailableState);


    useAbortableEffect((status) => {
        if (load) {
            const origin = load.stops[0].address;
            const destination = load.stops[load.stops.length - 1].address;
            getRate(origin, destination, load.payloadType).then(rate => {
                if (!status.aborted) {
                    setRate(rate);
                }
            });

            getOtherSuggestedLoads(load.id).then(loads => {
               if(!status.aborted) {
                    setSuggestedLoads(loads);
               }
            });

            setRouteMapAvailable(origin.latitude !== null && destination.latitude != null);
        }
    }, [load]);

    if (load) {
        return <>
            <SuggestedLoads loads={suggestedLoads} />
            <LoadStops load={load ? load : null} loadsInLane={loadsInLane}/>
            <LaneRate rate={rate} load={load}/>
            {showRouteMap ? <GoogleRouteMap load={load}/> : null}
            <LaneActivity load={load ? load : null}/>
        </>
    } else {
        return <div>No load set yet.</div>
    }
}

export default LaneContainer;