import React, {useEffect, useState} from "react"
import {Shipper} from "../interfaces/Load";
import {downloadFacilityData, getFacilities, getShippers, searchFacilities, uploadFacilityData} from "./facility-services";
import {
    Autocomplete,
    Button,
    CircularProgress,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    TablePagination,
    TextField,
    Tooltip,
    IconButton,
    Rating,
    Typography,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {styled} from "@mui/system";
import HelpIcon from "@mui/icons-material/Help";
import {Facility} from "../interfaces/Facility";
import {useHistory, useLocation} from "react-router-dom";
import useDebounce from "./useDebounce";
import { Download, Upload } from "@mui/icons-material";
import Loading from "../common/Loading";


const FilterBar = styled('div')`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  padding: 0 15px 15px 15px;
  border-bottom: 1px solid #ccc;
`

type FacilitiesListPanelTypes = {
    incomplete: boolean;
}

const FacilityList = styled(List)`
  padding: 0;
`

const FacilityListItemButton = styled(ListItemButton)`
  padding: 0;
`

const FacilityListItem = styled(ListItem)`
  &.header div {
    font-weight: bold;
  }

  padding: 20px 15px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: start;
`
const STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
    'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD',
    'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];


const FacilitiesListPanel = ({incomplete}: FacilitiesListPanelTypes) => {

    const history = useHistory();
    const location = useLocation();

    const facilityStatus = incomplete ? 'INCOMPLETE' : 'ACTIVE';

    const [facilityFilter, setFacilityFilter] = useState("");
    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [shippers, setShippers] = useState<Shipper[]>([]);
    const [states, setStates] = useState<string[]>([]);
    const [shippersLoading, setShippersLoading] = useState(true);
    const [statesLoading, setStatesLoading] = useState(true);
    const [shipperOpen, setShipperOpen] = useState(false);
    const [stateOpen, setStateOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [facilitiesCount, setFacilitiesCount] = useState(0);

    // what's been searched for so that pagination is consistent until they click search again
    const [currentFacilityFilter, setCurrentFacilityFilter] = useState("");
    const [currentShipperFilter, setCurrentShipperFilter] = useState<Shipper | null>(null);
    const [currentStateFilter, setCurrentStateFilter] = useState<string | null>(null);

    //use debounce here by observing val
    const debouncedVal = useDebounce(facilityFilter, 500);
    const _handler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setFacilityFilter(newVal);
    };

    useEffect(() => {
        getFacilities(facilityStatus, 0, 10).then(results => {
            setFacilitiesCount(results.totalElements);
            setFacilities(results.content);
        });
    }, [facilityStatus]);

    useEffect(() => {
        getShippers().then(shippers => {
            setShippersLoading(false);
            setShippers(shippers);
        }).catch(console.log);
    }, []);

    useEffect(() => {
        setStatesLoading(false);
        setStates(STATES);
    }, []);

    useEffect(() => {
        searchFacilities(currentFacilityFilter, currentShipperFilter, currentStateFilter, facilityStatus, page, rowsPerPage)
            .then(results => {
                setFacilitiesCount(results.totalElements);
                setFacilities(results.content);
            });
    }, [facilityStatus, currentShipperFilter, currentFacilityFilter,currentStateFilter, rowsPerPage, page]);

    //only set the values when debouncedVal changes and print them.
    useEffect(() => {
        setCurrentFacilityFilter(facilityFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedVal]);

    // when the button is pressed, store the filter values and execute the request
    // with those values.  If user paginates it will perform the search to the next
    // prev page with these filter values
    // function searchNow() {
    //     setCurrentFacilityFilter(facilityFilter);
    //     // setCurrentShipperFilter(shipperFilter);
    // }

    function clearAll() {
        setCurrentShipperFilter(null);
        setFacilityFilter("");
        setCurrentStateFilter(null);
    }

    const createFacility = () => {
        history.push(location.pathname + `/create`);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadExcel = () => {
        setLoading(true);
        downloadFacilityData(currentFacilityFilter, currentShipperFilter, currentStateFilter, facilityStatus)
        .then(excelData => {
            const byteString = new TextDecoder().decode(excelData);
            const arrayBuffer = base64ToArrayBuffer(byteString)
            var byteArray = new Uint8Array(arrayBuffer);
            var a = window.document.createElement('a');   
            a.href = window.URL.createObjectURL(new Blob([byteArray], { type:'application/octet-stream' }));
            a.download = `Facility_List_${Date.now()}.xlsx`;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            setLoading(false);
        }).catch(error => console.error(error));
    }

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
     }

     function uploadExcel(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            const excelData = reader.result !== null ? reader.result as string : ''
            setLoading(true);
            uploadFacilityData(excelData.split(";base64,")[1]).then(res => {
/*                console.log(res)
                 const snackBar = {snackbarOpen: true, snackbarType: 'success', snackbarMessage: res.statusDesc};
                <SnackBar snackBar={snackBar}/>*/
                setLoading(false);
            }).catch(error => console.error(error));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

    interface FacilityLineItemParm {
        facility: Facility;
    }

    const FacilityLineItem = ({facility}: FacilityLineItemParm) => {
        const location = useLocation();
        const history = useHistory();

        const handleFacilitySelected = (event) => {
            history.push(location.pathname + `/${facility.id}`);
        }

        return <FacilityListItemButton onClick={(evt) => handleFacilitySelected(evt)}>
            <FacilityListItem>
                <div>{facility.name}</div>
                <div>
                    {facility.rating && facility.rating > 0 ?
                    <Rating
                        name="text-feedback"
                        defaultValue={facility.rating}
                        readOnly
                        precision={0.5}
                    />
                    : '' }
                </div>
                <div>{facility.waitTime && facility.waitTime !== '' ? facility.waitTime  : '' }</div>
                <div>{facility.shippers ? facility.shippers.length > 0 ? facility.shippers.map(o => o.name).join(','): null: null}</div>
                <div>{facility.facilityType != null? facility.facilityType["name"] : null }</div>
                <div>{facility.address.city}</div>
                <div>{facility.address.state}</div>
                <div>{facility.address.zipCode}</div>
            </FacilityListItem>
        </FacilityListItemButton>
    }

    return <div>
        <div>
            <FilterBar>
                <Autocomplete sx={{width: "300px"}}
                              open={shipperOpen}
                              onOpen={() => {
                                  setShipperOpen(true)
                              }}
                              onClose={() => {
                                  setShipperOpen(false);
                              }}
                              value={currentShipperFilter}
                              onChange={(evt, value) => {
                                setCurrentShipperFilter(value);
                            } }
                              options={shippers}
                              loading={shippersLoading}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      label="Filter By Torch Client"
                                      InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                              <React.Fragment>
                                                  {shippersLoading ?
                                                      <CircularProgress color="inherit" size={20}/> : null}
                                                  {params.InputProps.endAdornment}
                                              </React.Fragment>
                                          ),
                                      }}
                                  />
                              )}
                />
                <Autocomplete sx={{width: "300px"}}
                              open={stateOpen}
                              onOpen={() => {
                                  setStateOpen(true)
                              }}
                              onClose={() => {
                                setStateOpen(false);
                              }}
                              value={currentStateFilter}
                              onChange={(evt, value) => {
                                setCurrentStateFilter(value);
                            } }
                              options={states}
                              loading={statesLoading}
                              isOptionEqualToValue={(option, value) => option === value}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      label="Filter By State"
                                      InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                              <React.Fragment>
                                                  {statesLoading ?
                                                      <CircularProgress color="inherit" size={20}/> : null}
                                                  {params.InputProps.endAdornment}
                                              </React.Fragment>
                                          ),
                                      }}
                                  />
                              )}
                />

                <TextField sx={{width: "400px"}}
                           className={"filter-entry"}
                           variant={"outlined"}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="start">
                                       <Tooltip title={<div>Searching with Filter:
                                           {/* <br/>&#8226;&nbsp;Enter (2) CAPITAL letters to search by STATE */}
                                           <br/>&#8226;&nbsp;Enter anything else to search by name, city or zip code
                                       </div>}>
                                           <HelpIcon color="primary"/>
                                       </Tooltip>
                                       <IconButton
                                        sx={{ visibility: facilityFilter ? "visible" : "hidden" }}
                                        onClick={() => setFacilityFilter("")}
                                        >
                                        <ClearIcon />
                                        </IconButton>
                                   </InputAdornment>
                           }}
                           value={facilityFilter}
                           onChange={
                            _handler
                            }
                           label={"Filter Facilities by City, Zip Code or Id"}
                           aria-label="Name or Id"
                           autoFocus={true}/>
                <Button variant={"outlined"}
                        onClick={clearAll}>Clear</Button>
                <Button variant={"outlined"}
                        onClick={createFacility}>Create</Button>
                <Tooltip   
                    title={<Typography fontSize={15}>Download Facility Excel</Typography>}
                    placement="top" 
                >
                    <IconButton color="primary" aria-label="download">
                        <Download onClick={downloadExcel}  />
                    </IconButton>
                </Tooltip>
                <Tooltip   
                    title={<Typography fontSize={15}>Upload Excel</Typography>}
                    placement="top" 
                >
                    <IconButton
                    component="label" color="primary" aria-label="download">
                    <input
                        id="upload-image"
                        hidden
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                        onChange={uploadExcel}
                    />

                        <Upload >
                            </Upload>
                        
                    </IconButton>
                </Tooltip>
            </FilterBar>

            <FacilityListItem className={"header"}>
                <div>Name</div>
                <div>Rating</div>
                <div>Avg Wait Time</div>
                <div>Client</div>
                <div>Facility Type</div>
                <div>City</div>
                <div>State</div>
                <div>Zip Code</div>
            </FacilityListItem>
            {loading ? <Loading></Loading> :
            <FacilityList>
                {facilities.map(f => <FacilityLineItem key={f.id} facility={f}/>)}
                <TablePagination
                    className={"facilities-pagination"}
                    component="div"
                    count={facilitiesCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </FacilityList>
            }
        </div>
    </div>
}

export default FacilitiesListPanel;
