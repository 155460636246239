import React, {useEffect, useState} from 'react';
import {addCarrierNote, fetchCarrierNotes} from "../service/carriers";
import {Carrier, CarrierNote} from "../interfaces/Carrier";
import {Fab, List, ListItem, ListItemText} from "@mui/material";
import {dateFormat} from "../service/time";
import {SuccessActionNotification} from "../common/SuccessActionNotification";
import NoteControl from "./NoteControl";
import AddIcon from "@mui/icons-material/Add";
import NoContentPanel from "../common/NoContentPanel";

type PropTypes = {
    carrier: Carrier
}

export const CarrierNotes = ({carrier}: PropTypes) => {
    const [notes, setNotes] = useState<CarrierNote[]>();
    const [noteSaved, setNoteSaved] = useState<boolean>(false);
    const [noteControlOpen, setNoteControlOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchCarrierNotes(carrier.id).then(notes => setNotes(notes));
    }, [carrier]);

    const addNote = (note):Promise<boolean> => {
        return new Promise((resolve) => addCarrierNote(carrier.id, note)
            .then(note => {
                setNoteSaved(true);
                fetchCarrierNotes(carrier.id)
                    .then(notes => {
                        setNotes(notes);
                        resolve(true);
                    })
            }));
    }

    const NoteList = () => {
        if (notes && notes.length > 0) {
            return <List>{notes.map(note => {
                return (<ListItem key={note.id} sx={{padding: "10px"}}>
                    <ListItemText>
                        {dateFormat(note.created)} - {note.note}
                    </ListItemText>
                </ListItem>);
            })}</List>
        }

        return <NoContentPanel>No Notes</NoContentPanel>;
    }

    const openNoteCtrl = () => {
        setNoteControlOpen(true);
    }

    return (<>
        <NoteControl noteControlOpen={noteControlOpen}
                     saveFn={addNote}
                     cancelFn={() => setNoteControlOpen(false)}/>

        <NoteList />
        <SuccessActionNotification message={"Note Saved"}
                                   open={noteSaved}
                                   close={() => setNoteSaved(false)}/>

        <Fab className={"add-note-icon"}
             size={"small"}
             aria-label={"Add"}
             color={"primary"}
             onClick={openNoteCtrl}>
            <AddIcon/>
        </Fab>
    </>);
}
