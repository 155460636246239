import React, {useEffect, useState} from "react";
import {ChargeLineItem, Load, LoadRep, Stop} from "../interfaces/Load";
import {
    buildSingleDocument,
    getDatRateForLoad,
    getLoad,
    getLoadLineItems,
    getLoadReps,
    getShipmentDocuments,
    getTNTRequestForLoad,
    requestShipwellResync,
    sendSMSToDriver
} from "../service/loads";
import {useParams} from "react-router-dom";
import {styled} from "@mui/system";
import SectionContainer from "../common/SectionContainer";
import "./Load.scss";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    LinearProgress,
    Link,
    Paper,
    Snackbar,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ShipwellEvents from "./ShipwellEvents";
import EdiDocuments from "./EdiDocuments";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Moment from "react-moment";
import {getTimeFormat} from "../service/time";
import {CarrierLink, TorchShipmentExternalLink} from "../common/Links";
import {getStatusLabel} from "../util/load-utils";
import {green, red} from "@mui/material/colors";
import {LocationSearchingTwoTone} from "@mui/icons-material";
import {DatRateForLoad, TNTRequest} from "../interfaces/RateData";
import DatRate from "../common/DatRate";
import LoadNotesContainer from "./LoadNotesContainer";
import {ACCESS_TOKEN, API_BASE_URL} from "../constants";
import {ShipmentDocument} from "../interfaces/ShipmentDocument";

const LoadLayout = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto;
  grid-gap: 10px;
  grid-template-areas:
        "status status"
        "details actions"
        "details notes"
`

const Status = styled('div')`
  grid-area: status;
`

const Actions = styled('div')`
  grid-area: actions;
`

const Details = styled('div')`
  grid-area: details;
`

const LineItemTableCell = styled(TableCell)`
  font-size: 1rem;
`

const ActionDivider = styled('div')`
  height: 15px;
  width: 100%;
`

const ActionLink = styled(Link)`
  cursor: pointer;
`

const FinancialDetailsContainer = styled('div')`
  margin-top: 20px;
`

const LoadContainer = () => {

    let {loadId} = useParams<{ loadId: string }>();
    const [load, setLoad] = useState<Load>();
    const [lineItems, setLineItems] = useState<ChargeLineItem[]>([]);
    const [loadReps, setLoadReps] = useState<LoadRep[]>([]);
    const [datRate, setDatRate] = useState<DatRateForLoad | null>();
    const [tntRequest, setTntRequest] = useState<TNTRequest | null>({count:0});

    const [refreshing,setRefreshing] = useState(false);
    const [refreshingStatusOpen, setRefreshingStatusOpen] = useState(false);
    const [refreshingError, setRefreshingError] = useState(false);
    const [refreshingErrorMessage, setRefreshingErrorMessage] = useState('');


    useEffect(() => {
        if (loadId) {
            getLoad(Number(loadId)).then(setLoad);
            getLoadLineItems(Number(loadId)).then(setLineItems);
            getLoadReps(Number(loadId)).then(setLoadReps);
            getDatRateForLoad(Number(loadId)).then(setDatRate);
            getTNTRequestForLoad(Number(loadId)).then(setTntRequest);
        }
    }, [loadId]);

    interface LoadNameParams {
        load: Load
    }

    const LoadName = ({load}: LoadNameParams) => {
        return <>
            (<TorchShipmentExternalLink shipwellId={load.shipwellId}>
            {load.shipwellRefId}
        </TorchShipmentExternalLink>) &nbsp; {load?.name ? load.name : 'No name provided'}
        </>;
    }

    const LoadStatus = () => {
        if (load) {
            return <Status>
                <SectionContainer>
                    {load.tracked ?
                        <Typography>
                            <Tooltip title={"Tracking available"}>
                                <LocationSearchingTwoTone fontSize={"small"} sx={{
                                    color: green[500],
                                    paddingBottom: "2px",
                                    paddingRight: "5px",
                                    verticalAlign: 'middle'
                                }}/>
                            </Tooltip>
                            {getStatusLabel(load.loadState)}
                        </Typography>
                        :
                        <Typography className={"tracked"}>
                            <Tooltip title={"Tracking not available"}>
                                <LocationSearchingTwoTone sx={{
                                    color: red[500],
                                    paddingBottom: "2px",
                                    paddingRight: "5px",
                                    verticalAlign: 'middle'
                                }}/>
                            </Tooltip>
                            {getStatusLabel(load.loadState)}
                        </Typography>
                    }
                    <LabelValue layoutType={"status"} label={"Load"} value={<LoadName load={load}/>}/>
                </SectionContainer>
            </Status>
        }

        return null;
    }

    function refreshLoad() {
        if (load) {
            setRefreshing(true);
            // want to make this more compact but also abstract it out so that all requests can use it
            // such as the getLoad/getLoadLineItems below - they should be able to push the snackbar up
            // as well if there is a failure but nothing if it loads successfully
            requestShipwellResync(load.shipwellRefId).then(() => {
                setRefreshing(false);
                setRefreshingError(false);
                setRefreshingErrorMessage('');
                setRefreshingStatusOpen(true);

                getLoad(Number(loadId)).then(setLoad);
                getLoadLineItems(Number(loadId)).then(setLineItems);
                getLoadReps(Number(loadId)).then(setLoadReps);
            }).catch((e) => {
                setRefreshing(false);
                setRefreshingError(true);
                setRefreshingErrorMessage(`Response status ${e.status}. Message: ${JSON.stringify(e)}`);
                setRefreshingStatusOpen(true);
            });
        }
    }

    function handleRefreshingStatusClose() {
        setRefreshingStatusOpen(false);
    }




    const LoadActions = () => {
        const [phoneValue, setPhoneValue] = useState(load?.driverPhoneNumber);
        const [smsSent, setSmsSent] = useState(false);

        const [shipmentDocuments, setShipmentDocuments] = useState<ShipmentDocument[]>([]);
        const [selected, setSelected] = useState<string[]>([]);

        useEffect(() => {
            if (load?.id) {
             getShipmentDocuments(load.id).then(setShipmentDocuments);
            }
        }, []);


        const handlePhoneValueChange = (event: any) => {
            setPhoneValue(event.target.value);
        };

        function handleSMSStatusClose() {
            setSmsSent(false);
        }

        function sendSMS() {
            if (load?.id && phoneValue) {
                if (window.confirm("Really send an SMS to " + phoneValue + " ?"))
                    sendSMSToDriver(load?.id, phoneValue,"download").then(tntRequestResponse => {
                        setSmsSent(true);
                        setTntRequest(tntRequestResponse);
                    })
            }
        }

/*        const sendInvoice = () => {
            setRefreshing(true);
            sendInvoiceFromJetpack(load?.id).then((response) => {
                setRefreshing(false);
            })
        }*/

        function sendSMSInstall() {
            if (load?.id && phoneValue) {
                if (window.confirm("Really send an install  SMS to " + phoneValue + " ?"))
                    sendSMSToDriver(load?.id, phoneValue, "install").then(tntRequestResponse => {
                        setSmsSent(true);
                        setTntRequest(tntRequestResponse)
                    })
            }
        }

        function buildSelected() {
            if (load?.id) {

                const link = document.createElement("a");
                link.target = "_blank";
                link.download = `${load.shipwellRefId}_combined.pdf`;

                buildSingleDocument(load.id,
                    shipmentDocuments.filter(ship =>
                        selected.find(sel => ship.fileName === sel) !== undefined))
                    .then((res) => {
                        link.href = URL.createObjectURL(
                            new Blob([res], { type: "application/pdf" })
                        );
                        link.click();
                    });
            }
        }

        const selectDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
            console.log("*** 0 size: " + selected.length + " " + JSON.stringify(selected) + " : " + event.target.name);
            if (event.target.checked) {
                    setSelected(prevState => [...prevState, event.target.name]);
                } else {
                    setSelected(selected.filter(i => i !== event.target.name));
                }
            console.log("*** 1 size: " + selected.length + " " + JSON.stringify(selected)+ " : " + event.target.name);
        };

        return <Actions>
            <SectionContainer title={"Actions"}>
                <div>
                    {refreshing ? <LinearProgress/> : null}
                    <ActionLink variant={"body2"} onClick={() => refreshLoad()}>Resync with Shipwell</ActionLink>
                    {refreshingStatusOpen ?
                        <Snackbar open={refreshingStatusOpen}
                                  autoHideDuration={6000}
                                  onClose={handleRefreshingStatusClose}
                                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                            <Alert onClose={handleRefreshingStatusClose}
                                   severity={refreshingError ? "error" : "success"}
                                   sx={{width: '100%'}}>
                                {refreshingError ? refreshingErrorMessage : "Load refreshed with Shipwell successfully"}
                            </Alert>
                        </Snackbar>
                        : null}
                </div>
                <ActionDivider/>
                <div>
                    <Link variant={"body2"} onClick={() => alert('Not Implemented Yet')}>Send EDI 214 Update</Link>
                </div>
                <div>
                    <Link variant={"body2"} onClick={() => alert('Not Implemented Yet')}>Send EDI 210 Update</Link>
                </div>

                <Snackbar open={smsSent}
                          autoHideDuration={6000}
                          onClose={handleSMSStatusClose}
                          message={"SMS sent to driver!"}
                          anchorOrigin={{vertical: 'top', horizontal: 'center'}}/>

                <Card sx={{marginTop:2, border:1, borderColor:red}}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Send a Track and Trace Message
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontColor:red}} color="text.secondary" gutterBottom>
                            SMS message has been sent {tntRequest?.count} times;
                        </Typography>
                        <TextField sx={{marginTop:2}} id="outlined-basic" value={phoneValue}  onChange={handlePhoneValueChange} label="Phone" variant="outlined" />
                        <Button sx={{marginTop:2}} onClick={sendSMS} variant="contained">Send SMS App</Button>

                        { tntRequest != null && tntRequest?.count && tntRequest?.count > 0 ?
                            <Button sx={{marginTop:2}} onClick={sendSMSInstall} variant="contained">Send SMS Install</Button>
                            :
                            <></>
                        }
                    </CardContent>
                </Card>

                <Card sx={{marginTop:2, border:1, borderColor:red}}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Invoice ({(load?.documentsCount != null && load?.documentsCount > 0) ? load?.documentsCount : 0}) and Documents
                        </Typography>
{/*                        <Button href={API_BASE_URL + `/invoice/zip/${load?.id}?torch_auth=` + localStorage.getItem(ACCESS_TOKEN)} variant="contained">Download Invoice</Button>
                        <Button sx={{marginTop:2}} href={API_BASE_URL + `/invoice/pdf/${load?.id}?torch_auth=` + localStorage.getItem(ACCESS_TOKEN)} variant="contained">Download PDF</Button>*/}
                        <Button sx={{marginTop:2, marginBottom:2}} href={API_BASE_URL + `/invoice/pdf/${load?.id}?torch_auth=` + localStorage.getItem(ACCESS_TOKEN)} variant="contained">Download Single PDF</Button>

                          {/* <Button sx={{marginTop:2, marginBottom:2}} onClick={sendInvoice} variant="contained">Send Invoice</Button>*/}

                        <Box sx={{border:1}}>
                        <Box sx={{ display: 'flex' }}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Select documents to download:</FormLabel>
                                <FormGroup>
                                    { shipmentDocuments.map(shipmentDocument =>
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={selectDocument} value={shipmentDocument.fileName} name={shipmentDocument?.fileName} />
                                        }
                                        label={shipmentDocument?.fileName}
                                    />
                                    )}
                                </FormGroup>
                            </FormControl>
                        </Box>
                            <Button sx={{margin:1, padding:1}} onClick={buildSelected} variant="contained">Download {selected.length} files as Single PDF</Button>
                        </Box>
                    </CardContent>
                </Card>

            </SectionContainer>
        </Actions>
    }

    interface LVParams {
        layoutType?: string;
        label: string;
        value: string | React.ReactNode;
    }

    function LabelValue({label, value, layoutType = "default"}: LVParams) {
        return <div className={`datapoint ${layoutType}`}>
            <span className={"label"}>{label}</span>
            <span className={"value"}>{value}</span>
        </div>
    }

    const CarrierBox = styled(Box)`
      display: flex;
      align-items: center;
    `

    const CarrierChip = styled(Chip)`
      margin-right: 5px;
    `

    const CarrierOnLoad = ({load}: LoadNameParams) => {

        const [showOperatingCarrier, setShowOperatingCarrier] = useState(true);

        function handleClick() {
            setShowOperatingCarrier(!showOperatingCarrier);
        }

        // if the operating carrier is set and is different than the carrier id of record then want to provide
        // a way for the user to see that a different carrier ran the load than was paid
        // not meant for double brokering scenarios but more for situations like Convoy
        if (load.carrier && load.operatingCarrier && load.operatingCarrier.id !== load.carrier.id) {
            return showOperatingCarrier ?
                <Tooltip title={"Operating Carrier for load. Different from Carrier of Record"}>
                    <CarrierBox>
                        <CarrierChip label="OC" size="small" onClick={handleClick}/>
                        <CarrierLink carrier={load.operatingCarrier}>{load.operatingCarrier?.name}</CarrierLink>
                    </CarrierBox>
                </Tooltip> :
                <Tooltip title={"Carrier of Record for load. Not the Operating Carrier"}>
                    <CarrierBox>
                        <CarrierChip label="CR" size="small" onClick={handleClick}/>
                        <CarrierLink carrier={load.carrier}>{load.carrier.name}</CarrierLink>
                    </CarrierBox>
                </Tooltip>
        }

        if (load.carrier) {
            return <CarrierLink carrier={load.carrier}>{load.carrier?.name}</CarrierLink>;
        }

        return <Box sx={{fontStyle: "italic"}}>No Carrier Assigned</Box>;
    }

    const Info = () => {
        const LayoutContainer = styled('div')`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "details line-items";
        `
        const DetailsContainer = styled('div')`
          grid-area: details;
        `
        const LineItemsContainer = styled('div')`
          grid-area: line-items;
        `

        function currency(label: string): string {
            return label === "USD" ? "$" : "";
        }

        function money(cents: number): string {
            return (cents / 100).toFixed(2);
        }

        interface StopDetailsParams {
            stop: Stop
        }

        const LoadReps = () => {
            return <>
                {loadReps.map(loadRep =>
                    <div key={loadRep.userId}>{loadRep.name} ({loadRep.roleName})</div>
                )}
            </>
        }

        const StopDetails = ({stop}: StopDetailsParams) => {
            return <>
                <Typography>{stop.address.streetOne}</Typography>
                <Typography>{stop.address.city}, {stop.address.state} {stop.address.zipCode}</Typography>
                <Typography><Moment format={getTimeFormat()}
                                    tz={stop.timeZoneId}>{stop.arrival}</Moment></Typography>
            </>
        }

        if (load) {
            return <LayoutContainer>
                <DetailsContainer>
                    <LabelValue label={"Customer"} value={load.shipper.name}/>
                    <LabelValue label={"Carrier"} value={<CarrierOnLoad load={load}/>}/>
                    <LabelValue label={"Pickup"} value={<StopDetails stop={load.stops[0]}/>}/>
                    <LabelValue label={"Delivery"} value={<StopDetails stop={load.stops[1]}/>}/>
                    <LabelValue label={"Equipment Type"} value={load.payloadType}/>
                    <LabelValue label={"Reps"} value={<LoadReps />} />
                </DetailsContainer>
                <LineItemsContainer>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <>
                                    <TableRow>
                                        <LineItemTableCell colSpan={4}><h4>Shipper Revenue</h4></LineItemTableCell>
                                    </TableRow>
                                    {lineItems.filter(li => li.type === 'VENDOR_CHARGE')
                                        .map(li => <TableRow key={li.id}>
                                            <LineItemTableCell>{li.unitName}</LineItemTableCell>
                                            <LineItemTableCell>{li.unitQuantity}</LineItemTableCell>
                                            <LineItemTableCell>{currency(li.unitAmountCurrency)}{li.unitAmount}</LineItemTableCell>
                                            <LineItemTableCell>{currency(li.unitAmountCurrency)}{money(li.effectiveAmount)}</LineItemTableCell>
                                        </TableRow>)}
                                    <TableRow>
                                        <LineItemTableCell colSpan={4}><h4>Carrier Costs</h4></LineItemTableCell>
                                    </TableRow>
                                    {lineItems.filter(li => li.type === 'CUSTOMER_CHARGE')
                                        .map(li => <TableRow key={li.id}>
                                            <LineItemTableCell>{li.unitName}</LineItemTableCell>
                                            <LineItemTableCell>{li.unitQuantity}</LineItemTableCell>
                                            <LineItemTableCell>{currency(li.unitAmountCurrency)}{li.unitAmount}</LineItemTableCell>
                                            <LineItemTableCell>{currency(li.unitAmountCurrency)}{money(li.effectiveAmount)}</LineItemTableCell>
                                        </TableRow>)}

                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <FinancialDetailsContainer>
                        <LabelValue label={"Max Buy"} value={load.maxBuy ? "$" + money(load.maxBuy) : "N/A"} />
                        <LabelValue label={"But It Now"} value={load.buyItNow ? "$" + money(load.buyItNow) : "N/A"} />
                        <LabelValue label={"DAT Rate"} value={datRate ? <DatRate level={"full"} rate={datRate} /> : "N/A"} />
                    </FinancialDetailsContainer>
                </LineItemsContainer>
            </LayoutContainer>;
        }
        return null;
    }

    const Events = () => {
        if (load) {
            return <ShipwellEvents load={load}/>
        }

        return null;
    }

    const EDI = () => {
        if (load) {
            return <EdiDocuments load={load}/>
        }

        return null;
    }

    const LoadDetails = () => {
        const [value, setValue] = useState("info");

        const handleChange = (v, e) => setValue(e);

        if (load) {
            return <Details>
                <SectionContainer fullBleed={true}>
                    <Box sx={{width: '100%', typography: 'body1'}}>
                        <TabContext value={value}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Info" value="info"/>
                                    <Tab label="Events" value="events"/>
                                    <Tab label="EDI" value="edi"/>
                                    <Tab label="Notes" value="notes"/>
                                </TabList>
                            </Box>
                            <TabPanel value="info"><Info/></TabPanel>
                            <TabPanel value="events"><Events/></TabPanel>
                            <TabPanel value="edi"><EDI/></TabPanel>
                            <TabPanel value="notes"><LoadNotesContainer load={load}></LoadNotesContainer></TabPanel>
                        </TabContext>
                    </Box>
                </SectionContainer>
            </Details>
        }

        return null;
    }

    if (load) {
        return <LoadLayout>
            <LoadStatus/>
            <LoadActions/>
            <LoadDetails/>
        </LoadLayout>
    }
    return <div>
        {load ? <></> : null}
    </div>;
}

export default LoadContainer;
