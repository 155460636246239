import React from "react";
import "./Settings.scss"
import Typography from "@mui/material/Typography";

const SettingsEdi = () => {

    return  <div className={"settings-edi"}>
        <Typography variant="h6" className={"title"} noWrap>
            EDI
        </Typography>
        <Typography variant="body1" className={"content"} noWrap>
            Placeholder for EDI behavior settings such as default filter, etc.
        </Typography>
    </div>;
}

export default SettingsEdi;
