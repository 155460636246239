import React from "react";
import {format} from "date-fns";

const RateChartToolTip = (props) => {
    const {active, payload} = props;
    if (active) {
        const currData = payload && payload.length ? payload[0].payload : null;
        return (
            <div className="rate-chart-tooltip">
                <p>{currData
                    ? format(new Date().setTime(currData.timeIndex), "MMM yyyy")
                    : " -- "}</p>
                <p className={"rate"}>Rate: ${currData ? currData.rate.toFixed(2) : " -- "}</p>
                <p>High: ${currData ? currData.rateHigh.toFixed(2) : " -- "}</p>
                <p>Low: ${currData ? currData.rateLow.toFixed(2) : " -- "}</p>
                <p>Fuel: ${currData ? currData.fuelSurcharge.toFixed(2) : " -- "}</p>
            </div>
        );
    }

    return null;
};

export default RateChartToolTip;
