import React, {Component} from 'react';
import {ACCESS_TOKEN} from '../constants';
import {Redirect} from 'react-router-dom'

class OAuth2RedirectHandler extends Component {

    getUrlParameter(name) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    render() {
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');

        if(token) {
            console.log('setting access token');
            localStorage.setItem(ACCESS_TOKEN, token);
            return <Redirect to={{
                pathname: "/home",
                state: { from: this.props.location }
            }}/>;
        } else {
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: this.props.location,
                    error: error
                }
            }}/>;
        }
    }
}

export default OAuth2RedirectHandler;
