import {useEffect, useRef} from "react";

export function useAbortableEffect(effect, dependencies) {
    const status = {}; // mutable status object
    useEffect(() => {
        status.aborted = false;
        // pass the mutable object to the effect callback
        // store the returned value for cleanup
        const cleanUpFn = effect(status);
        return () => {
            // mutate the object to signal the consumer
            // this effect is cleaning up
            status.aborted = true;
            if (typeof cleanUpFn === "function") {
                // run the cleanup function
                cleanUpFn();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies]);
}

export default function useKeypress(key, action, dependencies) {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === key) action()
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
