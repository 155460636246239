/* General utility functions */

export function copyToClipboard(text) {
    return navigator.clipboard.writeText(text);
}

export function formatPhoneNumber(phone: string): string {
    if (phone) {
        let result = "";
        let nextIdx: number = 0;
        if (phone.startsWith("+1")) {
            nextIdx = 2;
        }
        result += "(" + phone.substr(nextIdx, 3) + ") ";
        result += phone.substr(nextIdx + 3, 3);
        result += "-";
        result += phone.substr(nextIdx + 6);

        return result;
    }

    return phone;
}

/**
 * When you need to return a promise but already know that the situation will be an error.
 *
 * @param msg
 */
export function promiseError<T>(msg: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        reject(msg);
    });
}

const DAYS_TO_ABBREV = {
    "MONDAY": "mon",
    "TUESDAY": "tue",
    "WEDNESDAY": "wed",
    "THURSDAY": "thu",
    "FRIDAY": "fri",
    "SATURDAY": "sat",
    "SUNDAY": "sun"
}

export function dowAbbreviation(dayOfWeek: string) {
    return DAYS_TO_ABBREV[dayOfWeek.toUpperCase()];
}

const DAY_OF_WEEK = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

export function dayOfWeek(dowIndex: number): string {
    return DAY_OF_WEEK[dowIndex];
}