import {ACCESS_TOKEN, API_BASE_URL} from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then((response: Response) => {
            let clone = response.clone(); // allows re-reading body elsewhere
                return clone.json()
                    .then(json => {
                        if (!response.ok) {
                            return Promise.reject(response);
                        }
                        return json;
                    })
                    .catch(json_err => {
                        // we might have a 200 with empty body which bombs json parsing.
                        if (response.ok) {
                            return Promise.resolve();
                        }
                        return Promise.reject(response);
                    });
            }
        );
};

export const requestBytes = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then((response: any) => {
            let clone = response.clone(); // allows re-reading body elsewhere
            return clone.arrayBuffer()
            }
        );
};

export function getRate(rateData) {
    return torchFetch({
        url: API_BASE_URL + "/entity/rate_quote",
        method: 'post',
        body: JSON.stringify(rateData)
    });
}

export function analyzeEmail(emailData) {
    return torchFetch({
        url: API_BASE_URL + "/entity/email",
        method: 'post',
        body: JSON.stringify(emailData)
    });
}

export function torchFetch(reqOptions) {
    return request(reqOptions);
}

export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function saveOffer(loadId: string, carrierId: string, amount: number) {
    return request({
        url: API_BASE_URL + `/load/${loadId}/carrier/${carrierId}/rate`,
        method: 'POST',
        body: JSON.stringify({
            amount: amount,
            party: 'CARRIER',
            occurredAt: new Date().toISOString()
        })
    })
}

export function updateOffer(loadId: string, carrierId: string, offerId: string, amount: number) {
  return request({
    url: API_BASE_URL + `/load/${loadId}/carrier/${carrierId}/rate`,
    method: 'PUT',
    body: JSON.stringify({
      id: offerId,
      amount: amount,
      party: 'CARRIER',
      occurredAt: new Date().toISOString()
    })
  })
}

export function deleteOffer(loadId: string, carrierId: string, offerId: string) {
    return request({
        url: API_BASE_URL + `/load/${loadId}/carrier/${carrierId}/rate/${offerId}`,
        method: 'DELETE'
    })
}

export function declineLoad({loadId, carrierId, reasons}) {
    return request({
        url: API_BASE_URL + `/load/${loadId}/decline`,
        method: 'POST',
        body: JSON.stringify({
            loadId: loadId,
            carrierId: carrierId,
            reasons: reasons
        })
    })
}

export function saveFacility(facilityData) {
    return torchFetch({
        url: API_BASE_URL + "/facility",
        method: 'post',
        body: JSON.stringify(facilityData)
    });
}

export function sendBid(bidId: string, rate:number) {
    return request({
        url: `${API_BASE_URL}/integrations/bid`,
        method: 'POST',
        body: JSON.stringify({
            load_id: bidId,
            rate: rate,
            action: "BID"
        })
    })
}

export function sendBadBid(bidId: string, responseData:any) {
    return request({
        url: `${API_BASE_URL}/integrations/bid/feedback/${bidId}`,
        method: 'POST',
        body: JSON.stringify(responseData)
    })
}

