import React, {useEffect, useState} from "react";
import {Alert, Autocomplete, Button, Card, CardContent, MenuItem, Rating, Select, Snackbar, TextField, TextareaAutosize, Typography} from "@mui/material";
import { Stack } from "@mui/system";
import FacilityAllRatings from "./FacilityAllRatings";
import { AllRatings } from "../interfaces/Facility";
import { addUpdateFacilityRating, getAllFacilityRatings, getLoads } from "./facility-services";

type FacilityId = {
    facilityId: number;
    updateFacilityDetails: () => void;
}

interface PeerReview {
    id: number;
    value: string;
}

interface WaitTime {
    id: number;
    value: string;
}

const labels: { [index: number]: PeerReview[] } = {
    0.5: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Very Slow Turnaround Time"},{id:3, value: "Incorrect Paperwork"}, {id:4, value: "Poor Facility Conditions / Operations"}],
    1: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Very Slow Turnaround Time"},{id:3, value: "Incorrect Paperwork"}, {id:4, value: "Poor Facility Conditions / Operations"}],
    1.5: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Very Slow Turnaround Time"},{id:3, value: "Incorrect Paperwork"}, {id:4, value: "Poor Facility Conditions / Operations"}],
    2: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Slow Turnaround"}, {id:3, value: "Incorrect Paperwork"},{id:4, value: "Average Facility Conditions / Operations"}],
    2.5: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Slow Turnaround"}, {id:3, value: "Incorrect Paperwork"},{id:4, value: "Average Facility Conditions / Operations"}],
    3: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Slow Turnaround"}, {id:3, value: "Incorrect Paperwork"},{id:4, value: "Average Facility Conditions / Operations"}],
    3.5: [{id:0, value: "Limited Dock Doors"}, {id:1, value: "Limited Accessibility"}, {id:2, value: "Slow Turnaround"}, {id:3, value: "Incorrect Paperwork"},{id:4, value: "Average Facility Conditions / Operations"}],
    4: [{id:0, value: "Easy Check In"}, {id:1, value: "Multiple Dock Doors"}, {id:2, value: "Pleasant Employee(s)"},{id:3, value: "Accurate Paperwork"}, {id:4, value: "Quick Turnaround: Checkin to Checkout"}],
    4.5: [{id:0, value: "Easy Check In"}, {id:1, value: "Multiple Dock Doors"}, {id:2, value: "Pleasant Employee(s)"},{id:3, value: "Accurate Paperwork"}, {id:4, value: "Quick Turnaround: Checkin to Checkout"}],
    5: [{id:0, value: "Easy Check In"}, {id:1, value: "Multiple Dock Doors"}, {id:2, value: "Pleasant Employee(s)"},{id:3, value: "Accurate Paperwork"}, {id:4, value: "Quick Turnaround: Checkin to Checkout"}],
  };

const FacilityRatings = ({facilityId,updateFacilityDetails} : FacilityId) => {

    const [rating, setRatings] = useState<number>(0);
    const [isRating, setIsRating] = useState<boolean>(false);
    const [peerReviewNote, setPeerReviewNote] = useState<string>("");
    const [waitTime] = useState<WaitTime[]>([{id: 0, value: "0-0.5 Hrs"}, {id: 1, value: "0.5-1 Hrs"}, {id:2, value: "1-1.5 Hrs"}, {id: 3, value: "1.5-2 Hrs"}, {id:4, value: "2+ Hrs"}])
    const [waitTimeSelected, setWaitTimeSelected] = useState<string>('')
    const [allRatings, setAllRatings] = useState<AllRatings[]>([])
    const [isUserRatingExist, setIsUserRatingExist] = useState<boolean>(false)
    const [loggedInUserId, setLoggedInUserId] = useState<number>(0)
    const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>('')
    const [currentUserRating, setCurrentUserRating] = useState<AllRatings[]>([])
    const [currentLoadID, setCurrentLoadID] = useState<string | null>("");
    const [loadIdList, setLoadIdList] = useState<string[]>([])
    const [selectedRatingComments, setSelectedRatingComments] = useState<string[]>([])
    const [open, setOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [editDataAvailable, setEditDataAvailable] = useState<boolean>(false);

    useEffect(() => {
        fetchAllRatings();
        getLoads(facilityId).then(setLoadIdList);
        const loggedInUserData = JSON.parse(localStorage.getItem('userData') || '{}');
        setLoggedInUserId(loggedInUserData.id)
        setLoggedInUserEmail(loggedInUserData.email)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if(allRatings.length > 0) {
            checkCurrentUserRating();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allRatings])

    const fetchAllRatings = () => {
        getAllFacilityRatings(facilityId).then(setAllRatings);
        // getAllFacilityRatings(facilityId).then(ratings => {
        //     ratings[2].userEmailId = "meghkumar@torch3pl.com";
        //     ratings[2].userId = 2069222;
        //     ratings[2].loadId = "3FSD54135";
        //     setAllRatings(ratings);
        // });

    }

    const checkCurrentUserRating = () => {
        if(allRatings.length > 0) {
            const userRating = allRatings.filter((obj) => obj.userId === loggedInUserId)
            if(userRating.length > 0) {
                setCurrentUserRating(userRating)
                setIsUserRatingExist(true)
            }
        }
    }

    const buttonSelect = (selectedValue:string) => {
        setSelectedRatingComments(oldArray => [...oldArray, selectedValue]);
    }

    const addUserRatings = () => {
        setIsUserRatingExist(false)
        setEditDataAvailable(false)
    }

    const editRatings = (id: number) => {
        const currentUserDetails = currentUserRating.filter(rating => rating.id === id)[0];
        setRatings(currentUserDetails.ratingCount)
        setSelectedRatingComments(currentUserDetails.ratingFeedback)
        // setSelectedRatingComments(['Limited Accessibility', 'Incorrect Paperwork', 'Poor Facility Conditions / Operations'])
        setWaitTimeSelected(currentUserDetails.waitTime)
        setPeerReviewNote(currentUserDetails.additionalNotes)
        setCurrentLoadID(currentUserDetails.loadId)
        setIsRating(true);
        setIsUserRatingExist(false)
        setEditDataAvailable(true)
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    setErrorMessage("");
    };

    const saveNote = () => {
        // console.log(currentUserRating,currentLoadID,selectedRatingComments, currentUserRating.filter(rating => rating.loadId === currentLoadID));
        if(rating === 0 || peerReviewNote === '' || waitTimeSelected === '' || currentLoadID === null || currentLoadID === "") {
            setErrorMessage("Fill all rating details to submit");
            setOpen(true)
        } 
        else if(currentUserRating.filter(rating => rating.loadId === currentLoadID).length > 0 && editDataAvailable === false) {
            setErrorMessage("Facility Review for selected load ID already exist");
            setOpen(true)
        } 
        else {
            const ratings = {
                "ratingCount":      rating,
                "ratingFeedback":   selectedRatingComments,
                "waitTime":     waitTimeSelected,
                "additionalNotes":  peerReviewNote,
                "userId": loggedInUserId,
                "userEmailId": loggedInUserEmail,
                "facilityId":   facilityId,
                // "loadId": currentLoadID
                "loadId": currentLoadID
            }
            addUpdateFacilityRating(ratings, editDataAvailable).then(f => {
                fetchAllRatings()
                updateFacilityDetails()
                // setRatings(0)
                setSelectedRatingComments([])
                setPeerReviewNote("")
            }).catch(err => {
                console.log(err)
                // put up an error on the top of the screen
            });
        }
    }

    return <>
        <div className={"facility-ratings-container"}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </Alert>
            </Snackbar>
            {isUserRatingExist ? '' :
            <Card variant="outlined">
                <CardContent>
                <Typography variant="h5">Add Ratings</Typography>
                </CardContent>
                <CardContent>
                    <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography className="margin-bottom-zero" variant="subtitle2" gutterBottom>
                        Facility Review
                    </Typography>
                    <Rating name="no-value" 
                    value={rating}
                    precision={0.5} 
                    onChange={(event, newValue) => {
                        if(newValue !== null) {
                            setRatings(newValue);
                            setIsRating(true);
                            setSelectedRatingComments([]);
                        }
                    }}
                    />
                </Stack>
                {isRating ?
                <>
                <div className="row">
                {labels[rating].map((st,index) => 
                    <Button 
                        key={st.id}
                        onClick={() => buttonSelect(st.value)}
                        color={selectedRatingComments.includes(st.value) ? "primary" : "secondary"} 
                        className={"peer-suggestion-button"} 
                        variant={selectedRatingComments.includes(st.value) ? "contained" : "outlined"}
                    >
                        {st.value}
                    </Button>)}
                </div>
                </>
                : ''}

                <Stack direction="row" alignItems="center" className={"wait-time"} spacing={2}>
                    <Typography  className="margin-bottom-zero" variant="subtitle2" gutterBottom>
                        Wait Time
                    </Typography>
                    <Select
                                id="waitTIme"
                                name="waitTIme"
                                value={waitTimeSelected}
                                size={"small"}
                                displayEmpty
                                className="wait-time-drop-down"
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={(e) => setWaitTimeSelected(e.target.value)}
                            >
                                <MenuItem 
                                    value="" 
                                    selected={true} 
                                    key={"default"}
                                    >
                                        <em>Select Avg Wait Time</em>
                                    </MenuItem>
                                {waitTime?.map(option => {
                                    return (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    );
                                })}
                    </Select>

                    <Typography  className="margin-bottom-zero" variant="subtitle2" gutterBottom>
                       Load ID
                    </Typography>
                    <Autocomplete
                        disabled={editDataAvailable}
                        id="combo-box-demo"
                        size={"small"}
                        value={currentLoadID}
                        onChange={(evt, value) => {
                            setCurrentLoadID(value);
                        } }
                        options={loadIdList}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params}/>}
                    />

                </Stack>

                <TextareaAutosize placeholder="Add Note...." className={"peer-notes"} value={peerReviewNote} onChange={evt => setPeerReviewNote(evt.target.value)} minRows={4}/>


                <div className={"action-bar"}>
                    <Button variant={"outlined"}
                            onClick={saveNote}
                    >Save</Button>
                </div>
                </Stack>
                </CardContent>
            </Card>
            }
        </div>
        <div>
            {isUserRatingExist ? 
            <FacilityAllRatings addUserRatings={addUserRatings} editUserRatings={editRatings} allRatings={currentUserRating} ratingTitle={"User Rating"} isEditable={true} />
            : '' }
        </div>
        {allRatings.length > 0 ? 
        <div>
            <FacilityAllRatings addUserRatings={addUserRatings} editUserRatings={editRatings} allRatings={allRatings} ratingTitle={"All Ratings"} isEditable={false} />
        </div>
        : ''}
    </>
}

export default FacilityRatings;