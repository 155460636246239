import React from "react";
import {ShipwellEventProps, StopCreatedDetails} from "./types";
import {EventTitle} from "./EventTypeDispatcher";

const ShipmentStopCreated = ({event, title}: ShipwellEventProps) => {
    const details = JSON.parse(event.details)["1"] as StopCreatedDetails;

    return <div>
        <EventTitle>{title}</EventTitle>
        <div>{details.location.city}, {details.location.state_or_province}</div>
    </div>
}

export default ShipmentStopCreated;