import React from 'react';
import {Redirect, Route} from "react-router-dom";

/*
    Because the oauth token can be present but expired, we need to test it by
    loading user details, among other potential data. This creates 3 possible states:

    * Authenticated and loaded user details (authenticated && !loading) - final state
    * Authenticated but loading user details (authenticated && loading)
    * Not Authenticated or could not get user details (redirect to login)
 */
const PrivateRoute = ({component: Component, authenticated, loading, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (authenticated && !loading) {
                return <Component {...rest} {...props} />;
            } else if (authenticated && loading) {
                return <div>Retrieving current user information</div>
            } else {
                console.log('redirecting to login');
                return <Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}/>
            }
        }}
    />
);

export default PrivateRoute;
