import React from "react";
import {ShipwellEventProps, TimelineEventDetails} from "./types";
import {EventTitle} from "./EventTypeDispatcher";

const TimelineEventCreated = ({event}: ShipwellEventProps) => {

    const data = JSON.parse(event.details)["1"] as TimelineEventDetails;

    return <div>
        <EventTitle>Timeline Event Created</EventTitle>
        <div>{data.description}</div>
    </div>;
}

export default TimelineEventCreated;