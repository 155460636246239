import React from "react";
import {TaskWithOptions} from "./workflow";
import {styled} from "@mui/system";
import Moment from "react-moment";
import {getTimeFormat} from "../service/time";
import {TorchShipmentExternalLink} from "../common/Links";

type TaskProps = {
    task: TaskWithOptions;
}

const DetailsLayout = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 0;
`

const LoadTitle = styled('div')`
  margin: 10px 10px 0;
  font-size: 1.1rem;
`

const TaskDetails = ({task}: TaskProps) => {

    const load = task.task.load;

    return <>
        <LoadTitle><TorchShipmentExternalLink
            shipwellId={load.shipwellId}>{load.name} - {load.shipwellRefId}</TorchShipmentExternalLink></LoadTitle>
        <DetailsLayout>
            <div>
                <h4>Carrier</h4>
                <div>{load.carrier.name}</div>
                <div>Cell: {load.driverPhoneNumber}</div>
                <div>Notes: {load.notesForCarrier}</div>
            </div>
            {task.task.load.stops.map(stop => <div key={stop.stopCount}>
                <h4>{stop.pickup ? "Pickup" : stop.dropoff ? "Delivery" : "Intermediate"}</h4>
                <div>{stop.address.streetOne}</div>
                <div>{stop.address.city}, {stop.address.state}</div>
                <div><Moment format={getTimeFormat()} tz={stop.timeZoneId}>{stop.arrival}</Moment></div>
                <div>Facility Details</div>
            </div>)}
        </DetailsLayout>
    </>
}

export default TaskDetails;