import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {StyledEngineProvider} from '@mui/material/styles';
import {BrowserRouter, withRouter} from "react-router-dom";


import * as serviceWorker from './serviceWorker';

const AppWithRouter = withRouter(App)

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <AppWithRouter/>
            </StyledEngineProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


/*
const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);
root.render(<React.StrictMode>
    <BrowserRouter>
        <StyledEngineProvider injectFirst>
            <AppWithRouter/>
        </StyledEngineProvider>
    </BrowserRouter>
</React.StrictMode>);
 */