import {Button, Checkbox, FormControlLabel, FormGroup, TextField, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import {declineLoad} from "../../util/APIUtils";
import {Carrier} from "../../interfaces/Carrier";
import {NewFeedback, SkinnyLoad} from "../../interfaces/Load";
import {sendFeedback} from "../../service/loads";
import {styled} from "@mui/system";
import {JETPACK_BOOK_LOAD_INTENT, postCarrierUsageEvent} from "../../service/events";

type PropTypes = {
    loadDeclined: string,
    carrier: Carrier,
    load: SkinnyLoad,
    returnToCarrierList: () => void
}

const SmallText = styled(Typography)`
  font-style: italic;
  font-size: 0.9em;
  margin-top: 10px;
`

export const OutcomeViewStep = (props: PropTypes) => {
    return props.loadDeclined === "no" ? <OutcomeAcceptView load={props.load} carrier={props.carrier}/> :
        props.loadDeclined === "yes" ? <OutcomeDeclineView {...props}/> :
            <div className={"not-valid"}>You need to indicate acceptance or decline before this step</div>;
}

const OutcomeDeclineView = ({carrier, load, returnToCarrierList}: PropTypes) => {

    const [checked, setChecked] = useState({});
    const [dnuChecked, setDnuChecked] = useState(false);
    const [dnuReason, setDnuReason] = useState("");

    const [submitted, setSubmitted] = useState(false);
    const validated = Object.values(checked).some(v => v === true) &&
        (!dnuChecked || (dnuChecked && dnuReason.length > 0));

    const declineReasons = [
        {
            label: "Lane",
            value: "route"
        },
        {
            label: "Rate Issue",
            value: "rate"
        },
        {
            label: "Won't Haul Commodity",
            value: "commodity"
        },
        {
            label: "Weight",
            value: "weight"
        },
        {
            label: "No Truck",
            value: "no-truck"
        },
        {
            label: "Other",
            value: "other"
        }
    ]

    const decline = () => {
        if (!validated) {
            setSubmitted(true);
            return;
        }
        const newFeedback: NewFeedback = {
            type: "WARNING",
            notes: dnuReason,
            declineReasons: null,
            expiration: null
        };
        let feedbackPromise = (dnuChecked && dnuReason !== "") ?
            sendFeedback(load.id, carrier.id, newFeedback)
         : Promise.resolve();

        let declinePromise = declineLoad({
            loadId: load.id,
            carrierId: carrier.id,
            reasons: Object.keys(checked).filter(value => checked[value] === true)
        });

        Promise.all([feedbackPromise, declinePromise])
            .then(() => {
            returnToCarrierList();
        });
    }

    const declineCheckboxChange = (e) => {
        checked[e.target.name] = e.target.checked;
        setChecked({...checked});
    }

    const dnuReasonChanged = (e) => {
        setDnuReason(e.target.value);
    }

    return <div className={"outcome-container"}>
        <div className={"reasons"}>
            <div className={"header"}>Reasons</div>
            <FormGroup>
                {declineReasons.map(r => <FormControlLabel key={r.value}
                                                           control={<Checkbox size={"small"}
                                                                              sx={{m: -0.25}}
                                                                              checked={checked[r.value]||false}
                                                                              onChange={declineCheckboxChange}
                                                                              name={r.value}/>}
                                                           label={r.label}
                />)}
            </FormGroup>
        </div>
        <div className={"future"}>
            <div className={"header"}>Future</div>
            <FormGroup>
                <FormControlLabel control={<Checkbox size={"small"}/>}
                                  disabled={true}
                                  label="Contact Again"/>
                <FormControlLabel control={<Checkbox
                    checked={dnuChecked}
                    onChange={(e)=>setDnuChecked(!dnuChecked)}
                    name={"do_not_contact"}
                    size={"small"}/>} label="Don't Contact"/>
                <TextField label={"Reason"} value={dnuReason} onChange={dnuReasonChanged}
                           required={dnuChecked}
                           disabled={!dnuChecked}
                           error={submitted && dnuChecked && !dnuReason}
                           size={"small"} sx={{width: "80%", mt: 1}}/>
            </FormGroup>
        </div>
        <div className={"complete"}>
            <div className={"header"}>Next Up!</div>
            <Tooltip title={submitted && !validated ? "Give Reason(s) for Declining / Don't Contact" : ""}>
            <span>
            <Button variant="outlined"
                    onClick={decline}
                    disabled={!validated}>Finish and Return to List</Button>
                </span>
            </Tooltip>
            {!validated ? <SmallText>At least one reason must be checked</SmallText> : null}
        </div>
    </div>
}

type OutcomeAcceptViewParams = {
    load: SkinnyLoad;
    carrier: Carrier;
}

const OutcomeAcceptView = ({load, carrier}: OutcomeAcceptViewParams) => {

    const [loadboardReason, setLoadboardReason] = useState(false);
    const [rateReason, setRateReason] = useState(false);
    const [laneReason, setLaneReason] = useState(false);
    const [otherReason, setOtherReason] = useState("");
    const [contactForOrigin, setContactForOrigin] = useState(false);
    const [contactForDestination, setContactForDestination] = useState(false);


    const handleLoadboardReasonSelection = (event) => {
        setLoadboardReason(!loadboardReason);
    }
    const handleRateReasonSelection = (event) => {
        setRateReason(!rateReason);
    }
    const handleLaneReasonSelection = (event) => {
        setLaneReason(!laneReason);
    }

    const handleContactForOrigin = (event) => {
        setContactForOrigin(!contactForOrigin);
    }
    const handleContactForDestination = (event) => {
        setContactForDestination(!contactForDestination);
    }

    const handleOtherReason = (event) => {
        setOtherReason(event.target.value);
    }

    function goToTMS() {
        postCarrierUsageEvent({
            type: JETPACK_BOOK_LOAD_INTENT,
            loadId: load.id,
            carrierId: carrier.id,
            loadboardReason:loadboardReason,
            rateReason:rateReason,
            laneReason:laneReason,
            contactForOrigin:contactForOrigin,
            contactForDestination:contactForDestination,
            otherReason:otherReason
        });

        window.open("https://torch.shipwell.com/shipments/" + load.shipwellId, "_blank");
    }

    return <div className={"outcome-container"}>
        <div className={"reasons"}>
            <div className={"header"}>Reasons</div>
            <FormGroup>
                <FormControlLabel control={<Checkbox size={"small"} checked={loadboardReason} onClick={(event) => handleLoadboardReasonSelection(event)}/>} label="Loadboard"/>
                <FormControlLabel control={<Checkbox size={"small"} checked={rateReason} onClick={(event) => handleRateReasonSelection(event)}/>} label="Rate"/>
                <FormControlLabel control={<Checkbox size={"small"} checked={laneReason} onClick={(event) => handleLaneReasonSelection(event)}/>} label="Lane"/>
                <TextField onChange={(event) => handleOtherReason(event)} label={"Other"} size={"small"} defaultValue={otherReason}  sx={{width: "80%", mt: 1}}/>
            </FormGroup>
        </div>
        <div className={"future"}>
            <div className={"header"}>Future</div>
            <FormGroup>
                <FormControlLabel control={<Checkbox size={"small"} checked={contactForOrigin} onClick={(event) => handleContactForOrigin(event)}/>} label="Contact for Origin"/>
                <FormControlLabel control={<Checkbox size={"small"} checked={contactForDestination} onClick={(event) => handleContactForDestination(event)}/>} label="Contact for Destination"/>
                {/*<FormControlLabel control={<Checkbox size={"small"}/>} label="Both"/>*/}
            </FormGroup>
        </div>
        <div className={"complete"}>
            <div className={"header"}>Complete Booking</div>
            <Button variant="outlined" onClick={goToTMS}>Go to TMS</Button>
        </div>
    </div>
}

