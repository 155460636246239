import {EDILoadTenderWithUpdates} from "../interfaces/TenderedLoad";
import {RateWithHistorical} from "../interfaces/RateData";
import React, {useState} from "react";
import {useAbortableEffect} from "../util/hooks";
import {getRate} from "../service/lane";
import TenderLaneRateInfo from "./TenderLaneRateInfo";

import "./TenderRates.scss";
import Loading from "../common/Loading";

// function TenderAcceptanceRates({award}: { award: ShipperAward }) {
//
//     const [acceptanceRates, setAcceptanceRates]:
//         [acceptanceRates: AcceptanceRates | undefined, setAcceptanceRates: Function] = useState();
//
//     useAbortableEffect((status) => {
//         if (award) {
//             getAcceptanceRate(award.id).then(rates => {
//                 if (!status.aborted) {
//                     setAcceptanceRates(rates);
//                 }
//             });
//         }
//     }, [award]);
//
//     return <div className={"acceptance-rates-container"}>
//         <div>
//             <div className={"label"}>Lane Acceptance Rate</div>
//             <div className={"ar-rate"}>{acceptanceRates?.acceptanceRate}</div>
//         </div>
//
//         <div>
//             <div className={"label"}>Lane Volume</div>
//             <div className={"ar-volume"}>{acceptanceRates?.award.volume}</div>
//         </div>
//
//         <div>
//             <div className={"label"}>Accepted (this week)</div>
//             <div className={"ar-accepted"}>{acceptanceRates?.accepted}</div>
//         </div>
//
//         <div>
//             <div className={"label"}>Total (this week)</div>
//             <div className={"ar-total"}>{acceptanceRates?.total}</div>
//         </div>
//     </div>;
// }

const TenderRates = ({tender}: { tender: EDILoadTenderWithUpdates }) => {

    const [rateData, setRateData]: [rateData: RateWithHistorical | undefined, setRateData: Function] = useState();
    const [canGetRate, setCanGetRate] = useState(false);

    useAbortableEffect((status) => {
        if (tender) {
            const load = tender.tender;
            const origin = (load.stops && load.stops.length > 0) ? {zipCode: load.stops[0].postalCode} : undefined;
            const destination = (load.stops && load.stops.length > 0) ? {zipCode: load.stops[load.stops.length - 1].postalCode} : undefined;
            if (origin?.zipCode && destination?.zipCode) {
                setCanGetRate(true);
                getRate(origin, destination, load.payloadType).then(rate => {
                    if (!status.aborted) {
                        setRateData(rate);
                    }
                });
            }
        }
    }, [tender]);

    if(!canGetRate) {
        return null;
    }

    if (rateData && tender) {
        let cost = tender.tender.transactionAmount;
        return <div className={"tender-rate"}>
            <TenderLaneRateInfo rate={rateData} cost={cost} miles={tender.tender.miles}/>
        </div>;
    } else {
        return <Loading />
    }
}

export default TenderRates
