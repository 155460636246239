import React, {useState} from "react";
import {Box, Button, Modal} from "@mui/material";
import {styled} from "@mui/material/styles";
import OpenLoads from "./OpenLoads";
import {useHistory, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {routeMapAvailableState, showRouteMapState} from "./openloads.state";
import {JETPACK_OPEN_LANE_MAP, JETPACK_OPEN_LANE_SWITCH_DIALOG, postUsageEvent} from "../service/events";
import {Load} from "../interfaces/Load";

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  grid-area: actions;
`

const TButton = styled(Button)`
  flex-grow: 1;
`

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1175px;
  height: 80%;
  overflow: scroll;
  background-color: #f8f8f8;
  border: 1px solid #333;
  box-shadow: inset;
  padding: 10px;
`

type LoadActionsParams = {
    load: Load;
}

const LoadActions = ({load}: LoadActionsParams) => {

    const [open, setOpen] = useState(false);
    const {carrierId}: { carrierId?: string | undefined } = useParams();

    const [showRouteMap, setShowRouteMap] = useRecoilState(showRouteMapState);
    const [routeMapAvailable] = useRecoilState(routeMapAvailableState);
    
    const history = useHistory();

    const handleOpen = () => {
        postUsageEvent({
            type: JETPACK_OPEN_LANE_SWITCH_DIALOG,
            loadId: load.id
        });
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const swapLoad = (skinnyLoad) => {
        handleClose();
        if (carrierId) {
            history.push(`/open-loads/${skinnyLoad.id}/carrier/${carrierId}`);
        } else {
            history.push(`/open-loads/${skinnyLoad.id}`);
        }
    }

    const handleShowRoute = () => {
        postUsageEvent({
            type: JETPACK_OPEN_LANE_MAP,
            loadId: load.id
        });
        setShowRouteMap(true);
    }

    return <Wrapper>
        <TButton variant={"outlined"}
                 color={"secondary"}
                 onClick={handleOpen}>Different Load</TButton>

        <TButton variant={"outlined"}
                 color={"primary"}
                 onClick={handleShowRoute}
                 disabled={!routeMapAvailable || showRouteMap}>Show Route Map</TButton>

        <Modal
            open={open}
            onClose={handleClose}
            aria-describedby="Change the currently selected load and keep this carrier">
            <ModalBox>
                <OpenLoads onLoadSelected={swapLoad}/>
            </ModalBox>
        </Modal>
    </Wrapper>
}

export default LoadActions;

