import {RateWithHistorical} from "../interfaces/RateData";

/**
 * Return a currency string for the given value in dollar with
 * cents
 */
export function formatRate(rate: number, showCurrency:boolean = true): string {
    return (showCurrency ? "$" : "") + rate.toFixed(2);
}

export function formatCentsRate(rate: number): string {
    return "$" + (rate / 100).toFixed(2);
}

/**
 * Gives margin in fractional form
 */
export function getMargin(rate: RateWithHistorical, customerCost: number): number {
    const rev = customerCost / 100; // customerCost is in cents
    return (rev - rate.rate.rate) / rev;
}
