import React, {useEffect, useState} from "react";
import Loading from "../common/Loading";
import {styled} from "@mui/system";
import {Bid} from "../interfaces/Bids";
import {getBidsWithPage} from "../service/bids";
import BidList from "./BidList";
import Button from "@mui/material/Button";

const QueryControl = styled('div')`
  display: flex;
`

const ReloadButton = styled(Button)`
  margin-left: auto;
  margin-bottom: 10px;
`

const QueryResults = styled('div')`
 margin-bottom:10px;
`

const BidSearch = () => {

    const PAGE_SIZE: number = 50;
    const [bids, setBids] = useState<Bid[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageSize] = useState(PAGE_SIZE);

    useEffect(() => {
        setLoading(true);
        getBidsWithPage((pageSize / PAGE_SIZE) - 1, PAGE_SIZE * 6).then(l => {
            setBids(l);
            setLoading(false);
        }).catch(_ => setLoading(false));
    }, [pageSize]);

    const redoPageSize = (currentPage: number) => {
        setLoading(true);
        getBidsWithPage((pageSize / PAGE_SIZE) - 1, PAGE_SIZE * 6).then(l => {
            setBids(l);
            setLoading(false);
        }).catch(_ => setLoading(false));
    }

    const BidListContainer = () => {

        if (loading) {
            return <Loading/>
        }

        if (bids && bids.length > 0) {
            return <>
                <QueryControl>
                    <ReloadButton variant="outlined"
                            sx={{padding: "6px 20px"}}
                            onClick={() => redoPageSize(0)}>Reload</ReloadButton>
                </QueryControl>
                <QueryResults>
                    <BidList bids={bids} loading={loading}></BidList>
                </QueryResults>
            </>
        }
        return <Loading/>
    }

    return <BidListContainer></BidListContainer>
}

export default BidSearch;
