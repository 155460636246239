import React from "react";
import {TextField} from "@mui/material";
import ControlWrapper from "./ControlWrapper";
import {TaskField} from "../workflow";
import {FieldValueChanged} from "../Outcome";

type FieldProps = {
    field: TaskField,
    onChange: FieldValueChanged,
    value: string
};

const WorkflowTextField = ({field, onChange, value}: FieldProps) => {
    return <ControlWrapper>
        <TextField id={field.name}
                   label={field.label}
                   variant="outlined"
                   required={field.required}
                   value={value}
                   fullWidth
                   onChange={(e) => onChange(field.name, e.target.value)}/>
    </ControlWrapper>;
}

export default WorkflowTextField;