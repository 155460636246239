import React from "react";
import "./Settings.scss"
import Typography from "@mui/material/Typography";

const SettingsTasks = () => {

    return <div className={"settings-tasks"}>
        <Typography variant="h6" className={"title"} noWrap>
            Tasks
        </Typography>
        <Typography variant="body1" className={"content"} noWrap>
            Will add an option to default to infinite scrollable task list
        </Typography>
    </div>;
}

export default SettingsTasks;
