
/* Some of this can probably be dropped if the carrier has hauled this commodity with us before? */
import React, {useState} from "react";
import {
    Checkbox, FormControl,
    FormControlLabel,
    FormGroup, InputAdornment,
    TextField, Typography
} from "@mui/material";
import {TButton} from "./ContactContainer";

type PropTypes = {
    decline: (rate: string) => void,
    accept: (rate: string) => void
}

export const AskQuestionsStep = ({decline, accept}: PropTypes) => {

    // const [contact, setContact] = useState();
    // const [selectedContact, setSelectedContact] = useState("Select One");
    // const [pastContacts, setPastContacts] = useState([{value: "Select One"}, {value: "John"}, {value: "Suzanne"}, {value: "Ralph"}]);
    const [rate, setRate] = useState("");
    const [rateError, setRateError] = useState(false);
    const [rateLabel, setRateLabel] = useState('Rate Requested');

    const handleRateChange = function(e) {
        if (isNaN(e.target.value)) {
            setRateError(true);
            setRateLabel('Rate Requested Must be a Number');
        } else {
            setRateError(false);
            setRateLabel('Rate Requested');
        }

        setRate(e.target.value);
    }
/*    const selectContact = (evt) => {
        setContact(evt.target.value);
        setSelectedContact(evt.target.value);
        // if(updateContact) updateContact(evt.target.value.slice());
    }

    const contactNameChanged = (e) => {
        setContact(e.target.value);
    }*/

/*    const keyPressed = (e) => {
        if(e.keyCode === 13){
            console.log([{value: e.target.value}, ...pastContacts]);
            setPastContacts([{value: e.target.value}, ...pastContacts]);
             setSelectedContact(e.target.value);
             if(updateContact) {
                 updateContact(e.target.value.slice());
             }
        } else {
             setContact(e.target.value);
        }
    }*/

    /** Section can be removed in the mvp */
    return <div className={"step-container"}>
        <div className={"negotiation-contact-container"}>
            {/*<div className={"contact section"}>
                <div className={"header"}>Contact Details</div>
                <FormGroup>
                    <div className={"even-split"}>
                        <TextField size={"small"} label={"Contact's Name"} value={contact} onKeyDown={keyPressed} onChange={contactNameChanged}/>
                        <FormControl>
                            <InputLabel id="previous-contacts-label">Previous Contacts</InputLabel>
                            <Select labelId="previous-contacts-label"
                                    id="previous-contacts-select"
                                    label={"Previous Contacts"}
                                    value={selectedContact}
                                    size={"small"}
                                    onChange={selectContact}>
                                {pastContacts.map(c =>
                                    <MenuItem value={c.value} key={c.value}>{c.value}</MenuItem>
                                )};
                            </Select>
                        </FormControl>
                    </div>
                    <RadioGroup row={true} defaultValue={"call"} sx={{marginLeft: "3px", marginTop: "7px"}}>
                        <FormControlLabel control={<Radio size={"small"}/>} label="Phone" value={"call"}/>
                        <FormControlLabel control={<Radio size={"small"}/>} label="Email" value={"email"}/>
                    </RadioGroup>
                </FormGroup>
            </div>*/}
            <div className={"rates section"}>
                <div className={"header"}>Suitability</div>
                <FormGroup>
                    <FormControlLabel control={<Checkbox size={"small"}/>} label="Commodity"/>
                    <FormControlLabel control={<Checkbox size={"small"}/>} label="Equipment Available"/>
                    <FormControl fullWidth={true}>
                        <TextField error={rateError}
                                   label={rateLabel}
                                   value={rate} id="rate-quote-input" onChange={handleRateChange}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>
                                   }}
                                   size={"small"} sx={{width: "80%", mt: 1}}
                        />
                    </FormControl>
                </FormGroup>
            </div>
            <div className={"action section"}>
                <div className={"header"}>Result</div>
                <Typography variant={"body1"} mt={1} mb={1}>Will They Take the Load?</Typography>
                <TButton variant={"outlined"} color={"secondary"} onClick={() => decline(rate)}>No</TButton>
                <TButton variant={"outlined"} color={"success"} onClick={() => accept(rate)}>Yes</TButton>
            </div>
        </div>
    </div>
}
