import React, {useState} from "react";
import {TaskOption} from "./workflow";
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Backdrop, Button} from "@mui/material";
import OutcomeTitle from "./OutcomeTitle";
import SimpleOutcomeForm from "./SimpleOutcomeForm";
import Loading from "../common/Loading";

type OutcomesProps = {
    options: TaskOption[];
    working: boolean;
    submitTaskHandler: (option: TaskOption, data: Map<string, string>) => void;
};

const OutcomeAccordion = ({options, submitTaskHandler, working}: OutcomesProps) => {
    const [data, setData] = useState<Map<TaskOption, Map<string, string>>>(new Map());

    function dataChangedHandler(option: TaskOption, updated: Map<string, string>) {
        setData(new Map(data).set(option, updated));
    }

    function submitTaskData(option: TaskOption) {
        const taskData = data.get(option);
        if (taskData) {
            submitTaskHandler(option, taskData);
        }
    }

    return <>
        {options.map((option, idx) =>
            <Accordion key={option.name} defaultExpanded={idx === 0}>
                <AccordionSummary sx={{borderBottom: "1px dotted #ddd"}}>
                    <OutcomeTitle title={option.name} successful={option.successful}/>
                </AccordionSummary>
                <AccordionDetails>
                    <Backdrop open={working}
                              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Loading />
                    </Backdrop>
                    <SimpleOutcomeForm option={option}
                                       dataChangedHandler={dataChangedHandler}/>
                </AccordionDetails>
                <AccordionActions sx={{borderTop: "1px dotted #ddd"}}>
                    <Button onClick={() => submitTaskData(option)}>Submit</Button>
                </AccordionActions>
            </Accordion>
        )}
    </>
}

export default OutcomeAccordion;