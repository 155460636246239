import React, {useState} from "react";
import "./Facilities.scss";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {styled} from "@mui/system";
import FacilitiesListPanel from "./FacilitiesListPanel";

const FacilityTabPanel = styled(TabPanel)`
  padding: 15px 0;
`

const Facilities = () => {

    const [value, setValue] = useState('facilities');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <div className={"facilities-main-container"}>
        <h1>Facilities</h1>
        <div className={"functional-container"}>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Facilities" value="facilities"/>
                            <Tab label="Incomplete Facilities" value="incomplete"/>
                        </TabList>
                    </Box>
                    <FacilityTabPanel value="facilities">
                        <FacilitiesListPanel incomplete={false} />
                    </FacilityTabPanel>
                    <FacilityTabPanel value="incomplete">
                        <FacilitiesListPanel incomplete={true} />
                    </FacilityTabPanel>
                </TabContext>
            </Box>
        </div>
    </div>
}

export default Facilities;
