import React from "react";

import {CartesianGrid, ErrorBar, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {format} from "date-fns";
import RateChartToolTip from "./RateChartToolTip";

const dateFormatter = (date) => {
    return format(new Date().setTime(date), "MMM");
};

const moneyFormatter = (rate) => {
    return "$" + Math.round(rate);
}

const RateChart = ({data, width, height, showTickY, showTickX, domainX, domainY, axisLineY, header}) => {
    axisLineY = axisLineY === undefined ? true : axisLineY;
    showTickY = showTickY === undefined ? true : showTickY;
    showTickX = showTickX === undefined ? true : showTickX;
    domainX = domainX === undefined ? ["auto", "auto"] : domainX;
    domainY = domainY === undefined ? ["auto", "auto"] : domainY;
    height = height === undefined ? 400 : height;

    const getHeader = () => {
        if(header === undefined) {
            return null;
        }  else {
            return <div className={"chart-header"}>{header}</div>
        }
    }

    if (data.length > 0) {
        return (
            <div className={"chart-container"}>
                {getHeader()}
                <ResponsiveContainer width="99%" height={height}>
                    <LineChart width={width} height={height} data={data}>
                        <Line
                            name="Rate"
                            type="linear"
                            dataKey="rate"
                            stroke="darkblue"
                            strokeWidth={2}
                        >
                            <ErrorBar dataKey="range"/>
                        </Line>
                        <CartesianGrid stroke="#ddd" strokeDasharray="2 2"/>
                        <XAxis
                            domain={domainX}
                            dataKey="timeIndex"
                            tickFormatter={dateFormatter}
                            type={"number"}
                            tick={showTickX}
                            scale="time"
                        />
                        <YAxis
                            domain={domainY}
                            tick={showTickY}
                            tickFormatter={moneyFormatter}
                            axisLine={axisLineY}
                            width={showTickY ? 55 : 0}
                        />
                        <Tooltip content={<RateChartToolTip/>}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    } else {
        return null;
    }
};

export default RateChart;
