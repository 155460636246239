import React from "react";

type MailToProps = {
    email: string;
    children: React.ReactNode;
}

const MailToLink = ({email, children = "Send Email"}: MailToProps) => {
    return <a href={`mailto:${email}`}>{children}</a>
}

export default MailToLink;