import React from "react";
import {Load} from "../interfaces/Load";
import {styled} from "@mui/system";

interface Params {
    load: Load;
}

const City = styled('span')`
  text-transform: capitalize;
`

const State = styled('span')`
  text-transform: uppercase;
`

const LoadDescription = ({load}: Params) => {
    const first = load.stops[0].address;
    const last = load.stops[load.stops.length - 1].address;
    return <>
        {/* to lower case since some of our stop cities are all caps */}
        <City>{first.city?.toLowerCase()}</City> <State>{first.state}</State>
        &nbsp;&#8594;&nbsp;
        <City>{last.city?.toLowerCase()}</City> <State>{last.state}</State>
    </>
}

export default LoadDescription;