import React from "react";
import {SkinnyLoad} from "../interfaces/Load";
import {Card, CardActionArea, CardContent} from "@mui/material";
import {getLoadLabel, getShortDescription} from "../util/load-utils";
import Moment from "react-moment";
import {TruckType} from "../common/TruckType";
import {Tags} from "../common/Tags";
import {Torchers} from "../common/Torchers";

type PropTypes = {
    load: SkinnyLoad,
    selected: ((load: SkinnyLoad) => void)
}

const LoadCard = ({load, selected}: PropTypes) => {

    const activityCountClass = (count) => {
        if(count > 15) {
            return "high";
        } else if(count > 7) {
            return "med";
        } else {
            return "low";
        }
    }

    const activityFilter = (message, load) => { return message.type === "LOAD" && parseInt(message.content) === load.id;};

    return (<Card className={"load-card " + activityCountClass(load.activityCount)}>
        <CardActionArea onClick={() => selected(load)}>
            <CardContent className={"card-content"}>
                <div className={"title"}>{getLoadLabel(load)}</div>
                <div>
                    <Torchers task={load} applyFilter={activityFilter}/>
                </div>
                <div className={"card-content-grid"}>
                    <div className={"load-details"}>
                        <div className={"date-commodity"}>
                            <Moment format={"dddd"}>{load.stops[0].arrival}</Moment> <Moment
                            format={"MMM D"}>{load.stops[0].arrival}</Moment>
                        </div>
                        <div className={"card-line"}>
                            {getShortDescription(load)}
                        </div>
                        <div className={"card-line"}>
                            <TruckType task={load}/> {load.miles < 650 ?
                            <span className={"short-haul-label"}> Short Haul</span> : null}
                        </div>
                        <Tags task={load}/>
                    </div>
                    <div className={"load-activity-count"}>
                        <div className={activityCountClass(load.activityCount)}>
                            {load.activityCount}
                        </div>
                    </div>
                </div>
            </CardContent>
        </CardActionArea>
    </Card>)
}

export default LoadCard;
