import React, {useEffect, useState} from 'react';
import {Carrier} from "../interfaces/Carrier";
import {Button, Snackbar, TextField} from "@mui/material";
import {getLoadboardSetting, saveLoadboardSetting} from "../service/carriers";
import {LOADY_URL} from "../constants";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import {Load} from "../interfaces/Load";

type PropTypes = {
    carrier: Carrier
    load?:Load
}

export const CarrierLoadboardSettings = ({carrier, load}: PropTypes) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({
        value: '',
        isError: false,
        helperText: ''
    });
    const [phone, setPhone] = useState({
        value: '',
        isError: false,
        helperText: ''
    });
    const [email, setEmail] = useState({
        value: '',
        isError: false,
        helperText: ''
    });

    useEffect(() => {
        getLoadboardSetting(carrier.id).then(rs => {
            updateMessage(rs.greetingMessage);
            updatePhone(rs.phone);
            updateEmail(rs.email);
        });
    }, [carrier.id]);

    const handleClick = () => {
        copyLinkToClipboard();
        saveLoadboardSetting(carrier.id, {
            greetingMessage: message.value,
            phone: phone.value,
            email: email.value
        }).then(response => {
            console.log("Saved Loadboard Setting");
        })
    }

    function copyLinkToClipboard() {
        navigator.clipboard.writeText(LOADY_URL + `/${carrier.publicId}`);
        setOpen(true);
    }


    function copyLoadLinkToClipboard() {
        navigator.clipboard.writeText(LOADY_URL + `/load/${carrier.publicId}/${load?.shipwellRefId}`);
        setOpen(true);
    }

    function updateMessage(val) {
        const isError = val && val.length < 15;
        setMessage({
            value: val,
            isError: isError,
            helperText: isError ? 'Message must be at least 15 characters' : ''
        });
    }

    function updatePhone(val) {
        const isError = !isMobilePhone(val);
        setPhone({
            value: val,
            isError: isError,
            helperText: isError ? 'Not a valid phone number' : ''
        });
    }

    function updateEmail(val) {
        const isError = !isEmail(val);
        setEmail({
            value: val,
            isError: isError,
            helperText: isError ? 'Not a valid e-mail address' : ''
        });
    }

    return <div className={"carrier-loady"}>
        <div className={"loady-body"}>
            <div className={"loady-item"}>
                <TextField
                    id={'loadboardMessage'}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={3}
                    required={true}
                    error={message.isError}
                    helperText={message.helperText}
                    label={'Message for Carrier (Include your contact details)'}
                    value={message.value}
                    onChange={(evt) => updateMessage(evt.target.value)}
                />
            </div>
            <div className={"loady-item"}>
                <TextField
                    fullWidth
                    id={'phone'}
                    variant="outlined"
                    sx={{marginTop: "10px"}}
                    label={'Your phone number'}
                    value={phone.value}
                    required={true}
                    error={phone.isError}
                    helperText={phone.helperText}
                    onChange={(evt) => updatePhone(evt.target.value)}
                />
            </div>
            <div className={"loady-item"}>
                <TextField
                    fullWidth
                    id={'email'}
                    variant="outlined"
                    sx={{marginTop: "10px"}}
                    label={'Your e-mail address'}
                    value={email.value}
                    required={true}
                    error={email.isError}
                    helperText={email.helperText}
                    onChange={(evt) => updateEmail(evt.target.value)}
                />
            </div>
            <div className={"loady-action-bar"}>
                <Button color={"secondary"}
                        onClick={handleClick}
                        variant={"outlined"}>
                    Save and Copy link
                </Button>
                <Button onClick={copyLinkToClipboard}
                        variant={"outlined"}
                        sx={{marginLeft: "15px"}}>
                    Copy Link to Clipboard
                </Button>
                {load ?
                    <Button onClick={copyLoadLinkToClipboard}
                            variant={"outlined"}
                            sx={{marginLeft: "15px"}}>
                        Copy Link with Load to Clipboard
                    </Button>
                    :
                    <></>
                }
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={open}
                    onClose={() => setOpen(false)}
                    autoHideDuration={2000}
                    message="Copied to clipboard"
                />
            </div>
        </div>
    </div>
}
