import React from "react";
import {CarrierAssignedDetails, ShipwellEventProps} from "./types";
import {EventTitle} from "./EventTypeDispatcher";

const CarrierAssigned = ({event, title}: ShipwellEventProps) => {
    const details = JSON.parse(event.details)["1"] as CarrierAssignedDetails;

    return <div>
        <EventTitle>{title}</EventTitle>
        <div>(See Details) {details.vendor_id}</div>
    </div>
}

export default CarrierAssigned;