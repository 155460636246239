import {ShipwellEventProps} from "./types";
import {EventTitle} from "./EventTypeDispatcher";

const ShipmentCreated = ({event, title}: ShipwellEventProps) => {

    return <div>
        <EventTitle>{title}</EventTitle>
        <div>See Details for initial shipment state</div>
    </div>
}

export default ShipmentCreated;