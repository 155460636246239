import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {
    getContinuingLoadsEnabled,
    getMilitaryTimeDisplay, getRFPAutomatedLoadsEnabled, getTourAutomatedLoadsEnabled,
    setContinuingLoadsEnabled,
    setMilitaryTimeDisplay, setRFPAutomatedLoadsEnabled, setTourAutomatedLoadsEnabled
} from "../service/settings";


const SettingsGeneral = () => {

    const [militaryTime, setMilitaryTime] = useState<boolean>(false);
    const [continuingLoads, setContinuingLoads] = useState<boolean>(false);
    const [rfpAutomated, setRFPAutomated] = useState<boolean>(false);
    const [tourEnabled, setTourEnabled] = useState<boolean>(false);

    useEffect(() => {
        setMilitaryTime(getMilitaryTimeDisplay());
        setContinuingLoads(getContinuingLoadsEnabled());
        setRFPAutomated(getRFPAutomatedLoadsEnabled());
        setTourEnabled(getTourAutomatedLoadsEnabled());
    }, []);

    const handleMilitaryTimeChange = (e) => {
        setMilitaryTime(e.target.checked);
        setMilitaryTimeDisplay(e.target.checked);
    }

    const handleContinuingLoadsChange = (e) => {
        setContinuingLoads(e.target.checked);
        setContinuingLoadsEnabled(e.target.checked);
    }

    const handleRFPAutomatedChange = (e) => {
        setRFPAutomated(e.target.checked);
        setRFPAutomatedLoadsEnabled(e.target.checked);
    }

    const handleTourEnabledChange = (e) => {
        setTourEnabled(e.target.checked);
        setTourAutomatedLoadsEnabled(e.target.checked);
    }

    return <div className={"settings-general"}>
        <Typography variant="h6" className={"title"} noWrap>
            General
        </Typography>
        <Typography variant="body1" className={"content"} noWrap>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={militaryTime}
                                                     onChange={handleMilitaryTimeChange} />}
                                  label="Use military time for time display" />
            </FormGroup>
        </Typography>
        <Typography variant="body1" className={"content"} noWrap>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={continuingLoads}
                                                     onChange={handleContinuingLoadsChange} />}
                                  label="Enabled display of continuing loads in Open Loads view" />
            </FormGroup>
        </Typography>
        <Typography variant="body1" className={"content"} noWrap>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={rfpAutomated}
                                                     onChange={handleRFPAutomatedChange} />}
                                  label="Enabled display of RFP Automation Tools" />
            </FormGroup>
        </Typography>
        <Typography variant="body1" className={"content"} noWrap>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={tourEnabled}
                                                     onChange={handleTourEnabledChange} />}
                                  label="Torch Tours" />
            </FormGroup>
        </Typography>
    </div>;
}

export default SettingsGeneral;
