import { useEffect, useState } from "react";
import NoContentPanel from "../common/NoContentPanel";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import {LoadLink, TorchShipmentExternalLink } from "../common/Links";
import Moment from "react-moment";
import Loading from "../common/Loading";
import RocketIcon from '@mui/icons-material/Rocket';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import styled from "@emotion/styled";
import { FacilityPastUpcomingLoads } from "../interfaces/Facility";
import { getFaciliityLaods } from "./facility-services";

type FacilityId = {
    facilityId: number;
}

const RefId = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
  display: flex;
  align-items: center;
  column-gap: 5px;
`
const FacilityUpcomingLoadsContainer = ({facilityId} : FacilityId) => {
    const [loads, setLoads] = useState<FacilityPastUpcomingLoads[]>([]);
    
    useEffect(() => {
        getFaciliityLaods(facilityId, "FUTURE_LOADS").then(setLoads);
        // const testLoads = [
        //     {
        //         "shipwellId": "d20f46c0-0d5e-4eec-8504-5de0ee3bf803",
        //         "shipwellRefId": "FPSQ6N",
        //         "loadId": 1823353,
        //         "pickupDate": "2022-09-20T17:00:00Z",
        //         "status": "Tendered",
        //         "carrierName": "A. Duie Pyle"
        //     },
        //     {
        //         "shipwellId": "3f119090-8331-46e9-b72c-043dc12e3888",
        //         "shipwellRefId": "F3J5TN",
        //         "loadId": 1823286,
        //         "pickupDate": "2022-09-20T20:00:00Z",
        //         "status": "In Transit",
        //         "carrierName": "A. Duie Pyle"
        //     },
        //     {
        //         "shipwellId": "4543cf32-5358-4135-953a-20e0ed8a1f6e",
        //         "shipwellRefId": "UZAC4X",
        //         "loadId": 1823281,
        //         "pickupDate": "2022-09-20T20:00:00Z",
        //         "status": "In Transit",
        //         "carrierName": "A. Duie Pyle"
        //     },
        //     {
        //         "shipwellId": "ed798146-612c-4ac4-80a5-25dc79b20f09",
        //         "shipwellRefId": "M4R6GT",
        //         "loadId": 1821959,
        //         "pickupDate": "2022-09-19T02:57:43.581Z",
        //         "status": "Tendered",
        //         "carrierName": "A. Duie Pyle"
        //     },
        //     {
        //         "shipwellId": "6b4ecafb-c82d-485a-9613-d67ea4abd06b",
        //         "shipwellRefId": "MA835C",
        //         "loadId": 1566272,
        //         "pickupDate": "2022-09-20T15:29:58.124Z",
        //         "status": "In Transit",
        //         "carrierName": "A. Duie Pyle"
        //     },
        //     {
        //         "shipwellId": "d20f46c0-0d5e-4eec-8504-5de0ee3bf803",
        //         "shipwellRefId": "FPSQ6N",
        //         "loadId": 1823353,
        //         "pickupDate": "2022-09-20T17:00:00Z",
        //         "status": "Tendered",
        //         "carrierName": "A. Duie Pyle"
        //     },
        //     {
        //         "shipwellId": "3f119090-8331-46e9-b72c-043dc12e3888",
        //         "shipwellRefId": "F3J5TN",
        //         "loadId": 1823286,
        //         "pickupDate": "2022-09-20T20:00:00Z",
        //         "status": "In Transit",
        //         "carrierName": "A. Duie Pyle"
        //     }
        // ]
        // setLoads(testLoads)
    },[facilityId])

    if (loads && loads.length === 0) {
        return <NoContentPanel>No Upcoming Loads</NoContentPanel>
    }

    if (loads && loads.length > 0) {
        return (
            <div className={"past-loads-carrier"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Carrier</TableCell>
                            <TableCell>Pickup Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Links</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loads.map(l => (
                            <TableRow key={l.shipwellId}>
                                <TableCell className={"carrier-name"}>
                                    {l.carrierName}
                                </TableCell>
                                <TableCell>
                                        <Moment format={"MMM D"}>{new Date(l.pickupDate)}</Moment>
                                </TableCell>
                                <TableCell className={"carrier-name"}>
                                    {l.status}
                                </TableCell>
                                <TableCell align="left">
                                    <RefId>
                                        <div>
                                            <LoadLink loadId={l.loadId}>
                                                <Tooltip title={"JetPack Load View"}>
                                                    <RocketIcon fontSize={"small"}/>
                                                </Tooltip>
                                            </LoadLink>
                                        </div>
                                        <div>
                                            <TorchShipmentExternalLink shipwellId={l.shipwellId}>
                                                <Tooltip title={"Shipwell Load View"}>
                                                    <DirectionsBoatIcon fontSize={"small"}/>
                                                </Tooltip>
                                            </TorchShipmentExternalLink>
                                        </div>
                                        <div>{l.shipwellRefId}</div>
                                    </RefId>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>);
    }

    if (loads && loads.length === 0) {
        return <NoContentPanel>No Recent Activity</NoContentPanel>;
    }

    return (<div className={"loading"}>
        <Loading />
    </div>)
}

export default FacilityUpcomingLoadsContainer;