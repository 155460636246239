import React from "react";
import {SuggestedCarrier} from "../interfaces/SuggestedCarrier";
import {Load} from "../interfaces/Load";
import ContactContainer from "./contact/ContactContainer";
import CarrierIntel from "./CarrierIntel";
import CarrierDetails from "./CarrierDetails";

type PropTypes = {
    carrier: SuggestedCarrier;
    load: Load;
    openContactPanel: boolean;
    onContactComplete: () => void;
}

const CarrierDetailsContainer = ({carrier, load, openContactPanel, onContactComplete}: PropTypes) => {

    const gridClassName = openContactPanel ? "carrier-contact-intel-view" : "carrier-intel-view";

    return (<div className={gridClassName}>
        {openContactPanel ?
            <div className={"contact"}>
                <ContactContainer carrier={carrier.carrier}
                                  load={load}
                                  onContactComplete={onContactComplete}/>
            </div>
            : null}
        <div className={"address"}><CarrierDetails suggestedCarrier={carrier}/></div>
        <div className={"intel"}><CarrierIntel load={load} carrier={carrier.carrier} /></div>
    </div>);
}

export default CarrierDetailsContainer;
